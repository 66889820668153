import { Props } from "react-select";

const ConnectionSpeed: React.FC<Props> = ({ connection_speed }) => {
    return (
        <div>
            <div>
                <div className="system-meeting-data-subheading">Connection Speed</div>
                {Object.keys(connection_speed).map((key, index: number) => (
                    <div key={`connection-speed-${index}`}>
                        <div className="system-meeting-key-value" >
                            <div className="system-meeting-key">Upload Speed</div>
                            <div className="system-meeting-value">
                                {connection_speed[key]?.upload ? connection_speed[key]?.upload : "-----"}
                            </div>
                        </div>
                        <div className="system-meeting-key-value">
                            <div className="system-meeting-key">Download Speed</div>
                            <div className="system-meeting-value">
                                {connection_speed[key]?.download ? connection_speed[key]?.download : "-----"}
                            </div>
                        </div>
                        <div className="system-meeting-key-value">
                            <div className="system-meeting-key">Time</div>
                            <div className="system-meeting-value">
                                {connection_speed[key]?.timestamp ? (new Date(connection_speed[key]?.timestamp * 1000)).toLocaleString() : "-----"}
                            </div>
                        </div>
                        {(Object.keys(connection_speed).length - 1 !== parseInt(key)) && <hr />}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ConnectionSpeed;