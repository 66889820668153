import { Props } from "react-select";

const Geolocation: React.FC<Props> = ({ geolocation }) => {
    return (
        <div>
            <div className="system-meeting-data-subheading">Geolocation</div>
            <hr />
            <div>
                <div>
                    <div className="system-meeting-key-value">
                        <div className="system-meeting-key">IP</div>
                        <div className="system-meeting-value">
                            {!!geolocation?.ip ? geolocation?.ip : "-----"}
                        </div>
                    </div>
                    <hr />
                    <div className="system-meeting-key-value">
                        <div className="system-meeting-key">City</div>
                        <div className="system-meeting-value">
                            {!!geolocation?.city ? geolocation?.city : "-----"}
                        </div>
                    </div>
                    <hr />
                    <div className="system-meeting-key-value">
                        <div className="system-meeting-key">Country Code</div>
                        <div className="system-meeting-value">
                            {!!geolocation?.country_code ? geolocation?.country_code : "-----"}
                        </div>
                    </div>
                    <hr />
                    <div className="system-meeting-key-value">
                        <div className="system-meeting-key">Country Name</div>
                        <div className="system-meeting-value">
                            {!!geolocation?.country_name ? geolocation?.country_name : "-----"}
                        </div>
                    </div>
                    <hr />
                    <div className="system-meeting-key-value">
                        <div className="system-meeting-key">Internet Service Provider</div>
                        <div className="system-meeting-value">
                            {!!geolocation?.org ? geolocation?.org : "-----"}
                        </div>
                    </div>
                    <hr />
                    <div className="system-meeting-key-value">
                        <div className="system-meeting-key">Region Code</div>
                        <div className="system-meeting-value">
                            {!!geolocation?.region_code ? geolocation?.region_code : "-----"}
                        </div>
                    </div>
                    <hr />
                    <div className="system-meeting-key-value">
                        <div className="system-meeting-key">Region Name</div>
                        <div className="system-meeting-value">
                            {!!geolocation?.region ? geolocation?.region : "-----"}
                        </div>
                    </div>
                    <hr />
                    <div className="system-meeting-key-value">
                        <div className="system-meeting-key">Time Zone</div>
                        <div className="system-meeting-value">
                            {!!geolocation?.timezone ? geolocation?.timezone : "-----"}
                        </div>
                    </div>
                    <hr />
                    <div className="system-meeting-key-value">
                        <div className="system-meeting-key">Zip Code</div>
                        <div className="system-meeting-value">
                            {!!geolocation?.postal ? geolocation?.postal : "-----"}
                        </div>
                    </div>
                    <hr />
                    <div className="system-meeting-key-value">
                        <div className="system-meeting-key">Latitude</div>
                        <div className="system-meeting-value">
                            {!!geolocation?.latitude ? geolocation?.latitude : "-----"}
                        </div>
                    </div>
                    <hr />
                    <div className="system-meeting-key-value">
                        <div className="system-meeting-key">Longitude</div>
                        <div className="system-meeting-value">
                            {!!geolocation?.longitude ? geolocation?.longitude : "-----"}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Geolocation;