export const ROUTES = {
  HOME: "/",
  AUTH: {
    LOGIN: "/login"
  },
  PAGES: {
    OVERVIEW: "/home",
    PROFILE: "/me",
    USERS: "/users",
    USER: "/users/user/",
    EDIT_USER: "/edit/user",
    REPORTS: "/reports",
    REPORT: "/reports/report",
    SUPPORT: "/support",
    SUPPORT_DETAILS: "/support/support",
    FEEDBACK: "/feedback",
    FEEDBACK_DETAILS: "/feedback/info",
    COMPLAINTS: "/complaints",
    COMPLAINT_DETAILS: "/complaints/complaint/",
    HOSTING_ESCALATION: "/hosting/escalation",
    MEETINGS: "/meetings",
    ANALYTICS: "/analytics",
    SYSTEM: "/system",
    POLICY: "/policy",
    TERMS: "/terms",
    SYSTEM_VARIABLE: "/system_variable",
    VAT: "/vat",
    SMTP: "/smtp/configuration",
    TEMPLATE: "/templates",
    TEMPLATES: "/templates/design",
    CORPORATE_ACCOUNTS: '/corporate_accounts',
    VERIFICATION_REQUESTS: "/verification_requests",
    EMAIL_SERVICE: "/email_service",
    FAQ: "/faq",
    SYSTEM_ERRORS: "/system_errors",
    SYSTEM_MEETINGS: "/system_meetings",
    SYSTEM_MEETING_TO_USERS: "/system_meeting_to_users",
    EMAIL_NOTIFICATION_MANAGEMENT: "/email_notification_management",
    REQUESTS_EMAIL_LIST: "/email_notification_management/email_list",
    PAID_CONTENT_SALES: "/paid_content_sales",
    DBS_TO_WISE_PAYOUT: "/dbs_payout_to_wise",
  }
};
