import {COLUMN_TYPE} from "../../interfaces/table";
import { HeaderItemType } from "../../interfaces/table";
import {ROUTES} from "../../enum/routes";

export const ReportsTableName = "Reports";

export const ReportOnClick = ({ history, item }: any) => {
    history.push({
        pathname: `${ROUTES.PAGES.REPORT}`,
        state: item
    });
}
export const ReportsHeader = [
    {
        title: "",
        type: COLUMN_TYPE.STRING,
        meta: {
            key: "is_read",
        },
    },
    {
        title: "Ticket ID",
        type: COLUMN_TYPE.STRING,
        meta: {
            key: "id",
        },
    },
    {
        title: "Status",
        type: COLUMN_TYPE.STRING,
        meta: {
            key: "status",
        },
    },
    {
        title: "User ID",
        type: COLUMN_TYPE.STRING,
        meta: {
            key: "reportee",
            object_key: ["id"]
        },
    },
    {
        title: "User name",
        type: COLUMN_TYPE.STRING,
        meta: {
            key: "reportee",
            object_key: ["first_name", "last_name"]
        },
    },
    {
        title: "Registration date",
        type: COLUMN_TYPE.STRING,
        meta: {
            key: "created_at",
        },
    },
    {
        title: "Email",
        type: COLUMN_TYPE.STRING,
        meta: {
            key: "reportee",
            object_key: ["email"]
        },
    },
    {
        title: "Reported by",
        type: COLUMN_TYPE.STRING,
        meta: {
            key: "reporter",
            object_key: ["first_name", "last_name"]
        },
    },
    {
        title: "Report type",
        type: COLUMN_TYPE.STRING,
        meta: {
            key: "type"
        },
    },
] as HeaderItemType[];

//will be deleted after updated BE
export const reportsData: any = [
    {
        id: 1,
        status: "ACTIVE",
        type: "INDIVIDUAL_USER_REPORT",
        reporter: {
            id: 20,
            email: "peter@xsine.co",
            first_name: "Peter",
            last_name: "Pentsak",
            role: "VU",
            public_avatar_url: "string"
        },
        reportee: {
            id: 21,
            email: "rostyk110501@gmail.com",
            first_name: "Rostyslav",
            last_name: "Fedis",
            role: "VU",
            public_avatar_url: "string"
        },
        reason: "INAPPROPRIATE_CONTENT",
        text_form: "string",
        support_topic: "HOSTING",
        post_assets: [
            "string"
        ],
        post_text: "string",
        created_at: "2021-09-30T09:32:45.410Z"
    },
    {
        id: 2,
        status: "RESOLVED",
        type: "INDIVIDUAL_POST_REPORT",
        reporter: {
            id: 20,
            email: "peter@xsine.co",
            first_name: "Serg",
            last_name: "Marivtsan",
            role: "VU",
            public_avatar_url: "string"
        },
        reportee: {
            id: 10,
            email: "vova@gmail.com",
            first_name: "Vova",
            last_name: "Yakymiv",
            role: "VU",
            public_avatar_url: "string"
        },
        reason: "INAPPROPRIATE_CONTENT",
        text_form: "string",
        support_topic: "HOSTING",
        post_assets: [
            "string"
        ],
        post_text: "string",
        created_at: "2021-04-30T09:32:45.410Z"
    }
]
