import { Props } from "react-select";

export const noError = "-----";

const ConnectionContainer: React.FC<Props> = ({ systemMeetingToUsersData }) => {
    return (
        <div className="connection-container">
            <h5>Connection</h5>
            <div className="connection-container-data">
                {systemMeetingToUsersData?.map((meeting: any, index: number) => {
                    
                    const { connection } = meeting.result_test;

                    return <div className="user" key={`connection-container-data-${index}`}>
                        <div>
                            <div className="system-meeting-data-heading">{`User ID ${meeting.user_id}`}</div>
                        </div>
                        
                        {/* AdBlocker */}

                        <div className="system-meeting-key-value">
                            <div className="system-meeting-key">AdBlocker</div>
                            <div className={`system-meeting-value boolean-${!connection?.adBlocker?.success}`}>
                                {`${connection?.adBlocker?.success}`}
                            </div>
                        </div>
                        <div className="system-meeting-key-value">
                            <div className="system-meeting-key">Error</div>
                            <div className={`system-meeting-value error-${!connection?.adBlocker?.error}`}>
                                {!!connection?.adBlocker?.error ? `${connection?.adBlocker?.error}` : noError}
                            </div>
                        </div>
                        <hr />
                        
                        {/* Camera & Mic */}

                        <div className="system-meeting-key-value">
                            <div className="system-meeting-key">Camera & Mic</div>
                            <div className={`system-meeting-value boolean-${connection?.camera_mic?.success}`}>
                                {`${connection?.camera_mic?.success}`}
                            </div>
                        </div>
                        <div className="system-meeting-key-value">
                            <div className="system-meeting-key">Error</div>
                            <div className={`system-meeting-value error-${!connection?.camera_mic?.error}`}>
                                {!!connection?.camera_mic?.error ? `${connection?.camera_mic?.error}` : noError}
                            </div>
                        </div>
                        <hr />  
                        
                        {/* Exposable IP */}

                        <div className="system-meeting-key-value">
                            <div className="system-meeting-key">Exposable IP</div>
                            <div className={`system-meeting-value boolean-${connection?.exposableIp?.success}`}>
                                {`${connection?.exposableIp?.success}`}
                            </div>
                        </div>
                        <div className="system-meeting-key-value">
                            <div className="system-meeting-key">Error</div>
                            <div className={`system-meeting-value error-${!connection?.exposableIp?.error}`}>
                                {!!connection?.exposableIp?.error ? `${connection?.exposableIp?.error}` : noError}
                            </div>
                        </div>
                        <hr />
                        
                        {/* Stun */}

                        <div className="system-meeting-key-value">
                            <div className="system-meeting-key">Stun</div>
                            <div className={`system-meeting-value boolean-${connection?.stun?.success}`}>
                                {`${connection?.stun?.success}`}
                            </div>
                        </div>
                        <div className="system-meeting-key-value">
                            <div className="system-meeting-key">Error</div>
                            <div className={`system-meeting-value error-${!connection?.stun?.error}`}>
                                {!!connection?.stun?.error ? `${connection?.stun?.error}` : noError}
                            </div>
                        </div>
                        <hr />
                        
                        {/* Turn443 */}

                        <div className="system-meeting-key-value">
                            <div className="system-meeting-key">Turn443</div>
                            <div className={`system-meeting-value boolean-${connection?.turn443?.success}`}>
                                {`${connection?.turn443?.success}`}
                            </div>
                        </div>
                        <div className="system-meeting-key-value">
                            <div className="system-meeting-key">Error</div>
                            <div className={`system-meeting-value error-${!connection?.turn443?.error}`}>
                                {!!connection?.turn443?.error ? `${connection?.turn443?.error}` : noError}
                            </div>
                        </div>
                        <hr />
                        
                        {/* Turn80 */}

                        <div className="system-meeting-key-value">
                            <div className="system-meeting-key">Turn80</div>
                            <div className={`system-meeting-value boolean-${connection?.turn80?.success}`}>
                                {`${connection?.turn80?.success}`}
                            </div>
                        </div>
                        <div className="system-meeting-key-value">
                            <div className="system-meeting-key">Error</div>
                            <div className={`system-meeting-value error-${!connection?.turn80?.error}`}>
                                {!!connection?.turn80?.error ? `${connection?.turn80?.error}` : noError}
                            </div>
                        </div>
                        <hr />
                        
                        {/* VPN */}

                        <div className="system-meeting-key-value">
                            <div className="system-meeting-key">VPN</div>
                            <div className={`system-meeting-value boolean-${!connection?.vpn?.success}`}>
                                {`${connection?.vpn?.success}`}
                            </div>
                        </div>
                        <div className="system-meeting-key-value">
                            <div className="system-meeting-key">Error</div>
                            <div className={`system-meeting-value error-${!connection?.vpn?.error}`}>
                                {!!connection?.vpn?.error ? `${connection?.vpn?.error}` : noError}
                            </div>
                        </div>
                        <hr />

                        {/* WebRTC */}

                        <div className="system-meeting-key-value">
                            <div className="system-meeting-key">WebRTC</div>
                            <div className={`system-meeting-value boolean-${connection?.webrtc?.success}`}>
                                {`${connection?.webrtc?.success}`}
                            </div>
                        </div>
                        <div className="system-meeting-key-value">
                            <div className="system-meeting-key">Error</div>
                            <div className={`system-meeting-value error-${!connection?.webrtc?.error}`}>
                                {!!connection?.webrtc?.error ? `${connection?.webrtc?.error}` : noError}
                            </div>
                        </div>
                        <hr />

                        {/* WebSocket */}

                        <div className="system-meeting-key-value">
                            <div className="system-meeting-key">WebSocket</div>
                            <div className={`system-meeting-value boolean-${connection?.websocket?.success}`}>
                                {`${connection?.websocket?.success}`}
                            </div>
                        </div>
                        <div className="system-meeting-key-value">
                            <div className="system-meeting-key">Error</div>
                            <div className={`system-meeting-value error-${!connection?.websocket?.error}`}>
                                {!!connection?.websocket?.error ? `${connection?.websocket?.error}` : noError}
                            </div>
                        </div>
                    </div>
                })}
            </div>
        </div>
    );
}

export default ConnectionContainer;