import React from 'react'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'
import { SvgIconTypeMap } from '@material-ui/core';
import { Button } from '@mui/material';

interface IProps { 
    isAvailable?: boolean,
    icon: JSX.Element | OverridableComponent<SvgIconTypeMap<{}, "svg">>,
    text: string,
    onClick?: () => void
}

const OptionWithIconAndText: React.FC<IProps> = ({isAvailable, icon, text, onClick}) => {
    return (
        <div className={`option-with-icon-and-text-container ${isAvailable && 'available-option-with-icon-and-text-container'}`}>
            <Button className="icon-button" onClick={onClick}>
                <span>{icon}</span>
            </Button>
          <p>{text}</p>
        </div>
    )
}

export default OptionWithIconAndText