import axios from "axios";
import {IChangeEarningsRequest} from "../interfaces/earnings";

export const getUserEarnings = (userId: number) => {
    return axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/admin/earnings/${userId}`,
    })
        .then((response: any) => response?.data)
        .catch((error) => error.response?.data);
};

export const changeUserEarnings = (userId: number, data: IChangeEarningsRequest) => {
    return axios({
        method: "PUT",
        url: `${process.env.REACT_APP_API_URL}/admin/earnings/${userId}`,
        data
    })
        .then((response: any) => response?.data)
        .catch((error) => error.response?.data);
};
