import {useEffect, useState} from "react";
import { getSystemMeetingToUsersData } from "../../api/system-meeting-to-users";

export const useGetSystemMeetingToUsers = (system_meeting_id: string) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [systemMeetingToUsersData, setSystemMeetingToUsersData] = useState<any>()
    

    const getSystemMeetingToUsers = async () => {
        setLoading(true)
        const response = await getSystemMeetingToUsersData(system_meeting_id)
        if (response) {
            setLoading(false)
            setSystemMeetingToUsersData(response)
        }
    }

    useEffect(() => {
        (getSystemMeetingToUsers)()
        return () => setSystemMeetingToUsersData(undefined)
        // eslint-disable-next-line
    }, [system_meeting_id])

    return {
        systemMeetingToUsersData,
        loading
    }
}
