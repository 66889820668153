import {useEffect, useState} from "react";
import {getVatTable} from "../../api/vat";
import {IGetVatTableInfo} from "../../interfaces/vat";
import moment from "moment";
import {GET_FULL_DATE_WITH_NO_TIME} from "../../enum/date-formats";

export const useVatInfo = (triggerRefetch?: boolean) => {
    const [vatTableInfo, setVatTableInfo] = useState<IGetVatTableInfo[]>()
    const [vatLoading, setVatLoading] = useState<boolean>(false)

    useEffect(() => {
        (async () => {
            setVatLoading(true)
            const response = await getVatTable()
            if (response) {
                const vatInfo = response.map((v) =>
                    ({
                        ...v,
                        active: v.active ? "Yes" : "No",
                        registration_date: moment(v.registration_date, moment.defaultFormat).format(GET_FULL_DATE_WITH_NO_TIME)
                    }))
                setVatLoading(false)
                setVatTableInfo(vatInfo)
            }
        })()
    }, [triggerRefetch])


    return {
        vatTableInfo,
        vatLoading
    }
}