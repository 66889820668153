import { ISort } from "../interfaces/sort";

export const onHandleSearchAndSort = (setCurrentPage: any, setSort: any, setSearch: any)=> {
    const onHandleSort = (e: ISort) => {
        setSort(e)
        setCurrentPage(0)
    };

    const onHandleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value)
        setCurrentPage(0)
    };

    const onHandleClear = () => {
        setSearch("")
        setCurrentPage(0)
    }

    return {
        onHandleSort,
        onHandleSearch,
        onHandleClear
    }
}