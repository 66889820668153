import {useEffect, useState} from "react";
import {getUser} from "../../api/user";
import {SIZE} from "../../enum/asset-size";
import {IExtendedUserInfo} from "../../interfaces/user";


type IAssetSizeType = typeof SIZE.SMALL | typeof SIZE.MEDIUM | typeof SIZE.LARGE | typeof SIZE.ORIGINAL

export const useUserData = (userId: string, loading: boolean, asset_size?: IAssetSizeType) => {
    const [userLoading, setUserLoading] = useState<boolean>(false)
    const [user, setUser] = useState<IExtendedUserInfo>()
    useEffect(() => {
        (async () => {
            if (userId) {
                setUserLoading(true)
                const response = await getUser({id: userId, asset_size: asset_size || SIZE.SMALL})
                if (response) {
                    setUserLoading(false)
                    setUser(response?.data)
                }
            }
        })()
        // eslint-disable-next-line
    }, [loading])

    return {
        userLoading,
        user
    }
}
