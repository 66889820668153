export const SCORE_VALUES_IN_STARS = {
    ONE_STAR: {
        backend_key: 'one_star',
        stars_count: 1,
        count: 0.2
    },
    TWO_STARS: {
        backend_key: 'two_stars',
        stars_count: 2,
        count: 0.4
    },
    THREE_STARS: {
        backend_key: 'three_stars',
        stars_count: 3,
        count: 0.6
    },
    FOUR_STARS: {
        backend_key: 'four_stars',
        stars_count: 4,
        count: 0.8
    },
    FIVE_STARS: {
        backend_key: 'five_stars',
        stars_count: 5,
        count: 1
    }
};
