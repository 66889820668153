import {useEffect, useState} from "react";
import {IEarningsAnalyticsResponse, ISpendingAnalyticsResponse} from "../../interfaces/analytics";
import {getEarningsAnalytics, getSpendingAnalytics} from "../../api/analytics";
import moment from "moment";
import {REQUEST_DATE_FORMAT} from "../../enum/date-formats";
import {roundValue} from "../../utils/round-value";

export const useAnalytics = (date_from: string, country: string) => {
    const [earningsAnalytics, setEarningsAnalytics] = useState<IEarningsAnalyticsResponse>()
    const [spendingAnalytics, setSpendingAnalytics] = useState<ISpendingAnalyticsResponse>()
    const [analyticsLoading, setAnalyticsLoading] = useState<boolean>(false)
    const totalSpending = earningsAnalytics && spendingAnalytics ? roundValue(spendingAnalytics?.spendingOnMeetings + spendingAnalytics?.spendingOnCredits + spendingAnalytics?.spendingOnPaidContent) : 0

    useEffect(() => {
        (async () => {
            setAnalyticsLoading(true)
            const from = moment(date_from).format(REQUEST_DATE_FORMAT)
            const earningsResponse = await getEarningsAnalytics({date_from: from, country})
            const spendingResponse = await getSpendingAnalytics({date_from: from, country})
            if (earningsResponse && spendingResponse) {
                setAnalyticsLoading(false)
                setEarningsAnalytics(earningsResponse?.data)
                setSpendingAnalytics(spendingResponse?.data)
            }
        })()
    }, [date_from, country])

    return {
        spendingAnalytics: {...spendingAnalytics, totalSpending},
        earningsAnalytics,
        analyticsLoading
    }
}
