export const LIST_OF_COUNTRIES = [
    {
        "code": "AF",
        "code3": "AFG",
        "name": "Afghanistan",
        "number": "004",
        "currencyCode": "AFN",
        "currency": "Afghani"
    },
    {
        "code": "AL",
        "code3": "ALB",
        "name": "Albania",
        "number": "008",
        "currencyCode": "ALL",
        "currency": "Lek"
    },
    {
        "code": "DZ",
        "code3": "DZA",
        "name": "Algeria",
        "number": "012",
        "currencyCode": "DZD",
        "currency": "Algerian Dinar"
    },
    {
        "code": "AS",
        "code3": "ASM",
        "name": "American Samoa",
        "number": "016",
        "currencyCode": "EUR",
        "currency": "Euros"
    },
    {
        "code": "AD",
        "code3": "AND",
        "name": "Andorra",
        "number": "020",
        "currencyCode": "EUR",
        "currency": "Euros"
    },
    {
        "code": "AO",
        "code3": "AGO",
        "name": "Angola",
        "number": "024",
        "currencyCode": "AOA",
        "currency": "Angolan kwanza"
    },
    {
        "code": "AI",
        "code3": "AIA",
        "name": "Anguilla",
        "number": "660",
        "currencyCode": "XCD",
        "currency": "East Caribbean Dollar"
    },
    {
        "code": "AQ",
        "code3": "ATA",
        "name": "Antarctica",
        "number": "010",
        "currencyCode": "AQD",
        "currency": "Antarctican dollar"
    },
    {
        "code": "AG",
        "code3": "ATG",
        "name": "Antigua and Barbuda",
        "number": "028",
        "currencyCode": "XCD",
        "currency": "East Caribbean Dollar"
    },
    {
        "code": "AR",
        "code3": "ARG",
        "name": "Argentina",
        "number": "032",
        "currencyCode": "ARS",
        "currency": "Peso"
    },
    {
        "code": "AM",
        "code3": "ARM",
        "name": "Armenia",
        "number": "051",
        "currencyCode": "AMD",
        "currency": "Dram"
    },
    {
        "code": "AW",
        "code3": "ABW",
        "name": "Aruba",
        "number": "533",
        "currencyCode": "ANG",
        "currency": "Netherlands Antilles Guilder"
    },
    {
        "code": "AU",
        "code3": "AUS",
        "name": "Australia",
        "number": "036",
        "currencyCode": "AUD",
        "currency": "Australian Dollars"
    },
    {
        "code": "AT",
        "code3": "AUT",
        "name": "Austria",
        "number": "040",
        "currencyCode": "EUR",
        "currency": "Euros"
    },
    {
        "code": "AZ",
        "code3": "AZE",
        "name": "Azerbaijan",
        "number": "031",
        "currencyCode": "AZN",
        "currency": "Manat"
    },
    {
        "code": "BS",
        "code3": "BHS",
        "name": "Bahamas",
        "number": "044",
        "currencyCode": "BSD",
        "currency": "Bahamian Dollar"
    },
    {
        "code": "BH",
        "code3": "BHR",
        "name": "Bahrain",
        "number": "048",
        "currencyCode": "BHD",
        "currency": "Bahraini Dinar"
    },
    {
        "code": "BD",
        "code3": "BGD",
        "name": "Bangladesh",
        "number": "050",
        "currencyCode": "BDT",
        "currency": "Taka"
    },
    {
        "code": "BB",
        "code3": "BRB",
        "name": "Barbados",
        "number": "052",
        "currencyCode": "BBD",
        "currency": "Barbadian Dollar"
    },
    {
        "code": "BY",
        "code3": "BLR",
        "name": "Belarus",
        "number": "112",
        "currencyCode": "BYR",
        "currency": "Belarus Ruble"
    },
    {
        "code": "BE",
        "code3": "BEL",
        "name": "Belgium",
        "number": "056",
        "currencyCode": "EUR",
        "currency": "Euros"
    },
    {
        "code": "BZ",
        "code3": "BLZ",
        "name": "Belize",
        "number": "084",
        "currencyCode": "BZD",
        "currency": "Belizean Dollar"
    },
    {
        "code": "BJ",
        "code3": "BEN",
        "name": "Benin",
        "number": "204",
        "currencyCode": "XOF",
        "currency": "CFA Franc BCEAO"
    },
    {
        "code": "BM",
        "code3": "BMU",
        "name": "Bermuda",
        "number": "060",
        "currencyCode": "BMD",
        "currency": "Bermudian Dollar"
    },
    {
        "code": "BT",
        "code3": "BTN",
        "name": "Bhutan",
        "number": "064",
        "currencyCode": "INR",
        "currency": "Indian Rupee"
    },
    {
        "code": "BO",
        "code3": "BOL",
        "name": "Bolivia",
        "number": "068",
        "currencyCode": "BOB",
        "currency": "Boliviano"
    },
    {
        "code": "BA",
        "code3": "BIH",
        "name": "Bosnia and Herzegovina",
        "number": "070",
        "currencyCode": "BAM",
        "currency": "Bosnia and Herzegovina convertible mark"
    },
    {
        "code": "BW",
        "code3": "BWA",
        "name": "Botswana",
        "number": "072",
        "currencyCode": "BWP",
        "currency": "Pula"
    },
    {
        "code": "BV",
        "code3": "BVT",
        "name": "Bouvet Island",
        "number": "074",
        "currencyCode": "NOK",
        "currency": "Norwegian Krone"
    },
    {
        "code": "BR",
        "code3": "BRA",
        "name": "Brazil",
        "number": "076",
        "currencyCode": "BRL",
        "currency": "Brazil"
    },
    {
        "code": "IO",
        "code3": "IOT",
        "name": "British Indian Ocean Territory",
        "number": "086",
        "currencyCode": "USD",
        "currency": "USD"
    },
    {
        "code": "BN",
        "code3": "BRN",
        "name": "Brunei Darussalam",
        "number": "096",
        "currencyCode": "BND",
        "currency": "Bruneian Dollar"
    },
    {
        "code": "BG",
        "code3": "BGR",
        "name": "Bulgaria",
        "number": "100",
        "currencyCode": "BGN",
        "currency": "Lev"
    },
    {
        "code": "BF",
        "code3": "BFA",
        "name": "Burkina Faso",
        "number": "854",
        "currencyCode": "XOF",
        "currency": "CFA Franc BCEAO"
    },
    {
        "code": "BI",
        "code3": "BDI",
        "name": "Burundi",
        "number": "108",
        "currencyCode": "BIF",
        "currency": "Burundi Franc"
    },
    {
        "code": "CV",
        "code3": "CPV",
        "name": "Cabo Verde",
        "number": "132",
        "currencyCode": "CVE",
        "currency": "Escudo"
    },
    {
        "code": "KH",
        "code3": "KHM",
        "name": "Cambodia",
        "number": "116",
        "currencyCode": "KHR",
        "currency": "Riel"
    },
    {
        "code": "CM",
        "code3": "CMR",
        "name": "Cameroon",
        "number": "120",
        "currencyCode": "XAF",
        "currency": "CFA Franc BEAC"
    },
    {
        "code": "CA",
        "code3": "CAN",
        "name": "Canada",
        "number": "124",
        "currencyCode": "CAD",
        "currency": "Canadian Dollar"
    },
    {
        "code": "KY",
        "code3": "CYM",
        "name": "Cayman Islands",
        "number": "136",
        "currencyCode": "KYD",
        "currency": "Caymanian Dollar"
    },
    {
        "code": "CF",
        "code3": "CAF",
        "name": "Central African Republic",
        "number": "140",
        "currencyCode": "XAF",
        "currency": "CFA Franc BEAC"
    },
    {
        "code": "TD",
        "code3": "TCD",
        "name": "Chad",
        "number": "148",
        "currencyCode": "XAF",
        "currency": "CFA Franc BEAC"
    },
    {
        "code": "CL",
        "code3": "CHL",
        "name": "Chile",
        "number": "152",
        "currencyCode": "CLP",
        "currency": "Chilean Peso"
    },
    {
        "code": "CN",
        "code3": "CHN",
        "name": "China",
        "number": "156",
        "currencyCode": "CNY",
        "currency": "Yuan Renminbi"
    },
    {
        "code": "CX",
        "code3": "CXR",
        "name": "Christmas Island",
        "number": "162",
        "currencyCode": "AUD",
        "currency": "Australian Dollars"
    },
    {
        "code": "CC",
        "code3": "CCK",
        "name": "Cocos (Keeling) Islands",
        "number": "166",
        "currencyCode": "AUD",
        "currency": "Australian Dollars"
    },
    {
        "code": "CO",
        "code3": "COL",
        "name": "Colombia",
        "number": "170",
        "currencyCode": "COP",
        "currency": "Peso"
    },
    {
        "code": "KM",
        "code3": "COM",
        "name": "Comoros",
        "number": "174",
        "currencyCode": "KMF",
        "currency": "Comoran Franc"
    },
    {
        "code": "CD",
        "code3": "COD",
        "name": "Congo (the Democratic Republic of the)",
        "number": "180",
        "currencyCode": "CDF",
        "currency": "Congolese Frank"
    },
    {
        "code": "CG",
        "code3": "COG",
        "name": "Congo",
        "number": "178",
        "currencyCode": "XAF",
        "currency": "CFA Franc BEAC"
    },
    {
        "code": "CK",
        "code3": "COK",
        "name": "Cook Islands",
        "number": "184",
        "currencyCode": "NZD",
        "currency": "New Zealand Dollars"
    },
    {
        "code": "CR",
        "code3": "CRI",
        "name": "Costa Rica",
        "number": "188",
        "currencyCode": "CRC",
        "currency": "Costa Rican Colon"
    },
    {
        "code": "HR",
        "code3": "HRV",
        "name": "Croatia",
        "number": "191",
        "currencyCode": "HRK",
        "currency": "Croatian Dinar"
    },
    {
        "code": "CU",
        "code3": "CUB",
        "name": "Cuba",
        "number": "192",
        "currencyCode": "CUP",
        "currency": "Cuban Peso"
    },
    {
        "code": "CY",
        "code3": "CYP",
        "name": "Cyprus",
        "number": "196",
        "currencyCode": "CYP",
        "currency": "Cypriot Pound"
    },
    {
        "code": "CZ",
        "code3": "CZE",
        "name": "Czech Republic",
        "number": "203",
        "currencyCode": "CZK",
        "currency": "Koruna"
    },
    {
        "code": "CI",
        "code3": "CIV",
        "name": "Côte d'Ivoire",
        "number": "384",
        "currencyCode": "XOF",
        "currency": "CFA Franc BCEAO"
    },
    {
        "code": "DK",
        "code3": "DNK",
        "name": "Denmark",
        "number": "208",
        "currencyCode": "DKK",
        "currency": "Danish Krone"
    },
    {
        "code": "DJ",
        "code3": "DJI",
        "name": "Djibouti",
        "number": "262",
        "currencyCode": "DJF",
        "currency": "Djiboutian Franc"
    },
    {
        "code": "DM",
        "code3": "DMA",
        "name": "Dominica",
        "number": "212",
        "currencyCode": "XCD",
        "currency": "East Caribbean Dollar"
    },
    {
        "code": "DO",
        "code3": "DOM",
        "name": "Dominican Republic",
        "number": "214",
        "currencyCode": "DOP",
        "currency": "Dominican Peso"
    },
    {
        "code": "EC",
        "code3": "ECU",
        "name": "Ecuador",
        "number": "218",
        "currencyCode": "ECS",
        "currency": "Sucre"
    },
    {
        "code": "EG",
        "code3": "EGY",
        "name": "Egypt",
        "number": "818",
        "currencyCode": "EGP",
        "currency": "Egyptian Pound"
    },
    {
        "code": "SV",
        "code3": "SLV",
        "name": "El Salvador",
        "number": "222",
        "currencyCode": "SVC",
        "currency": "Salvadoran Colon"
    },
    {
        "code": "GQ",
        "code3": "GNQ",
        "name": "Equatorial Guinea",
        "number": "226",
        "currencyCode": "XAF",
        "currency": "CFA Franc BEAC"
    },
    {
        "code": "ER",
        "code3": "ERI",
        "name": "Eritrea",
        "number": "232",
        "currencyCode": "ETB",
        "currency": "Ethiopian Birr"
    },
    {
        "code": "EE",
        "code3": "EST",
        "name": "Estonia",
        "number": "233",
        "currencyCode": "EEK",
        "currency": "Estonian Kroon"
    },
    {
        "code": "SZ",
        "code3": "SWZ",
        "name": "Eswatini",
        "number": "748",
        "currencyCode": "SZL",
        "currency": "Lilangeni"
    },
    {
        "code": "ET",
        "code3": "ETH",
        "name": "Ethiopia",
        "number": "231",
        "currencyCode": "ETB",
        "currency": "Ethiopian Birr"
    },
    {
        "code": "FK",
        "code3": "FLK",
        "name": "Falkland Islands",
        "number": "238",
        "currencyCode": "FKP",
        "currency": "Falkland Pound"
    },
    {
        "code": "FO",
        "code3": "FRO",
        "name": "Faroe Islands",
        "number": "234",
        "currencyCode": "DKK",
        "currency": "Danish Krone"
    },
    {
        "code": "FJ",
        "code3": "FJI",
        "name": "Fiji",
        "number": "242",
        "currencyCode": "FJD",
        "currency": "Fijian Dollar"
    },
    {
        "code": "FI",
        "code3": "FIN",
        "name": "Finland",
        "number": "246",
        "currencyCode": "EUR",
        "currency": "Euros"
    },
    {
        "code": "FR",
        "code3": "FRA",
        "name": "France",
        "number": "250",
        "currencyCode": "EUR",
        "currency": "Euros"
    },
    {
        "code": "GF",
        "code3": "GUF",
        "name": "French Guiana",
        "number": "254",
        "currencyCode": "EUR",
        "currency": "Euros"
    },
    {
        "code": "PF",
        "code3": "PYF",
        "name": "French Polynesia",
        "number": "258",
        "currencyCode": "XPF",
        "currency": "CFP Franc"
    },
    {
        "code": "TF",
        "code3": "ATF",
        "name": "French Southern Territories",
        "number": "260",
        "currencyCode": "EUR",
        "currency": "Euros"
    },
    {
        "code": "GA",
        "code3": "GAB",
        "name": "Gabon",
        "number": "266",
        "currencyCode": "XAF",
        "currency": "CFA Franc BEAC"
    },
    {
        "code": "GM",
        "code3": "GMB",
        "name": "Gambia",
        "number": "270",
        "currencyCode": "GMD",
        "currency": "Dalasi"
    },
    {
        "code": "GE",
        "code3": "GEO",
        "name": "Georgia",
        "number": "268",
        "currencyCode": "GEL",
        "currency": "Lari"
    },
    {
        "code": "DE",
        "code3": "DEU",
        "name": "Germany",
        "number": "276",
        "currencyCode": "EUR",
        "currency": "Euros"
    },
    {
        "code": "GH",
        "code3": "GHA",
        "name": "Ghana",
        "number": "288",
        "currencyCode": "GHS",
        "currency": "Ghana cedi"
    },
    {
        "code": "GI",
        "code3": "GIB",
        "name": "Gibraltar",
        "number": "292",
        "currencyCode": "GIP",
        "currency": "Gibraltar Pound"
    },
    {
        "code": "GR",
        "code3": "GRC",
        "name": "Greece",
        "number": "300",
        "currencyCode": "EUR",
        "currency": "Euros"
    },
    {
        "code": "GL",
        "code3": "GRL",
        "name": "Greenland",
        "number": "304",
        "currencyCode": "DKK",
        "currency": "Danish Krone"
    },
    {
        "code": "GD",
        "code3": "GRD",
        "name": "Grenada",
        "number": "308",
        "currencyCode": "XCD",
        "currency": "East Caribbean Dollar"
    },
    {
        "code": "GP",
        "code3": "GLP",
        "name": "Guadeloupe",
        "number": "312",
        "currencyCode": "EUR",
        "currency": "Euros"
    },
    {
        "code": "GU",
        "code3": "GUM",
        "name": "Guam",
        "number": "316",
        "currencyCode": "USD",
        "currency": "USD"
    },
    {
        "code": "GT",
        "code3": "GTM",
        "name": "Guatemala",
        "number": "320",
        "currencyCode": "GTQ",
        "currency": "Quetzal"
    },
    {
        "code": "GG",
        "code3": "GGY",
        "name": "Guernsey",
        "number": "831",
        "currencyCode": "GGP",
        "currency": "Guernsey pound"
    },
    {
        "code": "GN",
        "code3": "GIN",
        "name": "Guinea",
        "number": "324",
        "currencyCode": "GNF",
        "currency": "Guinean Franc"
    },
    {
        "code": "GW",
        "code3": "GNB",
        "name": "Guinea-Bissau",
        "number": "624",
        "currencyCode": "XOF",
        "currency": "CFA Franc BCEAO"
    },
    {
        "code": "GY",
        "code3": "GUY",
        "name": "Guyana",
        "number": "328",
        "currencyCode": "GYD",
        "currency": "Guyanaese Dollar"
    },
    {
        "code": "HT",
        "code3": "HTI",
        "name": "Haiti",
        "number": "332",
        "currencyCode": "HTG",
        "currency": "Gourde"
    },
    {
        "code": "HM",
        "code3": "HMD",
        "name": "Heard Island and McDonald Islands",
        "number": "334",
        "currencyCode": "AUD",
        "currency": "Australian Dollars"
    },
    {
        "code": "VA",
        "code3": "VAT",
        "name": "Holy See",
        "number": "336",
        "currencyCode": "EUR",
        "currency": "Euros"
    },
    {
        "code": "HN",
        "code3": "HND",
        "name": "Honduras",
        "number": "340",
        "currencyCode": "HNL",
        "currency": "Lempira"
    },
    {
        "code": "HK",
        "code3": "HKG",
        "name": "Hong Kong",
        "number": "344",
        "currencyCode": "HKD",
        "currency": "HKD"
    },
    {
        "code": "HU",
        "code3": "HUN",
        "name": "Hungary",
        "number": "348",
        "currencyCode": "HUF",
        "currency": "Forint"
    },
    {
        "code": "IS",
        "code3": "ISL",
        "name": "Iceland",
        "number": "352",
        "currencyCode": "ISK",
        "currency": "Icelandic Krona"
    },
    {
        "code": "IN",
        "code3": "IND",
        "name": "India",
        "number": "356",
        "currencyCode": "INR",
        "currency": "Indian Rupee"
    },
    {
        "code": "ID",
        "code3": "IDN",
        "name": "Indonesia",
        "number": "360",
        "currencyCode": "IDR",
        "currency": "Indonesian Rupiah"
    },
    {
        "code": "IR",
        "code3": "IRN",
        "name": "Iran",
        "number": "364",
        "currencyCode": "IRR",
        "currency": "Iranian Rial"
    },
    {
        "code": "IQ",
        "code3": "IRQ",
        "name": "Iraq",
        "number": "368",
        "currencyCode": "IQD",
        "currency": "Iraqi Dinar"
    },
    {
        "code": "IE",
        "code3": "IRL",
        "name": "Ireland",
        "number": "372",
        "currencyCode": "EUR",
        "currency": "Euros"
    },
    {
        "code": "IM",
        "code3": "IMN",
        "name": "Isle of Man",
        "number": "833",
        "currencyCode": "GBP",
        "currency": "Manx pound"
    },
    {
        "code": "IL",
        "code3": "ISR",
        "name": "Israel",
        "number": "376",
        "currencyCode": "ILS",
        "currency": "Shekel"
    },
    {
        "code": "IT",
        "code3": "ITA",
        "name": "Italy",
        "number": "380",
        "currencyCode": "EUR",
        "currency": "Euros"
    },
    {
        "code": "JM",
        "code3": "JAM",
        "name": "Jamaica",
        "number": "388",
        "currencyCode": "JMD",
        "currency": "Jamaican Dollar"
    },
    {
        "code": "JP",
        "code3": "JPN",
        "name": "Japan",
        "number": "392",
        "currencyCode": "JPY",
        "currency": "Japanese Yen"
    },
    {
        "code": "JE",
        "code3": "JEY",
        "name": "Jersey",
        "number": "832",
        "currencyCode": "GBP",
        "currency": "Sterling"
    },
    {
        "code": "JO",
        "code3": "JOR",
        "name": "Jordan",
        "number": "400",
        "currencyCode": "JOD",
        "currency": "Jordanian Dinar"
    },
    {
        "code": "KZ",
        "code3": "KAZ",
        "name": "Kazakhstan",
        "number": "398",
        "currencyCode": "KZT",
        "currency": "Tenge"
    },
    {
        "code": "KE",
        "code3": "KEN",
        "name": "Kenya",
        "number": "404",
        "currencyCode": "KES",
        "currency": "Kenyan Shilling"
    },
    {
        "code": "KI",
        "code3": "KIR",
        "name": "Kiribati",
        "number": "296",
        "currencyCode": "AUD",
        "currency": "Australian Dollars"
    },
    {
        "code": "KP",
        "code3": "PRK",
        "name": "Korea (the Democratic People's Republic of)",
        "number": "408",
        "currencyCode": "KPW",
        "currency": "Won"
    },
    {
        "code": "KR",
        "code3": "KOR",
        "name": "Korea (the Republic of)",
        "number": "410",
        "currencyCode": "KRW",
        "currency": "Won"
    },
    {
        "code": "KW",
        "code3": "KWT",
        "name": "Kuwait",
        "number": "414",
        "currencyCode": "KWD",
        "currency": "Kuwaiti Dinar"
    },
    {
        "code": "KG",
        "code3": "KGZ",
        "name": "Kyrgyzstan",
        "number": "417",
        "currencyCode": "KGS",
        "currency": "Som"
    },
    {
        "code": "LA",
        "code3": "LAO",
        "name": "Lao People's Democratic Republic (the)",
        "number": "418",
        "currencyCode": "LAK",
        "currency": "Kip"
    },
    {
        "code": "LV",
        "code3": "LVA",
        "name": "Latvia",
        "number": "428",
        "currencyCode": "LVL",
        "currency": "Lat"
    },
    {
        "code": "LB",
        "code3": "LBN",
        "name": "Lebanon",
        "number": "422",
        "currencyCode": "LBP",
        "currency": "Lebanese Pound"
    },
    {
        "code": "LS",
        "code3": "LSO",
        "name": "Lesotho",
        "number": "426",
        "currencyCode": "LSL",
        "currency": "Loti"
    },
    {
        "code": "LR",
        "code3": "LBR",
        "name": "Liberia",
        "number": "430",
        "currencyCode": "LRD",
        "currency": "Liberian Dollar"
    },
    {
        "code": "LY",
        "code3": "LBY",
        "name": "Libya",
        "number": "434",
        "currencyCode": "LYD",
        "currency": "Libyan Dinar"
    },
    {
        "code": "LI",
        "code3": "LIE",
        "name": "Liechtenstein",
        "number": "438",
        "currencyCode": "CHF",
        "currency": "Swiss Franc"
    },
    {
        "code": "LT",
        "code3": "LTU",
        "name": "Lithuania",
        "number": "440",
        "currencyCode": "LTL",
        "currency": "Lita"
    },
    {
        "code": "LU",
        "code3": "LUX",
        "name": "Luxembourg",
        "number": "442",
        "currencyCode": "EUR",
        "currency": "Euros"
    },
    {
        "code": "MO",
        "code3": "MAC",
        "name": "Macao",
        "number": "446",
        "currencyCode": "MOP",
        "currency": "Pataca"
    },
    {
        "code": "MO",
        "code3": "MAC",
        "name": "Macao",
        "number": "446",
        "currencyCode": "MOP",
        "currency": "Macanese pataca"
    },
    {
        "code": "MG",
        "code3": "MDG",
        "name": "Madagascar",
        "number": "450",
        "currencyCode": "MGA",
        "currency": "Malagasy Franc"
    },
    {
        "code": "MW",
        "code3": "MWI",
        "name": "Malawi",
        "number": "454",
        "currencyCode": "MWK",
        "currency": "Malawian Kwacha"
    },
    {
        "code": "MY",
        "code3": "MYS",
        "name": "Malaysia",
        "number": "458",
        "currencyCode": "MYR",
        "currency": "Ringgit"
    },
    {
        "code": "MV",
        "code3": "MDV",
        "name": "Maldives",
        "number": "462",
        "currencyCode": "MVR",
        "currency": "Rufiyaa"
    },
    {
        "code": "ML",
        "code3": "MLI",
        "name": "Mali",
        "number": "466",
        "currencyCode": "XOF",
        "currency": "CFA Franc BCEAO"
    },
    {
        "code": "MT",
        "code3": "MLT",
        "name": "Malta",
        "number": "470",
        "currencyCode": "MTL",
        "currency": "Maltese Lira"
    },
    {
        "code": "MH",
        "code3": "MHL",
        "name": "Marshall Islands",
        "number": "584",
        "currencyCode": "USD",
        "currency": "USD"
    },
    {
        "code": "MQ",
        "code3": "MTQ",
        "name": "Martinique",
        "number": "474",
        "currencyCode": "EUR",
        "currency": "Euros"
    },
    {
        "code": "MR",
        "code3": "MRT",
        "name": "Mauritania",
        "number": "478",
        "currencyCode": "MRO",
        "currency": "Ouguiya"
    },
    {
        "code": "MU",
        "code3": "MUS",
        "name": "Mauritius",
        "number": "480",
        "currencyCode": "MUR",
        "currency": "Mauritian Rupee"
    },
    {
        "code": "YT",
        "code3": "MYT",
        "name": "Mayotte",
        "number": "175",
        "currencyCode": "EUR",
        "currency": "Euros"
    },
    {
        "code": "MX",
        "code3": "MEX",
        "name": "Mexico",
        "number": "484",
        "currencyCode": "MXN",
        "currency": "Peso"
    },
    {
        "code": "FM",
        "code3": "FSM",
        "name": "Micronesia (Federated States of)",
        "number": "583",
        "currencyCode": "USD",
        "currency": "USD"
    },
    {
        "code": "MD",
        "code3": "MDA",
        "name": "Moldova",
        "number": "498",
        "currencyCode": "MDL",
        "currency": "Leu"
    },
    {
        "code": "MC",
        "code3": "MCO",
        "name": "Monaco",
        "number": "492",
        "currencyCode": "EUR",
        "currency": "Euros"
    },
    {
        "code": "MN",
        "code3": "MNG",
        "name": "Mongolia",
        "number": "496",
        "currencyCode": "MNT",
        "currency": "Tugrik"
    },
    {
        "code": "ME",
        "code3": "MNE",
        "name": "Montenegro",
        "number": "499",
        "currencyCode": "EUR",
        "currency": "Euro"
    },
    {
        "code": "MS",
        "code3": "MSR",
        "name": "Montserrat",
        "number": "500",
        "currencyCode": "XCD",
        "currency": "East Caribbean Dollar"
    },
    {
        "code": "MA",
        "code3": "MAR",
        "name": "Morocco",
        "number": "504",
        "currencyCode": "MAD",
        "currency": "Dirham"
    },
    {
        "code": "MZ",
        "code3": "MOZ",
        "name": "Mozambique",
        "number": "508",
        "currencyCode": "MZN",
        "currency": "Metical"
    },
    {
        "code": "MM",
        "code3": "MMR",
        "name": "Myanmar",
        "number": "104",
        "currencyCode": "MMK",
        "currency": "Kyat"
    },
    {
        "code": "NA",
        "code3": "NAM",
        "name": "Namibia",
        "number": "516",
        "currencyCode": "NAD",
        "currency": "Dollar"
    },
    {
        "code": "NR",
        "code3": "NRU",
        "name": "Nauru",
        "number": "520",
        "currencyCode": "AUD",
        "currency": "Australian Dollars"
    },
    {
        "code": "NP",
        "code3": "NPL",
        "name": "Nepal",
        "number": "524",
        "currencyCode": "NPR",
        "currency": "Nepalese Rupee"
    },
    {
        "code": "NL",
        "code3": "NLD",
        "name": "Netherlands",
        "number": "528",
        "currencyCode": "EUR",
        "currency": "Euros"
    },
    {
        "code": "NC",
        "code3": "NCL",
        "name": "New Caledonia",
        "number": "540",
        "currencyCode": "XPF",
        "currency": "CFP Franc"
    },
    {
        "code": "NZ",
        "code3": "NZL",
        "name": "New Zealand",
        "number": "554",
        "currencyCode": "NZD",
        "currency": "New Zealand Dollars"
    },
    {
        "code": "NI",
        "code3": "NIC",
        "name": "Nicaragua",
        "number": "558",
        "currencyCode": "NIO",
        "currency": "Cordoba Oro"
    },
    {
        "code": "NE",
        "code3": "NER",
        "name": "Niger",
        "number": "562",
        "currencyCode": "XOF",
        "currency": "CFA Franc BCEAO"
    },
    {
        "code": "NG",
        "code3": "NGA",
        "name": "Nigeria",
        "number": "566",
        "currencyCode": "NGN",
        "currency": "Naira"
    },
    {
        "code": "NU",
        "code3": "NIU",
        "name": "Niue",
        "number": "570",
        "currencyCode": "NZD",
        "currency": "New Zealand Dollars"
    },
    {
        "code": "NF",
        "code3": "NFK",
        "name": "Norfolk Island",
        "number": "574",
        "currencyCode": "AUD",
        "currency": "Australian Dollars"
    },
    {
        "code": "MP",
        "code3": "MNP",
        "name": "Northern Mariana Islands",
        "number": "580",
        "currencyCode": "USD",
        "currency": "USD"
    },
    {
        "code": "NO",
        "code3": "NOR",
        "name": "Norway",
        "number": "578",
        "currencyCode": "NOK",
        "currency": "Norwegian Krone"
    },
    {
        "code": "OM",
        "code3": "OMN",
        "name": "Oman",
        "number": "512",
        "currencyCode": "OMR",
        "currency": "Sul Rial"
    },
    {
        "code": "PK",
        "code3": "PAK",
        "name": "Pakistan",
        "number": "586",
        "currencyCode": "PKR",
        "currency": "Rupee"
    },
    {
        "code": "PW",
        "code3": "PLW",
        "name": "Palau",
        "number": "585",
        "currencyCode": "USD",
        "currency": "USD"
    },
    {
        "code": "PS",
        "code3": "PSE",
        "name": "Palestine, State of",
        "number": "275",
        "currencyCode": "JOD",
        "currency": "Jordanian dinar"
    },
    {
        "code": "PA",
        "code3": "PAN",
        "name": "Panama",
        "number": "591",
        "currencyCode": "PAB",
        "currency": "Balboa"
    },
    {
        "code": "PG",
        "code3": "PNG",
        "name": "Papua New Guinea",
        "number": "598",
        "currencyCode": "PGK",
        "currency": "Kina"
    },
    {
        "code": "PY",
        "code3": "PRY",
        "name": "Paraguay",
        "number": "600",
        "currencyCode": "PYG",
        "currency": "Guarani"
    },
    {
        "code": "PE",
        "code3": "PER",
        "name": "Peru",
        "number": "604",
        "currencyCode": "PEN",
        "currency": "Nuevo Sol"
    },
    {
        "code": "PH",
        "code3": "PHL",
        "name": "Philippines",
        "number": "608",
        "currencyCode": "PHP",
        "currency": "Peso"
    },
    {
        "code": "PN",
        "code3": "PCN",
        "name": "Pitcairn",
        "number": "612",
        "currencyCode": "NZD",
        "currency": "New Zealand Dollars"
    },
    {
        "code": "PL",
        "code3": "POL",
        "name": "Poland",
        "number": "616",
        "currencyCode": "PLN",
        "currency": "Zloty"
    },
    {
        "code": "PT",
        "code3": "PRT",
        "name": "Portugal",
        "number": "620",
        "currencyCode": "EUR",
        "currency": "Euros"
    },
    {
        "code": "PR",
        "code3": "PRI",
        "name": "Puerto Rico",
        "number": "630",
        "currencyCode": "USD",
        "currency": "USD"
    },
    {
        "code": "QA",
        "code3": "QAT",
        "name": "Qatar",
        "number": "634",
        "currencyCode": "QAR",
        "currency": "Rial"
    },
    {
        "code": "MK",
        "code3": "MKD",
        "name": "Republic of North Macedonia",
        "number": "807",
        "currencyCode": "MKD",
        "currency": "Denar"
    },
    {
        "code": "RO",
        "code3": "ROU",
        "name": "Romania",
        "number": "642",
        "currencyCode": "RON",
        "currency": "Leu"
    },
    {
        "code": "RU",
        "code3": "RUS",
        "name": "Russian Federation",
        "number": "643",
        "currencyCode": "RUB",
        "currency": "Ruble"
    },
    {
        "code": "RW",
        "code3": "RWA",
        "name": "Rwanda",
        "number": "646",
        "currencyCode": "RWF",
        "currency": "Rwanda Franc"
    },
    {
        "code": "RE",
        "code3": "REU",
        "name": "Réunion",
        "number": "638",
        "currencyCode": "EUR",
        "currency": "Euros"
    },
    {
        "code": "BL",
        "code3": "BLM",
        "name": "Saint Barthélemy",
        "number": "652",
        "currencyCode": "EUR",
        "currency": "Euro"
    },
    {
        "code": "SH",
        "code3": "SHN",
        "name": "Saint Helena, Ascension and Tristan da Cunha",
        "number": "654",
        "currencyCode": "GBP",
        "currency": "Saint Helena pound"
    },
    {
        "code": "KN",
        "code3": "KNA",
        "name": "Saint Kitts and Nevis",
        "number": "659",
        "currencyCode": "XCD",
        "currency": "East Caribbean Dollar"
    },
    {
        "code": "LC",
        "code3": "LCA",
        "name": "Saint Lucia",
        "number": "662",
        "currencyCode": "XCD",
        "currency": "East Caribbean Dollar"
    },
    {
        "code": "MF",
        "code3": "MAF",
        "name": "Saint Martin (French part)",
        "number": "663",
        "currencyCode": "ANG",
        "currency": "Netherlands Antillean guilder"
    },
    {
        "code": "PM",
        "code3": "SPM",
        "name": "Saint Pierre and Miquelon",
        "number": "666",
        "currencyCode": "EUR",
        "currency": "Euro"
    },
    {
        "code": "VC",
        "code3": "VCT",
        "name": "Saint Vincent and the Grenadines",
        "number": "670",
        "currencyCode": "XCD",
        "currency": "East Caribbean Dollar"
    },
    {
        "code": "WS",
        "code3": "WSM",
        "name": "Samoa",
        "number": "882",
        "currencyCode": "EUR",
        "currency": "Euros"
    },
    {
        "code": "SM",
        "code3": "SMR",
        "name": "San Marino",
        "number": "674",
        "currencyCode": "EUR",
        "currency": "Euros"
    },
    {
        "code": "ST",
        "code3": "STP",
        "name": "Sao Tome and Principe",
        "number": "678",
        "currencyCode": "STD",
        "currency": "Dobra"
    },
    {
        "code": "SA",
        "code3": "SAU",
        "name": "Saudi Arabia",
        "number": "682",
        "currencyCode": "SAR",
        "currency": "Riyal"
    },
    {
        "code": "SN",
        "code3": "SEN",
        "name": "Senegal",
        "number": "686",
        "currencyCode": "XOF",
        "currency": "CFA Franc BCEAO"
    },
    {
        "code": "RS",
        "code3": "SRB",
        "name": "Serbia",
        "number": "688",
        "currencyCode": "RSD",
        "currency": "Serbian dinar"
    },
    {
        "code": "SC",
        "code3": "SYC",
        "name": "Seychelles",
        "number": "690",
        "currencyCode": "SCR",
        "currency": "Rupee"
    },
    {
        "code": "SL",
        "code3": "SLE",
        "name": "Sierra Leone",
        "number": "694",
        "currencyCode": "SLL",
        "currency": "Leone"
    },
    {
        "code": "SG",
        "code3": "SGP",
        "name": "Singapore",
        "number": "702",
        "currencyCode": "SGD",
        "currency": "Dollar"
    },
    {
        "code": "SK",
        "code3": "SVK",
        "name": "Slovak Republic",
        "number": "703",
        "currencyCode": "SKK",
        "currency": "Koruna"
    },
    {
        "code": "SI",
        "code3": "SVN",
        "name": "Slovenia",
        "number": "705",
        "currencyCode": "EUR",
        "currency": "Euros"
    },
    {
        "code": "SB",
        "code3": "SLB",
        "name": "Solomon Islands",
        "number": "090",
        "currencyCode": "SBD",
        "currency": "Solomon Islands Dollar"
    },
    {
        "code": "SO",
        "code3": "SOM",
        "name": "Somalia",
        "number": "706",
        "currencyCode": "SOS",
        "currency": "Shilling"
    },
    {
        "code": "ZA",
        "code3": "ZAF",
        "name": "South Africa",
        "number": "710",
        "currencyCode": "ZAR",
        "currency": "Rand"
    },
    {
        "code": "GS",
        "code3": "SGS",
        "name": "South Georgia and the South Sandwich Islands",
        "number": "239",
        "currencyCode": "GBP",
        "currency": "Sterling"
    },
    {
        "code": "ES",
        "code3": "ESP",
        "name": "Spain",
        "number": "724",
        "currencyCode": "EUR",
        "currency": "Euros"
    },
    {
        "code": "LK",
        "code3": "LKA",
        "name": "Sri Lanka",
        "number": "144",
        "currencyCode": "LKR",
        "currency": "Rupee"
    },
    {
        "code": "SD",
        "code3": "SDN",
        "name": "Sudan (the)",
        "number": "729",
        "currencyCode": "SDG",
        "currency": "Dinar"
    },
    {
        "code": "SR",
        "code3": "SUR",
        "name": "Suriname",
        "number": "740",
        "currencyCode": "SRD",
        "currency": "Surinamese Guilder"
    },
    {
        "code": "SJ",
        "code3": "SJM",
        "name": "Svalbard and Jan Mayen",
        "number": "744",
        "currencyCode": "NOK",
        "currency": "Norwegian Krone"
    },
    {
        "code": "SE",
        "code3": "SWE",
        "name": "Sweden",
        "number": "752",
        "currencyCode": "SEK",
        "currency": "Krona"
    },
    {
        "code": "CH",
        "code3": "CHE",
        "name": "Switzerland",
        "number": "756",
        "currencyCode": "CHF",
        "currency": "Swiss Franc"
    },
    {
        "code": "SY",
        "code3": "SYR",
        "name": "Syrian Arab Republic",
        "number": "760",
        "currencyCode": "SYP",
        "currency": "Syrian Pound"
    },
    {
        "code": "TW",
        "code3": "TWN",
        "name": "Taiwan",
        "number": "158",
        "currencyCode": "TWD",
        "currency": "Dollar"
    },
    {
        "code": "TJ",
        "code3": "TJK",
        "name": "Tajikistan",
        "number": "762",
        "currencyCode": "TJS",
        "currency": "Tajikistan Ruble"
    },
    {
        "code": "TZ",
        "code3": "TZA",
        "name": "Tanzania, United Republic of",
        "number": "834",
        "currencyCode": "TZS",
        "currency": "Shilling"
    },
    {
        "code": "TH",
        "code3": "THA",
        "name": "Thailand",
        "number": "764",
        "currencyCode": "THB",
        "currency": "Baht"
    },
    {
        "code": "TG",
        "code3": "TGO",
        "name": "Togo",
        "number": "768",
        "currencyCode": "XOF",
        "currency": "CFA Franc BCEAO"
    },
    {
        "code": "TK",
        "code3": "TKL",
        "name": "Tokelau",
        "number": "772",
        "currencyCode": "NZD",
        "currency": "New Zealand Dollars"
    },
    {
        "code": "TO",
        "code3": "TON",
        "name": "Tonga",
        "number": "776",
        "currencyCode": "TOP",
        "currency": "PaÕanga"
    },
    {
        "code": "TT",
        "code3": "TTO",
        "name": "Trinidad and Tobago",
        "number": "780",
        "currencyCode": "TTD",
        "currency": "Trinidad and Tobago Dollar"
    },
    {
        "code": "TN",
        "code3": "TUN",
        "name": "Tunisia",
        "number": "788",
        "currencyCode": "TND",
        "currency": "Tunisian Dinar"
    },
    {
        "code": "TR",
        "code3": "TUR",
        "name": "Turkey",
        "number": "792",
        "currencyCode": "TRY",
        "currency": "Lira"
    },
    {
        "code": "TM",
        "code3": "TKM",
        "name": "Turkmenistan",
        "number": "795",
        "currencyCode": "TMT",
        "currency": "Manat"
    },
    {
        "code": "TC",
        "code3": "TCA",
        "name": "Turks and Caicos Islands (the)",
        "number": "796",
        "currencyCode": "USD",
        "currency": "USD"
    },
    {
        "code": "TV",
        "code3": "TUV",
        "name": "Tuvalu",
        "number": "798",
        "currencyCode": "AUD",
        "currency": "Australian Dollars"
    },
    {
        "code": "UG",
        "code3": "UGA",
        "name": "Uganda",
        "number": "800",
        "currencyCode": "UGX",
        "currency": "Shilling"
    },
    {
        "code": "UA",
        "code3": "UKR",
        "name": "Ukraine",
        "number": "804",
        "currencyCode": "UAH",
        "currency": "Hryvnia"
    },
    {
        "code": "AE",
        "code3": "ARE",
        "name": "United Arab Emirates (the)",
        "number": "784",
        "currencyCode": "AED",
        "currency": "Dirham"
    },
    {
        "code": "GB",
        "code3": "GBR",
        "name": "United Kingdom",
        "number": "826",
        "currencyCode": "GBP",
        "currency": "Sterling"
    },
    {
        "code": "UM",
        "code3": "UMI",
        "name": "United States Minor Outlying Islands",
        "number": "581",
        "currencyCode": "USD",
        "currency": "USD"
    },
    {
        "code": "US",
        "code3": "USA",
        "name": "United States of America",
        "number": "840",
        "currencyCode": "USD",
        "currency": "USD"
    },
    {
        "code": "UY",
        "code3": "URY",
        "name": "Uruguay",
        "number": "858",
        "currencyCode": "UYU",
        "currency": "Peso"
    },
    {
        "code": "UZ",
        "code3": "UZB",
        "name": "Uzbekistan",
        "number": "860",
        "currencyCode": "UZS",
        "currency": "Som"
    },
    {
        "code": "VU",
        "code3": "VUT",
        "name": "Vanuatu",
        "number": "548",
        "currencyCode": "VUV",
        "currency": "Vatu"
    },
    {
        "code": "VE",
        "code3": "VEN",
        "name": "Venezuela (Bolivarian Republic of)",
        "number": "862",
        "currencyCode": "VEF",
        "currency": "Bolivar"
    },
    {
        "code": "VN",
        "code3": "VNM",
        "name": "Viet Nam",
        "number": "704",
        "currencyCode": "VND",
        "currency": "Dong"
    },
    {
        "code": "VG",
        "code3": "VGB",
        "name": "Virgin Islands (British)",
        "number": "092",
        "currencyCode": "USD",
        "currency": "USD"
    },
    {
        "code": "VI",
        "code3": "VIR",
        "name": "Virgin Islands (U.S.)",
        "number": "850",
        "currencyCode": "USD",
        "currency": "USD"
    },
    {
        "code": "WF",
        "code3": "WLF",
        "name": "Wallis and Futuna",
        "number": "876",
        "currencyCode": "XPF",
        "currency": "CFP Franc"
    },
    {
        "code": "EH",
        "code3": "ESH",
        "name": "Western Sahara",
        "number": "732",
        "currencyCode": "MAD",
        "currency": "Dirham"
    },
    {
        "code": "YE",
        "code3": "YEM",
        "name": "Yemen",
        "number": "887",
        "currencyCode": "YER",
        "currency": "Rial"
    },
    {
        "code": "ZM",
        "code3": "ZMB",
        "name": "Zambia",
        "number": "894",
        "currencyCode": "ZMK",
        "currency": "Kwacha"
    },
    {
        "code": "ZW",
        "code3": "ZWE",
        "name": "Zimbabwe",
        "number": "716",
        "currencyCode": "ZWD",
        "currency": "Zimbabwe Dollar"
    },
    {
        "code": "AX",
        "code3": "ALA",
        "name": "Åland Islands",
        "number": "248",
        "currencyCode": "EUR",
        "currency": "Euro"
    }
];