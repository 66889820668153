import {useEffect, useState} from "react";
import {IPopScoreResponse} from "../../interfaces/score";
import {getPopScoreByUser} from "../../api/score";


export const useUsersPopScore = (userId: string) => {
    const [popScoreResponse, setPopScoreResponse] = useState<IPopScoreResponse[]>([])

    const getStarsCounterByEnumValue = (amountOfStars: number) => {
        if (!!popScoreResponse?.length) {
            const starsArr = popScoreResponse?.filter((score) => score.count === amountOfStars)
            return starsArr?.length
        } else {
            return 0
        }
    }

    const totalScore = !!popScoreResponse?.length ? popScoreResponse?.length : 0
    useEffect(() => {
        (async () => {
            const response = await getPopScoreByUser(userId)
            if (response) {
                setPopScoreResponse(response?.data)
            }
        })()
        // eslint-disable-next-line
    }, [])

    return {
        getStarsCounterByEnumValue,
        totalScore
    }
}
