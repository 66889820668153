import React, { useEffect, useState } from "react";
import { DBSToWisePayoutHeader, DBSToWisePayoutTableName } from "../../constant/TableHeaders/dbs-to-wise-payout";
import Table from "../../components/Table";
import { useDBSToWisePayout } from "../../service/dbs-to-wise-payout/dbs-to-wise-payout";
import { basicBodyVariables } from "../../utils/body-variables";
import { INIT_PAGINATION, PAGE_SIZE } from "../../constant/pagination";
import { ISort } from "../../interfaces/sort";
import { SORT_BY } from "../../constant/dbs-to-wise-payout";
import { usePagination } from "../../service/common/pagination";
import { getDBSToWisePayoutData } from "../../api/dbs-to-wise-payout";
import Modal from "../../components/Modal";
import { useBeautifyJsonString } from "../../service/common/beautify-json-string";
import HTMLReactParser from "html-react-parser";
import { useHistory } from "react-router-dom";

const DBSToWisePayout: React.FC = () => {

    const [dbsToWisePayout, setDBSToWisePayout] = useState<any>([])
    const [currentPage, setCurrentPage] = useState<number>(0)

    const [previewTitle, setPreviewTitle] = useState<string | null>(null)
    const [openPreviewModal, setOpenPreviewModal] = useState<boolean>(false)
    const { beautifyString, beautifiedString: previewContent } = useBeautifyJsonString()
    const history = useHistory();

    // const [search, setSearch] = useState<string>("");
    // const [sort, setSort] = useState<ISort>({value: SORT_BY[0].value, label: SORT_BY[0].label});
    const search: string = "";
    const sort: ISort = { value: SORT_BY[0].value, label: SORT_BY[0].label };

    const { dbsToWisePayoutData } = useDBSToWisePayout(
        basicBodyVariables(INIT_PAGINATION, search, sort.value)
    )

    useEffect(() => {
        setDBSToWisePayout(dbsToWisePayoutData?.items)
    }, [dbsToWisePayoutData])

    const [newData] = usePagination(getDBSToWisePayoutData, {
        variables: basicBodyVariables({ page: currentPage + 1, pageSize: PAGE_SIZE }, search, sort.value)
    });

    useEffect(() => {
        if (dbsToWisePayout && newData?.items && newData?.items?.length > 0) {
            setDBSToWisePayout(dbsToWisePayout.concat(newData.items))
            setCurrentPage(newData?.currentPage)
        }
        // eslint-disable-next-line
    }, [newData])

    const viewData = async (item: any, key:any) =>{
        setPreviewTitle(DBSToWisePayoutHeader.find(item=>item.meta.key === key)?.title ?? '')
        beautifyString(item[key])
        setOpenPreviewModal(true)
    }

    return (
        <>
         <div className="back-btn-div">
                <button
                    className="back-btn mt-2 mb-2"
                    onClick={() => history.goBack()}
                >
                    Back
                </button> </div>
        <div className="dbs-to-wise-payout" >
           <Table items={dbsToWisePayout} header={DBSToWisePayoutHeader} tableName={DBSToWisePayoutTableName} onLinkClickHandler={viewData}/>
        </div>
        <Modal
            open={openPreviewModal}
            setOpen={setOpenPreviewModal}
            title={previewTitle}
            content={HTMLReactParser(previewContent)}
            confirmButton={'Ok'}
            customClass="dbs-payout-info-modal"
        />
        </>
    );
};

export default DBSToWisePayout;
