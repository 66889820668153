import {COLUMN_TYPE} from "../../interfaces/table";
import { HeaderItemType } from "../../interfaces/table";
import {ROUTES} from "../../enum/routes";

export const FeedbacksTableName = "Feedbacks";

export const FeedbackOnClick = ({ history, item }: any) => {
    history.push({
        pathname: `${ROUTES.PAGES.FEEDBACK_DETAILS}`,
        state: item
    });
}

export const FaedbacksHeader = [
    {
        title: "",
        type: COLUMN_TYPE.STRING,
        meta: {
            key: "is_read",
        },
    },
    {
        title: "Ticket ID",
        type: COLUMN_TYPE.STRING,
        meta: {
            key: "id",
        },
    },
    {
        title: "Status",
        type: COLUMN_TYPE.STRING,
        meta: {
            key: "status",
        },
    },
    {
        title: "Registration date",
        type: COLUMN_TYPE.STRING,
        meta: {
            key: "created_at",
        },
    },
    {
        title: "Reported by",
        type: COLUMN_TYPE.STRING,
        meta: {
            key: "reporter",
            object_key: ["first_name", "last_name"]
        },
    },
    // {
    //     title: "Report type",
    //     type: COLUMN_TYPE.STRING,
    //     meta: {
    //         key: "type"
    //     },
    // },
] as HeaderItemType[];

//will be deleted after updated BE
export const dataFeedback: any = [
    {
        id: 2,
        status: "RESOLVED",
        type: "FEEDBACK_FORM",
        reporter:{
            id: 10,
            email: "",
            first_name: "Vova",
            last_name: "Yakymiv",
            role: "VU",
            public_avatar_url: "string"
        },
        reportee:  {
            id: 20,
            email: "peter@xsine.co",
            first_name: "Serg",
            last_name: "Marivtsan",
            role: "VU",
            public_avatar_url: "string"
        },
        reason: "INAPPROPRIATE_CONTENT",
        text_form: "string",
        support_topic: "HOSTING",
        post_assets: [
            "string"
        ],
        post_text: "string",
        created_at: "2021-04-30T09:32:45.410Z"
    }
];
