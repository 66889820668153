import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button, TextField } from "@mui/material";
import Table from '../../../components/Table';
import { EmailNotificationManagementHeader } from "../../../constant/TableHeaders/email-notification-management-header";
import { useGetEmailNotificationManagement } from "../../../service/email-notification-management/email-notification-management";
import { emailNotificationMangementBodyVariable } from "../../../utils/body-variables";
import { INIT_PAGINATION, PAGE_SIZE } from "../../../constant/pagination";
import { ISort } from "../../../interfaces/sort";
import { SORT_BY } from "../../../constant/email-notification-management";
import { usePagination } from "../../../service/common/pagination";
import { deleteEmailNotificaitonManagementData, getEmailNotificaitonManagementData, addEmailNotificationManagementData } from "../../../api/email-notification-management";
import { EMAIL_NOTIFICATION_MANAGEMENT_TYPE } from "../../../enum/email-notification-management";
import Modal from "../../../components/Modal";
import SuccessOrErrorModal from "../../../components/Modal/SuccessOrErrorModal";
import { INFO_MODAL } from "../../../enum/successOrErrorModal";
import { EmailNotificationManagementDTO, ILocation } from "../../../interfaces/email-notification-management";

const RequestsEmailList = () => {
    const history = useHistory()
    const location : ILocation = useLocation()
    const email_notification_type: string = location.state;
    const search: string = "";
    const sort: ISort = { value: SORT_BY[0].value, label: SORT_BY[0].label };

    const [emailNotificationManagements, setEmailNotificationManagements] = useState<EmailNotificationManagementDTO[]>([])
    const [currentPage, setCurrentPage] = useState<number>(0)

    const [openAddEmailModal, setOpenAddEmailModal] = useState<boolean>(false)
    const [openRemoveEmailModal, setOpenRemoveEmailModal] = useState<boolean>(false)
    const [inputName, setInputName] = useState<string>('')
    const [inputEmail, setInputEmail] = useState<string>('')
    
    const [openAddEmailResponseModal, setOpenAddEmailResponseModal] = useState<boolean>(false)
    const [addEmailResult, setAddEmailResult] = useState<boolean | null>(null)
    const [addEmailMsg, setAddEmailMsg] = useState<string | null>(null)

    const [selectedItem, setSelectedItem] = useState<EmailNotificationManagementDTO | null>()
    const { emailNotificationManagementData } = useGetEmailNotificationManagement(
        emailNotificationMangementBodyVariable(INIT_PAGINATION, search, sort.value, email_notification_type)
    )

    useEffect(() => {
        setEmailNotificationManagements(emailNotificationManagementData?.items)
    }, [emailNotificationManagementData])

    const [newData] = usePagination(getEmailNotificaitonManagementData, {
        variables: emailNotificationMangementBodyVariable({ page: currentPage + 1, pageSize: PAGE_SIZE }, search, sort.value, email_notification_type)
    });


    useEffect(() => {
        if (emailNotificationManagements && newData?.items && newData?.items?.length > 0) {
            setEmailNotificationManagements(emailNotificationManagements.concat(newData.items))
            setCurrentPage(newData?.currentPage)
        }
        // eslint-disable-next-line
    }, [newData])

    const removeEmail = async () =>{
        if(!selectedItem) return;
        const res = await deleteEmailNotificaitonManagementData(selectedItem.id)
        if(res?.affected){
            setSelectedItem(null)
            const updatedData = await getEmailNotificaitonManagementData(emailNotificationMangementBodyVariable(INIT_PAGINATION, search, sort.value, email_notification_type))
            setEmailNotificationManagements(updatedData?.items)
        }
    }

    const displayTableName = () =>{
        switch (email_notification_type) {
            case EMAIL_NOTIFICATION_MANAGEMENT_TYPE.COMPLAINT:
                return `Complaint Emails`;
            case EMAIL_NOTIFICATION_MANAGEMENT_TYPE.FEEDBACK:
                return `Feedback Emails`;
            case EMAIL_NOTIFICATION_MANAGEMENT_TYPE.HOSTING_ESCALATION:
                return `Hosting Escalation Emails`;
            case EMAIL_NOTIFICATION_MANAGEMENT_TYPE.VERIFICATION_REQUEST:
                return `Verification Request Emails`;
            default: 
                return `Request Emails`
        }
    }

    const addEmailForm = () =>{
        return <div className="add-email-form">
                <TextField
               id="outlined"
                label="Name" value={inputName}
                onChange={(event: any) => setInputName(event.target.value)} name="name"
                InputLabelProps={{
                    shrink: true,
                }}
                />
                <TextField
                className="mt-4"
                id="outlined"
                label="E-Mail"
                value={inputEmail} onChange={(event: any) => setInputEmail(event.target.value)} name="email"
                InputLabelProps={{
                    shrink: true,
                }}
                />
        </div>
    }

    const addEmail = async () =>{
        const data = {
            name: inputName,
            email: inputEmail,
            type: email_notification_type
        }
        const res = await addEmailNotificationManagementData(data)
        if(res?.result?.isExpected){
            const updatedData = await getEmailNotificaitonManagementData(emailNotificationMangementBodyVariable(INIT_PAGINATION, search, sort.value, email_notification_type))
            setEmailNotificationManagements(updatedData?.items)
        }
        setInputName('')
        setInputEmail('')
        setAddEmailResult(!!res?.result?.isExpected)
        setAddEmailMsg(res?.message)
        setOpenAddEmailResponseModal(true)
    }

    return <>
        {/* <Button
            variant="outlined"
            className="go-back-button"
            onClick={goToEmailNotificationManagement}
        >
            Back
        </Button>      */}
           <div className="back-btn-div">
                <button
                    className="back-btn mt-2 mb-0"
                    onClick={() => history.goBack()}
                >
                    Back
                </button> </div>
        <br/>
        <Button
            variant="outlined"
            className="add-email-button"
            onClick={() => setOpenAddEmailModal(true)}
        >
            Add Email
        </Button>   
        <Table
            tableName={displayTableName()}
            header={EmailNotificationManagementHeader}
            items={emailNotificationManagements}
            onButtonClickHandler={(value: any) =>{ 
                setOpenRemoveEmailModal(true) 
                setSelectedItem(value)}
            }
        />
        { openAddEmailModal && 
            <Modal
                open={openAddEmailModal}
                setOpen={setOpenAddEmailModal}
                cancelButton={`Cancel`}
                title={`Add Email`}
                content={addEmailForm()}
                confirmButton={`Save`}
                onComplete={addEmail}
            />
        }
        { openRemoveEmailModal && selectedItem && 
            <Modal
                open={openRemoveEmailModal}
                setOpen={setOpenRemoveEmailModal} 
                cancelButton={`Cancel`}
                title={`Remove Email`}
                content={
                    <>
                    <div>Please confirm that you want to remove user from the email list :</div>
                    <br/>
                    <div><b>{selectedItem.name}</b></div>
                    <div><b>{selectedItem.email}</b></div>
                    </>
                }
                confirmButton={`Remove`}
                onComplete={removeEmail}
            />
        }
        { openAddEmailResponseModal && (addEmailResult !== null) &&
            <SuccessOrErrorModal
                open={openAddEmailResponseModal}
                setOpen={setOpenAddEmailResponseModal}
                type={addEmailResult ? INFO_MODAL.SUCCESS : INFO_MODAL.ERROR}
                content={addEmailMsg}
                button={`Ok`}
                buttonClass="close-modal-button"
            />    
        }
    </>
}

export default RequestsEmailList;