import axios from "axios";
import { IPagination } from "../interfaces/request";

export interface IGetSystemMeetings {
  search_by?: string;
  sort_by?: string;
  pagination: IPagination;
}

export interface IAdminComment {
  system_meeting_id: string
  admin_comment: string,
  meeting_failed: boolean,
}

export const getSystemMeetingData = (data: IGetSystemMeetings) => {
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/admin/systemMeetings`,
    data,
  })
    .then((response: any) => response?.data)
    .catch((error) => error.response?.data);
};

export const updateSystemMeetingData = (data: IAdminComment) => {
  return axios({
    method: "PUT",
    url: `${process.env.REACT_APP_API_URL}/admin/systemMeetings/update`,
    data,
  })
    .then((response: any) => response?.data)
    .catch((error) => error.response?.data);
};