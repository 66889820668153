import React, {useCallback, useEffect, useState} from 'react';
import Input from "../../../components/Input";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CustomSelect from "../../../components/Select";
import {SORT_BY} from "../../../constant/sort-complaints";
import Table from "../../../components/Table";
import {useAllTickets} from "../../../service/tickets/tickets";
import {FaedbacksHeader, FeedbacksTableName} from "../../../constant/TableHeaders/feedback-header";
import {ISort} from "../../../interfaces/sort";
import Loader from "../../../components/Loader";
import {bodyVariablesForTickets} from '../../../utils/body-variables';
import {INIT_PAGINATION, PAGE_SIZE} from '../../../constant/pagination';
import {TICKET_STATUS, TICKET_TYPE} from '../../../enum/tickets';
import {usePagination} from '../../../service/common/pagination';
import {getAllTickets} from '../../../api/tickets';
import TicketDetails from '../tickets-details';
import {onHandleSearchAndSort} from '../../../utils/sort-search';
import {overflowBody} from '../../../utils/style-document';
import {useUpdateTicketFromUnreadToRead} from "../../../service/tickets/update-from-unread-to-read";
import { useHistory } from 'react-router-dom';


const Feedbacks: React.FC = () => {
    const [search, setSearch] = useState<string>("");
    const [sort, setSort] = useState<ISort>({value: "DESC", label: "DESC"});
    const [currentPage, setCurrentPage] = useState<number>(0)
    const [feedbacksTickets, setFeedbacksTickets] = useState<any[]>([])
    const [openFeedbackInfo, setOpenFeedbackInfo] = useState<boolean>(false)
    const [feedbackInfo, setFeedbackInfo] = useState<number>()
    const history = useHistory();
    useUpdateTicketFromUnreadToRead(feedbackInfo, setFeedbacksTickets)

    const {onHandleSort, onHandleSearch, onHandleClear} = onHandleSearchAndSort(setCurrentPage, setSort, setSearch)

    const {tickets, loading} = useAllTickets(
        bodyVariablesForTickets(INIT_PAGINATION, sort?.value, '', {
            key: search,
            ticket_status: 'ACTIVE',
            ticket_type: TICKET_TYPE.FEEDBACK_FORM
        })
    )

    useEffect(() => {
        if (tickets) {
            setFeedbacksTickets(tickets?.items)
        }
    }, [tickets])

    const [newData] = usePagination(getAllTickets, {
        variables: bodyVariablesForTickets(
            {page: currentPage + 1, pageSize: PAGE_SIZE},
            sort?.value,
            '',
            {key: search, ticket_status: 'ACTIVE', ticket_type: TICKET_TYPE.FEEDBACK_FORM},
        )
    });

    useEffect(() => {
        if (newData?.items && newData?.items?.length > 0) {
            setFeedbacksTickets([...feedbacksTickets, ...newData?.items])
            setCurrentPage(newData?.currentPage)
        }
        // eslint-disable-next-line
    }, [newData])

    const openFeedbackCard = async (value: any) => {
        setOpenFeedbackInfo(true)
        setFeedbackInfo(value)
    }

    overflowBody(openFeedbackInfo)

    const updateFeedbackList = useCallback((ticket: any, resolvedTicket: any) => {
        const index = feedbacksTickets?.findIndex((i: any) => i?.id === ticket?.id)
        setFeedbacksTickets(((prevState: any) => {
            prevState[index] = resolvedTicket
            return prevState
        }))
        // eslint-disable-next-line
    }, [setFeedbacksTickets])

    const showOnlyActiveFeedbacks = feedbacksTickets?.filter((ticket: any) => ticket?.status !== TICKET_STATUS.RESOLVED)

    return (
        <>
        <div className="back-btn-div">
        <button
          className="back-btn mt-2 mb-2"
          onClick={() => history.goBack()}
        >
          Back
        </button> </div>{loading && !search && <Loader/>}
           
            {openFeedbackInfo && <TicketDetails
                type={TICKET_TYPE.FEEDBACK_FORM}
                info={feedbackInfo}
                setOpen={setOpenFeedbackInfo}
                updateTicketsDataList={updateFeedbackList}
            />}
            <div className='reports-page'>
                <div className="top-panels">
                    <label className="input-wrapper">
                        <Input
                            onChange={onHandleSearch}
                            value={search}
                            placeholder={"Search"}
                            type={"search"}
                            classInput="input-search"
                        />
                        <span className="clear-input">
            <span className="clear-input" onClick={onHandleClear}>
            {search ? <HighlightOffIcon/> : null}
          </span>
          </span>
                    </label>
                    <CustomSelect
                        onChange={onHandleSort}
                        placeholder={sort.label || "Sort"}
                        options={SORT_BY}
                    />
                </div>
                {!!showOnlyActiveFeedbacks?.length ? (
                        <div className="report-table">
                            <Table
                                header={FaedbacksHeader}
                                items={showOnlyActiveFeedbacks}
                                tableName={FeedbacksTableName}
                                onClickHandler={openFeedbackCard}
                            />
                        </div>) :
                    <h2 className="nth-found">Nothing found</h2>
                }
            </div>
        </>
    )
}

export default Feedbacks;
