export enum COLUMN_TYPE {
  STRING,
  BUTTON,
  LINK
}

export interface ColumnProps {
  item: any;
  meta: any;
}

export type HeaderItemType = {
  title: string;
  type: COLUMN_TYPE;
  meta: any;
};

export interface TableProps {
  items: any[];
  header: HeaderItemType[];
  tableName: string;
  onClickHandler?: any,
  onButtonClickHandler?: any
  onLinkClickHandler?: any
}

interface onClickType {
  history: any;
  item: any;
  event: any;
}

interface ButtonMetaTypes {
  onClick: (props: onClickType) => void;
  label: string;
}

interface LinkTextMetaTypes {
  onClick: (props: onClickType) => void;
  key: any;
  obj?: string;
} 

export interface ButtonColumnTypes extends ColumnProps {
  meta: ButtonMetaTypes; 
  onButtonClickHandler?: any
}

export interface LinkTextColumnTypes extends ColumnProps {
  meta: LinkTextMetaTypes; 
  onLinkClickHandler?: any
}
