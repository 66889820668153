import {useEffect, useState} from "react";
import {IVerificationGetRequest, IVerificationResponse} from "../../interfaces/verification-requests";
import {getVerificationRequests} from "../../api/verification-requests";

export const useGetVerificationRequests = (body: IVerificationGetRequest) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [verificationRequestsData, setVerificationRequestsData] = useState<IVerificationResponse>()


    const getData = async () => {
        setLoading(true)
        const response = await getVerificationRequests(body)
        if (response) {
            setLoading(false)
            setVerificationRequestsData(response)
        }
    }

    useEffect(() => {
        (async () => {
            await getData()
        })()
        return () => setVerificationRequestsData(undefined)
        // eslint-disable-next-line
    }, [])

    return {
        verificationRequestsData,
        loading
    }
}

