import axios from "axios";
import { IPagination } from "../interfaces/request";
import {IEditProfileSettings, IExtendedUserInfo} from "../interfaces/user";

export interface IGetUsers {
  search_by?: string;
  sort_by?: string ;
  isSuspended?: boolean;
  pagination: IPagination;
}

// export interface IFlowFilterDTO {
//     status: string
// }

export const getUsers = (data: IGetUsers) => {
    return axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/admin/users`,
        data: {...data, sort_by: 'CREATED_AT'}
    })
        .then((response: any) => response?.data)
        .catch((error) => error.response?.data);
};

export const getUsersCount = () => {
    return axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/admin/users/count`
    })
        .then((response: any) => response?.data)
        .catch((error) => error.response?.data);
};

export const getUser = (data: { id: string, asset_size: string }) => {
    return axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/admin/user/${data.id}`,
        data: data.asset_size
    })
        .then((response: any) => response?.data)
        .catch((error) => error.response?.data);
};

export const getUserComplaintsCount = (id: string, data: {type: any}) => {
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/admin/complaints/count/${id}`,
    data
  })
      .then((response: any) => response?.data)
      .catch((error) => error.response?.data);
};


export const updateLoginSercurity = (data: IEditProfileSettings) => {
    return axios.put(`${process.env.REACT_APP_API_URL}/user/system`, data)
        .then((response) => response && response.data)
        .catch((error) =>
            error &&
            error.response &&
            error.response.message
                ? {error: error.response.message}
                : {error: 'unknown error'},
        );
}

export const blockUser = (userId: string | number) => {
    return axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/admin/user/suspend/${userId}`,
    })
        .then((response: any) => response?.data)
        .catch((error) => error.response?.data);
};

export const activateUser = (userId: number) => {
    return axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/admin/user/activate/${userId}`,
    })
    .then((response: any) => response?.data)
    .catch((error) => error.response?.data);
};

export const blockUserHosting = (userId: string | number) => {
    return axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/admin/user/suspend/hosting/${userId}`,
    })
        .then((response: any) => response?.data)
        .catch((error) => error.response?.data);
};

export const activateUserHosting = (userId: number) => {
    return axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/admin/user/activate/hosting/${userId}`,
    })
        .then((response: any) => response?.data)
        .catch((error) => error.response?.data);
};

export const deleteUserPost = (postId: number) => {
    return axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_API_URL}/admin/remove/post/${postId}`,
    })
        .then((response: any) => response?.data)
        .catch((error) => error.response?.data);
};

export const changeStatusInEarnings = (userId: number, status: string) => {
    return axios({
        method: "PUT",
        url: `${process.env.REACT_APP_API_URL}/admin/earnings/status/${userId}`,
        data: {status: status}
    })
        .then((response: any) => response?.data)
        .catch((error) => error.response?.data);
};

export const changeFeature = (userId: number) => {
    return axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/admin/user/${userId}/feature`,
    })
        .then((response: any) => response?.data)
        .catch((error) => error.response?.data);
};

export const updateUser = (user: IExtendedUserInfo) => {
    return axios({
        method: "PUT",
        url: `${process.env.REACT_APP_API_URL}/user`,
        data: {
            first_name: user.first_name?.trim(),
            last_name: user.last_name,
            gender: user.gender,
            dob: user.dob,
            short_bio: user.short_bio,
            hrf: user.hrf,
            custom_hosting_rate: user?.custom_hosting_rate,
            // public_avatar_url: user.public_avatar_url,
            metadata: user.metadata,
            id: user.id
        }
    })
        .then((response) => response && response.data)
        .catch((error) =>
            error &&
            error.response &&
            error.response.message
                ? {error: error.response.message}
                : {error: 'unknown error'},
        );
}