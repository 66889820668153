// eslint-disable-next-line
import { Button, Card, CardContent, TextField } from "@material-ui/core";
import { RootStateOrAny, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { getUser, updateUser } from "../../api/user";
import { SIZE } from "../../enum/asset-size";
import SuccessOrErrorModal from "../../components/Modal/SuccessOrErrorModal";
import { INFO_MODAL } from "../../enum/successOrErrorModal";
import { ROUTES } from "../../enum/routes";
// interface IProps {
//   bio: string;
//   setBio: React.Dispatch<React.SetStateAction<string>>;
// }
const EditUserProfile = () => {
  const history = useHistory();
  // const [userLoading, setUserLoading] = useState<boolean>(false);
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
  const [user, setUser] = useState<any>();
  const [editedText, setEditedText] = useState("");
  const { variableValue: maxCharacters } = useVariableValues(
    "PROFILE_BIO_CHARACTERS_COUNT"
  );
  const maxCharactersAllowed = maxCharacters ? maxCharacters : 155;
  // eslint-disable-next-line
  const [openEditBio, setOpenEditBio] = useState<boolean>(false);
  // const [openEditBio, setOpenEditBio] = useState<boolean>(false)
  // const [bioLoader, setBioLoader] = useState<boolean>(false);
  const userId: any = history.location?.state || "";

  useEffect(() => {
    (async () => {
      if (userId) {
        // setUserLoading(true);
        getUserData();
      }
    })();
    // eslint-disable-next-line
  }, [history.location?.state]);

  const getUserData = async () => {
    const response = await getUser({ id: userId.id, asset_size: SIZE.SMALL });
    console.log(response,"value")
    if (response) {
      // setUserLoading(false);
      setUser(response?.data);
      setEditedText(response?.data?.short_bio);
    }
  };

  const handleInputChange = (e: any) => {
    setEditedText(e.target.value.slice(0, +maxCharactersAllowed));
  };

  const editBio = async () => {
    // setBioLoader(true);
    const deepUserCopy = JSON.parse(JSON.stringify(user));
    console.log(user,"userData",deepUserCopy)
    deepUserCopy.short_bio = editedText;
    deepUserCopy.id = userId.id;
    const response = await updateUser(deepUserCopy);
    setOpenSuccessModal(true);
    setTimeout(() => {
      history.push(ROUTES.PAGES.USERS);
    }, 1200);
    setOpenEditBio(false);
    if (response) {
      // setBioLoader(false);
      return await getUserData();
    }
  };
  const cancelEdit = () => {
    setEditedText(user?.short_bio);
    setOpenEditBio(false);
    history.goBack();
  };
  return (
    <div className="containers d-flex justify-content-center">
      <Card style={{ width: "720px" }}>
        <CardContent>
          <h3>Edit short bio</h3>
          <div className="info-card">
            <TextField
              required
              id="outlined-required"
              label="Short bio"
              defaultValue={editedText}
              value={editedText}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              onChange={(e) => handleInputChange(e)}
              multiline
              minRows={5}
              style={{ width: "100%" }}
            />
            <div className={"bio-counter mb-4"}>
              {editedText === undefined || editedText == null
                ? `0/${maxCharactersAllowed}`
                : editedText?.length + `/${maxCharactersAllowed}`}
            </div>
            <div className="btn-container mb-2">
              <Button
                variant="text"
                aria-label="delete"
                className="btn cancel"
                onClick={() => cancelEdit()}
              >
                Cancel
              </Button>
              <Button
                variant="text"
                color="primary"
                className="btn save"
                onClick={() => editBio()}
                disabled={editedText?.length > 0 ? false : true}
              >
                Save
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>
      {openSuccessModal && (
        <SuccessOrErrorModal
          title={"Success"}
          open={openSuccessModal}
          setOpen={setOpenSuccessModal}
          type={INFO_MODAL.SUCCESS}
          content={"Bio edited successfully!"}
          //   onActionClicked={() => history.goBack()}
        />
      )}
    </div>
  );
};
export default EditUserProfile;

export const useVariableValues = (variableKey: string) => {
  const variable = useSelector((state: RootStateOrAny) =>
    state.variable?.variables.find(
      (v: { key: string; value: string }) => v?.key === variableKey
    )
  );
  const variableValue = variable?.value;
  return {
    variableValue,
  };
};
