import React, { useEffect, useState } from "react";
import SuccessOrErrorModal from "../../components/Modal/SuccessOrErrorModal";
import { INFO_MODAL } from "../../enum/successOrErrorModal";
import Modal from "../../components/Modal";
import Loader from "../../components/Loader";
import AddIcon from "@material-ui/icons/Add";
import AddNewSMTPModal from "./Modals/AddNewSMTP";
import Table from "../../components/Table";
import { COLUMN_TYPE } from "../../interfaces/table";
import { useSMTPInfo } from "../../service/smtp/smtpInfo";
import { IUpdateSmtpBody } from "../../interfaces/smtp";
import { deleteSmtpData } from "../../api/smtp";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../enum/routes";

const SMTP = () => {
  //MODAL WINDOW
  const history = useHistory();
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
  const [openValidationErrorModal, setOpenValidationErrorModal] =
    useState<boolean>(false);
  const [openAddFieldModal, setOpenAddFieldModal] = useState<boolean>(false);
  const [triggerRefetch, setTriggerRefetch] = useState<boolean>(false);
  const { smtpTableInfo, smtpLoading } = useSMTPInfo(triggerRefetch);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [editableData, setEditableData] = useState<IUpdateSmtpBody>();
  const [deleteSMTPId, setDeleteSMTPId] = useState<number>(0);
  const [successMsg, setSuccessMsg] = useState<string>("");

  const [rows, setRows] = useState<any>([]);

  const smtpHeader = [
    {
      title: "ID",
      type: COLUMN_TYPE.STRING,
      meta: {
        key: "id",
      },
    },
    {
      title: "Email",
      type: COLUMN_TYPE.STRING,
      meta: {
        key: "username",
      },
    },
    {
      title: "SMTP",
      type: COLUMN_TYPE.STRING,
      meta: {
        key: "host",
      },
    },
    {
      title: "Port",
      type: COLUMN_TYPE.STRING,
      meta: {
        key: "port",
      },
    },
    {
      title: "Edit",
      type: COLUMN_TYPE.BUTTON,
      // icon: <Edit/>,
      meta: {
        label: "Edit",
        onClick: ({ history, item }: any) => {
          setIsEdit(true);
          setEditableData(item);
          setOpenAddFieldModal(true);
        },
      },
    },
    {
      title: "Delete",
      type: COLUMN_TYPE.BUTTON,
      // icon: <Edit/>,
      meta: {
        label: "Delete",
        onClick: ({ history, item, event }: any) => {
          setDeleteSMTPId(item.id);
          setOpenConfirmModal(true);
          event.stopPropagation();
        },
      },
    },
    //   {
    //     title: "Secure",
    //     type: COLUMN_TYPE.STRING,
    //     meta: {
    //       key: "secure",
    //     },
    //   },
  ];

  useEffect(() => {
    if (smtpTableInfo) {
      setRows(smtpTableInfo);
    }
  }, [smtpTableInfo]);

  const deleteSMTP = async () => {
    setTriggerRefetch(true);
    const response = await deleteSmtpData(deleteSMTPId);
    if (!response) {
      setOpenErrorModal(true);
      setTriggerRefetch(false);
    } else {
      setOpenSuccessModal(true);
      setSuccessMsg("You have delete SMTP configuration sucessfully.");
      setTriggerRefetch(false);
    }
  };
  const onComplete = async (
    apiRequest: (params?: any) => Promise<any>,
    requestBody: any
  ) => {
    setTriggerRefetch(true);
    const response = await apiRequest(requestBody);
    if (!response) {
      setOpenErrorModal(true);
      setTriggerRefetch(false);
    } else {
      setOpenSuccessModal(true);
      setSuccessMsg(
        `You have ${
          !isEdit ? "added" : "updated"
        } smtp configuration successfully.`
      );
      setTriggerRefetch(false);
    }
  };

  if (smtpLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className="back-btn-div">
        <button
          className="back-btn smtp-btn"
          onClick={() => history.push(ROUTES.PAGES.OVERVIEW)}
        >
          Back
        </button>
      </div>
      {rows.length > 0 ? (
        <Table header={smtpHeader} items={rows} tableName="SMTP" />
      ) : (
        <h2 className="nth-found">Nothing found</h2>
      )}
      {openValidationErrorModal && (
        <SuccessOrErrorModal
          title={"Error"}
          type={INFO_MODAL.ERROR}
          button={<button>OK</button>}
          content={"Field value is not valid!"}
          open={openValidationErrorModal}
          setOpen={setOpenValidationErrorModal}
        />
      )}
      {openErrorModal && (
        <SuccessOrErrorModal
          title={"Error"}
          type={INFO_MODAL.ERROR}
          button={<button>OK</button>}
          content={"Something went wrong!"}
          open={openErrorModal}
          setOpen={setOpenErrorModal}
        />
      )}

      {openSuccessModal && (
        <SuccessOrErrorModal
          title={"Success"}
          type={INFO_MODAL.SUCCESS}
          button={<button>Ok</button>}
          content={
            successMsg !== ""
              ? successMsg
              : `You successfully updated smtp field!`
          }
          open={openSuccessModal}
          setOpen={setOpenSuccessModal}
        />
      )}
      {openConfirmModal && (
        <Modal
          title={"Confirm"}
          confirmButton={<button>Confirm</button>}
          cancelButton={<button>Cancel</button>}
          content={`Are you sure you want to delete this SMTP configuration?`}
          open={openConfirmModal}
          setOpen={setOpenConfirmModal}
          onComplete={deleteSMTP}
        />
      )}
      {openAddFieldModal && (
        <AddNewSMTPModal
          open={openAddFieldModal}
          setOpen={setOpenAddFieldModal}
          onComplete={onComplete}
          edit={isEdit}
          setIsEdit={setIsEdit}
          editableData={editableData}
        />
      )}
      <div
        className={"add-field"}
        onClick={() => {
          setOpenAddFieldModal(true);
          setIsEdit(false);
          setEditableData(undefined);
        }}
      >
        <AddIcon />
      </div>
    </>
  );
};
export default SMTP;
