import { COLUMN_TYPE } from "../../components/Table/column-factory";
import { HeaderItemType } from "../../interfaces/table";

export const DBSToWisePayoutTableName = "DBS To Wise Payout"

export const DBSToWisePayoutHeader = [
  {
    title: "ID",
    type: COLUMN_TYPE.STRING,
    meta: {
      key: "id", 
    },
  },
  {
    title: "Source",
    type: COLUMN_TYPE.STRING,
    meta: {
      key: "source", 
    },
  },
  {
    title: "Target",
    type: COLUMN_TYPE.STRING,
    meta: {
      key: "target", 
    },
  },
  {
    title: "Response Type",
    type: COLUMN_TYPE.STRING,
    meta: {
      key: "response_type", 
    },
  },
  {
    title: "Amount",
    type: COLUMN_TYPE.STRING,
    meta: {
      key: "amount", 
    },
  },
  {
    title: "Status",
    type: COLUMN_TYPE.STRING,
    meta: {
      key: "status", 
    },
  },
  {
    title: "Request",
    type: COLUMN_TYPE.LINK,
    meta: {
      key: "request_body", 
      onClick: () => {},
    },
  },
  {
    title: "Logs",
    type: COLUMN_TYPE.LINK,
    meta: {
      key: "logs", 
      onClick: () => {}
    },
  },
  {
    title: "Created",
    type: COLUMN_TYPE.STRING,
    meta: {
      key: "created_at", 
    },
  },
  {
    title: "Transaction Id",
    type: COLUMN_TYPE.STRING,
    meta: {
      key: "transaction_id", 
    },
  },
] as HeaderItemType[];
