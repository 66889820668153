import React, {useEffect, useState} from "react";
import {getComplaint} from "../../api/complaint";
import {INIT_PAGINATION, PAGE_SIZE} from "../../constant/pagination";
import {usePagination} from "../common/pagination";
import {overflowBody} from "../../utils/style-document";
import {IEventWhichIncludesComplaints, IGetComplaintsRequest} from "../../interfaces/complaints";
import {COMPLAINTS_SEND_FROM} from "../../enum/complaint";
import {useUpdateComplaintsFromUnreadToRead} from "./update-from-unread-to-read";

export const useGetComplaints = (body: IGetComplaintsRequest) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [complaintData, setComplaintData] = useState<any>()


    const getData = async () => {
        setLoading(true)
        const response = await getComplaint(body)
        if (response) {
            setLoading(false)
            setComplaintData(response)
        }
    }

    useEffect(() => {
        (getData)()
        return () => setComplaintData(undefined)
        // eslint-disable-next-line
    }, [body.filter.key])

    return {
        complaintData,
        loading
    }
}


export const useControlComplaints = (sentFrom: string[]) => {
    const [search, setSearch] = useState<string>("")
    const [currentPage, setCurrentPage] = useState<number>(0)
    const [eventWhichContainsComplaints, setEventWhichContainsComplaints] = useState<IEventWhichIncludesComplaints[]>([])
    const [openComplaintInfo, setOpenComplaintInfo] = useState<boolean>(false)
    const [complaintInfo, setComplaintInfo] = useState<any>()

    useUpdateComplaintsFromUnreadToRead(complaintInfo, setEventWhichContainsComplaints)

    const {complaintData, loading} = useGetComplaints(
        {
            statuses: ["PENDING", "FURTHER_INVESTIGATION"],
            send_from: sentFrom,
            filter: {
                key: search
            },
            pagination: INIT_PAGINATION
        }
    )

    const [newData] = usePagination(getComplaint, {
        variables: {
            statuses: ["PENDING", "FURTHER_INVESTIGATION"],
            send_from: sentFrom,
            filter: {
                key: search
            },
            pagination: {
                page: currentPage + 1,
                pageSize: PAGE_SIZE
            },
        }
    });

    useEffect(() => {
        setEventWhichContainsComplaints(complaintData?.items)
    }, [complaintData])

    useEffect(() => {
        if (newData?.items && newData?.items?.length > 0) {
            setEventWhichContainsComplaints([...complaintData?.items, ...newData?.items])
            setCurrentPage(newData?.currentPage)
        }
        // eslint-disable-next-line
    }, [newData])

    const openComplaintCard = (value: any) => {
        setOpenComplaintInfo(true)
        const findEvent = eventWhichContainsComplaints?.find((eventWithComplaint: IEventWhichIncludesComplaints) =>
            eventWithComplaint?.complaints?.find((complaint) => complaint?.id === value?.id))
        setComplaintInfo(findEvent)
    }

    overflowBody(openComplaintInfo)

    const updateComplaintDataArr = (eventId: string, decision: string) => {
        setEventWhichContainsComplaints(prevState => prevState.filter((e: IEventWhichIncludesComplaints) => e.id !== eventId))
    }
    const onHandleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value)
        setCurrentPage(0)
    };

    const onHandleClear = () => {
        setSearch("")
        setCurrentPage(0)
    }

    const items = sentFrom?.includes(COMPLAINTS_SEND_FROM.COMPLAINT_FORM) ?
        eventWhichContainsComplaints?.map((eventWithComplaints: IEventWhichIncludesComplaints) => eventWithComplaints?.complaints[0])?.flat() :
        eventWhichContainsComplaints?.map((eventWithComplaints: IEventWhichIncludesComplaints) =>
            eventWithComplaints?.complaints?.find((c) => c.send_complaint_from === COMPLAINTS_SEND_FROM.PRE_MEETING_EXIT))

    return {
        loading,
        complaints: {
            complaintInfo: {
                open: openComplaintInfo,
                setOpen: setOpenComplaintInfo,
                data: complaintInfo
            },
            openComplaintCard,
            update: updateComplaintDataArr,
            items: items
        },
        search: {
            value: search,
            onChange: onHandleSearch,
            clear: onHandleClear
        }
    }
}