import { COLUMN_TYPE } from "../../components/Table/column-factory";
import { HeaderItemType } from "../../interfaces/table";

export const PaidContentSalesTableName = "Paid Content Sales"

export const PaidContentSalesHeader = [
  {
    title: "ID",
    type: COLUMN_TYPE.STRING,
    meta: {
      key: "id", // paid_content_purchase_history_id
    },
  },
  {
    title: "Creator",
    type: COLUMN_TYPE.STRING,
    meta: {
      key: "creator",
    },
  },
  {
    title: "Buyer",
    type: COLUMN_TYPE.STRING,
    meta: {
      key: "buyer",
    },
  },
  {
    title: "Item",
    type: COLUMN_TYPE.STRING,
    meta: {
      key: "item",
    },
  },
  {
    title: "Price",
    type: COLUMN_TYPE.STRING,
    meta: {
      key: "price",
    },
  },
  {
    title: "Time",
    type: COLUMN_TYPE.STRING,
    meta: {
      key: "time",
    },
  },
] as HeaderItemType[];
