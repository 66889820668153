import { Props } from 'react-select';

const Browser: React.FC<Props> = ({ browser }) => {
    return (
        <div>
            <div className="system-meeting-data-subheading">Browser</div>
            <div>
                <div>
                    <div className="system-meeting-key-value">
                        <div className="system-meeting-key">Name</div>
                        <div className="system-meeting-value">
                            {!!browser?.name ? browser?.name : "-----"}
                        </div>
                    </div>
                    <hr />
                    <div className="system-meeting-key-value">
                        <div className="system-meeting-key">Version</div>
                        <div className="system-meeting-value">
                            {!!browser?.version ? browser?.version : "-----"}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Browser;