import React, { useEffect, useState } from "react";
import TemplateEditor from "./editor";
import { Route, Switch, useHistory } from "react-router-dom";
import { ROUTES } from "../../enum/routes";
import { getAllEmailTemplatesList } from "../../api/email-templates";
import { getAllTemplateVersions } from "../../api/email-templates-version";
import { IGetListOfTemplates } from "../../interfaces/sendgrid";
import TemplateList from "./TemplateList";
import CreateTemplate from "./CreateTemplate";
import { Checkbox } from "@mui/material";

const EditorContainer = () => {
  const [
    selectAutoTriggeredStaticCategory,
    setSelectAutoTriggeredStaticCategory,
  ] = useState<boolean>(false);
  const [
    selectAdminInitiatedStaticCategory,
    setSelectAdminInitiatedStaticCategory,
  ] = useState<boolean>(false);
  const [selectDynamicCategory, setSelectDynamicCategory] =
    useState<boolean>(false);
  const [templateList, setTemplateList] = useState<any>([]);
  const [showForm, setShowForm] = useState(false);
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const response = await getAllTemplateVersions();
      if (response) {
        if (
          selectDynamicCategory &&
          !selectAutoTriggeredStaticCategory &&
          !selectAdminInitiatedStaticCategory
        ) {
          setTemplateList(
            response.filter((item: any) => item.template.template_name === "DM")
          );
        } else if (
          selectAutoTriggeredStaticCategory &&
          !selectDynamicCategory &&
          !selectAdminInitiatedStaticCategory
        ) {
          setTemplateList(
            response.filter(
              (item: any) => item.template.template_name === "aST"
            )
          );
        } else if (
          !selectAutoTriggeredStaticCategory &&
          !selectDynamicCategory &&
          selectAdminInitiatedStaticCategory
        ) {
          setTemplateList(
            response.filter(
              (item: any) => item.template.template_name === "iST"
            )
          );
        } else if (
          selectAutoTriggeredStaticCategory &&
          !selectDynamicCategory &&
          selectAdminInitiatedStaticCategory
        ) {
          setTemplateList(
            response.filter(
              (item: any) =>
                item.template.template_name === "iST" ||
                item.template.template_name === "aST"
            )
          );
        } else if (
          selectAutoTriggeredStaticCategory &&
          selectDynamicCategory &&
          !selectAdminInitiatedStaticCategory
        ) {
          setTemplateList(
            response.filter(
              (item: any) =>
                item.template.template_name === "DM" ||
                item.template.template_name === "aST"
            )
          );
        } else if (
          !selectAutoTriggeredStaticCategory &&
          selectDynamicCategory &&
          selectAdminInitiatedStaticCategory
        ) {
          setTemplateList(
            response.filter(
              (item: any) =>
                item.template.template_name === "DM" ||
                item.template.template_name === "iST"
            )
          );
        } else {
          setTemplateList(response);
        }
      }
      // if (response) {
      //     setLoading(false)
      //     let versionsArray: ISendGridVersion[] = [];
      //     SEND_GRID_TEMPLATE_NAMES.forEach((templateItem, templateIndex: number) =>{
      //         const currentTemplateList: ISendGridTemplates | any = response.templates?.find((template) => template.name === templateItem);
      //         if(templateIndex === 0){
      //             const activeTemplateVersion = currentTemplateList.versions?.find((versionItem:any) => versionItem.active )
      //             setSelectedTemplate(activeTemplateVersion)
      //             setSelectedTemplateId(activeTemplateVersion.id)
      //         }
      //         versionsArray.push(...currentTemplateList.versions);
      //     });
      //     setTemplates(versionsArray)
      // }
    })();
    // eslint-disable-next-line
  }, [
    selectDynamicCategory,
    selectAutoTriggeredStaticCategory,
    selectAdminInitiatedStaticCategory,
  ]);

  const updateTemplateListAfterDelete = (data: any) => {
    setSelectDynamicCategory(false);
    setSelectAutoTriggeredStaticCategory(false);
    setSelectAdminInitiatedStaticCategory(false);
    setTemplateList(data);
  };

  const submitHandler = async (
    apiRequest: (params?: any) => Promise<any>,
    requestBody: any
  ) => {
    const response = await apiRequest(requestBody);
    if (response.message === "Template created successfully.") {
      const response: IGetListOfTemplates = await getAllEmailTemplatesList();
      if (response) {
        setTemplateList(response);
      }
    }
  };

  return (
    <div className="template-page">
      <div className="back-btn-div">
        <button
          className="back-btn email-service-btn"
          onClick={() => history.push(ROUTES.PAGES.OVERVIEW)}
        >
          Back
        </button>
      </div>

      <div className="create-dynamic-template">
        <div className="btn-add-template">
          <label onClick={() => history.push(ROUTES.PAGES.TEMPLATES)}>
            Add Template
          </label>
        </div>
        <div className="checkboxes">
          <div className="check-box-container">
            <Checkbox
              inputProps={{ "aria-label": "Checkbox A" }}
              checked={selectAutoTriggeredStaticCategory}
              value={selectAutoTriggeredStaticCategory}
              onChange={(event) => {
                setSelectAutoTriggeredStaticCategory(event.target.checked);
              }}
            />
            <span>Show Auto-triggered templates</span>
          </div>
          <div className="check-box-container">
            <Checkbox
              inputProps={{ "aria-label": "Checkbox A" }}
              checked={selectAdminInitiatedStaticCategory}
              value={selectAdminInitiatedStaticCategory}
              onChange={(event) => {
                setSelectAdminInitiatedStaticCategory(event.target.checked);
              }}
            />
            <span>Show Admin initiated templates</span>
          </div>
          <div className="check-box-container">
            <Checkbox
              inputProps={{ "aria-label": "Checkbox A" }}
              checked={selectDynamicCategory}
              value={selectDynamicCategory}
              onChange={(event) => {
                setSelectDynamicCategory(event.target.checked);
              }}
            />
            <span>Show Dynamic Templates</span>
          </div>
        </div>
      </div>
      <div className="template-list">
        <TemplateList
          list={templateList}
          setList={updateTemplateListAfterDelete}
        />
      </div>
      {showForm && (
        // <form className="template-form">
        //   <input
        //     className="enter-template-name"
        //     placeholder="Enter Template Name"
        //     onChange={(e) => setTemplateName(e.target.value)}
        //   />
        //   <Button name="Submit" onClickHandler={submitHandler} />
        // </form>
        <CreateTemplate
          open={showForm}
          setOpen={setShowForm}
          onComplete={submitHandler}
        />
      )}
      <Switch>
        <Route path={ROUTES.PAGES.TEMPLATES} component={TemplateEditor} exact />
        {/* <TemplateEditor
        // onChange={(data: any) => {
        //   setContent(data);
        // }}
        /> */}
      </Switch>
      {/* // <Preview content={content} /> */}
    </div>
  );
};

export default EditorContainer;
