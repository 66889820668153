import { Props } from "react-select";
import Browser from "./Components/browser";
import ConnectionSpeed from "./Components/connectionSpeed";
import Geolocation from "./Components/geolocation";
import Resolution from "./Components/resolution";

const EnvironmentContainer: React.FC<Props> = ({ systemMeetingToUsersData }) => {

    return (
        <div className="environment-container">
            <h5>Environment</h5>
            <div className="environment-container-data">
                {systemMeetingToUsersData?.map((meeting: any, index: number) => {
                    const { browser, geolocation, connection_speed, resolution } = meeting.result_test?.environment;
                    return <div className="user" key={`environment-container-data-${index}`}>
                        <div>
                            <div className="system-meeting-data-heading">
                                {`User ID ${meeting.user_id}`}
                            </div>
                        </div>
                        <Browser browser={browser} />
                        <hr />
                        <Geolocation geolocation={geolocation} />
                        <hr />
                        <Resolution resolution={resolution} />
                        <hr />
                        <ConnectionSpeed connection_speed={connection_speed} />
                    </div>
                })}
            </div>
        </div>
    );
}

export default EnvironmentContainer;