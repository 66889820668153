import React, {useCallback, useMemo, useState} from "react"
import moment from "moment";
import {TRANSACTION_VARIABLE_DATES} from "../../enum/variables";
import {findValueByVariableKey} from "../../utils/variables";
import {IVariable} from "../../interfaces/variables";
import {RootStateOrAny, useSelector} from "react-redux";
import {GridRowData} from "@mui/x-data-grid";


export const useTransactionDatesControl = (data: GridRowData) => {
    const transactionDateObj = {
        date: data.value,
        isLastDayOfMonth: data.isLastDayOfMonth,
        nextDateValue: data.nextDateValue
    }
    const variables: IVariable[] = useSelector((state: RootStateOrAny) => state.variable?.variables)
    const [date, setDate] = useState<string>(transactionDateObj.date)
    const [time, setTime] = useState<string>(moment(transactionDateObj.date).format("HH:mm"))
    const [nextDate, setNextDate] = useState<string>(transactionDateObj.nextDateValue)
    const [nextTime, setNextTime] = useState<string>(moment(transactionDateObj.nextDateValue).format("HH:mm"))
    const [isLastDayOfMonth, setIsLastDayOfMonth] = useState<boolean>(true)


    const currentDateFormatted = moment().format("yyyy-MM-DD")
    const currentDateWithTime = `${moment(date).format("yyyy-MM-DD")} ${time}`
    const nextDateWithTime = `${moment(nextDate).format("yyyy-MM-DD")} ${nextTime}`

    const cutOffPointVariable = findValueByVariableKey(variables, TRANSACTION_VARIABLE_DATES.CUT_OFF_POINT)
    const transferDateVariable = findValueByVariableKey(variables, TRANSACTION_VARIABLE_DATES.TRANSFER_DATE)
    const disbursementDateVariable = findValueByVariableKey(variables, TRANSACTION_VARIABLE_DATES.DISBURSEMENT_DATE)

    const canEditCurrentDate = useMemo(() => {
        switch (data.key) {
            case TRANSACTION_VARIABLE_DATES.CUT_OFF_POINT:
                return moment(transferDateVariable.date).subtract(1, "day").isAfter(moment());
            case TRANSACTION_VARIABLE_DATES.TRANSFER_DATE:
                return moment(disbursementDateVariable.date).subtract(1, "day").isAfter(moment())
            case TRANSACTION_VARIABLE_DATES.DISBURSEMENT_DATE:
                return moment(date).isAfter(moment())
        }
    }, [date, data.key, disbursementDateVariable, transferDateVariable])


    const cutOffPoint = canEditCurrentDate ? cutOffPointVariable?.date : cutOffPointVariable?.nextDate
    const transferDate = canEditCurrentDate ? transferDateVariable?.date : transferDateVariable?.nextDate
    const disbursementDate = canEditCurrentDate ? disbursementDateVariable?.date : disbursementDateVariable?.nextDate

    const handleDateChange = useCallback((date: any) => {
        if (date) {
            canEditCurrentDate ? setDate(date) : setNextDate(date)
        }
    }, [setDate, setNextDate, canEditCurrentDate])

    const handleTimeChange = useCallback((date: Date | null) => {
        const result = moment(date).format("HH:mm")
        canEditCurrentDate ? setTime(result) : setNextTime(result)
    }, [setTime, setNextTime, canEditCurrentDate])

    const handleRadioChangeValue = useCallback((event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        setIsLastDayOfMonth(value === "isLastDayOfMonth")
    }, [setIsLastDayOfMonth])

    const timeInputValue = canEditCurrentDate ?
        new Date(`${moment(date).format("yyyy-MM-DD")} ${time}`) :
        new Date(`${moment(nextDate).format("yyyy-MM-DD")} ${nextTime}`);

    const dateInputValue = canEditCurrentDate ?
        moment(date).format("yyyy-MM-DD") :
        moment(nextDate).format("yyyy-MM-DD")

    const daysInSelectedMonth = canEditCurrentDate ? moment(date).daysInMonth() : moment(nextDate).daysInMonth()
    const daySelected = canEditCurrentDate ? moment(date).format(("DD")) : moment(nextDate).format("DD")

    const showRadioButtons = +daySelected === +daysInSelectedMonth && +daysInSelectedMonth !== 31

    const minMaxValues = () => {
        let minDate: string = currentDateFormatted
        let maxDate: string = disbursementDate;
        let minTime: Date = new Date();
        let maxTime: Date = new Date(disbursementDate);
        switch (data.key) {
            case TRANSACTION_VARIABLE_DATES.CUT_OFF_POINT:
                minDate = currentDateFormatted
                // SHOULD BE DAY BEFORE TRANSFER DATE
                maxDate = moment(transferDate).subtract(1, "day").format("yyyy-MM-DD")
                minTime = new Date()
                maxTime = new Date(transferDate);
                break
            case TRANSACTION_VARIABLE_DATES.TRANSFER_DATE:
                // SHOULD BE DAY AFTER CUT_OFF_POINT
                minDate = moment(cutOffPoint).add(1, "day").format("yyyy-MM-DD")
                // SHOULD BE DAY BEFORE DISBURSEMENT_DATE
                maxDate = moment(disbursementDate).subtract(1, "day").format("yyyy-MM-DD")
                minTime = new Date(cutOffPoint)
                maxTime = new Date(disbursementDate)
                break;
            case TRANSACTION_VARIABLE_DATES.DISBURSEMENT_DATE:
                const daysInDisbursementMonth = moment(disbursementDate).daysInMonth()
                // SHOULD BE DAY AFTER TRANSFER_DATE
                minDate = moment(transferDate).add(1, "day").format("yyyy-MM-DD")
                // SHOULD BE DAY BEFORE DISBURSEMENT_DATE
                maxDate = `${moment(disbursementDate).format("yyyy-MM")}-${daysInDisbursementMonth}`
                minTime = new Date(transferDate)
                maxTime = new Date(disbursementDate)

        }

        return {
            minDate,
            maxDate,
            minTime,
            maxTime
        }
    }

    function validateTransactionDateValue() {
        const value = canEditCurrentDate ? currentDateWithTime : nextDateWithTime
        switch (data.key) {
            case TRANSACTION_VARIABLE_DATES.CUT_OFF_POINT:
                return moment(value).isBefore(transferDate) &&
                    moment(value).isBefore(disbursementDate);
            case TRANSACTION_VARIABLE_DATES.TRANSFER_DATE:
                return moment(value).isAfter(cutOffPoint) &&
                    moment(value).isBefore(disbursementDate);
            case TRANSACTION_VARIABLE_DATES.DISBURSEMENT_DATE:
                return moment(value).isAfter(cutOffPoint) &&
                    moment(value).isAfter(transferDate)
        }
    }

    const requestJSONValue = useMemo(() => {
        const currentDateWithoutTime = moment(date).format("yyyy-MM-DD")
        const nextDateWithoutTime = moment(nextDate).format("yyyy-MM-DD")
        const monthAhead = moment(currentDateWithoutTime).add(1, "month").format("yyyy-MM-DD")
        const timezone = moment().tz(moment.tz.guess()).format("Z")
        return {
            date: canEditCurrentDate ? `${currentDateWithoutTime} ${time} ${timezone}` : transactionDateObj.date,
            isLastDayOfMonth: showRadioButtons ? isLastDayOfMonth : false,
            nextDate: canEditCurrentDate ?
                `${monthAhead} ${time} ${timezone}` :
                `${nextDateWithoutTime} ${nextTime} ${timezone}`
        }
    }, [date, nextDate, time, nextTime, canEditCurrentDate, showRadioButtons, isLastDayOfMonth, transactionDateObj.date])

    const valueIsTheSameAsBefore = canEditCurrentDate ?
        (transactionDateObj.date === currentDateWithTime) :
        (transactionDateObj.nextDateValue === nextDateWithTime)


    const messageAboutPreviousDateTriggered = !canEditCurrentDate ?
        `Previous ${data.key} was triggered at ${moment(transactionDateObj.date).format("DD-MM-yyyy HH:mm")}` :
        ""

    return {
        handleDateChange,
        handleTimeChange,
        date: dateInputValue,
        time: timeInputValue,
        showRadioButtons,
        handleRadioChangeValue,
        radioGroupValue: isLastDayOfMonth ? "isLastDayOfMonth" : "everySelectedDayOfMonth",
        radioOptions: {
            isLastDayOfMonth: {
                value: "isLastDayOfMonth",
                label: "Every last day of month"
            },
            everySelectedDayOfMonth: {
                value: "everySelectedDayOfMonth",
                label: `Every ${daysInSelectedMonth}th day of month`
            }
        },
        minMaxValues: minMaxValues(),
        requestJSONValue: requestJSONValue,
        isValid: validateTransactionDateValue(),
        disabled: valueIsTheSameAsBefore && validateTransactionDateValue(),
        messageAboutPreviousDateTriggered
    }
}