import React, {CSSProperties, useEffect, useState} from "react"
import Modal from "../../../../../components/Modal";
import {GridRowData} from "@mui/x-data-grid";
import {Radio, RadioGroup} from "@material-ui/core";
import {FormControlLabel} from "@mui/material";
import TextField from '@mui/material/TextField';
import TimePicker from '@mui/lab/TimePicker';
import {useTransactionDatesControl} from "../../../../../service/variables/transaction-dates-control";
import {DatePicker} from "@mui/lab";

interface IProps {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    onComplete: () => Promise<void>,
    setRequestBodyJSONValue: (jsonValue: any) => void,
    data: GridRowData
}

// QUICK STYLES
const STYLES_DATE_INPUT: CSSProperties = {marginRight: 10, caretColor: "transparent"}
const STYLES_RADIO_GROUP: CSSProperties = {marginTop: 10}
const STYLES_INPUTS_WRAPPER: CSSProperties = {display: "flex", alignItems: "center"}
const STYLES_PREVIOUS_TRIGGER: CSSProperties = {fontSize: 14, position: "relative", top: -15, fontWeight: "bold"}


const EditTransactionDateModal: React.FC<IProps> = (
    {
        open,
        setOpen,
        setRequestBodyJSONValue,
        onComplete,
        data,
    }) => {

    const {
        date,
        time,
        handleDateChange,
        handleTimeChange,
        showRadioButtons,
        handleRadioChangeValue,
        radioGroupValue,
        radioOptions,
        minMaxValues,
        requestJSONValue,
        disabled,
        messageAboutPreviousDateTriggered
    } = useTransactionDatesControl(data)

    const [openDatePicker, setOpenDatePicker] = useState<boolean>(false)
    const [openTimePicker, setOpenTimePicker] = useState<boolean>(false)

    // eslint-disable-next-line
    useEffect(() => setRequestBodyJSONValue(requestJSONValue), [requestJSONValue])

    const handleKeyPress = (e: any) => {
        e.preventDefault()
        return false;
    }

    return (
        <Modal
            title={`Edit ${data.key} (${data.description})`}
            open={open}
            subtitle={"Some of the dates are disabled due to validation constraints, to avoid wrong order of transaction dates execution"}
            setOpen={setOpen}
            confirmButton={"Save"}
            cancelButton={"Cancel"}
            disabled={disabled}
            onComplete={onComplete}
            disableClose={true}
            content={
                <React.Fragment>
                    <span style={STYLES_PREVIOUS_TRIGGER}>{messageAboutPreviousDateTriggered}</span>
                    <div style={STYLES_INPUTS_WRAPPER}>
                        <DatePicker
                            open={openDatePicker}
                            onClose={() => setOpenDatePicker(false)}
                            inputFormat={"dd-MM-yyyy"}
                            label={"Transaction date"}
                            onChange={handleDateChange}
                            value={new Date(date)}
                            minDate={new Date(minMaxValues.minTime)}
                            maxDate={new Date(minMaxValues.maxDate)}
                            renderInput={(params:any) => {
                                return (
                                    <TextField
                                        {...params}
                                        style={STYLES_DATE_INPUT}
                                        onKeyPress={handleKeyPress}
                                        onClick={(e) => setOpenDatePicker(true)}
                                    />
                                );
                            }}
                        />
                        <TimePicker
                            open={openTimePicker}
                            onClose={() => setOpenTimePicker(false)}
                            ampmInClock={false}
                            ampm={false}
                            views={["hours"]}
                            label="Transaction time"
                            value={time}
                            onChange={handleTimeChange}
                            renderInput={(params: any) => <TextField
                                {...params}
                                onKeyPress={handleKeyPress}
                                style={{caretColor: "transparent"}}
                                onClick={() => setOpenTimePicker(true)}
                            />}
                        />
                    </div>
                    {showRadioButtons && <RadioGroup
                        style={STYLES_RADIO_GROUP}
                        defaultValue={radioOptions.isLastDayOfMonth.value}
                        name="radio-buttons-group"
                        value={radioGroupValue}
                        onChange={handleRadioChangeValue}
                    >
                        <FormControlLabel
                            value={radioOptions.isLastDayOfMonth.value}
                            control={<Radio color={"primary"}/>}
                            label={radioOptions.isLastDayOfMonth.label}
                        />
                        <FormControlLabel
                            value={radioOptions.everySelectedDayOfMonth.value}
                            control={<Radio color={"primary"}/>}
                            label={radioOptions.everySelectedDayOfMonth.label}
                        />
                    </RadioGroup>}
                </React.Fragment>
            }
        />
    )
}

export default EditTransactionDateModal;