import {useEffect, useState} from 'react'
import {IPostBody} from "../../interfaces/request";

export const usePagination = (request: (...args: any) => any, args: IPostBody): any[] => {
    const [newData, setNewData] = useState<any>([])
    const [scrollLoad, setScrollLoad] = useState<boolean>(false)
    const [pageCount, setPageCount] = useState<boolean>(false)
    const {variables} = {...args};

    useEffect(() => {
        document.addEventListener('scroll', handleScroll);
        return () => document.removeEventListener('scroll', handleScroll);
    })

    const handleScroll = async () => {
        if (document?.scrollingElement && document?.scrollingElement?.scrollHeight - Math.round(window.innerHeight + document.documentElement.scrollTop) < 300 && variables?.totalCountOfPages !== variables?.pagination?.page) {
            setPageCount(true)
        } else {
            setPageCount(false)
        }
    }
    
    useEffect(() => {        
        (async() => {
            if(pageCount){
                const response = await request(variables)
                if (response) {                    
                    setNewData(response)
                    setScrollLoad(true)
                }
            }
        })()
        // eslint-disable-next-line
    }, [pageCount])

    return[newData, scrollLoad]
}