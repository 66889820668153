const DATE = "DATE";
const USER = "USER";

export const SORT_BY = [
  {
    value: DATE,
    label: "Date",
  },
  {
    value: USER,
    label: "User",
  },
];