import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@mui/material";
import { useGetSystemMeetingToUsers } from "../../service/system-meeting-to-user/system-meeting-to-user";
import "./index.scss";
import { Props } from "react-select";
import EnvironmentContainer from "./Components/Environment/environment";
import ConnectionContainer from "./Components/Connection/connection";
import UserContainer from "./Components/User/user";
import TimestampContainer from "./Components/Timestamp/timestamp";

const SystemMeetingToUsers: React.FC<Props> = (props) => {

    const history = useHistory();
    const { system_meeting_id } = props.location.state;
    const { systemMeetingToUsersData } = useGetSystemMeetingToUsers(system_meeting_id)

    const goToSystemMeetingList = async () => {
        history.goBack()
    }

    return (
        <>
            <Button
                variant="outlined"
                className="go-back-button"
                onClick={goToSystemMeetingList}
            >
                Go Back
            </Button>
            <div className="system-meetings-to-user-page" >
                <div className="system-meeting-container-left">
                    <div className="system-meeting-container-left-top">
                        <UserContainer systemMeetingToUsersData={systemMeetingToUsersData?.result_test} />
                        <TimestampContainer systemMeetingToUsersData={systemMeetingToUsersData?.result_test} />
                    </div>
                    <div className="system-meeting-container-left-bottom">
                        <EnvironmentContainer systemMeetingToUsersData={systemMeetingToUsersData?.result_test} />
                    </div>
                </div>
                <div className="system-meeting-container-right">
                    <ConnectionContainer systemMeetingToUsersData={systemMeetingToUsersData?.result_test} />
                </div>
            </div>
        </>
    );
};

export default SystemMeetingToUsers;
