import React, {useState, useEffect} from "react";
import {getMeetings} from "../../api/meetings";
import Input from "../../components/Input";
import CustomSelect from "../../components/Select";
import {INIT_PAGINATION, PAGE_SIZE} from "../../constant/pagination";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import {SORT_BY} from "../../constant/sort-meetings";
import Table from "../../components/Table";
import {contentPreviewList, MeetingsHeader, MeetingTableName} from "../../constant/TableHeaders/meetings-header";
import {ISort} from "../../interfaces/sort";
import Loader from "../../components/Loader";
import {SCORE_VALUES_IN_STARS} from "../../enum/score";
import {basicBodyVariables} from "../../utils/body-variables";
import {useGetMeetings} from "../../service/meetings/meetings";
import {usePagination} from "../../service/common/pagination";
import {onHandleSearchAndSort} from "../../utils/sort-search";
import Button from "../../components/Button";
import Papa from 'papaparse';
import Modal from "../../components/Modal";
import HTMLReactParser from "html-react-parser";
import { useHistory } from "react-router-dom";

const Meetings: React.FC = () => {
    const [search, setSearch] = useState<string>("");
    const [sort, setSort] = useState<ISort>({value: SORT_BY[0].value, label: SORT_BY[0].label});
    const [currentPage, setCurrentPage] = useState<number>(0)
    const [meetings, setMeetings] = useState<any>([])
    const history = useHistory();
    const [previewTitle, setPreviewTitle] = useState<string | null>('')
    const [previewContent, setPreviewContent] = useState<string>('')
    const [openPreviewModal, setOpenPreviewModal] = useState<boolean>(false)

    const {onHandleSort, onHandleSearch, onHandleClear} = onHandleSearchAndSort(setCurrentPage, setSort, setSearch)

    const {meetingsData, loading} = useGetMeetings(
        basicBodyVariables(INIT_PAGINATION, search, sort.value)
    )

    useEffect(() => {
        setMeetings(meetingsData?.items)
    }, [meetingsData])

    const [newData] = usePagination(getMeetings, {
        variables: basicBodyVariables({page: currentPage + 1, pageSize: PAGE_SIZE}, search, sort.value)
    });

    useEffect(() => {
        if (newData?.items && newData?.items?.length > 0) {
            setMeetings([...meetings, ...newData?.items])
            setCurrentPage(newData?.currentPage)
        }
        // eslint-disable-next-line
    }, [newData])

    const mappedMeetings = meetings?.map((meeting: any, index: number) => {
        const scoreArr = Object.values(SCORE_VALUES_IN_STARS).map((v) => {
            return v.count === +meeting.score ? v.stars_count : []
        })
        return {...meeting, score: scoreArr[index]}
    })

    if ((!meetings || meetings?.length === 0 || loading) && search === '') {
        return <Loader/>
    }

    const downloadCsv = () =>{
        const data = meetings.map((obj: any) => [
            obj.id,
            obj.status,
            obj.type,
            obj.timeStart,
            obj.timeEnd,
            obj.initiator_pre_event_note,
            obj.score,
            obj.price,
            `${obj.hosting_user?.first_name ?? ''} ${obj.hosting_user?.last_name ?? ''}`.trim(),
            `${obj.initiator_user?.first_name ?? ''} ${obj.initiator_user?.last_name ?? ''}`.trim()
        ]);

        const fields = ['ID', 'Status', 'Type', 'Time start', 'Time end', 'Initiator pre event note', 'Score', 'Price', 'HU', 'IU'];

        const csv = Papa.unparse({
            data,
            fields
        });

        const blob = new Blob([csv]);
        const a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.download = 'Meetings Report.csv';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    const viewData = async (item: any, key:any) =>{
        if(!contentPreviewList.includes(key)) return;
        setPreviewTitle(MeetingsHeader.find(item=>item.meta.key === key)?.title ?? '')
        setPreviewContent(item[key] ?? '')
        setOpenPreviewModal(true)
    }

    return (
        <>
        <div className="meetings-page">
        <div className="back-btn-div">
        <button
          className="back-btn mt-2 mb-2"
          onClick={() => history.goBack()}
        >
          Back
        </button> </div>
            <div className="top-panels">
                <label className="input-wrapper">
                    <Input
                        onChange={onHandleSearch}
                        value={search}
                        placeholder={"Search"}
                        type={"search"}
                        classInput="input-search"
                    />
                    <span className="clear-input" onClick={onHandleClear}>
                        {search ? <HighlightOffIcon /> : null}
                    </span>
                </label>
                <CustomSelect
                    onChange={onHandleSort}
                    placeholder={sort.label || "Sort"}
                    options={SORT_BY}
                />
            </div>
            <div className="button-container">
                <Button name={'Download/Export Report'} onClickHandler={downloadCsv}/>
            </div>
            {!!mappedMeetings?.length ?
                <Table header={MeetingsHeader} items={mappedMeetings} tableName={MeetingTableName} onLinkClickHandler={viewData} /> :
                <h2 className="nth-found">Nothing found</h2>
            }
        </div>
        <Modal
        open={openPreviewModal}
        setOpen={setOpenPreviewModal}
        title={previewTitle}
        content={HTMLReactParser(previewContent)}
        confirmButton={'Ok'}
        customClass="dbs-payout-info-modal"
        />
        </>
    );
};

export default Meetings;
