import axios from "axios";


export const getSystemMeetingToUsersData = (system_meeting_id: string)=> {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/admin/systemMeetingToUser/analytics/${system_meeting_id}`,
  })
      .then((response: any) => response?.data)
      .catch((error) => error.response?.data);
};
