import {RootStateOrAny, useSelector} from "react-redux";
import {IVariable} from "../../interfaces/variables";
import {useEffect, useState} from "react";
import {CELL_FIELD_TYPES, ICorrectRowValue} from "./system-variables-table";


export const useRowValueValidation = (validatingRowValue: ICorrectRowValue) => {
    const variables: IVariable[] = useSelector((state: RootStateOrAny) => state.variable?.variables)
    const variableKeys: string[] = variables.map((variable: IVariable) => variable.key)
    const [isValid, setIsValid] = useState<boolean>(true)
    const [errorMessage, setErrorMessage] = useState<string>("")

    useEffect(() => {
        setIsValid(isValidResult())
        // eslint-disable-next-line
    }, [validatingRowValue])

    function compareCurrentValueWithPrevAndNext() {
        if (variableKeys.includes(validatingRowValue.key)) {
            const sameValuesWithDifferentIndexes = variables.filter((v) => {
                return v.key.slice(0, -1) === validatingRowValue.key.slice(0, -1)
            }).sort((a, b) => a.key.localeCompare(b.key))
            const indexOfCurrentVariable = sameValuesWithDifferentIndexes.findIndex((v) => v.key === validatingRowValue.key)
            for (let i = 0; i < sameValuesWithDifferentIndexes.length; i++) {
                if (+indexOfCurrentVariable > i) {
                    if ((+validatingRowValue.value <= +sameValuesWithDifferentIndexes[i].value)) {
                        setErrorMessage(`Current value conflicting with the ${sameValuesWithDifferentIndexes[i].key}`)
                        return +validatingRowValue.value > +sameValuesWithDifferentIndexes[i].value;
                    }
                } else if (+indexOfCurrentVariable < i) {
                    if ((+validatingRowValue.value >= +sameValuesWithDifferentIndexes[i].value)) {
                        setErrorMessage(`Current value conflicting with the ${sameValuesWithDifferentIndexes[i].key}`)
                        return +validatingRowValue.value < +sameValuesWithDifferentIndexes[i].value;
                    }
                }
            }
        }
    }

    function isValidResult(): any {
        switch (validatingRowValue.fieldType) {
            case CELL_FIELD_TYPES.NUMBER:
                return compareCurrentValueWithPrevAndNext() === undefined ? true : compareCurrentValueWithPrevAndNext();
            default:
                return true;

        }
    }

    return {
        isValid,
        errorMessage
    }
}