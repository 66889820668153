import React, {useRef, useState} from "react"
import {UserRoleStyles} from "../../../constant/user";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import {useHistory} from "react-router"
import {useClickAway} from "react-use";
import {useUserData} from "../../../service/user/user-data";
import {activateUser, blockUser, deleteUserPost} from "../../../api/user";
import Loader from "../../../components/Loader";
import {updateTicketStatus} from "../../../api/tickets";
import {TICKET_STATUS, TICKET_TYPE} from "../../../enum/tickets";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {ROUTES} from "../../../enum/routes";

interface IProps {
    type: typeof TICKET_TYPE.INDIVIDUAL_USER_REPORT | typeof TICKET_TYPE.INDIVIDUAL_POST_REPORT
        | typeof TICKET_TYPE.FEEDBACK_FORM | typeof TICKET_TYPE.SUPPORT_FORM,
    info: any,
    setOpen: any,
    updateTicketsDataList: (ticket: any, resolvedTicket: any) => void
}

const TicketInfo: React.FC<IProps> = ({type, info, setOpen, updateTicketsDataList}) => {
    const history = useHistory()
    const [openImg, setOpenImg] = useState<boolean>(false)
    const [selectedImg, setSelectedImg] = useState<string | undefined>()
    const ref = useRef<HTMLImageElement>(null)
    const [loading, setLoading] = useState<boolean>(false)

    useClickAway(ref, () => setOpenImg(false))
    const {user: reporter, userLoading: reporterLoading} = useUserData(info?.reporter?.id, loading)
    const {user: reportee, userLoading: reporteeLoading} = useUserData(info?.reportee?.id, loading)

    const reporterSuspendActivateBtnColor = {
        backgroundColor: reporter?.suspended ? "#65E5BF" : "#FF766B"
    }

    const reporteeSuspendActivateBtnColor = {
        backgroundColor: reportee?.suspended ? "#65E5BF" : "#FF766B"
    }

    const openImagePreview = (asset: string) => {
        setOpenImg(true)
        setSelectedImg(asset)
    }

    const resolveTicketAndUpdateList = () => {
        const ticketInfoCopy = JSON.parse(JSON.stringify(info))
        info.status = TICKET_STATUS.RESOLVED
        updateTicketsDataList(info, ticketInfoCopy)
    }

    const unblockUser = async (userId: number) => {
        setLoading(true)
        const response = await activateUser(userId)
        const isResolved = await updateTicketStatus(info?.id, true)
        if (response && isResolved) {
            setLoading(false)
            resolveTicketAndUpdateList()
        }
    }

    const suspendUser = async (userId: number) => {
        setLoading(true)
        const response = await blockUser(userId)
        const isResolved = await updateTicketStatus(info?.id, true)
        if (response && isResolved) {
            resolveTicketAndUpdateList()
            setLoading(false)
        }
    }

    const notRelevantReport = async () => {
        setLoading(true)
        const isResolved = await updateTicketStatus(info?.id, true)
        if (isResolved) {
            resolveTicketAndUpdateList()
            setLoading(false)
            setOpen(false)
        }
    }

    const removePost = async (postId: number) => {
        setLoading(true)
        const response = await deleteUserPost(postId)
        const isResolved = await updateTicketStatus(info?.id, true)
        if (response && isResolved) {
            resolveTicketAndUpdateList()
            setLoading(false)
            setOpen(false)
        }
    }

    const renderInfoSection = (title: string, information: string, className?: string, isLink?: boolean, link?: string) => {
        return (
            <>
                {!!information && (
                    <>
                        <div className={"info"}>
                            <span>{title}</span>
                            {isLink ?
                                <a href={link} className={className} target={"_blank"}
                                   rel="noreferrer">{information}</a>
                                :
                                <span className={className}>{information}</span>
                            }
                        </div>
                        <hr/>
                    </>
                )}
            </>
        )
    }

    const returnCorrectEmailFromDependingOnTicketType = () => {
        switch (type) {
            case TICKET_TYPE.SUPPORT_FORM:
                return process.env.REACT_APP_SUPPORT_EMAIL;
            case TICKET_TYPE.FEEDBACK_FORM:
                return process.env.REACT_APP_FEEDBACK_EMAIL;
            default:
                return process.env.REACT_APP_SERVICE_EMAIL
        }
    }

    const contactUser = (email: string, first_name: string) => {
        history.push({
            pathname: ROUTES.PAGES.EMAIL_SERVICE,
            state: {
                first_name,
                email,
                from: returnCorrectEmailFromDependingOnTicketType()
            }
        })
    }

    const reporterProfileLink = `${process.env.REACT_APP_CHUSP_DOMAIN}/p/users/user/${info?.reporter?.id}`
    const reporteeProfileLink = `${process.env.REACT_APP_CHUSP_DOMAIN}/p/users/user/${info?.reportee?.id}`
    return (
        <>
            {(reporterLoading || reporteeLoading) && <Loader/>}
            <div className="ticket-author">
                <div className={"ticket-info"}>
                    <h2>Reporter</h2>
                    {renderInfoSection("Profile", reporterProfileLink, "", true, reporterProfileLink)}
                    {renderInfoSection("ID", `${info?.reporter?.id}`)}
                    {renderInfoSection("Name", `${info?.reporter?.first_name} ${!!info?.reporter?.last_name ? info?.reporter?.last_name : ""}`)}
                    {renderInfoSection("Email", info?.reporter?.email)}
                    {renderInfoSection("Role", info?.reporter?.role, `${UserRoleStyles[info?.reporter?.role]} role`)}
                    <div className="user-actions">
                        {reporter?.suspended ? (
                            <button
                                style={reporterSuspendActivateBtnColor}
                                onClick={() => unblockUser(info?.reporter?.id)}
                            >
                                Activate
                            </button>
                        ) : (
                            <button
                                style={reporterSuspendActivateBtnColor}
                                onClick={() => suspendUser(info?.reporter?.id)}
                            >
                                Suspend
                            </button>
                        )}
                        <button
                            onClick={() => contactUser(info?.reporter?.email, info?.reporter?.first_name)}>Contact
                        </button>
                    </div>
                </div>
                {!!info?.reportee && (
                    <div className={"ticket-info"}>
                        <h2>Reportee</h2>
                        {renderInfoSection("Profile", reporteeProfileLink, "", true, reporteeProfileLink)}
                        {renderInfoSection("ID", `${info?.reportee?.id}`)}
                        {renderInfoSection("Name", `${info?.reportee?.first_name} ${info?.reportee?.last_name}`)}
                        {renderInfoSection("Email", info?.reportee?.email)}
                        {renderInfoSection("Role", info?.reportee?.role, `${UserRoleStyles[info?.reporter?.role]} role`)}
                        <div className="user-actions">
                            {reportee?.suspended ? (
                                <button
                                    style={reporteeSuspendActivateBtnColor}
                                    onClick={() => unblockUser(info?.reportee?.id)}
                                >
                                    Activate
                                </button>
                            ) : (
                                <button
                                    style={reporteeSuspendActivateBtnColor}
                                    onClick={() => suspendUser(info?.reportee?.id)}
                                >
                                    Suspend
                                </button>
                            )}
                            <button
                                onClick={() => contactUser(info?.reportee?.email, info?.reportee?.first_name)}>Contact
                            </button>
                        </div>
                    </div>
                )}
            </div>
            <div className="ticket_details">
                <div className={"ticket-info"}>
                    <h2>Ticket info</h2>
                    {renderInfoSection("Ticket type", info?.type)}
                    {renderInfoSection("Topic", info?.support_topic)}
                    {renderInfoSection("Status", info?.status)}
                    {renderInfoSection("Created at", info?.created_at)}
                    {!!info.reason?.length && (
                        <div>
                            <h2>Reasons</h2>
                            {info?.reason.map((reason: string) => <span><CheckBoxIcon/> {reason}</span>)}
                        </div>
                    )}
                    {!!info?.text_form && (
                        <div>
                            <h2>Message</h2>
                            <span>"{info?.text_form}"</span>
                        </div>
                    )}
                    {info?.status !== TICKET_STATUS.RESOLVED && (
                        <button onClick={() => notRelevantReport()} className="ticket-action">Not relevant</button>
                    )}
                </div>
                {!!info?.post_assets?.length && (
                    <div className={"ticket-assets"}>
                        <h2>Reported posts</h2>
                        <div className={"reported-posts"}>
                            {info?.post_assets?.map((asset: { url: string, id: string }) => (
                                <div className={"reported-img"}>
                                    <img src={asset?.url} alt={""} onClick={() => openImagePreview(asset?.url)}/>
                                    <button onClick={() => removePost(+asset?.id)}><DeleteForeverIcon/></button>
                                </div>
                            ))}
                        </div>
                        {openImg && <div className={"img-wrapper"}>
                            <img
                                src={selectedImg}
                                className={"ticket-assets-img-open"}
                                ref={ref}
                                alt={'Complaint asset'}
                            />
                        </div>}
                    </div>
                )}
            </div>
        </>
    )
}


export default TicketInfo
