import React, {SetStateAction, useCallback, useEffect, useRef, useState} from "react"
import {createNewVatField} from "../../../../api/vat";
import Modal from "../../../../components/Modal";
import {Autocomplete, TextField} from "@mui/material";
import {LIST_OF_COUNTRIES} from "../../../../enum/list-of-countries";


interface IProps {
    open: boolean,
    setOpen: React.Dispatch<SetStateAction<boolean>>,
    onComplete: any
}

const AddNewVatCountryModal: React.FC<IProps> = ({open, setOpen, onComplete}) => {
    const autoCompleteRef = useRef<any>(null)
    const [selectedCountry, setSelectedCountry] = useState<string>("")
    const [nationalCurrency, setNationalCurrency] = useState<string>("")
    const [active, setActive] = useState<string>("Yes")
    const [rate, setRate] = useState<string>("")

    useEffect(() => {
        const countryInfo = LIST_OF_COUNTRIES.find((info) => info.name === selectedCountry)
        if (countryInfo) {
            setNationalCurrency(countryInfo.currencyCode)
        }
    }, [selectedCountry])

    const handleCountriesChange = useCallback((event: React.SyntheticEvent, value: any) => {
        value ? setSelectedCountry(value) : setSelectedCountry("")
    }, [setSelectedCountry])

    const handleActiveFlagChange = useCallback((event: React.SyntheticEvent, value: any) => {
        value ? setActive(value) : setActive("")
    }, [setActive])

    const handleCurrencyChange = useCallback((event:  React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setNationalCurrency(event.target.value)
    }, [setNationalCurrency])

    const handleRateChange = useCallback((event:  React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setRate(event.target.value)
    }, [setRate])

    const disableButton = !selectedCountry || !nationalCurrency || !active || !rate


    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title={"Add new vat field"}
            refToIgnore={autoCompleteRef}
            onComplete={() => onComplete(createNewVatField, {
                country: selectedCountry,
                active: active.toUpperCase() === "YES",
                rate: +rate,
                national_currency: nationalCurrency.toUpperCase(),
            })}
            confirmButton={"Create"}
            cancelButton={"Cancel"}
            disabled={disableButton}
            content={
                <form className={"vat-form"}>
                    <Autocomplete
                        options={LIST_OF_COUNTRIES.map((info) => info.name)}
                        onChange={handleCountriesChange}
                        value={selectedCountry}
                        freeSolo={true}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                label="Countries"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />}
                    />
                    <TextField
                        label="National currency"
                        style={{marginBottom: 15}}
                        placeholder="EUR/SGD"
                        onChange={handleCurrencyChange}
                        value={nationalCurrency}
                        fullWidth
                        inputProps={{
                            pattern: "[a-z]"
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <Autocomplete
                        options={["Yes", "No"]}
                        onChange={handleActiveFlagChange}
                        value={active}
                        freeSolo={true}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                style={{marginBottom: 15}}
                                label="VAT Flag"
                            />}
                    />
                    <TextField
                        type={"number"}
                        label="Rate"
                        placeholder="0.1"
                        onChange={handleRateChange}
                        value={rate}
                        fullWidth
                        inputProps={{
                            step: "0.1"
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </form>}
        />
    )
}

export default AddNewVatCountryModal;