import React, {CSSProperties, useEffect, useState} from "react"
import Modal from "../../../../../components/Modal";
import {GridRowData} from "@mui/x-data-grid";
import {TextField} from "@mui/material";
import {useObjectsArrayVariableControl} from "../../../../../service/variables/objects-array-control";
import TimeConverterModal from "../../../TimeConverterInput";
import {DATA_TYPE} from "../../../../../service/variables/system-variables-table";

interface IProps {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    onComplete: () => Promise<void>,
    setRequestBodyJSONValue: (jsonValue: any) => void,
    data: GridRowData
}

// QUICK STYLES
const STYLES_ERROR_MESSAGE: CSSProperties =
    {fontSize: 14, color: "red", position: "relative", top: -15, display: "inline-block"}
const STYLES_SUBTITLE_TEXT: CSSProperties = {cursor: "pointer"}

const EditObjectArrayModal: React.FC<IProps> = (
    {
        open,
        setOpen,
        data,
        setRequestBodyJSONValue,
        onComplete
    }) => {
    const [openConverter, setOpenConverter] = useState<boolean>(false)
    const {
        currentList,
        onChange,
        requestJSONValue,
        disabled,
        isValidField,
        showFieldErrorMessageIfNotValid,
    } = useObjectsArrayVariableControl(data)


    // eslint-disable-next-line
    useEffect(() => setRequestBodyJSONValue(requestJSONValue), [requestJSONValue])


    const showSubtitle = () => {
        switch (data.dataType) {
            case DATA_TYPE.UNIX_DATE:
                return <span onClick={openConvert} style={STYLES_SUBTITLE_TEXT}>
                    Make sure to pass value in seconds for TR values,
                        You can click here and use time converter which will calculate time for you
                </span>
            default:
                return null
        }
    }

    const openConvert = () => {
        setOpenConverter(true)
    }
    return (
        <React.Fragment>
            <Modal
                title={`Edit ${data.key} (${data.description})`}
                open={open}
                disableClose={true}
                setOpen={setOpen}
                subtitle={showSubtitle()}
                confirmButton={"Confirm"}
                disabled={disabled}
                onComplete={onComplete}
                cancelButton={"Cancel"}
                content={currentList &&
                <>
                    {currentList?.map((i: any, index: number) => (
                        <React.Fragment>
                            {Object.keys(i).map((key) => {
                                const currentVariableKeyName = key + (index + 1)
                                return <>
                                    <TextField
                                        key={index}
                                        error={isValidField && isValidField.key === currentVariableKeyName ? !isValidField.valid : false}
                                        style={{marginBottom: 15}}
                                        type={"number"}
                                        label={currentVariableKeyName}
                                        placeholder=""
                                        onChange={(e) => onChange(index, currentVariableKeyName, e.target.value)}
                                        value={i[key]}
                                        fullWidth
                                        inputProps={{
                                            pattern: "^[0-9]+$"
                                        }}
                                    />
                                    <span style={STYLES_ERROR_MESSAGE}>{showFieldErrorMessageIfNotValid(currentVariableKeyName)}</span>
                                </>
                            })}
                        </React.Fragment>
                    ))}
                </>

                }
            />
            <TimeConverterModal open={openConverter} setOpen={setOpenConverter}/>
        </React.Fragment>
    )
}

export default EditObjectArrayModal;