import React, {CSSProperties} from "react"


interface IProps {
    src: string,
    imageRef: any
}

const STYLES_WRAPPER: CSSProperties = {width: "100%", height: "100%", position: "fixed", top: 0, left: 0, background: "rgba(0, 0, 0, 0.75)", zIndex: 2}
const STYLES_IMAGE: CSSProperties = {width: "450px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}

const ImagePreview: React.FC<IProps> = ({src, imageRef}) => {

    return (
        <div style={STYLES_WRAPPER}>
            <img style={STYLES_IMAGE} ref={imageRef} src={src} alt={""}/>
        </div>
    )
}

export default ImagePreview