import axios, {AxiosResponse} from "axios";

export const getDocs = (type: string) => {
    return axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/system/dynamic/page/${type}`
    })
        .then((response: AxiosResponse) => response && response?.data)
        .catch((error) => error.response?.data)
}

export const updateDocs = (data:{type:string, data: any}) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/admin/system/dynamic/page`, data)
        .then((response) => response && response.data )
        .catch((error) =>
            error &&
            error.response &&
            error.response.message
                ? {error: error.response.message}
                : {error: 'unknown error'},
        );
}