import { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
//import {loginAction, logoutAction} from '../redux/reducers/auth';
import { authPasswordCreate, authPasswordExist, authRequest, signinRequest } from "../../api/auth";
import { useCookies } from "./cookies";
import { deleteToken, setToken } from "./token";
import { USER_ROLE } from "../../enum/user";
import { loginAction, logoutAction } from "../../redux/reducers/auth";
import { AUTH_KEY } from "../../enum/auth";

export const useAuth = () => {
  const { auth } = useSelector((state: RootStateOrAny) => state);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  // const [refreshLoader] = useState<boolean>(false);
  const { getItem, removeItem, setItemWithExpiration, setItem } = useCookies();
  const dispatch = useDispatch();
  const [refreshLoader, setRefreshLoader] = useState<boolean>(false)

  return {
    error,
    loading,
    refreshLoader,
    login: async (
      credentials: { email: string; password: string },
      remember: boolean | undefined,
      callback: () => void
    ) => {
      setLoading(true);
      const response = await signinRequest(credentials);

      if (response && response.message) {
        setError(response.message);
      } else if (response && response.data) {
          const { token, user } = response.data;

          const authToken = token.type + " " + token.accessToken;

          if (remember) {
            await setItemWithExpiration({
              key: AUTH_KEY,
              value: authToken,
              expiresIn: token.expiresIn,
            });
          }
          await setItem({
            key: AUTH_KEY,
            value: authToken,
            expiresIn: token.expiresIn,
          });
          setToken(authToken);

          dispatch(loginAction({ user }));

          callback();
        } else {
          setError("User doesn't exist.");
        }
      setLoading(false);
    },

    authCheck: async (callback: () => void) => {
      setLoading(true);
      const authToken = await getItem(AUTH_KEY);

      if (authToken && !auth.user) {
        setToken(authToken);
        const response = await authRequest();

        if (response && response.message) {
          setError(response.message);
        } else if (response && response.data) {
          const user = response.data;
          dispatch(loginAction({ user }));
          callback();
        }
      }
      setLoading(false);
    },

    refreshUser: async () => {
        setRefreshLoader(true)
        const authToken = await getItem(AUTH_KEY);
        if (authToken) {
            const response = await authRequest();
            if (response && response.message) {
                setError(response.message);
            } else if (response) {
                const user = response.data;
                dispatch(loginAction({user}));
            }
        }
        setRefreshLoader(false);
    },

    logout: async () => {
      setLoading(true);
      await removeItem(AUTH_KEY);
      await dispatch(logoutAction());
      await deleteToken();
      setLoading(false);
    },

    hasToken: async () => {
      setLoading(true);
      const authToken = await getItem(AUTH_KEY);
      setLoading(false);
      return !!authToken;
    },
  };
};

export const useUserRole = () => {
  const { user } = useSelector((state: RootStateOrAny) => state.auth);
  return {
    isAdmin: user && user.user && user.user.role === USER_ROLE.ADU,
  };
};

export const usePasswordExist = (email: string, emailBlur: boolean) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [passwordExist, setPasswordExist] = useState<any>()
  
  const getData = async () => {
      setLoading(true)
      const response = await authPasswordExist(email);
      if (response) {
          setLoading(false)
          setPasswordExist(response)
      }
  }

  useEffect(() => {
    email && emailBlur && (getData)()
    // eslint-disable-next-line
  }, [emailBlur, email])

  return {
      passwordExist,
      loading
  }
}

export const usePasswordCreate = (create: boolean, id: number | undefined, credentials: {email: string, password: string}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [passwordError, setPasswordError] = useState<any>()

  const getData = async () => {
      setLoading(true)
      const response = credentials.password && await authPasswordCreate(id, credentials);
      if (response && response.message) {
        setPasswordError(response.message);
      } else {
          setLoading(false)
      }
  }

  useEffect(() => {
    create && (getData)()
    // eslint-disable-next-line
  }, [create])

  return {
    passwordError,
    setPasswordError,
    loading
  }
}