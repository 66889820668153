import React, {CSSProperties, useRef, useState} from "react"
import {IComplaint} from "../../../interfaces/complaints";
import WarningOutlined from '@material-ui/icons/WarningOutlined';
import EmailIcon from '@material-ui/icons/Email';
import ImageIcon from '@material-ui/icons/Image';
import ImagePreview from "../../../components/ImagePreview";
import {useClickAway} from "react-use";
import {COMPLAINT_FORM_REASONS, COMPLAINTS_SEND_FROM, HOSTING_USER_ESCALATION_REASONS} from "../../../enum/complaint";

interface IProps {
    complaint: IComplaint
}

const STYLES_INFORMATION_CONTAINER: CSSProperties = {display: "flex", margin: "3% 0"}
const STYLES_INFORMATION: CSSProperties = {wordBreak: "break-all", fontSize: 16}
const STYLES_SVG: CSSProperties = {margin: 0, fontSize: 24, marginRight: "3%"}
const STYLES_IMG_LIST: CSSProperties = {display: "flex", alignItems: "center"}
const STYLES_IMG: CSSProperties = {width: "120px", height: "120px", objectFit: "cover", borderRadius: "6px", cursor: "pointer", marginRight: "3%"}

const ComplaintInfo: React.FC<IProps> = ({complaint}) => {
    const [openPreview, setOpenPreview] = useState<boolean>(false)
    const [selectedImage, setSelectedImage] = useState<string>("")
    const [imageLoaded, setImageLoaded] = useState<boolean>(false)
    const imageRef = useRef<HTMLImageElement>(null)

    const openImagePreview = (imageSrc: string) => {
        setOpenPreview(true)
        setSelectedImage(imageSrc)
    }

    useClickAway(imageRef, () => {
        setOpenPreview(false)
        setSelectedImage("")
    })

    const renderComplaintOrEscalationTitleForUser = () => {
        switch (complaint.send_complaint_from) {
            case COMPLAINTS_SEND_FROM.COMPLAINT_FORM:
                return "Complaint info";
            case COMPLAINTS_SEND_FROM.PRE_MEETING_EXIT:
                return "Host user escalation report";
            case COMPLAINTS_SEND_FROM.COMPLAINT_RESPOND:
                return "Fan user response"
            default:
                return ""
        }
    }

    const renderReasonLabel = (reason: string) => {
        switch (complaint.send_complaint_from) {
            case COMPLAINTS_SEND_FROM.COMPLAINT_FORM:
                return COMPLAINT_FORM_REASONS[reason as keyof typeof COMPLAINT_FORM_REASONS]?.label
            case COMPLAINTS_SEND_FROM.PRE_MEETING_EXIT:
                return HOSTING_USER_ESCALATION_REASONS[reason as keyof typeof HOSTING_USER_ESCALATION_REASONS]?.label
            default:
                return ""
        }
    }

    return (
        <div>
            <h2>{renderComplaintOrEscalationTitleForUser()}</h2>
            {complaint?.send_complaint_from !== COMPLAINTS_SEND_FROM.COMPLAINT_RESPOND && complaint?.reasons?.map((reason) => (
                <div style={STYLES_INFORMATION_CONTAINER}>
                    <WarningOutlined style={STYLES_SVG}/>
                    <span style={STYLES_INFORMATION}>{renderReasonLabel(reason)}</span>
                </div>
            ))}
            {!!complaint?.additional_info?.length && (
                <div style={STYLES_INFORMATION_CONTAINER}>
                    <EmailIcon style={STYLES_SVG}/>
                    <span style={STYLES_INFORMATION}>{complaint?.additional_info}</span>
                </div>
            )}
            {!!complaint?.complaint_assets?.length && <div style={STYLES_INFORMATION_CONTAINER}>
                <ImageIcon style={STYLES_SVG}/>
                <div style={STYLES_IMG_LIST}>
                    {complaint?.complaint_assets?.map((asset) =>
                        <img
                            onClick={() => openImagePreview(asset.public_url)}
                            onLoad={() => setImageLoaded(true)}
                            style={imageLoaded ? STYLES_IMG : {...STYLES_IMG, filter: "blur(8px)"}}
                            src={asset.public_url}
                            alt={""}
                        />)
                    }
                </div>
            </div>}
            {openPreview && <ImagePreview src={selectedImage} imageRef={imageRef}/>}
        </div>
    )
}


export default ComplaintInfo