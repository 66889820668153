import axios from "axios";
import { IPagination } from "../interfaces/request";

export interface IGetPaidContentSales {
  search_by?: string;
  sort_by?: string;
  pagination: IPagination;
}

export const getPaidContentSalesData = (data: IGetPaidContentSales) => {
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/admin/paid-content-sales`,
    data,
  })
    .then((response: any) => response?.data)
    .catch((error) => error.response?.data);
};