import { Props } from "react-select";

const Resolution: React.FC<Props> = ({ resolution }) =>{
    return (
        <div>
            <div className="system-meeting-data-subheading">Resolution</div>
            <div>
                <div className="system-meeting-key-value">
                    <div className="system-meeting-key">Start</div>
                    <div className="system-meeting-value">
                        {resolution?.start?.value ? `${resolution.start.value.width}X${resolution.start.value.height}`  : "-----"}
                    </div>
                </div>
                <hr />
                <div className="system-meeting-key-value">
                    <div className="system-meeting-key">End</div>
                    <div className="system-meeting-value">
                        {resolution?.end?.value  ? `${resolution.end.value.width}X${resolution.end.value.height}`  : "-----"}
                    </div>
                </div>
                <hr />
                <div className="system-meeting-key-value">
                    <div className="system-meeting-key">Maximum</div>
                    <div className="system-meeting-value">
                        {resolution?.max?.value  ? `${resolution.max.value.width}X${resolution.max.value.height}`  : "-----"}
                    </div>
                </div>
                <hr />
                <div className="system-meeting-key-value">
                    <div className="system-meeting-key">Minimum</div>
                    <div className="system-meeting-value">
                        {resolution?.min?.value  ? `${resolution.min.value.width}X${resolution.min.value.height}`  : "-----"}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Resolution;