import React, {CSSProperties, useEffect} from "react"
import Modal from "../../../../../components/Modal";
import {GridRowData} from "@mui/x-data-grid";
import {TextField} from "@mui/material";
import {useNumberVariablesControl} from "../../../../../service/variables/number-values-control";

interface IProps {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    onComplete: () => Promise<void>,
    setRequestBodyJSONValue: (jsonValue: string) => void,
    data: GridRowData
}

// QUICK STYLES
const STYLES_ERROR_MESSAGE: CSSProperties = {fontSize: 12, color: "red"}
const STYLES_PERCENTAGE_VALUE: CSSProperties = {color: "grey", marginTop: 5, display: "inline-block"}

const EditNumberModal: React.FC<IProps> = (
    {
        open,
        setOpen,
        onComplete,
        setRequestBodyJSONValue,
        data
    }) => {
    const {
        value,
        onChange,
        renderValueWithAdditionalUnitDependingOnDataType,
        disabled,
        errorMessage,
        isValid,
    } = useNumberVariablesControl(data)

    // eslint-disable-next-line
    useEffect(() => setRequestBodyJSONValue(value), [value])

    return (
        <Modal
            title={`Edit ${data.key} (${data.description})`}
            subtitle={"Make sure to pass a valid value and not impact other parameters"}
            open={open}
            setOpen={setOpen}
            confirmButton={"Confirm"}
            onComplete={onComplete}
            cancelButton={"Cancel"}
            disabled={disabled}
            content={(
                <React.Fragment>
                    <TextField
                        type={"number"}
                        label="Amount"
                        error={!isValid}
                        id="outlined-error-helper-text"
                        placeholder="10"
                        onChange={onChange}
                        value={value}
                        fullWidth
                        inputProps={{
                            pattern: "^[0-9]+$",
                            step: 0.1
                        }}
                    />
                    {isValid && !!value.length &&
                    <span style={STYLES_PERCENTAGE_VALUE}> {renderValueWithAdditionalUnitDependingOnDataType()} </span>}
                    {!isValid && <span style={STYLES_ERROR_MESSAGE}>{errorMessage}</span>}
                </React.Fragment>
            )}
        />
    )
}

export default EditNumberModal;