import React from "react"
import Modal from "../../../components/Modal";
import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from "@mui/material";
import {IVerificationDecision} from "../../../interfaces/verification-requests";


interface IProps {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    reason: {
        value: string,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    },
    onMakeDecision: (decision: IVerificationDecision) => Promise<void>
}

const REJECT_VERIFICATION_REASONS = {
    REJECTED_PHOTO_ID_MISSING: {
        label: "Photo ID is missing, incomplete or not legible",
        value: "REJECTED_PHOTO_ID_MISSING"
    },
    REJECTED_NOT_MATCHING_AVATAR: {
        label: "Photo ID not matching profile picture",
        value: "REJECTED_NOT_MATCHING_AVATAR"
    }
}

const ReasonForRejectModal: React.FC<IProps> = (
    {
        open,
        setOpen,
        reason,
        onMakeDecision
    }) => {
    return (
        <Modal
            disableClose={true}
            disabled={reason?.value?.length === 0}
            onComplete={async () => await onMakeDecision(reason.value)}
            confirmButton={"OK"}
            cancelButton={"Cancel"}
            open={open}
            setOpen={setOpen}
            content={
                <FormControl>
                    <FormLabel>Reject reason</FormLabel>
                    <RadioGroup
                        value={reason.value}
                        onChange={reason.onChange}
                    >
                        {Object.values(REJECT_VERIFICATION_REASONS).map((reason) => (
                            <FormControlLabel value={reason.value} control={<Radio color={"primary"}/>}
                                              label={reason.label}/>
                        ))}
                    </RadioGroup>
                </FormControl>
            }
        />
    )
}


export default ReasonForRejectModal;