export const DOCUMENT_DESCRIPTION = [
    {
        text: "-\tA ‘hyperlink’ within a document is a click-able string which, when clicked, will direct the user to defined URL (URL = an address which points a page or section ) "
    },
    {
        text: "-\tIn order for Chusp to understand to which specific section with the Term he should be directed (unique URL pointing that section), we need to create the html section (div tag with the unique id (identifier)"
    },
]

export const DOCUMENT_CREATE_LINK = {
    FIRST_STEP: "1.\tSelect a document (e.g. Terms, Privacy policy) within which you want to create  intra-document links/cross references to specified paraggraphs/sections within that document (so user will be directed to such sections without having to scroll/search for them)",
    SECOND_STEP: "2.\t‘Create a unique URL’ by entering a string in the input field; this string will be appended to ‘https:/dev.chusp.com/p/policy?’ to form a policy\n" +
        "\n" +
        "This URL can then be used within Chusp dev/prod environment to direct the user to that specific document or section within that document \n",
    THIRD_STEP: "3.\tOnce you entered the text into the input field; the newly generated URL will be displayed under step 3 ",
    FOURTH_STEP: "4. Click here to copy the generated URL: ",
    FIFTH_STEP: "4.\tWithin the document within which we want to insert/ user the hyperlink:",
    SIX_STEP: "-\tSelect/highlight key words which should, when clicked on by the user, should direct user to the specific, newly generated URL  (for instance: if the newly generated URL is meant to direct the user to section ‘Annex A’, select/highlight all relevant strings within the document that make reference to Annex A-\tSelect/highlight key words which should, when clicked on by the user, should direct user to the specific, newly generated URL  (for instance: if the newly generated URL is meant to direct the user to section ‘Annex A’, select/highlight all relevant strings within the document that make reference to Annex",
    SEVENTH_STEP: "-\tOn the header bar, click on the ‘insert/edit’ ",
    EIGHT_STEP: "5. \tThis will prompt a modal window requiring the newly created URL to be entered (just click ‘Control V’ or ‘right click + paste’ to insert the URL from the clipboard into the URL field"
}

export const DOCUMENT_CREATE_HTML = {
    FIRST_STEP: "1. Within the Admin Panel, select ‘HTML’, and drag + drop into the document, and at the documentation location where Annex A is to be displayed.\n" +
        "Upon dropping the ‘HTML’ icon from the menue into the chosen location/position within the document, a placeholder ‘Hello, world!’ will be displayed by default \n",
    SECOND_STEP: "2. Now generate the desired HTML by replacing the dummy ‘Hello, world!’ by the desired text\n" +
        "Double click on the add block -> On the right panel, there will appear an ‘HTML tab, showing ‘Hello World’ bracketed by ‘strong’\n" +
        "\tThe content in this tab represents the HTML content \n",
    THIRD_STEP: "3. a) Insert the relevant, unique identifier which we created / chose under step 3 of the ‘Instruction for creating link’ procedure (only the specific string appended to the general URL needs to be entered, not the entire URL; for instance, only Annex A, instead of ‘https:/dev.chusp.com/p/policy?/AnnexA )\n" +
        "-> by default, the ‘div id’ input field  will be pre-populated with the current entry/input represented in input field off step 3 of process ‘Instruction for creating for link’ (the ‘add unique field’)\n (\tWe’re creating an HTML container/section with the unique identifier inserted into the ‘div id’ input field as per above)",
    FOURTH_STEP: ".b) Insert the actual (text) content which is meant to replace ‘Hello, world’ into field ‘Section title’\n" +
        "- copy/past the entire section that is to be referenced/represented as HTML by the relevant UR\n",
    SIXTH_STEP: "5.\t‘Click to copy final result’ ",
    SEVENTH_STEP: "6.\tCopy paste it into the ‘HTML’ input field within the ‘Content’ panel to the right; the thus newly generated HTM content (e.g. the entire Annnex A) will appear instead of the ‘Hello world’ placeholder text"
}