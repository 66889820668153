import React, {useCallback, useEffect, useState} from "react";
import {GridCellParams} from "@mui/x-data-grid";
import {CELL_FIELD_TYPES, DATA_TYPE} from "../../../../service/variables/system-variables-table";
import EditNumberModal from "./EditNumberModal";
import EditStringsArrayModal from "./EditStringsArrayModal";
import EditObjectArrayModal from "./EditObjectArrayModal";
import EditTransactionDateModal from "./EditTransactionDateModal";
import {changeSystemVariable} from "../../../../api/system";
import Loader from "../../../../components/Loader";
import SuccessOrErrorModal from "../../../../components/Modal/SuccessOrErrorModal";
import {INFO_MODAL} from "../../../../enum/successOrErrorModal";
import {useVariables} from "../../../../service/variables/variables";
import EditStringsModal from "./EditStringModal";

interface IProps {
    clickedRow: GridCellParams | null
}

export const EditCellModals: React.FC<IProps> = (
    {
        clickedRow
    }) => {
    const [openModal, setOpenModal] = useState<boolean>(false)
    const [requestBodyJSONValue, setRequestBodyJSONValue] = useState(clickedRow?.row?.value)
    const [openErrorModal, setOpenErrorModal] = useState<boolean>(false)
    const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const {getVariables, loading: dispatchVariablesLoading} = useVariables()

    useEffect(() => setOpenModal(true), [clickedRow])
    useEffect(() => setRequestBodyJSONValue(clickedRow?.row?.value), [clickedRow?.row?.value])

    const formRequestBodyValue = useCallback((jsonValue: any) => setRequestBodyJSONValue(jsonValue), [setRequestBodyJSONValue])

    const onComplete = async () => {
        setLoading(true)
        const response = await changeSystemVariable({key: clickedRow?.row?.key, value: requestBodyJSONValue});
        if (response && response?.data?.isExpected) {
            setOpenSuccessModal(true)
            // FOR REDUX
            await getVariables()
        } else {
            setOpenErrorModal(true)
        }
        response && setLoading(false)
    }

    if (loading || dispatchVariablesLoading) return <Loader/>

    const renderCorrectModal = () => {
        if (openModal) {
            switch (clickedRow?.row?.fieldType) {
                case CELL_FIELD_TYPES.NUMBER:
                    if(clickedRow?.row.dataType === DATA_TYPE.RESOLUTION){
                        return <EditStringsModal
                            open={openModal}
                            setOpen={setOpenModal}
                            data={clickedRow?.row}
                            onComplete={onComplete}
                            setRequestBodyJSONValue={formRequestBodyValue}
                        />
                    }
                    else {
                        return <EditNumberModal
                            open={openModal}
                            setOpen={setOpenModal}
                            onComplete={onComplete}
                            setRequestBodyJSONValue={formRequestBodyValue}
                            data={clickedRow?.row}
                        />
                    }
                case CELL_FIELD_TYPES.STRINGS_ARRAY:
                    return <EditStringsArrayModal
                        open={openModal}
                        setOpen={setOpenModal}
                        onComplete={onComplete}
                        setRequestBodyJSONValue={formRequestBodyValue}
                        data={clickedRow?.row}
                    />
                case CELL_FIELD_TYPES.OBJECTS_ARRAY:
                    return <EditObjectArrayModal
                        open={openModal}
                        setOpen={setOpenModal}
                        onComplete={onComplete}
                        data={clickedRow?.row}
                        setRequestBodyJSONValue={formRequestBodyValue}
                    />
                case CELL_FIELD_TYPES.TRANSACTION_DATE:
                    return <EditTransactionDateModal
                        open={openModal}
                        setOpen={setOpenModal}
                        data={clickedRow?.row}
                        onComplete={onComplete}
                        setRequestBodyJSONValue={formRequestBodyValue}
                    />
            }
        }
    }


    return <>
        {openModal && renderCorrectModal()}
        <SuccessOrErrorModal
            title={"Error"}
            type={INFO_MODAL.ERROR}
            button={<button>OK</button>}
            content={'Something went wrong!'}
            open={openErrorModal}
            setOpen={setOpenErrorModal}
        />
        <SuccessOrErrorModal
            title={"Success"}
            type={INFO_MODAL.SUCCESS}
            button={<button>OK</button>}
            content={`You successfully updated system variable!`}
            open={openSuccessModal}
            setOpen={setOpenSuccessModal}
        />
    </>
}

export default EditCellModals;