import axios from "axios";

export const getAllTemplateVersions = () => {
    return axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/template-version/get/all`,
    })
        .then((response: any) => response?.data)
        .catch((error) => error.response?.data);
};

export const getSelectedTemplateVersions = (id:number) => {
    return axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/template-version/${id}`,
    })
        .then((response: any) => response?.data)
        .catch((error) => error.response?.data);
};

export const createEmailTemplateVersion = (data:any) => {
    return axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/template-version/create`,
        data,
    })
        .then((response: any) => response?.data)
        .catch((error) => error.response?.data);
}

export const updateEmailTemplateVersion = (templateVersionId:any,data:any) => {
    return axios({
        method: "PUT",
        url: `${process.env.REACT_APP_API_URL}/template-version/${templateVersionId}`,
        data,
    })
        .then((response: any) => response?.data)
        .catch((error) => error.response?.data);
}

export const deleteTemplateVersion = (templateId: string) => {
    return axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_API_URL}/template-version/delete/${templateId}`,
    })
        .then((response: any) => response?.data)
        .catch((error) => error.response?.data);
};