import React from 'react'
import chusp from "../../shared/images/chusp-loader.png";


const ChuspAnimated = () => {
    return (
        <div className={'chusp-container'}>
            <div className="sparks">
                <div className="spark s1">{""}</div>
                <div className="spark s2">{""}</div>
                <div className="spark s3">{""}</div>
                <div className="spark s4">{""}</div>
                <div className="spark s5">{""}</div>
                <div className="spark s6">{""}</div>
                <div className="spark s7">{""}</div>
                <div className="spark s8">{""}</div>
                <div className="spark s9">{""}</div>
                <div className="spark s10">{""}</div>
                <div className="spark s11">{""}</div>
                <div className="spark s12">{""}</div>
            </div>
            <div className={'chusp-wrapper'}>
                <div className={'chusp-loader'}>
                    <img src={chusp} alt={'Chusp logo'}/>
                </div>
            </div>

        </div>
    )
}
export default ChuspAnimated
