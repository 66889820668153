import React, {useRef} from 'react'
import {useClickAway} from "react-use";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import {INFO_MODAL} from "../../../enum/successOrErrorModal";

interface IProps {
    title?: JSX.Element | JSX.Element[] | null | string,
    content?: JSX.Element | JSX.Element[] | null | string,
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    onComplete?: any,
    button?: JSX.Element | JSX.Element[] | null | string,
    type: string,
    buttonClass?: string
    onActionClicked?: () => void
}

const SuccessOrErrorModal: React.FC<IProps> = (
    {
        title,
        button,
        open, setOpen,
        content,
        onComplete,
        type,
        buttonClass,
        onActionClicked
    }
) => {
    const ref = useRef<HTMLDivElement>(null)
    // const history = useHistory();

    useClickAway(ref, () => setOpen(false))

    const confirmModal = async () => {
        console.log('confirmModal')
        onComplete && await onComplete()
        setOpen(false)
    }
    // const actionClicked = () => {
    //     onActionClicked && onActionClicked()
    // }
    return (
        <div className={`wrapper ${open ? 'active' : ''}`}>
            <div className="details-modal" ref={ref}>
                <div className="icon-container">
                    {
                        type === INFO_MODAL.SUCCESS ?
                            <CheckCircleIcon className={'success-icon'}/>
                            :
                            <RemoveCircleIcon className={'error-icon'}/>

                    }
                </div>
                <div className="icon-modal-title">
                    <h1>{title}</h1>
                </div>
                <div className="details-modal-content">
                    <p>
                        {content}
                    </p>
                </div>
                <div className={`confirm-button ${buttonClass ?? ''}`} onClick={confirmModal}>
                    {button}
                </div>
            </div>
        </div>
    )


}

export default SuccessOrErrorModal;