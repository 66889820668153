export const SERVICE_VARIABLE_NUMBERS = {
    CUSTOM: "CUSTOM",
    HBR: "HBR",
    HRF1: "HRF1",
    HRF2: "HRF2",
    HRF3: "HRF3",
    HRF4: "HRF4",
    CPM0: "CPM0",
    CPM1: "CPM1",
    CPM2: "CPM2",
    CPM3: "CPM3",
    FR_MIN_DATASET_SIZE: "FR_MIN_DATASET_SIZE",
    MIN_FR_SCORE: "MIN_FR_SCORE",
    MIN_PAYOUT_AMOUNT: "MIN_PAYOUT_AMOUNT",
    RELIABLITY_SCORE_CALCULATION_THRESHOLD: "RELIABLITY_SCORE_CALCULATION_THRESHOLD",
    REPEAT_SLOT_WEEK_COUNTER: "REPEAT_SLOT_WEEK_COUNTER",
    MAX_QUALITY_RESOLUTION_FOR_MEETING_MODE: "MAX_QUALITY_RESOLUTION_FOR_MEETING_MODE"
};

export const SERVICE_VARIABLE_PERCENTAGES = {
    BDF0: "BDF0",
    BDF1: "BDF1",
    BDF2: "BDF2",
    BDF3: "BDF3",
    CHUSP_FEE: "CHUSP_FEE",
}

export const SERVICE_VARIABLE_TIMES = {
    MU: "MU",
    COMPLAINT_WINDOW_PERIOD: "COMPLAINT_WINDOW_PERIOD",
    EVENT_UNPAID_STATUS_ALIVE_TIME: "EVENT_UNPAID_STATUS_ALIVE_TIME"
}

export const SERVICE_VARIABLE_OBJECTS = {
    SYSTEM_LANGUAGES: "SYSTEM_LANGUAGES",
    REFUND_RULES: "REFUND_RULES"
}

export const TRANSACTION_VARIABLE_DATES = {
    TRANSFER_DATE: "TRANSFER_DATE",
    DISBURSEMENT_DATE: "DISBURSEMENT_DATE",
    CUT_OFF_POINT: "CUT_OFF_POINT"
}

export const VARIABLES_WITH_JSON_VALUES = {
    SYSTEM_LANGUAGES: "SYSTEM_LANGUAGES",
    REFUND_RULES: "REFUND_RULES"
}
export const QUALITY_RESOLUTIONS = ['240p','360p','480p','720p','1080p','2160p'];