import React, {useCallback, useEffect, useState} from 'react';
import {ISort} from "../../../interfaces/sort";
import {useAllTickets} from "../../../service/tickets/tickets";
import Loader from "../../../components/Loader";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CustomSelect from "../../../components/Select";
import Input from "../../../components/Input";
import {SORT_BY} from "../../../constant/sort-complaints";
import Table from "../../../components/Table";
import {ReportsHeader, ReportsTableName} from "../../../constant/TableHeaders/reports-header";
import {usePagination} from '../../../service/common/pagination';
import {TICKET_STATUS, TICKET_TYPE} from '../../../enum/tickets';
import {getAllTickets} from '../../../api/tickets';
import {INIT_PAGINATION, PAGE_SIZE} from '../../../constant/pagination';
import {bodyVariablesForTickets} from '../../../utils/body-variables';
import TicketDetails from '../tickets-details';
import {onHandleSearchAndSort} from '../../../utils/sort-search';
import {overflowBody} from '../../../utils/style-document';
import {useUpdateTicketFromUnreadToRead} from "../../../service/tickets/update-from-unread-to-read";
import { useHistory } from 'react-router-dom';

const Reports: React.FC = () => {
    const [search, setSearch] = useState<string>("")
    const [sort, setSort] = useState<ISort>({value: "DESC", label: "DESC"})
    const [currentPage, setCurrentPage] = useState<number>(0)
    const [reportsTickets, setReportsTickets] = useState<any>()
    const [openReportInfo, setOpenReportInfo] = useState<boolean>(false)
    const [reportInfo, setReportInfo] = useState<number>()
    const history = useHistory();

    useUpdateTicketFromUnreadToRead(reportInfo, setReportsTickets)

    const {onHandleSort, onHandleSearch, onHandleClear} = onHandleSearchAndSort(setCurrentPage, setSort, setSearch)

    const {tickets: postReports, loading: postReportsLoading} = useAllTickets(
        bodyVariablesForTickets(INIT_PAGINATION, sort?.value, '', {
            key: search,
            ticket_status: 'ACTIVE',
            ticket_type: TICKET_TYPE.INDIVIDUAL_POST_REPORT
        })
    )

    const {tickets: paidContentReports, loading: paidContentReportsLoading} = useAllTickets(
        bodyVariablesForTickets(INIT_PAGINATION, sort?.value, '', {
            key: search,
            ticket_status: 'ACTIVE',
            ticket_type: TICKET_TYPE.INDIVIDUAL_PAID_CONTENT_REPORT
        })
    )
    
    const {tickets: userReports, loading: userReportsLoading} = useAllTickets(
        bodyVariablesForTickets(INIT_PAGINATION, sort?.value, '', {
            key: search,
            ticket_status: 'ACTIVE',
            ticket_type: TICKET_TYPE.INDIVIDUAL_USER_REPORT
        })
    )

    const [newPostReports] = usePagination(getAllTickets, {
        variables: bodyVariablesForTickets({page: currentPage + 1, pageSize: PAGE_SIZE}, sort?.value, '', {
            key: search,
            ticket_status: 'ACTIVE',
            ticket_type: TICKET_TYPE.INDIVIDUAL_POST_REPORT
        })
    });
    const [newPaidContentReports] = usePagination(getAllTickets, {
        variables: bodyVariablesForTickets({page: currentPage + 1, pageSize: PAGE_SIZE}, sort?.value, '', {
            key: search,
            ticket_status: 'ACTIVE',
            ticket_type: TICKET_TYPE.INDIVIDUAL_PAID_CONTENT_REPORT
        })
    });
    const [newUserReports] = usePagination(getAllTickets, {
        variables: bodyVariablesForTickets({page: currentPage + 1, pageSize: PAGE_SIZE}, sort?.value, '', {
            key: search,
            ticket_status: 'ACTIVE',
            ticket_type: TICKET_TYPE.INDIVIDUAL_USER_REPORT
        })
    });

    useEffect(() => {
        if (userReports && postReports && paidContentReports) {
            setReportsTickets([...userReports?.items, ...postReports?.items, ...paidContentReports?.items])
        }
    }, [postReports, userReports, paidContentReports])

    useEffect(() => {
        if (newPostReports?.items && newPostReports?.items?.length > 0) {
            setReportsTickets([...reportsTickets, ...newPostReports?.items])
            setCurrentPage(newPostReports?.currentPage)
        }
        // eslint-disable-next-line
    }, [newPostReports])
    
    useEffect(() => {
        if (newPaidContentReports?.items && newPaidContentReports?.items?.length > 0) {
            setReportsTickets([...reportsTickets, ...newPaidContentReports?.items])
            setCurrentPage(newPaidContentReports?.currentPage)
        }
        // eslint-disable-next-line
    }, [newPaidContentReports])

    useEffect(() => {
        if (newUserReports?.items && newUserReports?.items?.length > 0) {
            setReportsTickets([...reportsTickets, ...newUserReports?.items])
            setCurrentPage(newUserReports?.currentPage)
        }
        // eslint-disable-next-line
    }, [newUserReports])

    const openReportCard = (value: any) => {
        setOpenReportInfo(true)
        setReportInfo(value)
    }

    overflowBody(openReportInfo)

    const updateReportsList = useCallback((ticket: any, resolvedTicket: any) => {
        const index = reportsTickets?.findIndex((i: any) => i?.id === ticket?.id)
        setReportsTickets(((prevState: any) => {
            prevState[index] = resolvedTicket
            return prevState
        }))
        // eslint-disable-next-line
    }, [setReportsTickets])

    const showOnlyActiveComplaintTickets = reportsTickets?.filter((ticket: any) => ticket?.status !== TICKET_STATUS.RESOLVED)

    const showLoader = (postReportsLoading && userReportsLoading && paidContentReportsLoading) && !search
    return (
        <>
            <div className="back-btn-div">
        <button
          className="back-btn mt-2 mb-2"
          onClick={() => history.goBack()}
        >
          Back
        </button>
               {showLoader && <Loader/>}</div>
            {openReportInfo && <TicketDetails
                type={TICKET_TYPE.INDIVIDUAL_USER_REPORT}
                info={reportInfo}
                setOpen={setOpenReportInfo}
                updateTicketsDataList={updateReportsList}
            />}
            <div className='reports-page'>
                <div className="top-panels">
                    <label className="input-wrapper">
                        <Input
                            onChange={onHandleSearch}
                            value={search}
                            placeholder={"Search"}
                            type={"search"}
                            classInput="input-search"
                        />
                        <span className="clear-input" onClick={onHandleClear}>
            {search ? <HighlightOffIcon/> : null}
          </span>
                    </label>
                    <CustomSelect
                        onChange={onHandleSort}
                        value={sort?.value}
                        placeholder={sort.label || "Sort"}
                        options={SORT_BY}
                    />
                </div>
                {!!showOnlyActiveComplaintTickets?.length ? (
                        <div className="report-table">
                            <Table
                                header={ReportsHeader}
                                items={showOnlyActiveComplaintTickets}
                                tableName={ReportsTableName}
                                onClickHandler={openReportCard}
                            />
                        </div>) :
                    <h2 className="nth-found">Nothing found</h2>
                }
            </div>
        </>
    )
}

export default Reports;
