import React, {CSSProperties, useState} from "react"
import Modal from "../../../components/Modal";
import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from "@mui/material";
import {IComplaint} from "../../../interfaces/complaints";
import {IBasicUserInfo} from "../../../interfaces/user";


interface IProps {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    onMakeDecision: (adminDecision: string) => void,
    hostingUser: IBasicUserInfo,
    fanUser: IBasicUserInfo,
    complaintsWithNoReply: boolean,
    singleComplaint: IComplaint
}

const STYLES_DESCRIPTION_TEXT: CSSProperties = {fontSize: 18, opacity: 0.7, textDecoration: "underline"}

const ACCEPT_DECISIONS = (huName: string, fuName: string) => {
    return {
        ACCEPTED_HU: {
            label: `Decide in favour of reporter (${huName} - host user)`,
            value: "ACCEPTED_HU",
        },
        ACCEPTED_FU: {
            label: `Decide in favour of respondent (${fuName} - fan user)`,
            value: "ACCEPTED_FU"
        },
        ACCEPTED_FIFTY_FIFTY: {
            label: "50% / 50% (Undecided)",
            value: "ACCEPTED_FIFTY_FIFTY"
        },
    }
}

const ComplaintDecisions: React.FC<IProps> = (
    {
        open,
        setOpen,
        onMakeDecision,
        hostingUser,
        fanUser,
        complaintsWithNoReply,
        singleComplaint
    }) => {
    const [decision, setDecision] = useState<string>("")

    const renderDescriptionMessage = singleComplaint?.complaint_author?.id === hostingUser?.id ?
        `${hostingUser?.first_name} will receive earnings` :
        `${fanUser?.first_name} will get a refund`

    const detectComplaintAuthorRole = singleComplaint?.complaint_author?.id === hostingUser?.id ? "(hosting user)" : "(fan user)"

    return (
        <Modal
            disabled={decision?.length === 0}
            disableClose={true}
            confirmButton={"Ok"}
            cancelButton={"Cancel"}
            open={open}
            setOpen={setOpen}
            onComplete={async () => onMakeDecision(decision)}
            content={
                complaintsWithNoReply ?
                    <>
                        <p>Are you sure you want to accept this complaint
                            for {singleComplaint?.complaint_author?.first_name} {detectComplaintAuthorRole}?</p>
                        <p style={STYLES_DESCRIPTION_TEXT}><em>{renderDescriptionMessage}</em></p>
                    </>
                    :
                    <FormControl>
                        <FormLabel>Admin decision</FormLabel>
                        <RadioGroup
                            value={decision}
                            onChange={(e) => setDecision(e.target.value)}
                        >
                            {Object.values(ACCEPT_DECISIONS(hostingUser?.first_name, fanUser?.first_name)).map((d) => (
                                <FormControlLabel value={d.value} control={<Radio color={"primary"}/>} label={d.label}/>
                            ))}
                        </RadioGroup>
                    </FormControl>
            }
        />
    )
}


export default ComplaintDecisions;