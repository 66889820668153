import {useState} from 'react';

interface ICookie {
  key: string,
  expiresIn?: number,
  value: string
}

export const useCookies = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  return {
    error,
    loading,
    setItem: async (cookie: ICookie) => {
      setLoading(true);
      try {
        // eslint-disable-next-line
        document.cookie = cookie.key + '=' + encodeURIComponent(cookie.value) + ";" + "path=/";
      } catch (e) {
        setError(error);
      }
      setLoading(false);
    },
    setItemWithExpiration: async (cookie: ICookie) => {
      setLoading(true);
      try {
        const expires = new Date(Date.now() + (cookie.expiresIn ? cookie.expiresIn : 7 * 864e5)).toUTCString();
        // eslint-disable-next-line
        document.cookie = cookie.key + "=" + encodeURIComponent(cookie.value) + ";expires=" + expires + ";" + "path=/";
      } catch (e) {
        setError(error);
      }
      setLoading(false);
    },
    getItem: async (key: string) => {
      setLoading(true);
      try {
        return document.cookie.split('; ').reduce((r, v) => {
          const parts = v.split('=')
          return parts[0] === key ? decodeURIComponent(parts[1]) : r
        }, '');
      } catch (e) {
        setError(error);
      }
      setLoading(false);
    },
    removeItem: async (key: string) => {
      setLoading(true);
      try {
        document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      } catch (e) {
        setError(error);
      }
      setLoading(false);
    },
  };
};
