import React, { useEffect, useState } from "react";
import Table from "../../components/Table";
import { PaidContentSalesHeader, PaidContentSalesTableName } from "../../constant/TableHeaders/paid-content-sales-header";
import { useGetPaidContentSales } from "../../service/paid-content-sales/paid-content-sales";
import { basicBodyVariables } from "../../utils/body-variables";
import { INIT_PAGINATION, PAGE_SIZE } from "../../constant/pagination";
import { ISort } from "../../interfaces/sort";
import { SORT_BY } from "../../constant/sort-paid-content-sales";
import { usePagination } from "../../service/common/pagination";
import { getPaidContentSalesData } from "../../api/paid-content-sales";
import { useHistory } from "react-router-dom";

const PaidContentSales: React.FC = () => {

    const [paidContentSales, setPaidContentSales] = useState<any>([])
    const [currentPage, setCurrentPage] = useState<number>(0)
    const history = useHistory();

    // const [search, setSearch] = useState<string>("");
    // const [sort, setSort] = useState<ISort>({value: SORT_BY[0].value, label: SORT_BY[0].label});
    const search: string = "";
    const sort: ISort = { value: SORT_BY[0].value, label: SORT_BY[0].label };

    const { paidContentSalesData } = useGetPaidContentSales(
        basicBodyVariables(INIT_PAGINATION, search, sort.value)
    )

    useEffect(() => {
        setPaidContentSales(paidContentSalesData?.items)
    }, [paidContentSalesData])

    const [newData] = usePagination(getPaidContentSalesData, {
        variables: basicBodyVariables({ page: currentPage + 1, pageSize: PAGE_SIZE }, search, sort.value)
    });

    useEffect(() => {
        if (paidContentSales && newData?.items && newData?.items?.length > 0) {
            setPaidContentSales(paidContentSales.concat(newData.items))
            setCurrentPage(newData?.currentPage)
        }
        // eslint-disable-next-line
    }, [newData])

    return (
        <>
            <div className="back-btn-div">
                <button
                    className="back-btn mt-2 mb-2"
                    onClick={() => history.goBack()}
                >
                    Back
                </button> </div>
            <div className="paid-content-sales-page" >
                <Table items={paidContentSales} header={PaidContentSalesHeader} tableName={PaidContentSalesTableName} />
            </div>
        </>

    );
};

export default PaidContentSales;
