import {useEffect, useState} from "react";
import {ROUTES} from "../../enum/routes";
import {getUnreadTicketsCount} from "../../api/tickets";
import {TICKET_TYPE} from "../../enum/tickets";
import {getUnreadComplaintsCount} from "../../api/complaint";
import {COMPLAINTS_SEND_FROM} from "../../enum/complaint";
import {getUndreadVerificationRequestsCount} from "../../api/verification-requests";
import {getUnreadSystemErrorsCount} from "../../api/system-errors";

export const useUnreadDataCounter = (path: string) => {
    const [counter, setCounter] = useState<number>(0)

    useEffect(() => {
        (async () => {
            let response
            switch (path) {
                case ROUTES.PAGES.REPORTS:
                    response = await getUnreadTicketsCount(TICKET_TYPE.REPORT_FORM)
                    break;
                case ROUTES.PAGES.SUPPORT:
                    response = await getUnreadTicketsCount(TICKET_TYPE.SUPPORT_FORM)
                    break;
                case ROUTES.PAGES.FEEDBACK:
                    response = await getUnreadTicketsCount(TICKET_TYPE.FEEDBACK_FORM)
                    break;
                case ROUTES.PAGES.COMPLAINTS:
                    response = await getUnreadComplaintsCount(COMPLAINTS_SEND_FROM.COMPLAINT_FORM)
                    break;
                case ROUTES.PAGES.HOSTING_ESCALATION:
                    response = await getUnreadComplaintsCount(COMPLAINTS_SEND_FROM.PRE_MEETING_EXIT)
                    break;
                case ROUTES.PAGES.VERIFICATION_REQUESTS:
                    response = await getUndreadVerificationRequestsCount()
                    break;
                case ROUTES.PAGES.SYSTEM_ERRORS:
                    response = await getUnreadSystemErrorsCount()
                    break;
            }
            if (response && response?.data) {
                setCounter(response?.data)
            }
        })()
    }, [path])

    return {
        counter
    }
}