import React from "react"
import {useEffect} from "react";
import {IVerificationRequestObject} from "../../interfaces/verification-requests";
import {updateUnreadVerificationRequestsToRead} from "../../api/verification-requests";
import {UNREAD_INDICATOR_DISAPPEAR_DELAY} from "../../enum/unread-indicator-dissapear-time";

export const useUpdateVerificationRequestFromUnreadToRead = (clickedCardData: any, setState: React.Dispatch<React.SetStateAction<any>>) => {

    useEffect(() => {
        (async () => {
            const ids = [clickedCardData?.id]
            if (clickedCardData && !clickedCardData?.is_read && ids?.length) {
                setTimeout(async () => {
                    await updateUnreadVerificationRequestsToRead(ids)
                    setState(((prevState: IVerificationRequestObject[]) => {
                        try {
                            const copy = JSON.parse(JSON.stringify(prevState))
                            copy?.forEach((i: IVerificationRequestObject) => {
                                if (ids?.includes(i.id)) {
                                    i.is_read = true
                                }
                            })
                            return copy
                        } catch (err) {
                            return
                        }
                    }))
                }, UNREAD_INDICATOR_DISAPPEAR_DELAY)
            }
        })()
        // eslint-disable-next-line
    }, [clickedCardData])
}