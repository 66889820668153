import Close from '@material-ui/icons/Close';
import React, {useRef} from 'react'
import {useClickAway} from "react-use";

interface IProps {
    title?: JSX.Element | JSX.Element[] | null | string,
    subtitle?: JSX.Element | JSX.Element[] | null | string,
    content?: JSX.Element | JSX.Element[] | null | string,
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    onComplete?: any,
    confirmButton?: JSX.Element | JSX.Element[] | null | string,
    cancelButton?: JSX.Element | JSX.Element[] | null | string,
    refToIgnore?: React.MutableRefObject<any>,
    disabled?: boolean,
    disableClose?: boolean,
    customClass?: string,
    width?: string,
    id?:string
}

const Modal: React.FC<IProps> = (
    {
        id,
        title,
        subtitle,
        confirmButton,
        cancelButton,
        open,
        setOpen,
        content,
        onComplete,
        refToIgnore,
        disabled,
        disableClose,
        customClass,
        width
    }) => {
    const ref = useRef<HTMLDivElement>(null)

    useClickAway(ref, () => {
        !refToIgnore && !disableClose && setOpen(false)
    })

    const confirmModal = async () => {
        setOpen(false);
        onComplete && await onComplete()
    }

    const onCancel = () => {
        setOpen(false)
    }

    return (
        <div id={id} className={`wrapper ${open ? 'active' : ''}`}>
            <div className={`details-modal ${customClass ?? ''}`}  ref={ref} style={{'width': width}}>
               
                {title && (
                    <div className='d-inline-flex'>
                    <div className="details-modal-title">
                        <h1>{title}</h1>
                    </div>
                    <div className='float-right'>
                    <button className="close-icon  m-2" onClick={onCancel} style={{border: 'none', background: 'transparent'}}><Close/></button>
                </div>
                </div>
                )}
                {subtitle && <div className="details-modal-subtitle">
                    <h2>{subtitle}</h2>
                </div>}
                <div className="details-modal-content">
                    <div>
                        {content}
                    </div>
                </div>
                <div className="modal-actions">
                    <button className={"cancel"} onClick={onCancel}>{cancelButton}</button>
                    <button disabled={disabled} className={"confirm"} onClick={confirmModal}>{confirmButton}</button>
                </div>
            </div>
        </div>
    )


}

export default Modal;