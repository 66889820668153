import {useEffect, useState} from "react";
import {IPagination} from "../../interfaces/request";
import { getSystemMeetingData } from "../../api/system-meetings";

export const useGetSystemMeetings = (body: {pagination: IPagination, search_by: string, sort_by: string}) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [systemMeetingsData, setSystemMeetingsData] = useState<any>()
    

    const getSystemMeetings = async () => {
        setLoading(true)
        const response = await getSystemMeetingData(body)
        if (response) {
            setLoading(false)
            setSystemMeetingsData(response)
        }
    }

    useEffect(() => {
        (getSystemMeetings)()
        return () => setSystemMeetingsData(undefined)
        // eslint-disable-next-line
    }, [body.search_by, body.sort_by])

    return {
        systemMeetingsData,
        loading
    }
}
