import axios from "axios";
import {ICreateTopicRequestBody} from "../interfaces/faq";

export const getTopics = () => {
    return axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/admin/faq/get`,
    })
        .then((response: any) => response?.data)
        .catch((error) => error.response?.data);
};

export const createTopic = (faq: ICreateTopicRequestBody) => {
    return axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/admin/faq/create`,
        data: faq
    })
        .then((response: any) => response?.data)
        .catch((error) => error.response?.data);
};

export const deleteTopic = (topicId: string) => {
    return axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_API_URL}/admin/faq/${topicId}`,
    })
        .then((response: any) => response?.data)
        .catch((error) => error.response?.data);
};


export const updateTopic = (topicId: string, data: ICreateTopicRequestBody) => {
    return axios({
        method: "PUT",
        url: `${process.env.REACT_APP_API_URL}/admin/faq/${topicId}`,
        data
    })
        .then((response: any) => response?.data)
        .catch((error) => error.response?.data);
};

