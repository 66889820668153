import OptionWithIconAndText from "../../components/option-with-icon-and-text";
import VerifiedUserRounded from '@material-ui/icons/VerifiedUserRounded';
import SmsIcon from '@material-ui/icons/Sms';
import FeedbackIcon from '@material-ui/icons/Feedback';
import { useHistory } from "react-router-dom";
import { EMAIL_NOTIFICATION_MANAGEMENT_TYPE } from "../../enum/email-notification-management";

const EmailNotificationManagement = () => {

    const history = useHistory()

    return  (<>
         <div className="back-btn-div">
                <button
                    className="back-btn mt-2"
                    onClick={() => history.goBack()}
                >
                    Back
                </button> </div><div className="email-notification-type">
        <div className="email-notification-type-heading">
            <h3>Email Notification Management</h3>
        </div>
        <div className={"email-notification-type-container"}>
            <OptionWithIconAndText
                icon={<VerifiedUserRounded />}
                text={"Verification Requests"}
                isAvailable={true}
                onClick={() => history.push({
                    pathname: '/email_notification_management/email_list',
                    state: EMAIL_NOTIFICATION_MANAGEMENT_TYPE.VERIFICATION_REQUEST
                })}
            />
            <OptionWithIconAndText
                icon={<SmsIcon />}
                text={"Hosting Escalations"}
                isAvailable={true}
                onClick={() => history.push({
                    pathname: '/email_notification_management/email_list',
                    state: EMAIL_NOTIFICATION_MANAGEMENT_TYPE.HOSTING_ESCALATION
                })}
            />
            <OptionWithIconAndText
                icon={<SmsIcon />}
                text={"Complaints"}
                isAvailable={true}
                onClick={() => history.push({
                    pathname: '/email_notification_management/email_list',
                    state: EMAIL_NOTIFICATION_MANAGEMENT_TYPE.COMPLAINT
                })}
            />
            <OptionWithIconAndText
                icon={<FeedbackIcon />}
                text={"Feedback"}
                isAvailable={true}
                onClick={() => history.push({
                    pathname: '/email_notification_management/email_list',
                    state: EMAIL_NOTIFICATION_MANAGEMENT_TYPE.FEEDBACK
                })}
            />
        </div>
    </div></>)
}

export default EmailNotificationManagement