import axios from "axios";
import {ICorporateAccountsBalanceTWRequestBody} from "../interfaces/corpotare-accounts";

export const getTWBalance = (data: ICorporateAccountsBalanceTWRequestBody) => {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/transferwise/account/balance`,
    data,
  })
    .then((response: any) => response?.data)
    .catch((error) => error.response?.data);
};

export const getDBSBalance = () => {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/dbs/get/balance`,
  })
      .then((response: any) => response?.data)
      .catch((error) => error.response?.data);
};