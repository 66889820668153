import { COLUMN_TYPE } from "../../components/Table/column-factory";
import { HeaderItemType } from "../../interfaces/table";

export const SystemTableName = "System Errors"

export const SystemErrorsHeader = [
    {
        title: "",
        type: COLUMN_TYPE.STRING,
        meta: {
            key: "is_read",
        },
    },
    {
        title: "Status code",
        type: COLUMN_TYPE.STRING,
        meta: {
            key: "status_code",
        },
    },
    {
        title: "Stack trace",
        type: COLUMN_TYPE.STRING,
        meta: {
            key: "stackTraceShortened",
        },
    },
    {
        title: "Status",
        type: COLUMN_TYPE.STRING,
        meta: {
            key: "status",
        },
    },
    {
        title: "Module",
        type: COLUMN_TYPE.STRING,
        meta: {
            key: "module"
        },
    },
    {
        title: "Target",
        type: COLUMN_TYPE.STRING,
        meta: {
            key: "target"
        },
    },
    {
        title: "Created at",
        type: COLUMN_TYPE.STRING,
        meta: {
            key: "created_at"
        },
    }
] as HeaderItemType[];
