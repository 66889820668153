import axios from "axios";
import {IVerificationDecision, IVerificationGetRequest} from "../interfaces/verification-requests";

export const getVerificationRequests = (data: IVerificationGetRequest) => {
    return axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/admin/get/verification/requests`,
        data,
    })
        .then((response: any) => response?.data)
        .catch((error) => error.response?.data);
};

export const makeVerificationRequestDecision = (data: {status: IVerificationDecision}, id: string) => {
    return axios({
        method: "PUT",
        url: `${process.env.REACT_APP_API_URL}/admin/verification/request/${id}`,
        data,
    })
        .then((response: any) => response?.data)
        .catch((error) => error.response?.data);
};

export const getUndreadVerificationRequestsCount = () => {
    return axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/admin/verification/request/unread/count`,
    })
        .then((response: any) => response?.data)
        .catch((error) => error.response?.data);
};

export const updateUnreadVerificationRequestsToRead = (ids: string[]) => {
    return axios({
        method: "PUT",
        url: `${process.env.REACT_APP_API_URL}/admin/verification/requests/read`,
        data: {ids}
    })
        .then((response: any) => response?.data)
        .catch((error) => error.response?.data);
};

