import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import authReducer from './reducers/auth';
import variableReducer from "./reducers/variable";


export default configureStore({
  reducer: {
      auth: authReducer,
      variable: variableReducer
  },
  middleware: [...getDefaultMiddleware({ immutableCheck: false })],
});