import {IRetrieveInfoFromObject} from "../interfaces/complaints";

export const COMPLAINT_DECISION = {
    ACCEPTED_HU: "ACCEPTED_HU",
    ACCEPTED_FU: "ACCEPTED_FU",
    ACCEPTED_FIFTY_FIFTY: "ACCEPTED_FIFTY_FIFTY",
    REJECTED: "REJECTED",
    FURTHER_INVESTIGATION: "FURTHER_INVESTIGATION",
}

export const COMPLAINT_USER_ROLE = {
    AUTHOR: "AUTHOR",
    RESPONDENT: "RESPONDENT"
}

export const COMPLAINTS_SEND_FROM_SORT_PARAMS = {
    PRE_MEETING_EXIT: 1,
    COMPLAINT_RESPOND: 2,
    COMPLAINT_FORM: 3,
}

export const COMPLAINTS_SEND_FROM = {
    COMPLAINT_FORM: "COMPLAINT_FORM",
    COMPLAINT_RESPOND: "COMPLAINT_RESPOND",
    PRE_MEETING_EXIT: "PRE_MEETING_EXIT"
}

export const COMPLAINT_TYPE_TO_RENDER = {
    LAUNCHED_THROUGH_COMPLAINT_FORM: "LAUNCHED_THROUGH_COMPLAINT_FORM",
    HOSTING_ESCALATION_ROUTINE: "HOSTING_ESCALATION_ROUTINE"
}

export const RETRIEVE_INFO_FROM_EVENT_OBJECT: IRetrieveInfoFromObject[] = [
    {
        key: "public_identifier",
        label: "Booking reference",
    },
    {
        key: "status",
        label: "Meeting status",
    },
    {
        key: "hosting_user",
        label: "Hosting user",
        subKey: "first_name",
    },
    {
        key: "initiator_user",
        label: "Fan user",
        subKey: "first_name",
    },
    {
        key: "type",
        label: "Meeting type",
    },
    {
        key: "timeStart",
        label: "Time start",
        valueTypeToExpect: "timezone-date"
    },
    {
        key: "timeEnd",
        label: "Time End",
        valueTypeToExpect: "timezone-date"
    },
    {
        key: "price",
        label: "Rate",
        valueTypeToExpect: "price"
    }
]

export const HOSTING_USER_ESCALATION_REASONS = {
    PERSONAL_REASONS: {
        label: "Personal reasons",
        value: "PERSONAL_REASONS"
    },
    TECHNICAL_ISSUES: {
        label: "Technical reasons",
        value: "TECHNICAL_ISSUES"
    },
    OFFENSIVE_BEHAVIOUR_FU: {
        label: 'The conduct of the meeting partner was offensive',
        value: 'OFFENSIVE_BEHAVIOUR_FU'
    },
    OTHER_REASONS: {
        label: "Other reasons",
        value: "OTHER_REASONS"
    }
}

export const COMPLAINT_FORM_REASONS = {
    OFFENSIVE_BEHAVIOUR_FU: {
        label: 'Inappropriate/Offensive Behaviour during the meeting',
        value: 'OFFENSIVE_BEHAVIOUR_FU'
    },
    INAPPROPRIATE_MESSAGE_FU: {
        label: 'Inappropriate Message by Fan User',
        value: 'INAPPROPRIATE_MESSAGE_FU'
    },
    OFFENSIVE_BEHAVIOUR_HU: {
        label: 'Inappropriate/Offensive Behaviour during the meeting',
        value: 'OFFENSIVE_BEHAVIOUR_HU'
    },
    FAKE_HU: {
        label: 'Fake Host User',
        value: 'FAKE_HU'
    },
    TECHNICAL_ISSUES: {
        label: 'Serious Technical Issues on part of Host User/Failure to comply with Technical Requirements',
        value: 'TECHNICAL_ISSUES'
    },
}


export const RETRIEVE_INFO_ABOUT_COMPLAINANT_OR_RESPONDENT: IRetrieveInfoFromObject[] = []