interface IDocumentRequestObk {
    [key: string]: {
        label: string,
        value: string
    }
}

export const DOCUMENTS_REQUEST: IDocumentRequestObk = {
    POLICY: {
        label: "Privacy Policy",
        value: "POLICY"
    },
    TERMS: {
        label: "Terms",
        value: "TERMS",
    }
}


