import React, {CSSProperties, useMemo, useState} from "react"
import {TextField} from "@mui/material";
import Modal from "../../../components/Modal";
import {useCopyToClipboard} from "react-use";


// QUICK STYLES
const STYLES_INPUT: CSSProperties = {
    color: "dimgray"
}
const STYLES_WRAPPER: CSSProperties = {
    display: "flex",
    alignItems: "center",
}
const STYLES_UNIT_BUTTON = (active: boolean): CSSProperties => {
    return {
        border: `2px solid ${active ? "#458eff" : "white"}`,
        color: active ? "#458eff" : "white",
        backgroundColor: active ? "white" : "#458eff",
        padding: "5px 10px",
        cursor: "pointer",
    }
}

interface IProps {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const TimeConverterModal: React.FC<IProps> = ({open, setOpen}) => {
    const [sourceValue, setSourceValue] = useState<string>("")
    const [unit, setUnit] = useState<"hours" | "minutes">("hours")

    const targetResult = useMemo(() => {
        switch (unit) {
            case "hours":
                return `${+sourceValue * 3600}`
            case "minutes":
                return `${+sourceValue * 60}`
            default:
                return ""
        }
    }, [sourceValue, unit])

    // eslint-disable-next-line
    const [state, copyToClipboard] = useCopyToClipboard();

    const onComplete = () => {
        copyToClipboard(targetResult)
    }

    const label = () => {
        switch (unit) {
            case "hours":
                return "Hours to seconds"
            case "minutes":
                return "Minutes to seconds"
            default:
                return ""
        }
    }

    const selectUnit = (unit: "hours" | "minutes") => {
        setUnit(unit)
    }

    const renderUnitsOption = () => {
       return (
           <div style={STYLES_WRAPPER}>
               <button onClick={() => selectUnit("hours")} style={STYLES_UNIT_BUTTON(unit === "hours")}>Hours</button>
               <button  onClick={() => selectUnit("minutes")} style={STYLES_UNIT_BUTTON(unit === "minutes")}>Minutes</button>
           </div>
       )
    }

    return (
        <Modal
            open={open}
            disableClose={true}
            disabled={sourceValue.length === 0}
            cancelButton={"Back"}
            onComplete={onComplete}
            setOpen={setOpen}
            confirmButton={"Copy result"}
            title={"Time converter"}
            subtitle={renderUnitsOption()}
            content={
                <div style={STYLES_WRAPPER}>
                    <TextField
                        style={STYLES_INPUT}
                        label={label()}
                        type={"number"}
                        value={sourceValue}
                        onChange={(e) => setSourceValue(e.target.value)}
                        inputProps={{
                            pattern: "^[0-9]+$"
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}

                    />
                    <TextField
                        style={STYLES_INPUT}
                        label={"Result in seconds"}
                        value={targetResult}
                        disabled
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
            }
        />

    )
}

export default TimeConverterModal;