import axios from "axios";
import {ICreateVatFieldRequestBody, IGetVatTableInfo, IUpdateVatFieldRequestBody} from "../interfaces/vat";

export const getVatTable = (): Promise<IGetVatTableInfo[]> => {
    return axios.get(`${process.env.REACT_APP_API_URL}/admin/vat/get`)
        .then((response) => response && response.data )
        .catch((error) =>
            error &&
            error.response &&
            error.response.message
                ? {error: error.response.message}
                : {error: 'unknown error'},
        );
}

export const updateVatField = (data: IUpdateVatFieldRequestBody): Promise<IGetVatTableInfo[]> => {
    return axios.put(`${process.env.REACT_APP_API_URL}/admin/vat/update`, data)
        .then((response) => response && response.data )
        .catch((error) =>
            error &&
            error.response &&
            error.response.message
                ? {error: error.response.message}
                : {error: 'unknown error'},
        );
}

export const createNewVatField = (data: ICreateVatFieldRequestBody) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/admin/vat/create`, data)
        .then((response) => response && response.data )
        .catch((error) =>
            error &&
            error.response &&
            error.response.message
                ? {error: error.response.message}
                : {error: 'unknown error'},
        );
}

