import {useEffect, useState} from "react";
import {getUserEarnings} from "../../api/earnings";
import {IUserEarningsResponse} from "../../interfaces/earnings";

export const useUserEarnings = (userId: number, triggerRefetch: boolean) => {
    const [userEarningsLoading, setUserEarningsLoading] = useState<boolean>(false)
    const [earnings, setEarnings] = useState<IUserEarningsResponse>()

    useEffect(() => {
        (async () => {
            if(!triggerRefetch){
                setUserEarningsLoading(true)
                const response = await getUserEarnings(userId)
                if (response) {
                    setEarnings(response?.data)
                    setUserEarningsLoading(false)
                }
            }
        })()
    }, [userId, triggerRefetch])


    return {
        userEarningsLoading,
        earnings
    }
}