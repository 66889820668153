import {useEffect, useState} from "react";
import {IPagination} from "../../interfaces/request";
import { getEmailNotificaitonManagementData } from "../../api/email-notification-management";

export const useGetEmailNotificationManagement = (body: {pagination: IPagination, search_by: string, sort_by: string, type: string}) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [emailNotificationManagementData, setEmailNotificationManagementData] = useState<any>()
    

    const getEmailNotificaitonManagement = async () => {
        setLoading(true)
        const response = await getEmailNotificaitonManagementData(body)
        if (response) {
            setLoading(false)
            setEmailNotificationManagementData(response)
        }
    }

    useEffect(() => {
        (getEmailNotificaitonManagement)()
        return () => setEmailNotificationManagementData(undefined)
        // eslint-disable-next-line
    }, [body.search_by, body.sort_by])

    return {
        emailNotificationManagementData,
        loading
    }
}
