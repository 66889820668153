import React, {useRef} from "react";
import {RootStateOrAny, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {ROUTES} from "../../enum/routes";
import {SidebarProps} from "../../interfaces/sidebar";
import {useAuth} from "../../service/auth/auth";
import {Avatar} from "@material-ui/core";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import logo from "../../shared/images/chusp.svg";


const Header: React.FC<SidebarProps> = ({setOpenSideBar}) => {
    const ref = useRef(null)
    const user = useSelector((state: RootStateOrAny) => state.auth?.user?.user);
    const history = useHistory();
    const {logout} = useAuth();

    const popupStyles = {
        padding: "15px 10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        color: "grey",
        cursor: "pointer"
    }

    const onLogout = async () => {
        await logout();
        history.push(ROUTES.AUTH.LOGIN);
        window.location.reload()
   };

    return (
        <div className="header-container">
            <span className="burger-menu" onClick={() => setOpenSideBar(true)}>&#9776;</span>
            <div className="profile-wrapper">
                <Avatar
                    ref={ref}
                    className={"profile-image"}
                    onClick={() => history.push(ROUTES.PAGES.PROFILE)}
                >
                    {user?.first_name[0]}
                </Avatar>
                <img
                    onClick={() => history.push(ROUTES.PAGES.OVERVIEW)}
                    src={logo} alt={''}
                    className={'chusp-logo'}
                />
                <div style={popupStyles} onClick={onLogout}>
                    <ExitToAppIcon/>
                    <span>Logout</span>
                </div>
            </div>
        </div>
    );
};

export default Header;
