import { COLUMN_TYPE } from "../../components/Table/column-factory";
import { ROUTES } from "../../enum/routes";
import { HeaderItemType } from "../../interfaces/table";

export const ComplaintsTableName = "Complaints"
export const ComplaintsOnclick = ({ history, item }: any) => {
    history.push({
      pathname: `${ROUTES.PAGES.COMPLAINT_DETAILS}${item.id}`,
      state: item
    });
  }

export const ComplaintsHeader = [
  {
    title: "",
    type: COLUMN_TYPE.STRING,
    meta: {
      key: "is_read",
    },
  },
  {
    title: "Date creation",
    type: COLUMN_TYPE.STRING,
    meta: {
      key: "created_at",
    },
  },
  {
    title: "Name",
    type: COLUMN_TYPE.STRING,
    meta: {
      key: "complaint_author",
      object_key: ["first_name", "last_name"]
    },
  },
  {
    title: "Reasons",
    type: COLUMN_TYPE.STRING,
    meta: {
      key: "reasons",
    },
  },
  {
    title: "Attachment",
    type: COLUMN_TYPE.STRING,
    meta: {
      key: "complaint_assets"
    },
  },
  {
    title: "Status",
    type: COLUMN_TYPE.STRING,
    meta: {
      key: "status"
    },
  },
  
] as HeaderItemType[];
