import {COLUMN_TYPE} from "../../interfaces/table";
import { HeaderItemType } from "../../interfaces/table";
import {ROUTES} from "../../enum/routes";

export const VerificationRequestTableName = "Verification requests";

export const VerificationRequestOnClick = ({ history, item }: any) => {
    history.push({
        pathname: `${ROUTES.PAGES.VERIFICATION_REQUESTS}`,
        state: item
    });
}

export const VerificationRequestsHeader = [
    {
        title: "",
        type: COLUMN_TYPE.STRING,
        meta: {
            key: "is_read",
        },
    },
    {
        title: "Verification ID",
        type: COLUMN_TYPE.STRING,
        meta: {
            key: "id",
        },
    },
    {
        title: "Status",
        type: COLUMN_TYPE.STRING,
        meta: {
            key: "status",
        },
    },
    {
        title: "Request date",
        type: COLUMN_TYPE.STRING,
        meta: {
            key: "created_at",
        },
    },
    {
        title: "User",
        type: COLUMN_TYPE.STRING,
        meta: {
            key: "user",
            object_key: ["first_name", "last_name"]
        },
    },
] as HeaderItemType[];

