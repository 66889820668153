const COUNTRY_SYSTEM = "COUNTRY_SYSTEM";
const DOB = "DOB";
const CREATED_AT = "CREATED_AT";

export const SORT_BY = [
  {
    value: COUNTRY_SYSTEM,
    label: "Country",
  },
  {
    value: DOB,
    label: "DOB",
  },
  {
    value: CREATED_AT,
    label: "Created",
  },
];
