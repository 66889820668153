import axios from 'axios'

export const signinRequest = (credentials: { email: string, password: string }) => {
    return axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/admin/auth/signin`,
        data: credentials
    })
        .then((response: any) => response && response?.data)
        .catch((error) => error.response?.data);
}

export const authRequest = () => {
    return axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/auth`
    })
        .then((response: any) => response && response?.data)
        .catch((error) => error.response?.data)
}

export const authPasswordExist = (email: string) => {
    return axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/admin/auth/pass/exist`,
        data: {email}
    })
        .then((response: any) => response && response?.data?.data)
        .catch((error) => error.response?.data)
}

export const authPasswordCreate = (id: number | undefined, credentials: {email: string, password: string}) => {
    return axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/admin/auth/pass/create/${id}`,
        data: credentials
    })
        .then((response: any) => response && response?.data)
        .catch((error) => error.response?.data)
}