import React from "react";
import { ColumnProps, COLUMN_TYPE } from "../../interfaces/table";
import ButtonColumn from "./Columns/button-column";
import LinkTextColumn from "./Columns/link-text-column";
import TextColumn from "./Columns/text-column";

interface CFactory extends ColumnProps {
  columnType: COLUMN_TYPE;
  onButtonClickHandler?: any
  onLinkClickHandler?: any
}

const ColumnFactory: React.FC<CFactory> = ({ columnType, onButtonClickHandler, onLinkClickHandler, ...props}) => {
  switch (columnType) {
    case COLUMN_TYPE.STRING:
      return <TextColumn {...props} />;
    case COLUMN_TYPE.LINK:
      return <LinkTextColumn {...props} onLinkClickHandler={onLinkClickHandler} />;
    case COLUMN_TYPE.BUTTON:
      return <ButtonColumn {...props} onButtonClickHandler={onButtonClickHandler}/>;
    default:
      throw new Error("Unsupported enum type");
  }
};

export { COLUMN_TYPE, ColumnFactory };
