import {COLUMN_TYPE} from "../../interfaces/table";
import { HeaderItemType } from "../../interfaces/table";

export const EmailNotificationManagementHeader = [
    {
        title: "Name",
        type: COLUMN_TYPE.STRING,
        meta: {
            key: "name",
        },
    },
    {
        title: "Email",
        type: COLUMN_TYPE.STRING,
        meta: {
            key: "email",
        },
    },
    {
        type: COLUMN_TYPE.BUTTON,
        meta: {
            label: 'Remove',
            onClick: () => {} 
        }
    }
] as HeaderItemType[];

