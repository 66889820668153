import axios from "axios";

export const getUserSpending = (userId: number) => {
    return axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/admin/spending/${userId}`,
    })
        .then((response: any) => response?.data)
        .catch((error) => error.response?.data);
};
