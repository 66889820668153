// MORE CAN BE ADDED IN CASE OF ANY NEW TEMPLATES

export const SEND_GRID_TEMPLATE_IDS = {
    COMMON: {
        id: "d-3a38e4b1fbd74d529cec5b46dad41c5b",
        version: "c07c6ce9-8a5c-4697-aba2-2d728f7643c1"
    },
    ENABLE_SEND_BUTTON: ["1e2c1270-8d88-4460-9757-56102786cd64","1163b009-4880-4bcc-8c2e-354c24dc0543","89259a1c-53a1-46fa-9aee-faf6db5712c7"]
}

export const SMTP_TEMPLATE_IDS = {
    APPEND_RECEIVER_NAME: ["Action Required: Upload Valid Profile Picture",  "Chusp Marketing", "Be a Host User"]
}
export const SEND_GRID_TEMPLATE_NAMES = ["Chusp-admin-dynamic-content", "YoutubeInfluencerMarketingTemplate1"];