import {COLUMN_TYPE} from "../../interfaces/table";
import { HeaderItemType } from "../../interfaces/table";
import {ROUTES} from "../../enum/routes";

export const SupportsTableName = "Supports";

export const SupportOnClick = ({ history, item }: any) => {
    history.push({
        pathname: `${ROUTES.PAGES.SUPPORT_DETAILS}`,
        state: item
    });
}

export const SupportsHeader = [
    {
        title: "",
        type: COLUMN_TYPE.STRING,
        meta: {
            key: "is_read",
        },
    },
    {
        title: "Ticket ID",
        type: COLUMN_TYPE.STRING,
        meta: {
            key: "id",
        },
    },
    {
        title: "Status",
        type: COLUMN_TYPE.STRING,
        meta: {
            key: "status",
        },
    },
    {
        title: "Registration date",
        type: COLUMN_TYPE.STRING,
        meta: {
            key: "created_at",
        },
    },
    {
        title: "Reported by",
        type: COLUMN_TYPE.STRING,
        meta: {
            key: "reporter",
            object_key: ["first_name", "last_name"]
        },
    },
    // {
    //     title: "Report type",
    //     type: COLUMN_TYPE.STRING,
    //     meta: {
    //         key: "type"
    //     },
    // },
] as HeaderItemType[];
