import {RootStateOrAny, useSelector} from "react-redux";
import {useEffect, useMemo, useState} from "react";
import {GridColumns} from "@mui/x-data-grid";
import {
    SERVICE_VARIABLE_NUMBERS,
    SERVICE_VARIABLE_OBJECTS, SERVICE_VARIABLE_PERCENTAGES, SERVICE_VARIABLE_TIMES,
    TRANSACTION_VARIABLE_DATES,
    VARIABLES_WITH_JSON_VALUES
} from "../../enum/variables";
import {isArrayOfDates, isArrayOfNumbers, isArrayOfStrings} from "../../utils/validation";
import {IVariable} from "../../interfaces/variables";

export const CELL_FIELD_TYPES = {
    NUMBER: "NUMBER",
    NUMBER_ARRAY: "NUMBER_ARRAY",
    STRINGS_ARRAY: "STRINGS_ARRAY",
    STRING: "STRING",
    OBJECTS_ARRAY: "OBJECTS_ARRAY",
    OBJECT: "OBJECT",
    DATE: "DATE",
    TRANSACTION_DATE: "TRANSACTION_DATE",
    DATE_ARRAY: "DATE_ARRAY",
}

export const DATA_TYPE = {
    LIST_OF_LANGUAGES: "LIST_OF_LANGUAGES",
    UNIX_DATE: "UNIX_DATA",
    MINUTES: "MINUTES",
    HOURS: "HOURS",
    SECONDS: "SECONDS",
    PERCENTAGE: "PERCENTAGE",
    MONEY: "MONEY",
    RESOLUTION: "RESOLUTION"
}

export type ICellFieldTypes = typeof CELL_FIELD_TYPES.DATE |
    typeof CELL_FIELD_TYPES.NUMBER |
    typeof CELL_FIELD_TYPES.NUMBER_ARRAY |
    typeof CELL_FIELD_TYPES.STRING |
    typeof CELL_FIELD_TYPES.STRINGS_ARRAY |
    typeof CELL_FIELD_TYPES.OBJECT |
    typeof CELL_FIELD_TYPES.OBJECTS_ARRAY |
    typeof CELL_FIELD_TYPES.TRANSACTION_DATE |
    typeof CELL_FIELD_TYPES.DATE |
    typeof CELL_FIELD_TYPES.DATE_ARRAY

export type IDataType = typeof DATA_TYPE.UNIX_DATE |
    typeof DATA_TYPE.LIST_OF_LANGUAGES

export interface IDefaultRow extends IVariable {
    id: number,
}

export interface ICorrectRowValue extends IDefaultRow {
    fieldType: ICellFieldTypes,
    dataType?: IDataType
    nextDateValue?: string,
    isLastDayOfMonth?: boolean
}

const defaultRows: IDefaultRow[] = [
    {id: 1, key: 'Snow', value: 'Jon'},
    {id: 2, key: 'Lannister', value: 'Cersei'},
    {id: 3, key: 'Lannister', value: 'Jaime'},
    {id: 4, key: 'Stark', value: 'Arya'},
    {id: 5, key: 'Targaryen', value: 'Daenerys'},
];

const columns: GridColumns = [
    {field: 'key', headerName: 'Key', width: 500, editable: false},
    {field: 'value', headerName: 'Value', width: 500, editable: false},
];

export const useSystemVariablesTableData = () => {
    const variables = useSelector((state: RootStateOrAny) => state.variable?.variables)
    const [rows, setRows] = useState<IDefaultRow[]>(defaultRows)

    useEffect(() => variables && setRows(variables), [variables])

    const rowsWithTheCorrectlyFilterSingleValue = useMemo(() => {
        return rows.map((row: IDefaultRow, index: number) => {
            return formParsedValueDependingOnKeyTypeGroup(row, index)
        })
        // eslint-disable-next-line
    }, [rows])


    function formParsedValueDependingOnKeyTypeGroup(row: IDefaultRow, index: number): ICorrectRowValue {
        if (isTransactionVariableDate(row)) {
            const obj = JSON.parse(row.value)
            return {
                id: index,
                key: row.key,
                value: obj.date,
                description: row?.description,
                nextDateValue: obj.nextDate,
                isLastDayOfMonth: obj.isLastDayOfMonth,
                fieldType: CELL_FIELD_TYPES.TRANSACTION_DATE,
            }
        } else if (isJsonVariable(row)) {
            const obj = JSON.parse(row.value)
            return {
                id: index,
                key: row.key,
                value: row.value,
                description: row?.description,
                fieldType: renderFieldTypeForParsedJSON(obj.items),
                dataType: appendCorrectDataTypeToVariable(row.key)
            }
        } else {
            return {
                id: index,
                key: row.key,
                value: row.value,
                description: row?.description,
                fieldType: CELL_FIELD_TYPES.NUMBER,
                dataType: appendCorrectDataTypeToVariable(row.key)
            }
        }
    }

    function appendCorrectDataTypeToVariable(key: string) {
        switch (key) {
            case SERVICE_VARIABLE_OBJECTS.REFUND_RULES:
                return DATA_TYPE.UNIX_DATE;
            case SERVICE_VARIABLE_OBJECTS.SYSTEM_LANGUAGES:
                return DATA_TYPE.LIST_OF_LANGUAGES;
            case SERVICE_VARIABLE_TIMES.MU:
                return DATA_TYPE.MINUTES;
            case SERVICE_VARIABLE_TIMES.COMPLAINT_WINDOW_PERIOD:
                return DATA_TYPE.HOURS;
            case SERVICE_VARIABLE_TIMES.EVENT_UNPAID_STATUS_ALIVE_TIME:
                return DATA_TYPE.SECONDS;
            case SERVICE_VARIABLE_NUMBERS.MIN_PAYOUT_AMOUNT:
                return DATA_TYPE.MONEY;
            case SERVICE_VARIABLE_NUMBERS.MAX_QUALITY_RESOLUTION_FOR_MEETING_MODE:
                return DATA_TYPE.RESOLUTION;
            default:
                if (Object.keys(SERVICE_VARIABLE_PERCENTAGES).includes(key)) {
                    return DATA_TYPE.PERCENTAGE
                }

        }
    }


    function renderFieldTypeForParsedJSON(field: any) {
        if (isArrayOfStrings(field)) {
            return CELL_FIELD_TYPES.STRINGS_ARRAY
        } else if (isArrayOfNumbers(field)) {
            return CELL_FIELD_TYPES.NUMBER_ARRAY
        } else if (isArrayOfDates(field)) {
            return CELL_FIELD_TYPES.DATE_ARRAY
        } else {
            return CELL_FIELD_TYPES.OBJECTS_ARRAY
        }
    }

    function isTransactionVariableDate(variable: IDefaultRow) {
        return Object.keys(TRANSACTION_VARIABLE_DATES).includes(variable.key)
    }

    function isJsonVariable(variable: IDefaultRow) {
        return Object.keys(VARIABLES_WITH_JSON_VALUES).includes(variable.key)
    }

    return {
        rows: rowsWithTheCorrectlyFilterSingleValue,
        columns,
    }
}
