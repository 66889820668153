import axios from "axios";
import {IPagination} from "../interfaces/request";
import {IGetComplaintsRequest} from "../interfaces/complaints";

export interface IGetUsers {
  filter: string;
  pagination: IPagination;
  sort_by?: string;
  sort?: string;
}

export interface IComplaintActions {
  decision: string
}

export const getComplaint = (data: IGetComplaintsRequest) => {
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/admin/complaints`,
    data,
  })
    .then((response: any) => response?.data)
    .catch((error) => error.response?.data);
};

export const complaintButtonsActions = (complaintId: string, data: IComplaintActions) => {
  return axios({
    method: "PATCH",
    url: `${process.env.REACT_APP_API_URL}/admin/complaint/update/${complaintId}`,
    data,
  })
    .then((response: any) => response?.data)
    .catch((error) => error.response?.data);
};

export const getUnreadComplaintsCount = (type: string) => {
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/admin/complaints/unread/count`,
    data: {type}
  })
      .then((response: any) => response?.data)
      .catch((error) => error.response?.data);
};

export const updateComplaintsFromUnreadToRead = (ids: string[]) => {
  return axios({
    method: "PUT",
    url: `${process.env.REACT_APP_API_URL}/admin/complaints/read`,
    data: {ids}
  })
      .then((response: any) => response?.data)
      .catch((error) => error.response?.data);
};