import {useEffect, useState} from "react";
import {activateTemplate} from "../../api/email-service";
import { getAllTemplateVersions } from "../../api/email-templates-version";
import { getSmtpTable } from "../../api/smtp";

export const useSendGridTemplates = () => {
    const [templates, setTemplates] = useState<any[]>([])
    const [emailFromOptions, setEmailFromOtions] = useState<any[]>([])
    const [selectedTemplateId, setSelectedTemplateId] = useState<number>();
    const [selectedTemplate, setSelectedTemplate] = useState<any>()
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        (async () => {
            setLoading(true)
            const response: any = await getAllTemplateVersions()
            const template = response && response.length > 0 &&  response?.find((t: any) => t.active === true)
            setSelectedTemplateId(template?.id)
            setSelectedTemplate(template)
            const smtpListResponse: any = await getSmtpTable()
            setEmailFromOtions(smtpListResponse)
            if (response) {
                setLoading(false)
                setTemplates(response)
            }
        })()
    //     // eslint-disable-next-line
    }, [])

    const selectTemplate = async (templateId: number) => {
        setSelectedTemplateId(templateId)
        const template = templates?.find((t: any) => t.id === templateId)
        setSelectedTemplate(template)
        if (template) {
            setLoading(true)
            const response = await activateTemplate(templateId)
            response && setLoading(false)
        }
    }

    const isActive = (templateId: number) => {
        return templateId === selectedTemplateId
    }

    return {
        templates,
        setSelectedTemplateId,
        selectTemplate,
        isActive,
        selectedTemplate,
        loading,
        emailFromOptions
    }
}