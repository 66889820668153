const ASC = "ASC";
const DESC = "DESC";

export const SORT_BY = [
  {
    value: ASC,
    label: ASC,
  },
  {
    value: DESC,
    label: DESC,
  },
];