import {useCallback, useEffect, useState} from "react";
import {SYSTEM_ERROR_STATUSES} from "../../enum/system-error";
import {getListOfSystemErrors, updateStatusOfSystemError} from "../../api/system-errors";
import {ISort} from "../../interfaces/sort";
import {PAGE_SIZE} from "../../constant/pagination";
import {IGetSystemErrorsResponse, ISystemErrorsRows, ISystemErrorStatus} from "../../interfaces/system-errors";
import {usePagination} from "../common/pagination";

const CUT_SYMBOLS_FOR_TABLE_ROW_AMOUNT = 50

export const useErrorHandlersControl = () => {
    const [updateStatusLoading, setUpdateStatusLoading] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [sortBy, setSortBy] = useState<ISystemErrorStatus>(SYSTEM_ERROR_STATUSES.PENDING)
    const [currentPage, setCurrentPage] = useState<number>(0)

    const [errorMessage, setErrorMessage] = useState<string>("")
    const [openErrorModal, setOpenErrorModal] = useState<boolean>(false)

    const [tableRowsData, setTableRowsData] = useState<ISystemErrorsRows[]>([])

    const [detailsPageOpened, setDetailsPageOpened] = useState<boolean>(false)
    const [clickedErrorRowInfo, setClickedErrorRowInfo] = useState<ISystemErrorsRows>()


    const [newData] = usePagination(getListOfSystemErrors, {
        variables: {
            status: sortBy,
            pagination: {
                pageSize: PAGE_SIZE,
                page: currentPage + 1
            }
        }
    });

    useEffect(() => {
        if (newData?.items && newData?.items?.length > 0) {
            const uploadedErrors: ISystemErrorsRows[] = newData?.items?.map((i: IGetSystemErrorsResponse) => ({
                id: i.id,
                status: i.status,
                module: i.module,
                target: i.target,
                status_code: i.status_code,
                created_at: i.created_at,
                stacktrace: i.stacktrace,
                is_read: i.is_read,
                stackTraceShortened: `${i.stacktrace.slice(0, CUT_SYMBOLS_FOR_TABLE_ROW_AMOUNT)}...`
            }))
            setTableRowsData([...tableRowsData, ...uploadedErrors])
            setCurrentPage(newData?.currentPage)
        }
        // eslint-disable-next-line
    }, [newData])


    useEffect(() => {
        (async () => {
            if (Object.values(SYSTEM_ERROR_STATUSES).includes(sortBy)) {
                setLoading(true)
                const response = await getListOfSystemErrors({
                    status: sortBy,
                    pagination: {
                        page: 0,
                        pageSize: PAGE_SIZE
                    }
                })
                if (response && response?.items) {
                    const data: IGetSystemErrorsResponse[] = response?.items
                    setTableRowsData(
                        data?.map((i) => ({
                            id: i.id,
                            status: i.status,
                            module: i.module,
                            target: i.target,
                            status_code: i.status_code,
                            created_at: i.created_at,
                            stacktrace: i.stacktrace,
                            is_read: i.is_read,
                            stackTraceShortened: `${i.stacktrace.slice(0, CUT_SYMBOLS_FOR_TABLE_ROW_AMOUNT)}...`
                        }))
                    )
                }
                if (response) {
                    setLoading(false)
                }
            }
        })()
    }, [sortBy])

    const onHandleSort = (e: ISort) => {
        setSortBy(e.value)
    };

    const onErrorDetailsOpen = (value: any) => {
        setDetailsPageOpened(true)
        setClickedErrorRowInfo(value)
    }

    const onErrorDetailsClose = () => {
        setDetailsPageOpened(false)
        setClickedErrorRowInfo(undefined)
    }

    const onUpdateStatus = useCallback(async (id: string, status: ISystemErrorStatus) => {
        setUpdateStatusLoading(true)
        const response = await updateStatusOfSystemError(id, status)
        if (response && (response?.data || response?.isExpected)) {
            setTableRowsData((prev) => {
                const copy: ISystemErrorsRows[] = JSON.parse(JSON.stringify(prev))
                copy.forEach((i) => {
                    if (i.id === id) {
                        i.status = status
                    }
                })
                return copy;
            })
            setUpdateStatusLoading(false)
            onErrorDetailsClose()
        }
        if (response && response?.message) {
            setOpenErrorModal(true)
            setErrorMessage(response?.message)
        }
        response && setUpdateStatusLoading(false)
    }, [])

    return {
        data: {
            loading,
            updateStatusLoading,
            tableRowsData: tableRowsData.filter((r) => r.status === sortBy),
            setTableRowsData,
            clickedErrorRowInfo,
            detailsPageOpened,
        },
        onAction: {
            onErrorDetailsOpen,
            onErrorDetailsClose,
            onUpdateStatus,
        },
        sortBy: {
            sortOptions: Object.values(SYSTEM_ERROR_STATUSES).map((status) => ({
                value: status,
                label: status.substring(0, 1).toUpperCase() + status.substring(1)
            })),
            value: sortBy,
            onChange: onHandleSort
        },
        error: {
            message: errorMessage,
            openModal: openErrorModal,
            setOpenModal: setOpenErrorModal
        }
    }
}