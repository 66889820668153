import { COLUMN_TYPE } from "../../components/Table/column-factory";
import { ROUTES } from "../../enum/routes";
import { HeaderItemType } from "../../interfaces/table";

export const MeetingTableName = "Meetings"

export const contentPreviewList = ['initiator_pre_event_note']

export const MeetingsHeader = [
  {
    title: "ID",
    type: COLUMN_TYPE.STRING,
    meta: {
      key: "id",
    },
  },
  {
    title: "Status",
    type: COLUMN_TYPE.STRING,
    meta: {
      key: "status",
    },
  },
  {
    title: "Type",
    type: COLUMN_TYPE.STRING,
    meta: {
      key: "type",
    },
  },
  {
    title: "Time start",
    type: COLUMN_TYPE.STRING,
    meta: {
      key: "timeStart",
    },
  },
  {
    title: "Time end",
    type: COLUMN_TYPE.STRING,
    meta: {
      key: "timeEnd",
    },
  },
  {
    title: "Initiator pre event note",
    type: COLUMN_TYPE.LINK,
    meta: {
      key: "initiator_pre_event_note",
      onClick: () => {}
    },
  },
  {
    title: "Score",
    type: COLUMN_TYPE.STRING,
    meta: {
      key: "score",
    },
  },
  {
    title: "Price",
    type: COLUMN_TYPE.STRING,
    meta: {
      key: "price",
    },
  },
  {
    title: "HU",
    type: COLUMN_TYPE.LINK,
    meta: {
      obj: "hosting_user",
      key: ["first_name", "last_name"],
      onClick: ({ history, item }: any) => {
        history.push({
          pathname: `${ROUTES.PAGES.USER}${item.hosting_user.id}`,
          state: item.hosting_user.id
        });
      },
    },
  },
  {
    title: "IU",
    type: COLUMN_TYPE.LINK,
    meta: {
      obj: "initiator_user",
      key: ["first_name", "last_name"],
      onClick: ({ history, item }: any) => {
        history.push({
          pathname: `${ROUTES.PAGES.USER}${item.initiator_user.id}`,
          state: item.initiator_user.id
        });
      },
    },
  },
] as HeaderItemType[];
