import React, {CSSProperties, useEffect} from "react"
import Modal from "../../../../../components/Modal";
import {GridRowData} from "@mui/x-data-grid";
import Chip from '@mui/material/Chip';
import {Autocomplete, TextField} from "@mui/material";
import {useStringsArrayVariableControl} from "../../../../../service/variables/strings-array-control";


interface IProps {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    onComplete: () => Promise<void>,
    setRequestBodyJSONValue: (jsonValue: string) => void,
    data: GridRowData
}

const AMOUNT_OF_ELEMENTS_TO_SHOW = 17

// QUICK STYLES
const STYLES_CHIP: CSSProperties = {margin: 2}
const STYLES_MORE_BUTTON: CSSProperties = {textAlign: "right", cursor: "pointer", color: "grey"}
const STYLES_TIP_TEXT: CSSProperties = {display: 'inline-block', padding: "30px 0 10px", fontSize: 14}
const STYLES_INPUT: CSSProperties = {marginBottom: 15}
const STYLES_ERROR: CSSProperties = {fontSize: 14, color: "red"}

function formRequestBodyJSON(arr: string[]) {
    const requestBody = {
        items: arr
    }
    return JSON.stringify(requestBody)
}

const EditStringsArrayModal: React.FC<IProps> = (
    {
        open,
        setOpen,
        data,
        onComplete,
        setRequestBodyJSONValue
    }) => {
    const {
        newChipValue,
        onChange,
        items,
        itemsToShow,
        showMoreOrLessLabel,
        onAdd,
        onDelete,
        autoComplete,
        toggleShowMore,
        disabled,
        error
    } = useStringsArrayVariableControl(data)

    useEffect(() => {
        setRequestBodyJSONValue(formRequestBodyJSON(items))
        // eslint-disable-next-line
    }, [items])


    return (
        <Modal
            title={`Edit ${data.key} (${data.description})`}
            open={open}
            setOpen={setOpen}
            confirmButton={"Save"}
            onComplete={onComplete}
            cancelButton={"Cancel"}
            disabled={disabled}
            disableClose={true}
            content={
                <React.Fragment>
                    {itemsToShow && itemsToShow.map((i: string) => (
                        <Chip
                            style={STYLES_CHIP}
                            label={i}
                            onDelete={() => onDelete(i)}
                        />
                    ))}
                    {items.length > AMOUNT_OF_ELEMENTS_TO_SHOW &&
                    <div onClick={toggleShowMore} style={STYLES_MORE_BUTTON}>
                        <span>{showMoreOrLessLabel}</span>
                    </div>}
                    <form onSubmit={onAdd}>
                        <span style={STYLES_TIP_TEXT}>
                            Select option that you want to add and click "enter"
                        </span>
                        {autoComplete && <Autocomplete
                            options={autoComplete.options}
                            onChange={onChange}
                            value={newChipValue}
                            freeSolo={true}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    style={STYLES_INPUT}
                                    label={autoComplete.label}
                                />}
                        />}
                        {error && <span style={STYLES_ERROR}>{error}</span>}
                    </form>
                </React.Fragment>
            }
        />
    )
}

export default EditStringsArrayModal;