import { Props } from "react-select";

const TimestampContainer: React.FC<Props> = ({ systemMeetingToUsersData }) => {
    return (
        <div className="timestamp-container">
            <h5>Timestamp</h5>
            <div className="timestamp-container-data">
                {systemMeetingToUsersData?.map((meeting: any, index: number) => {
                    
                    const { timestamp } = meeting.result_test;

                    return <div className="user" key={`timestamp-container-data-${index}`}>
                        <div>
                            <div className="system-meeting-data-heading">{`User ID ${meeting.user_id}`}</div>
                        </div>
                        <div className="system-meeting-key-value">
                            <div className="system-meeting-key">Joined At</div>
                            <div className="system-meeting-value">
                                {timestamp?.joined_at ? (new Date(timestamp?.joined_at * 1000)).toLocaleString() : "---"}
                            </div>
                        </div>
                        <hr />
                        <div className="system-meeting-key-value">
                            <div className="system-meeting-key">Left At</div>
                            <div className="system-meeting-value">
                                {timestamp?.left_at ? new Date(timestamp?.left_at  * 1000).toLocaleString() : "---"}
                            </div>
                        </div>
                    </div>
                })}
            </div>
        </div>
    );
}

export default TimestampContainer;