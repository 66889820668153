import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import Input from "../../components/Input";
import Modal from "../../components/Modal";
import {ROUTES} from "../../enum/routes";
import {useAuth, usePasswordCreate, usePasswordExist} from "../../service/auth/auth";
import {Button, Checkbox} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import ChuspAnimated from "../../components/ChuspAnimated/ChuspAnimated";
import {FormControlLabel, FormGroup} from "@mui/material";


const Login: React.FC = () => {
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [emailBlur, setEmailBlur] = useState<boolean>(false);
    const [rememberMe, setRememberMe] = useState<boolean>(false)

    const [userId, setUserId] = useState<number>();

    const [createdPassword, setCreatedPassword] = useState<string>("");
    const [repeatCreatedPassword, setRepeatCreatedPassword] = useState<string>("");
    const [showCreatedPassword, setShowCreatedPassword] = useState<boolean>(false);
    const [showRepeatCreatedPassword, setShowRepeatCreatedPassword] = useState<boolean>(false);

    const [createPassword, setCreatePassword] = useState<boolean>(false);
    const [isRenderCreatePassContainer, setIsRenderCreatePassContainer] = useState<boolean>(false);
    const [isRenderCreatePassModal, setIsRenderCreatePassModal] = useState<boolean>(false);

    const {error, login} = useAuth();
    const {passwordExist} = usePasswordExist(email, emailBlur);
    const {passwordError, setPasswordError} = usePasswordCreate(
        createPassword, 
        userId, 
        {
            email, 
            password: createdPassword === repeatCreatedPassword ? createdPassword : ''
        })

    const history = useHistory();

    useEffect(() => {
        if (passwordExist?.is_exist === false) {
            setIsRenderCreatePassModal(true)
            setUserId(passwordExist?.user_id)
        }
    }, [passwordExist])
    
    const onLogin = async (e: React.FormEvent) => {
        e.preventDefault();

        await login({email, password}, rememberMe, () => {
            history.push(ROUTES.PAGES.OVERVIEW);
        });
        // await Login()
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const onCreatePassword = () => {
        setCreatePassword(true)
        if (createdPassword === repeatCreatedPassword) {
            setIsRenderCreatePassContainer(false)
        } else {
            setPasswordError('Please enter identical passwords')
        }
    };
    
    const renderSigninContainer = () => (
        <form className="signin-form">
            <ChuspAnimated/>
            <h1>Login</h1>
            <label className="email-wrapper">
                <Input
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setEmail(e.target.value)
                        setEmailBlur(false)
                    }
                    }
                    onBlur={() =>
                        setEmailBlur(true)
                    }
                    value={email}
                    placeholder={"Email"}
                />
            </label>
            <label className="pass-wrapper">
                <Input
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setPassword(e.target.value)
                    }
                    value={password}
                    placeholder={"Password"}
                    type={showPassword ? "text" : "password"}
                />
                <span className="show-hide" onClick={handleClickShowPassword}>
                {showPassword ? <Visibility/> : <VisibilityOff/>}
                </span>
            </label>
            <label className="error-label">{error}</label>
            <FormGroup style={{width: "100%", paddingLeft: 50, position: "relative", top: -15}}>
                <FormControlLabel
                    style={{color: "white"}}
                    control={<Checkbox
                        value={rememberMe}
                        onChange={(e) => setRememberMe(e.target.checked)}
                        color={"primary"}/>}
                    label="Remember me"
                />
            </FormGroup>
            <Button variant="contained" onClick={onLogin} type="submit">
                Log In
            </Button>
        </form>
    )

    const renderCreatePassContainer = () => (
        <form className="create-pass-form">
            <ChuspAnimated/>
            <h1>Create Password</h1>
            <label className="pass-wrapper">
                <Input
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setCreatedPassword(e.target.value)
                        setCreatePassword(false)
                        setPasswordError('')
                    }}
                    value={createdPassword}
                    placeholder={"Password"}
                    type={showCreatedPassword ? "text" : "password"}
                />
                <span className="show-hide" onClick={() => setShowCreatedPassword(!showCreatedPassword)}>
                {showCreatedPassword ? <Visibility/> : <VisibilityOff/>}
                </span>
            </label>
            <label className="pass-wrapper">
                <Input
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setRepeatCreatedPassword(e.target.value)
                        setCreatePassword(false)
                        setPasswordError('')
                    }}
                    value={repeatCreatedPassword}
                    placeholder={"Repeat password"}
                    type={showRepeatCreatedPassword ? "text" : "password"}
                />
                <span className="show-hide" onClick={() => setShowRepeatCreatedPassword(!showRepeatCreatedPassword)}>
                {showRepeatCreatedPassword ? <Visibility/> : <VisibilityOff/>}
                </span>
            </label>
            <label className="error-label">{passwordError}</label>
            <Button variant="contained" onClick={onCreatePassword}>
                Create Password
            </Button>
        </form>
    )

    return (
        <div className="login-container">
            {isRenderCreatePassContainer ? renderCreatePassContainer() : renderSigninContainer()}
            {isRenderCreatePassModal && <Modal
                title={"Confirm"}
                onComplete={() => {
                    setIsRenderCreatePassModal(false)
                    setIsRenderCreatePassContainer(true)
                }}
                confirmButton={<button>Create Password</button>}
                content={`There's no password associated with your email address. Please create a password to proceed`}
                open={isRenderCreatePassModal} setOpen={setIsRenderCreatePassModal}
            />}
        </div>
    );
};

export default Login;
