import React from "react";
import {EARNINGS_STATUS} from "../../../../enum/user";
import Modal from "../../../../components/Modal";
import {changeStatusInEarnings} from "../../../../api/user";


interface IProps {
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    userId: number,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const ChangeEarningsStatus: React.FC<IProps> = ({open, setOpen, userId, setLoading}) => {

    const onComplete = async () =>{
        setLoading(true);
        if (userId) {
            const response = await changeStatusInEarnings(+userId, EARNINGS_STATUS.CANCELED);
            response && setLoading(false);
        }
    };
    return(
        <Modal
            open={open}
            setOpen={setOpen}
            title="Are you sure you want to reset the earnings balance of this user?"
            cancelButton="Cancel"
            confirmButton="Confirm"
            content="If you press confirm, user's earnings balance will be reset"
            onComplete={onComplete}
        />
    )
}
export default ChangeEarningsStatus;