import {GridRowData} from "@mui/x-data-grid";
import {useEffect, useMemo, useState} from "react";
import {IVariable} from "../../interfaces/variables";

type IFieldError = { key: string, message: string } | null
type IValidField = { key: string, valid: boolean } | null
// type IHelperLabelField = { [key: string]: string } | null

export function convertToVariableArray(arr: any[]): IVariable[] {
    let modifiedData: IVariable[] = []
    arr.forEach((obj: any, index) => {
        Object.keys(obj).forEach((key) => {
            modifiedData.push({
                key: key + (index + 1),
                value: obj[key]
            })
        })
    })
    return modifiedData
}

export const useObjectsArrayVariableControl = (data: GridRowData) => {
    const [fieldErrorMessage, setFieldErrorMessage] = useState<IFieldError>(null)
    const [isValidField, setIsValidField] = useState<IValidField>(null)
    // const [fieldHelperLabel, setFieldHelperLabel] = useState<IHelperLabelField>(null)
    const [disabled, setDisabled] = useState<boolean>(true)
    const [variableArrayWithKeysForValidation, setVariableArrayWithKeysForValidation] = useState<any[]>([])
    const items = useMemo<any[]>(() => {
        try {
            return JSON.parse(data.value)?.items
        } catch (e) {
            return []
        }
    }, [data])


    const [currentVariables, setCurrentVariables] = useState<any[]>(items)

    useEffect(() => {
        const isNothingChanged = JSON.stringify(items) === JSON.stringify(currentVariables)
        setDisabled(isNothingChanged)
        // eslint-disable-next-line
    }, [currentVariables])

    useEffect(() => {
        setVariableArrayWithKeysForValidation(convertToVariableArray(items))
    }, [items])

    const onChange = (index: number, updatingKey: string, value: string) => {
        const copy = JSON.parse(JSON.stringify(currentVariables))
        copy.forEach((item: any, i: number) => {
            Object.keys(item).forEach((key) => {
                if (index === i && key === updatingKey.slice(0, -1)) {
                    copy[i][key] = value ? +value : ""
                }
                const objToValidate = {
                    key: updatingKey,
                    value
                }
                const valid: any = validateObjValueWithPrevAndNext(objToValidate) === undefined ?
                    true :
                    validateObjValueWithPrevAndNext(objToValidate);
                if (valid) {
                    setFieldErrorMessage(null)
                }
                const disabled = data.value[key] === value || !valid || value === ""
                setDisabled(disabled)
                setIsValidField({
                    key: updatingKey,
                    valid
                })
            })
        })
        setCurrentVariables(copy)
        // eslint-disable-next-line
    }

    function validateObjValueWithPrevAndNext(obj: IVariable) {
        if (variableArrayWithKeysForValidation.find((v) => v.key === obj.key)) {
            const sameValuesWithDifferentIndexes = variableArrayWithKeysForValidation.filter((v) => {
                return v.key.slice(0, -1) === obj.key.slice(0, -1)
            }).sort((a, b) => a.key.localeCompare(b.key))
            const indexOfCurrentVariable = sameValuesWithDifferentIndexes.findIndex((v) => v.key === obj.key)
            for (let i = 0; i < sameValuesWithDifferentIndexes.length; i++) {
                if (+indexOfCurrentVariable > i) {
                    if ((+obj.value >= +sameValuesWithDifferentIndexes[i].value)) {
                        setFieldErrorMessage({
                            key: obj.key,
                            message: `Current value conflicting with the ${sameValuesWithDifferentIndexes[i].key}`
                        })
                        return +obj.value < +sameValuesWithDifferentIndexes[i].value;
                    }
                } else if (+indexOfCurrentVariable < i) {
                    if ((+obj.value <= +sameValuesWithDifferentIndexes[i].value)) {
                        setFieldErrorMessage({
                            key: obj.key,
                            message: `Current value conflicting with the ${sameValuesWithDifferentIndexes[i].key}`
                        })
                        return +obj.value > +sameValuesWithDifferentIndexes[i].value;
                    }
                }
            }
        }
    }

    function showFieldErrorMessageIfNotValid(fieldKey: string): string {
        const canShow = fieldErrorMessage && fieldErrorMessage.key === fieldKey
        return canShow ? fieldErrorMessage.message : ""
    }

    const requestBody = useMemo(() => {
        return {items: currentVariables}
    }, [currentVariables])

    return {
        currentList: currentVariables,
        onChange,
        requestJSONValue: requestBody,
        isValidField: isValidField,
        fieldErrorMessage,
        // fieldHelperLabel,
        disabled,
        showFieldErrorMessageIfNotValid,
    }
}