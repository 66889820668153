export const REQUEST_DATE_FORMAT = 'YYYY-MM-DD HH:mm Z'

export const GET_MONTH_NAME = 'MMMM'

export const GET_FULL_DATE_WITH_TIME = 'YYYY-MM-DD HH:mm'
export const GET_FULL_DATE_WITH_NO_TIME = 'YYYY-MM-DD'


export const GET_DAY = 'DD'
export const GET_WEEKDAY_SHORTEN_NAME = 'ddd'
export const GET_WEEKDAY_FULL_NAME = 'dddd'

export const GET_TIME = 'HH:mm'

export const GET_DATE_WITH_MONTH_SHORTEN_DAY = 'DD MMM YYYY HH:mm'
