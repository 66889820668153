import React, {useState} from 'react'
import {Avatar} from "@mui/material";
import {RootStateOrAny, useSelector} from "react-redux";
import {IExtendedUserInfo} from "../../interfaces/user";
import Input from "../../components/Input";
import {updateLoginSercurity} from "../../api/user";
import Loader from "../../components/Loader";
import {useHistory} from "react-router-dom"
import {ROUTES} from "../../enum/routes";
import {patternEmail, patternPassword} from "../../utils/validation";

const EditProfile = () => {
    const history = useHistory()
    const user: IExtendedUserInfo = useSelector((state: RootStateOrAny) => state.auth?.user?.user);
    const [email, setEmail] = useState<string>(user?.email)
    const [password, setPassword] = useState<string>("")
    const [loading, setLoading] = useState<boolean>(false)

    const editMyProfile = async () => {
        setLoading(true)
        const requestBody = {
            first_name: user?.first_name,
            email,
            password
        }
        const response = await updateLoginSercurity(requestBody)
        if (response) {
            setLoading(false)
            history.push(ROUTES.PAGES.USERS)
        }
    }

    const disabledButton = !patternEmail.test(email) || !patternPassword.test(password)

    return (
        <div className={"edit"}>
            {loading && <Loader/>}
            <h2>Edit profile</h2>
            <div className={"edit__top"}>
                <Avatar
                    className={'edit__top-avatar'}
                >
                    {user?.first_name[0]}
                </Avatar>
                <span>
                    {!!user?.first_name ? user?.first_name : ''} {!!user?.last_name ? user?.last_name : ''}
                </span>
            </div>
            <div className={"edit__main"}>
                <div className={"edit__main-email"}>
                    <Input
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        placeholder={"Email"}
                    />
                </div>
                <div className={"edit__main-password"}>
                    <Input
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        placeholder={"*******"}
                    />
                </div>
                <button disabled={disabledButton} onClick={editMyProfile}>Save</button>
            </div>
        </div>
    )
}


export default EditProfile
