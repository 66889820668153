import {useEffect, useState} from "react";
import {IPagination} from "../../interfaces/request";
import {getAllTickets} from "../../api/tickets";
import {ITicketFilterParam} from "../../interfaces/tickets";

export const useAllTickets = (body: {filter: ITicketFilterParam, pagination: IPagination, sort_by?: string, sort?: string}) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [tickets, setTickets] = useState<any>()

    const getData = async () => {
        setLoading(true)
        const response = await getAllTickets(body)
        if (response) {
            setLoading(false)
            setTickets(response)
        }
    }

    useEffect(() => {
        (getData)()
        return () => setTickets(undefined)
        // eslint-disable-next-line
    }, [body.sort, body.filter.key])

    return {
        tickets,
        loading
    }
}
