import { createSlice } from '@reduxjs/toolkit';

import { AUTH } from '../types';

interface User {
    id: number,
    email: string,
    name: string,
    role: string
}

interface State {
    user: User | null
}

const defaultSlice = createSlice({
    name: AUTH,
    initialState: {
        user: null
    },
    reducers: {
        loginAction(state: State, action) {
            state.user = action.payload;
        },
        logoutAction(state: State) {
            state.user = null;
        }
    }
})

export const {
    loginAction,
    logoutAction,
} = defaultSlice.actions;
export default defaultSlice.reducer;