import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import { ButtonColumnTypes } from "../../../interfaces/table";
import { AdminCommentLabel } from "../../../enum/system-meeting";

const ButtonColumn: React.FC<ButtonColumnTypes> = ({ item, meta, onButtonClickHandler }) => {
  const history = useHistory();
  const { label, onClick } = meta;
  const [isCommented, setIsCommented] = useState<boolean>(false)

  const handleClick = (event:any) => {
    onClick({ history, item,event });
    !!onButtonClickHandler && onButtonClickHandler(item) 
  };

  useEffect(() => {
    if(!!item?.comment) setIsCommented(true);
  }, [item])

  return (
    <Button onClick={(e)=>handleClick(e)} variant="contained">
      {!isCommented ? label : AdminCommentLabel.viewEdit}
    </Button>
  );
};

export default ButtonColumn;
