import {useEffect, useState} from "react";
import {IPagination} from "../../interfaces/request";
import { getMeetings } from "../../api/meetings";

export const useGetMeetings = (body: {pagination: IPagination, search_by: string, sort_by: string}) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [meetingsData, setMeetingsData] = useState<any>()
    

    const getData = async () => {
        setLoading(true)
        const response = await getMeetings(body)
        if (response) {
            setLoading(false)
            setMeetingsData(response)
        }
    }

    useEffect(() => {
        (getData)()
        return () => setMeetingsData(undefined)
        // eslint-disable-next-line
    }, [body.search_by, body.sort_by])

    return {
        meetingsData,
        loading
    }
}
