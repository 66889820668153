import React, {useEffect, useState} from 'react';
import {useAnalytics} from "../../service/analytics/analytics";
import {Autocomplete, TextField, CircularProgress} from "@mui/material";
import {LIST_OF_COUNTRIES} from "../../enum/list-of-countries";
import {getKeyByValue} from "../../utils/common";
import moment from "moment"
import {roundValue} from "../../utils/round-value";
import {getPayableAndTotalEarnings} from "../../api/analytics";
import {ICutOffPointResponse} from "../../interfaces/analytics";
import { useHistory } from 'react-router-dom';

const Analytics: React.FC = () => {
    const initialDateFrom = moment().subtract(1, "month").format("YYYY-MM-DD")
    const [dateFrom, setDateFrom] = useState<string>(initialDateFrom)
    const [loading, setLoading] = useState<boolean>(false)
    const [cutOffPointData, setCutOffPointData] = useState<ICutOffPointResponse>();
    const [selectedCountry, setSelectedCountry] = useState<string>("Singapore")
    const history = useHistory();

    useEffect(() => {
        setSelectedCountry("Singapore")
    }, [])


    const countryAcronym = getKeyByValue(LIST_OF_COUNTRIES, selectedCountry);
    const requestDateParam = dateFrom ? dateFrom : initialDateFrom;

    const {spendingAnalytics, earningsAnalytics, analyticsLoading} = useAnalytics(requestDateParam, countryAcronym?.code);


    useEffect( () => {
        (async () => {
            setLoading(true);
            const response = await getPayableAndTotalEarnings();
            setCutOffPointData(response?.data);
            response && setLoading(false);
        })()
    }, []);

    const renderStatistic = (title: string, statistics: number | undefined, currency: string, showNA?: boolean) => {
        return (
            <div className="info">
                <span>{title}</span>
                <span>{statistics ? roundValue(statistics) : 0}{currency}</span>
            </div>
        )
    }

    return (
        <>
           <div className="back-btn-div">
                <button
                    className="back-btn mt-2 mb-2"
                    onClick={() => history.goBack()}
                >
                    Back
                </button> </div>
                <div className="analytics-page">
            <div className="analytics-card left-card">
                <div className="analytic-inputs">
                    <Autocomplete
                        className={'countries'}
                        options={LIST_OF_COUNTRIES.map((info) => info.name)}
                        onChange={(event, value) => value ? setSelectedCountry(value) : setSelectedCountry("Singapore")}
                        value={selectedCountry}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                label="Countries"
                            />}
                    />
                    <TextField
                        className={'date'}
                        type={"date"}
                        onChange={(e) => setDateFrom(e.target.value)}
                        value={dateFrom}
                    />
                </div>
                <div className="spending">
                    <h2>Spending</h2>
                    {renderStatistic("Total spending", spendingAnalytics.totalSpending, "$")}
                    {renderStatistic("Spending on meetings", spendingAnalytics?.spendingOnMeetings, "$")}
                    {renderStatistic("Spending on credits", spendingAnalytics?.spendingOnCredits, "$")}
                    {renderStatistic("Spending on paid content", spendingAnalytics?.spendingOnPaidContent, "$")}
                    {renderStatistic("Monthly spending", spendingAnalytics?.spendingPerMonth, "$")}
                </div>
                <hr/>
                <div className="earnings">
                    <h2>Earnings</h2>
                    {renderStatistic("Earnings Balance", earningsAnalytics?.unremittedEarnings, "$")}
                </div>
                {analyticsLoading && (
                    <CircularProgress
                        className='analytics-loader'
                    />)
                }
            </div>
            <div className="analytics-card">
                <div className="earnings">
                    <h2>Earnings & Cash Flows</h2>
                    {renderStatistic("Earnings Balance (less than MPA)", cutOffPointData?.totalEarningsBalance, "$", true)}
                    {renderStatistic("Payable Earning Balance", cutOffPointData?.payableEarningsBalance, "$")}
                </div>
                {loading && (
                    <CircularProgress
                        className='analytics-loader'
                    />)
                }
            </div>
        </div>
        </>
    
    )
}

export default Analytics;
