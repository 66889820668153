import {useEffect, useState} from "react";
import {getSuccessRateByUser} from "../../api/score";


export const useUsersSuccessRate = (userId: number | undefined) => {
    const [successRate, setSuccessRate] = useState<number>(0)


    useEffect(() => {
        (async () => {
            if (userId) {
                const response = await getSuccessRateByUser(userId)
                if (response?.data) {
                    setSuccessRate(response?.data?.count)
                }
            }
        })()
        // eslint-disable-next-line
    }, [])

    return {
        successRate
    }
}
