import React, {CSSProperties} from "react";
import Select, {OptionTypeBase} from "react-select";

type IOption = {
    value: string,
    label: string
}

interface IProps {
    value?: OptionTypeBase[] | OptionTypeBase | null | string,
    onChange?: (e: any) => void,
    placeholder?: string,
    options: IOption[],
    className?: string,
    defaultValue?: string,
    style?: CSSProperties
}

const CustomSelect: React.FC<IProps> = (
    {
        onChange,
        value,
        placeholder,
        options,
        className,
        style
    }) => {
    return (
        <div className="select-container" style={style}>
            <Select
                options={options}
                onChange={onChange}
                placeholder={placeholder}
                value={value}
                className={className}
            />
        </div>
    );
};

export default CustomSelect;