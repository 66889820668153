import React, { RefObject, useEffect, useRef, useState } from 'react';
import { Box, FormControl, FormLabel, IconButton, Tab, TextField } from "@material-ui/core";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import TabContext from '@mui/lab/TabContext';
import { TabList, TabPanel } from '@mui/lab';
import EditIcon from "@material-ui/icons/Edit";

interface PreviewProps {
  value: string;
  handleTabChange: (event: React.SyntheticEvent, newValue: number) => void;
  minHeight: number;
  html: string;
  css?: string;
  cardRef?: RefObject<HTMLDivElement>;
  toggleMaximize?: () => void,
  isPreviewMaximized?: boolean,
  allowDesktopViewOnly?: boolean,
  allowFullScreen?: boolean,
  customClass?: string,
  showTabList?: boolean,
  optionForOpacity: boolean,
  handleBgOpacityChange?: (e: any) => void,
  selectedOpacity?: number

}
const Preview: React.FC<PreviewProps> = ({ value,
  handleTabChange, minHeight, html, css, cardRef, toggleMaximize,
  isPreviewMaximized, allowDesktopViewOnly = false, allowFullScreen = true,
  customClass, showTabList = true, handleBgOpacityChange, optionForOpacity = false, selectedOpacity }) => {
  const imageRef = useRef<HTMLIFrameElement>(null);
  const [containerWidth, setContainerWidth] = useState<number | undefined>(undefined);
  const [opacity, setOpacity] = useState<number>(selectedOpacity !== null && selectedOpacity !== undefined ? selectedOpacity : 0.1);
  const [editBGOpacity, setEditBGOpacity] = useState<boolean>(false);
  useEffect(() => {
    if (selectedOpacity !== null && selectedOpacity !== undefined) {
      setOpacity(selectedOpacity);
    }
  }, [selectedOpacity]);
 
  const handleResize = () => {
    const width = (document.querySelector('#desktop_preview') as HTMLElement | null)?.offsetWidth; // Cast to HTMLElement
    setContainerWidth(width);
  };

  useEffect(() => {
    handleResize(); // Call once to set initial width
    window.addEventListener('resize', handleResize); // Add resize event listener
    return () => window.removeEventListener('resize', handleResize); // Remove event listener on unmount
  }, [value, isPreviewMaximized]);

  const zoomOutFactor = 0.9;
  const scaleFactor = containerWidth ? containerWidth / (1400) : 1 + zoomOutFactor;

  const saveOpacity = (value: any) => {
    setOpacity(value)
    // Your event handling logic here
    if (optionForOpacity && handleBgOpacityChange) {
      handleBgOpacityChange(value);
    }
  };
  return (
    <div className={`card h-100 mt-4 ${customClass}`}>
      {
        showTabList && <div className="card-header align-items-center" style={{ display: 'grid', gridTemplateColumns: '1fr auto' }}>
          <div><h6 className="mb-0">Template Preview </h6>
          </div>
          <div>
            {allowFullScreen && <IconButton
              onClick={
                toggleMaximize
              }
            >
              {!isPreviewMaximized ? <FullscreenIcon /> : <FullscreenExitIcon />}
            </IconButton>
            }
          </div>
        </div>
      }
      <div className='card-body'>
        <TabContext value={'' + value}>
          <div className='d-flex justify-content-between'>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              {
                showTabList && <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                  <Tab label="Desktop View" value="1" />
                  <Tab label="Mobile View" value="2" />
                </TabList>
              }

            </Box>
            {optionForOpacity &&
              <Box className='d-flex justify-content-between' style={{ width: "25%", paddingRight: "42px" }}>
                {
                  !editBGOpacity ? <>
                    <FormControl className='mt-2 pt-2'>
                      {" "}
                      <FormLabel component="legend">
                        Transparency: <b>  {
                          selectedOpacity}</b>
                      </FormLabel>
                    </FormControl>
                    <IconButton
                      onClick={() => {
                        setEditBGOpacity(!editBGOpacity);
                      }}
                      className="pt-1"
                    >
                      <EditIcon />
                    </IconButton>
                  </> :
                    <TextField
                      id="standard-number"
                      label="Transparency"
                      type="number"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={opacity}
                      onChange={(e) => saveOpacity(e.target.value)}
                      inputProps={{
                        min: 0,
                        max: 1,
                        step: 0.1, // Optional: Specify step size if needed
                      }}
                    />
                }
              </Box>
            }</div>
          <TabPanel value="1">
            <div className="col-lg-12 col-md-12 col-sm-12" id="desktop_preview">
              <div style={{ width: '100%', height: 'auto', overflow: 'hidden', border: '1px solid rgb(204, 204, 204)' }}>
                <div style={{ width: '1500px', height: '100%', transform: `scale(${scaleFactor}) translateX(-2%)`, transformOrigin: 'top left', }} >
                  <div style={{
                    width: '1440px', height: '900px',
                  }}>
                    <iframe
                      ref={imageRef}
                      srcDoc={`<html><style>${css}</style><body>${html}</body></html>`}
                      title="Preview"
                      sandbox="allow-scripts"
                      width="100%" height="900px"
                    />
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel value="2">
            <div className="row d-flex justify-content-center align-items-center">
              <div ref={cardRef} style={{
                border: '1px solid #ccc',
                height: '100%', overflow: 'hidden'
              }}>
                <iframe title="Mobile Preview" srcDoc={`<html><style>${css}</style><body>${html}</body></html>`} ref={imageRef}
                  width="360px" height="640px" sandbox="allow-scripts" >
                </iframe>
              </div>
            </div>
          </TabPanel>
        </TabContext>
      </div>
    </div>
  );
};

export default Preview;