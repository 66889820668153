import axios from "axios";
import {ICommonResponse} from "../interfaces/response";

export const getSystemVariables = () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/system/variables`)
        .then((response) => response && response.data )
        .catch((error) =>
            error &&
            error.response &&
            error.response.message
                ? {error: error.response.message}
                : {error: 'unknown error'},
        );
}
export const changeSystemVariable = (data:{key: string, value: string}): Promise<ICommonResponse> => {
    return axios.patch(`${process.env.REACT_APP_API_URL}/system/admin/variable`, data)
        .then((response) => response && response.data )
        .catch((error) =>
            error &&
            error.response &&
            error.response.message
                ? {error: error.response.message}
                : {error: 'unknown error'},
        );
}