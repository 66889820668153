import React, {useRef} from "react";
import { NavLink } from "react-router-dom";
import {useClickAway} from "react-use";
import SidebarItems from "../../constant/sidebar-items";
import { SidebarProps } from "../../interfaces/sidebar";

const renderItem = (item: any, closeSidebar: () => void) => {
  return (
    <NavLink
      activeClassName="selected"
      className="sidebar-options"
      to={item.path}
      key={item.path}
      onClick={() => closeSidebar()}
    >
      <span className="sidebar-icon">{item.icon}</span>
      <span>{item.title}</span>
    </NavLink>
  );
};

const Sidebar: React.FC<SidebarProps> = ({ openSideBar, setOpenSideBar }) => {
  const ref = useRef<HTMLDivElement>(null)
  useClickAway(ref, () => openSideBar && setOpenSideBar(false))
  return (
    <div className={`main-sidebar ${openSideBar ? "activated" : ''}`} ref={ref}>
      <div className="main-logo">
        {/*<span className="close-btn" onClick={() => setOpenSideBar(false)}>&times;</span>*/}
      </div>
      {SidebarItems.map((item) => renderItem(item, () => setOpenSideBar(false)))}
    </div>
  );
};

export default Sidebar;
