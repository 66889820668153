import axios from "axios";
import { IPagination } from "../interfaces/request";

interface IGetEmailNotificationMangement {
  search_by?: string;
  sort_by?: string;
  pagination: IPagination;
  type: string;
}

interface IAddEmailNotificationManagement {
  name: string,
  email: string,
  type: string
}

export const addEmailNotificationManagementData = (data: IAddEmailNotificationManagement) =>{
  return axios({
    method: "PUT",
    url: `${process.env.REACT_APP_API_URL}/admin/email-notification-management`,
    data,
  })
    .then((response: any) => response?.data)
    .catch((error) => error.response?.data);
}

export const getEmailNotificaitonManagementData = (data: IGetEmailNotificationMangement) => {
    return axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/admin/email-notification-management`,
      data,
    })
      .then((response: any) => response?.data)
      .catch((error) => error.response?.data);
};

export const deleteEmailNotificaitonManagementData = (id: string) => {
    return axios({
      method: "DELETE",
      url: `${process.env.REACT_APP_API_URL}/admin/email-notification-management/${id}`
    })
      .then((response: any) => response?.data)
      .catch((error) => error.response?.data);
};