import React, { useEffect, useState } from "react";
import ChipInput from "material-ui-chip-input";
import { Autocomplete, TextField, Button } from "@mui/material";
import { useEmailServiceControl } from "../../service/email-service";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Loader from "../../components/Loader";
import SuccessOrErrorModal from "../../components/Modal/SuccessOrErrorModal";
import { INFO_MODAL } from "../../enum/successOrErrorModal";
import { useSendGridTemplates } from "../../service/email-service/sendgrid-templates";
import { FORMATS, MODULES } from "../../constant/react-quill";
import Papa from "papaparse";
import { Chip, FormControl, Grid } from "@material-ui/core";
import {
  SEND_GRID_TEMPLATE_IDS,
  SMTP_TEMPLATE_IDS,
} from "../../enum/send-grid-templates";
import Preview from "../TemplateEditor/Preview";
import { useHistory } from "react-router";
import { ROUTES } from "../../enum/routes";
import Modal from "../../components/Modal";
import * as XLSX from "xlsx";
import EmailReceiver from "./EmailReceiver";
import ReactDOM from "react-dom";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const EmailService = () => {
  const history = useHistory();
  const {
    templates,
    isActive,
    selectTemplate,
    selectedTemplate,
    loading: templateLoading,
    // emailFromOptions,
  } = useSendGridTemplates();
  const [file, setFile] = useState<any>();
  const [value, setValue] = useState("1");
  const [showPreviewInFullScreen, setShowPreviewInFullScreen] =
    useState<boolean>(false);
  const [selectedChip, setSelectedChip] = useState<any>();
  const emailTypeOptions = ["TO", "BCC"];
  const [selected, setSelected] = useState(false);
  const [isEmailTypeEmpty, setIsEmailTypeEmpty] = useState<boolean>(false);
  const [isSubjectEmpty, setIsSubjectEmpty] = useState<boolean>(false);
  const [fileType, setFileType] = useState<string>("");
  const [isValidEmail, setIsValidEmail] = useState(true);

  const {
    loading,
    autoCompleteOptions,
    autoCompleteBccOptions,
    handleAutoCompleteChange,
    handleAutoCompleteBccChange,
    selectedUserName,
    receivers,
    bccReceivers,
    setBccReceivers,
    handleSelectMailGroup,
    searchValue,
    searchBccValue,
    handleSearchUsers,
    handleBccSearchUsers,
    emailBody,
    handleEmailBodyChange,
    onSend,
    disableSend,
    setDisableSend,
    handleReceiversDelete,
    handleBccReceiversDelete,
    sendEmailLoading,
    errorModal,
    successModal,
    emailFrom,
    emailFromOptions,
    handleEmailSentFrom,
    handleEmailSubject,
    emailSubject,
    setCsvData,
    setIsUploadCsv,
    isUploadCsv,
    handleEmailCC,
    emailCC,
    handleEmailType,
    groupReceivers,
    emailType,
    addedEmailsFromFileCount,
    csvData,
    setEmailType,
  } = useEmailServiceControl(selectedTemplate, setFile);


  const autoTriggeredTemplateSelected =
    selectedTemplate?.template?.template_name === "aST";
  const adminInitiatedTemplateSelected =
    selectedTemplate?.template?.template_name === "iST";

  const handleKeyPress = (e: any) => {
    e.preventDefault();
    return false;
  };

  const getFileExtension = (fileName: string): string => {
    return fileName.split(".").pop()?.toLowerCase() ?? "";
  };

  const onChange = (e: any) => {
    e.preventDefault();
    setIsUploadCsv(true);
    let excelCsvFile: any;
    excelCsvFile = e?.target?.files[0];
    if (!excelCsvFile) return;
    if (excelCsvFile) {
      setFileType(getFileExtension(excelCsvFile.name));
      const reader = new FileReader();
      reader.onload = (event) => {
        const result = event?.target?.result;
        if (excelCsvFile.name.endsWith(".csv")) {
          setFile(excelCsvFile);
          // Parse CSV file
          csvParser(excelCsvFile);
        } else if (excelCsvFile.name.endsWith(".xlsx")) {
          setFile(excelCsvFile);
          // Parse Excel file
          excelParser(result);
        } else {
          // Unsupported file format
          errorModal.setOpen(true);
          errorModal.setMessage(
            "Unsupported file format. Please upload a CSV or Excel file."
          );
        }
      };
      if (excelCsvFile.name.endsWith(".csv")) {
        reader.readAsText(excelCsvFile);
      } else if (excelCsvFile.name.endsWith(".xlsx")) {
        reader.readAsBinaryString(excelCsvFile);
      } else {
        errorModal.setOpen(true);
        errorModal.setMessage(
          "Unsupported file format. Please upload a CSV or Excel file."
        );
      }
    }
    e.target.value = null;
    handleEmailCC('')
    setBccReceivers([])
    handleAutoCompleteBccChange(e, [])
  };

  const csvParser = (csvFile: any) => {
    Papa.parse(csvFile, {
      skipEmptyLines: true,
      transform: (value) => value.trim(), // Trim whitespace from values
      complete: (parsed) => {
        const filteredData = parsed.data.map((row: any) =>
          row.filter((cell: any) => cell !== "")
        ); // Filter out empty values
        const arrVal = filteredData.filter((val) => val.length > 0);
        setCsvData(arrVal);
      },
    });
  };
  const excelParser = (excelFile: any) => {
    const workbook = XLSX.read(excelFile, { type: "binary" });
    const sheetName = workbook.SheetNames[0];
    const sheet = workbook.Sheets[sheetName];
    const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
    const arrVal = jsonData.filter((val: any) => val.length > 0);
    setCsvData(arrVal);
  };
  const showLoader = sendEmailLoading || templateLoading;

  useEffect(() => {
    if (selectedTemplate) {
      const { id } = selectedTemplate;
      const isEmailBodyEmpty: boolean =
        !emailBody || emailBody === "<p><br></p>";
      if (
        !SEND_GRID_TEMPLATE_IDS.ENABLE_SEND_BUTTON.includes(id) &&
        isEmailBodyEmpty
      ) {
        setDisableSend(true);
        return;
      }
      if (
        SEND_GRID_TEMPLATE_IDS.ENABLE_SEND_BUTTON.includes(id) &&
        disableSend
      ) {
        setDisableSend(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplate, emailBody, disableSend]);

  useEffect(() => {
    if (selectedTemplate) {
      const { id } = selectedTemplate;
      if (isActive(id)) {
        handleEmailSubject(selectedTemplate.template_email_header)
      }
    }
    // eslint-disable-next-line
  }, [selectedTemplate])

  const checkBeforeSend = async () => {
    if (emailCC !== "" && isValidEmail === false) {
      return true;
    } else {
      const { template_version_name } = selectedTemplate;
      if (
        !emailType &&
        (
          (groupReceivers[0] === "All" || groupReceivers[0] === "HU" || groupReceivers[0] === "FU") ||
          (csvData && csvData?.length > 0)
        )
      ) {
        setIsEmailTypeEmpty(true);
        return true;
      } else {
        const receiverValues: string[] = ["All", "HU", "FU"];
        // Check if any element in the array is in the predefined set
        const containsPredefinedValue = receivers.some(element => receiverValues.includes(element));
        if (!emailSubject) {
          setIsSubjectEmpty(true)
          return;
        } else if ((csvData && csvData?.length > 0) || (!containsPredefinedValue && receivers.length > 0) || groupReceivers.length > 0) {
          setIsSubjectEmpty(false)
          onSend(
            SMTP_TEMPLATE_IDS.APPEND_RECEIVER_NAME.includes(template_version_name)
          );
        } else {
          errorModal.setOpen(true)
          errorModal.setMessage("Select email receivers first.")
          return true;
        }
      }
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue("" + newValue);
  };

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const readxslxFile = () => {
    const newWindow = window.open("", "_blank");
    if (!newWindow) return; // Handle popup blockers
    newWindow.document.title = "Excel Data";
    newWindow.document.head.innerHTML = `
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap.min.css" integrity="sha384-BVYiiSIFeK1dGmJRAkycuHAHRg32OmUcww7on3RYdg4Va+PmSTsz/K68vbdEjh4u" crossorigin="anonymous">
  `;
    newWindow.document.body.innerHTML = '<div id="root"></div>';
    const TableDisplayComponent = <EmailReceiver excelData={csvData} fileType={fileType} />;
    ReactDOM.render(TableDisplayComponent, newWindow.document.getElementById('root'));
  }
  const validateEmail = (email: string) => {
    // Regular expression for email validation
    if (email !== "") {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return regex.test(email);
    } else {
      return true;
    }
  };

  return (
    <>
      {" "}
      <div className="back-btn-div">
        <button
          className="back-btn email-service-btn"
          onClick={() => history.push(ROUTES.PAGES.OVERVIEW)}
        >
          Back
        </button>
      </div>
      <div className="email-container-page">
        <div className="email-wrapper">
          {showLoader && <Loader />}
          <div className={"email-container"}>
            <Autocomplete
              loading={loading}
              className={`email-input ${autoTriggeredTemplateSelected ? "disable-color" : ""
                }`}
              options={autoCompleteOptions}
              onChange={handleAutoCompleteChange}
              value={selectedUserName}
              freeSolo={false}
              disabled={isUploadCsv || autoTriggeredTemplateSelected}
              renderInput={(params) => (
                <TextField
                  {...params}
                  value={searchValue}
                  label={"Users"}
                  onChange={handleSearchUsers}
                  placeholder={"Find users who should receive an email message"}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
            {addedEmailsFromFileCount > 0 && (
              <p>
                Total {addedEmailsFromFileCount} receivers added from file
                successfully.
              </p>
            )}
            <div className="row d-flex mt-4 mb-4">
              <div
                style={{ width: "40%" }}
                className={`pl-4 ${addedEmailsFromFileCount !== 0 ? "disable-color" : ""
                  }`}
              >
                <FormControl style={{ display: "block", alignItems: "center" }}>
                  <p className="receiver-label">Receivers</p>
                  {receivers.map((item: any) => (
                    <Chip
                      className="receivers-chip"
                      size="medium"
                      label={item}
                      onKeyPress={handleKeyPress}
                      onClick={(e: any) => {
                        handleEmailCC('')
                        setBccReceivers([])
                        handleAutoCompleteBccChange(e, [])
                        setSelected(!selected);
                        handleSelectMailGroup(e);
                        setSelectedChip(!selectedChip);
                      }}
                      onDelete={
                        item !== "All" && item !== "HU" && item !== "FU"
                          ? (e) => {
                            setSelected(!selected);
                            handleReceiversDelete(item);
                          }
                          : undefined
                      }
                      style={{
                        backgroundColor: groupReceivers.includes(item)
                          ? "rgb(100, 181, 246)"
                          : "#e0e0e0",
                        margin: '2px 5px 5px 0px',
                        minWidth: '50px'
                      }}
                      disabled={
                        autoTriggeredTemplateSelected ||
                        addedEmailsFromFileCount !== 0
                      }
                    />
                  ))}
                </FormControl>
              </div>
              <div style={{ width: "60%" }}>
                <FormControl
                  style={{ display: "flex", alignItems: "flex-start" }}
                >
                  {(isUploadCsv || receivers.includes(groupReceivers[0])) && (
                    <Autocomplete
                      style={{ width: "40%" }}
                      size="small"
                      // multiple
                      className={`mt-4 email-input ${autoTriggeredTemplateSelected ? "disable-color" : ""
                        }`}
                      disableClearable
                      disabled={autoTriggeredTemplateSelected}
                      id="tags-outlined"
                      options={emailTypeOptions}
                      freeSolo={false}
                      onBlur={() => setIsEmailTypeEmpty(false)}
                      onChange={handleEmailType}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Email Type"
                          placeholder="Email Type"
                          error={isEmailTypeEmpty}
                          helperText={isEmailTypeEmpty && "Select Email Type"}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  )}
                </FormControl>
              </div>
            </div>
          </div>
          {successModal.open && (
            <SuccessOrErrorModal
              title={"Success"}
              open={successModal.open}
              setOpen={successModal.setOpen}
              type={INFO_MODAL.SUCCESS}
              content={"Successfully sent"}
            />
          )}
          {errorModal.open && (
            <SuccessOrErrorModal
              title={"Error"}
              open={errorModal.open}
              setOpen={errorModal.setOpen}
              type={INFO_MODAL.ERROR}
              content={
                errorModal.message
                  ? errorModal.message
                  : "Something went wrong..."
              }
            />
          )}
        </div>
        <div className="upload-container">
          <div className="upload-btn">
            <Button
              disabled={autoTriggeredTemplateSelected}
              variant="contained"
              component="label"
              className="upload"
            >
              Upload File
              <input
                type="file"
                hidden
                accept=".csv,.xlsx"
                multiple
                onChange={onChange}
              />
            </Button>
            <div className="span-container">
              {!file ? (
                <span className="supported-file">
                  Supported files .csv/.xlsx
                </span>
              ) : (
                <span>
                  <>
                    <button
                      onClick={readxslxFile}
                      style={{
                        background: "none",
                        border: "none",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      {file.name}{" "}
                    </button>
                    {file && (
                      <span
                        onClick={() => {
                          setFile(null);
                          setCsvData([]);
                          setIsUploadCsv(false);
                          setEmailType("");
                        }}
                        className="ml-2"
                      >
                        <HighlightOffIcon fontSize="small" />
                      </span>
                    )}
                  </>
                </span>
              )}
            </div>
          </div>
          {/* Below div is just to fix alignment with the left column and nothing with the functionality. */}
          {addedEmailsFromFileCount > 0 && (
            <p style={{ visibility: "hidden" }}>
              Total receivers added from file successfully.
            </p>
          )}
          <div className="row d-flex mt-2">
            <div style={{ width: "35%" }} className="pl-4">
              <FormControl style={{ display: "flex", alignItems: "center" }}>
                <ChipInput
                  style={{ visibility: "hidden" }}
                  // fullWidth
                  disableUnderline
                  value={receivers}
                  defaultValue={["All"]}
                  label={"Receivers"}
                />
              </FormControl>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
      <div className="row d-flex mt-2 mb-4" id="email-container">
        <div className="col-6 col-md-6 col-lg-6 pr-0">
          <Autocomplete
            className={`mt-0 email-input ${autoTriggeredTemplateSelected ? "disable-color" : ""
              }`}
            options={emailFromOptions}
            onChange={handleEmailSentFrom}
            value={emailFrom}
            freeSolo={false}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={"From:"}
                label={"From"}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
            disabled={autoTriggeredTemplateSelected}
          />
          {<TextField
            type="email"
            fullWidth
            className={`email-input ${autoTriggeredTemplateSelected ? "disable-color" : ""
              }`}
            value={emailCC}
            label={"СС"}
            onChange={(e) => {
              handleEmailCC(e.target.value);
              setIsValidEmail(validateEmail(e.target.value));
            }}
            placeholder={"Email address"}
            disabled={
              isUploadCsv ||
                receivers.includes(groupReceivers[0]) ||
                autoTriggeredTemplateSelected
                ? true
                : false
            }
            InputProps={{
              style: {
                borderColor: isValidEmail ? "initial" : "red",
              },
            }}
            error={!isValidEmail}
            helperText={!isValidEmail ? "Enter a valid email address." : ""}
          />}
          <Autocomplete
            multiple
            className={`email-input ${autoTriggeredTemplateSelected ? "disable-color" : ""
              }`}
            disableClearable
            disabled={
              isUploadCsv ||
                receivers.includes(groupReceivers[0]) ||
                autoTriggeredTemplateSelected
                ? true
                : false
            }
            id="tags-outlined"
            options={autoCompleteBccOptions}
            value={bccReceivers}
            freeSolo={false}
            onChange={handleAutoCompleteBccChange}
            getOptionLabel={(selectedBccUserName: any) => selectedBccUserName}
            renderInput={(params) => (
              <TextField
                {...params}
                value={searchBccValue}
                onChange={handleBccSearchUsers}
                label="BCC"
                placeholder="Add emails as Bcc to send mails."
              />
            )}
            renderTags={(values) =>
              values.map((value, index) => (
                <>
                  <Chip
                    key={value + index}
                    label={value}
                    onDelete={(data) => handleBccReceiversDelete(value)}
                  />
                </>
              ))
            }
          />

          <TextField
            fullWidth
            className={`email-input subject-input ${autoTriggeredTemplateSelected ? "disable-color" : ""
              }`}
            value={emailSubject}
            label={"Subject"}
            onChange={(e) => handleEmailSubject(e.target.value)}
            placeholder={"Email subject"}
            error={!emailSubject && isSubjectEmpty}
            disabled={autoTriggeredTemplateSelected}
            helperText={
              !emailSubject && isSubjectEmpty && "Subject is required"
            }
          />
          <ReactQuill
            className={`email-body ${autoTriggeredTemplateSelected || adminInitiatedTemplateSelected
              ? "disable-color"
              : ""
              }`}
            theme="snow"
            formats={FORMATS}
            modules={MODULES}
            value={autoTriggeredTemplateSelected || adminInitiatedTemplateSelected ? '' : emailBody}
            onChange={handleEmailBodyChange}
            readOnly={
              autoTriggeredTemplateSelected || adminInitiatedTemplateSelected
            }
          />
          <Button
            onClick={checkBeforeSend}
            fullWidth
            variant={"contained"}
            color={"primary"}
            disabled={
              (emailCC !== "" && isValidEmail === false) || (selectedTemplate?.template?.template_name === "DM" &&
                (emailBody === "<p><br></p>" || emailBody === "") &&
                disableSend) ||
              selectedTemplate?.template?.template_name === "aST" ||
              !emailFrom
            }
          >
            Send
          </Button>
          {!!templates.length && (
            <div className={"templates"}>
              <h2 className="mt-4 pt-4 template-heading ">Dynamic templates</h2>
              <div className="template-container pt-2">
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={2}
                >
                  {templates.map(
                    (templateInfo, index) =>
                      templateInfo.template.template_name === "DM" && (
                        <Grid item xs key={index} className="mb-4">
                          <>
                            <img
                              key={index}
                              onClick={() => {
                                selectTemplate(templateInfo.id);
                              }}
                              style={{
                                border: isActive(templateInfo.id)
                                  ? "5px solid #458eff"
                                  : "1px solid lightgrey",
                              }}
                              className="image-container"
                              src={templateInfo.thumbnail}
                              alt={templateInfo.template_version_name}
                            />
                            <span>{templateInfo.template_version_name}</span>
                          </>
                        </Grid>
                      )
                  )}
                </Grid>
              </div>
              <h2 className="mt-4 pt-4 template-heading ">Admin Initiated templates (Static)</h2>
              <div className="template-container pt-2">
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={2}
                >
                  {templates.map(
                    (templateInfo, index) =>
                      templateInfo.template.template_name === "iST" && (
                        <Grid item xs>
                          <>
                            <img
                              key={index}
                              onClick={() => {
                                selectTemplate(templateInfo.id);
                              }}
                              style={{
                                border: isActive(templateInfo.id)
                                  ? "5px solid #458eff"
                                  : "1px solid lightgrey",
                              }}
                              className="image-container"
                              src={templateInfo.thumbnail}
                              alt={templateInfo.template_version_name}
                            />
                            <span>{templateInfo.template_version_name}</span>
                          </>

                        </Grid>
                      )
                  )}
                </Grid>
              </div>
              <h2 className="mt-4 pt-4 template-heading ">Auto-triggered templates (Static)</h2>
              <div className="template-container pt-2">
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={2}
                >
                  {templates.map(
                    (templateInfo, index) =>
                      templateInfo.template.template_name === "aST" && (
                        <Grid item xs key={index} className="mb-4">
                          <img
                            key={index}
                            onClick={() => {
                              selectTemplate(templateInfo.id);
                            }}
                            style={{
                              border: isActive(templateInfo.id)
                                ? "5px solid #458eff"
                                : "1px solid lightgrey",
                            }}
                            className="image-container"
                            src={templateInfo.thumbnail}
                            alt={templateInfo.template_version_name}
                          />
                          <span>{templateInfo.template_version_name}</span>
                        </Grid>
                      )
                  )}
                </Grid>
              </div>
            </div>
          )}
        </div>
        <div className="col-6 col-md-6 col-lg-6 pl-0">
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={2}
            className="ml-2 temp-preview-contianer pt-0"
            style={{ height: "796px" }}
          >
            <Grid item xs className="pt-0">
              <Preview
                value={"" + value}
                handleTabChange={handleTabChange}
                minHeight={720}
                allowFullScreen={true}
                customClass="temp-preview-box"
                html={
                  (emailBody !== "" && emailBody !== "<p><br></p>")
                    ? selectedTemplate?.html_content.replace(
                      "{{{html_content}}}",
                      emailBody
                    )
                    : selectedTemplate?.html_content
                }
                toggleMaximize={() => {
                  setShowPreviewInFullScreen(!showPreviewInFullScreen);
                }}
                isPreviewMaximized={showPreviewInFullScreen}
                optionForOpacity={false}
              />
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="row">
        <div className="col-6 col-md-6 col-lg-6">
        </div>
      </div>
      <Modal
        title="Template Preview"
        width="100%"
        disableClose={true}
        confirmButton={"Ok"}
        cancelButton={"Cancel"}
        content={
          <>
            {value === "1" && (
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div
                  style={{
                    border: "1px solid #ccc",
                    padding: "10px",
                  }}
                  className="row d-flex justify-content-center align-items-center"
                >
                  <iframe
                    id="desktop_iframe"
                    srcDoc={`<html><body>${(emailBody !== "" && emailBody !== "<p><br></p>")
                      ? selectedTemplate?.html_content.replace(
                        "{{{html_content}}}",
                        emailBody
                      )
                      : selectedTemplate?.html_content
                      }</body></html>`}
                    title="Preview"
                    sandbox="allow-scripts"
                    width="100%"
                    height="100%"
                  />
                </div>
              </div>
            )}
            {value === "2" && (
              <div className="row d-flex justify-content-center align-items-center">
                <div className="col-lg-6 col-md-6 col-sm-6 d-flex justify-content-center align-items-center">
                  <div
                    style={{
                      border: "1px solid #ccc",
                      padding: "10px",
                    }}
                  >
                    <iframe
                      id="mobile_iframe"
                      title="Mobile Preview"
                      srcDoc={`<html><style></style><body>${(emailBody !== "" && emailBody !== "<p><br></p>")
                        ? selectedTemplate?.html_content.replace(
                          "{{{html_content}}}",
                          emailBody
                        )
                        : selectedTemplate?.html_content
                        }</body></html>`}
                      width="370"
                      height="640px"
                      sandbox="allow-scripts"
                    ></iframe>
                  </div>
                </div>
              </div>
            )}
          </>
        }
        open={showPreviewInFullScreen}
        setOpen={setShowPreviewInFullScreen}
      />
    </>
  );
};

export default EmailService;
