import {useCallback, useEffect, useState} from "react";
import {createTopic, deleteTopic, getTopics, updateTopic} from "../../api/faq";
import {IGetTopicsResponse} from "../../interfaces/faq";

export interface INewTopicValues {
    topic: string,
    question: string,
    answer: string
}

interface IActivateField {
    id: string
}

interface ITopicValue {
    question: string,
    answer: string
}

interface ITopics {
    id: string,
    topic: string,
    value: ITopicValue[]
}

const NEW_TOPIC_FIELDS_PROPERTIES = [
    {
        key: "topic",
        label: "Topic title",
        placeholder: "Enter new topic title",
    },
    {
        key: "question",
        label: "Question",
        placeholder: "Enter question",
        multiline: true,
        rows: 1
    },
    {
        key: "answer",
        label: "Answer",
        placeholder: "Enter answer",
        multiline: true,
        rows: 5
    }
]

export const useFAQControl = () => {
    const [triggerFetch, setTriggerFetch] = useState<boolean>(false)
    const [topics, setTopics] = useState<ITopics[]>([])
    const [loading, setLoading] = useState<boolean>(false)

    // MODS
    const [isCreateMode, activateCreateMode] = useState<boolean>(false)
    const [isEditTopicMode, activateEditTopicMode] = useState<IActivateField>({id: ""})
    const [isAddQuestionAnswerMode, activateAddQuestionAnswerMode] = useState<IActivateField>({id: ""})
    const [isEditQuestionAnswerMode, activateEditQuestionAnswerMode] = useState<{ question: string }>({question: ""})

    const [newFaqValues, setNewFaqValues] = useState<INewTopicValues>({topic: "", question: "", answer: ""})

    // ERROR
    const [openErrorModal, setOpenErrorModal] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>("")

    useEffect(() => {
        if (!isCreateMode) {
            setNewFaqValues({topic: "", question: "", answer: ""})
        }
    }, [isCreateMode])

    useEffect(() => {
        (async () => {
            setLoading(true)
            const response = await getTopics()
            if (response && response?.items) {
                const data = response?.items?.map((item: IGetTopicsResponse) => ({
                    id: item.id,
                    topic: item.topic,
                    value: JSON.parse(item.value)
                }))
                setTopics(data)
                if (response?.items?.length === 0) {
                    activateCreateMode(true)
                }
            }
            response && setLoading(false)
        })()
    }, [triggerFetch])

    const resetFaqNewInputValues = () => {
        setNewFaqValues({topic: "", answer: "", question: ""})
    }

    const clearError = () => {
        setErrorMessage("")
    }

    // MODS METHODS
    // ACTIVATE
    const onActivateEditTopicTitleMode = (id: string) => {
        activateEditTopicMode({id})
        const topicById = topics?.find((t) => t.id === id)
        topicById && setNewFaqValues({
            topic: topicById?.topic,
            answer: "",
            question: ""
        })
    }

    const onActivateQuestionAnswerMode = (question: string) => {
        activateEditQuestionAnswerMode({question})
        const topicWithProvidedQuestion = topics?.find((t) => t.value?.find((v) => v.question === question))
        const questionData = topicWithProvidedQuestion?.value?.find((v) => v.question === question)
        if (questionData) {
            setNewFaqValues({
                topic: "",
                question: questionData.question,
                answer: questionData.answer
            })
        }
    }

    const onActivateCreateTopicMode = () => {
        activateCreateMode(true)
    }

    const onActivateAddQuestionAnswerMode = (id: string) => {
        activateAddQuestionAnswerMode({id})
    }

    // DEACTIVATE
    const onDeactivateCreateTopicMode = () => {
        activateCreateMode(false)
    }

    const onDeactivateEditTopicTitleMode = () => {
        activateEditTopicMode({id: ""})
    }

    const onDeactivateAddQuestionAnswerMode = () => {
        activateAddQuestionAnswerMode({id: ""})
        resetFaqNewInputValues()
    }

    const onDeactivateEditAnswerQuestionMode = () => {
        activateEditQuestionAnswerMode({question: ""})
    }


    const onNewFaqValueChange = useCallback((key: string, value: string) => {
        setNewFaqValues((prevState) => {
            const copy = JSON.parse(JSON.stringify(prevState))
            copy[key] = value
            return copy
        })
    }, [])


    const onNewTopicAdd = async () => {
        const data = JSON.stringify([{
            question: newFaqValues.question,
            answer: newFaqValues.answer
        }])
        setLoading(true)
        const response = await createTopic({
            data,
            topic: newFaqValues.topic
        })
        if (response && response?.message) {
            setErrorMessage(response?.message)
            setOpenErrorModal(true)
        }
        setTriggerFetch(!triggerFetch)
        activateCreateMode(false)
        resetFaqNewInputValues()
        response && setLoading(false)
    }

    const onTopicEdit = async (id: string) => {
        onDeactivateEditTopicTitleMode()
        const topicById = topics?.find((t) => t.id === id)
        if (topicById) {
            const data = {
                data: JSON.stringify(topicById?.value),
                topic: newFaqValues?.topic
            }
            const topicNameExist = topics?.find((t) => t.topic === newFaqValues?.topic)
            if (!topicNameExist) {
                setLoading(true)
                const response = await updateTopic(id, data)
                if (response && response?.message) {
                    setErrorMessage(response?.message)
                    setOpenErrorModal(true)
                }
                resetFaqNewInputValues()
                setTriggerFetch(!triggerFetch)
                response && setLoading(false)
            } else {
                setErrorMessage("Couldn't update as a topic with the provided title already exists")
                setOpenErrorModal(true)
            }
        }
    }

    const onTopicDelete = async (id: string) => {
        setLoading(true)
        const response = await deleteTopic(id)
        setTriggerFetch(!triggerFetch)
        response && setLoading(false)
    }

    const onNewQuestionAnswerAddToTheTopic = async (id: string) => {
        const topicById = topics?.find((t) => t.id === id)
        const copy: ITopics = JSON.parse(JSON.stringify(topicById))
        if (topicById) {
            setLoading(true)
            copy?.value?.push({
                question: newFaqValues.question,
                answer: newFaqValues.answer
            })
            const questionOrAnswerExist = topics.find((t) =>
                t.value.find((v) => v.question.toUpperCase() === newFaqValues.question.toUpperCase()) ||
                t.value.find((v) => v.answer.toUpperCase() === newFaqValues.answer.toUpperCase()))
            if (questionOrAnswerExist) {
                setLoading(false)
                setOpenErrorModal(true)
                setErrorMessage("Question or answer already exists")
            } else {
                const data = {
                    data: JSON.stringify(copy.value),
                    topic: topicById?.topic
                }
                const response = await updateTopic(id, data)
                onDeactivateAddQuestionAnswerMode()
                setTriggerFetch(!triggerFetch)
                response && setLoading(false)
            }
        }
    }

    const onSaveEditQuestionAnswer = async () => {
        const topicWithProvidedQuestion = topics?.find((t) => t.value?.find((v) => v.question === isEditQuestionAnswerMode.question))
        if (topicWithProvidedQuestion) {
            setLoading(true)
            const copy: ITopics = JSON.parse(JSON.stringify(topicWithProvidedQuestion))
            const itemIndex = copy?.value.findIndex(v => v?.question === isEditQuestionAnswerMode?.question);
            if (itemIndex > -1) {
                copy.value[itemIndex] = {
                    question: newFaqValues.question,
                    answer: newFaqValues.answer
                };
            } else {
                copy.value.push({
                    question: newFaqValues.question,
                    answer: newFaqValues.answer
                });
            }
            const data = {
                data: JSON.stringify(copy?.value),
                topic: copy?.topic
            }
            const response = await updateTopic(topicWithProvidedQuestion?.id, data)
            resetFaqNewInputValues()
            setTriggerFetch(!triggerFetch)
            onDeactivateEditAnswerQuestionMode()
            response && setLoading(false)
        }
    }

    const onDeleteQuestionAnswer = async (question: string) => {
        const topicWithProvidedQuestion = topics?.find((t) => t.value?.find((v) => v.question === question))
        if (topicWithProvidedQuestion) {
            setLoading(true)
            const data = {
                data: JSON.stringify(topicWithProvidedQuestion?.value?.filter((v) => v?.question !== question)),
                topic: topicWithProvidedQuestion?.topic
            }
            const response = await updateTopic(topicWithProvidedQuestion?.id, data)
            if (topicWithProvidedQuestion?.value?.length === 1) {
                await onTopicDelete(topicWithProvidedQuestion?.id)
            }
            onDeactivateEditAnswerQuestionMode()
            resetFaqNewInputValues()
            setTriggerFetch(!triggerFetch)
            response && setLoading(false)
        }
    }

    return {
        loading,
        topics: {
            list: topics,
            topic: {
                create: onNewTopicAdd,
                edit: onTopicEdit,
                delete: onTopicDelete,
                onValuesChange: onNewFaqValueChange,
                values: newFaqValues,
                disableAdd: Object.values(newFaqValues).some((v) => v.length === 0),
                disableEdit: newFaqValues?.topic?.length === 0
            },
            answerQuestion: {
                create: onNewQuestionAnswerAddToTheTopic,
                edit: onSaveEditQuestionAnswer,
                delete: onDeleteQuestionAnswer,
                disableSaveOrEdit: newFaqValues.answer.length === 0 || newFaqValues.question.length === 0,
            },
            createInputFields: NEW_TOPIC_FIELDS_PROPERTIES
        },
        mods: {
            editTopic: {
                isActive: isEditTopicMode,
                activate: onActivateEditTopicTitleMode,
                deactivate: onDeactivateEditTopicTitleMode
            },
            createTopic: {
                isActive: isCreateMode,
                activate: onActivateCreateTopicMode,
                deactivate: onDeactivateCreateTopicMode
            },
            addAnswerQuestion: {
                isActive: isAddQuestionAnswerMode,
                activate: onActivateAddQuestionAnswerMode,
                deactivate: onDeactivateAddQuestionAnswerMode
            },
            editAnswerQuestion: {
                isActive: isEditQuestionAnswerMode,
                activate: onActivateQuestionAnswerMode,
                deactivate: onDeactivateEditAnswerQuestionMode
            }
        },
        errorModal: {
            open: openErrorModal,
            setOpen: setOpenErrorModal,
            message: errorMessage,
            onActionClicked: clearError
        },

    }
}