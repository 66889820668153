import { useState } from "react";
import { useDispatch } from "react-redux";
import {getSystemVariables} from "../../api/system";
import { setVariablesAction } from "../../redux/reducers/variable";

export const useVariables = () => {
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch();
    return {
        error,
        loading,
        getVariables: async () => {
            setLoading(true);
            const response = await getSystemVariables();
            if (response && response.message) {
                setError(response.message);
            } else {
                if (response && response.items) {
                    const arrayWithGeneratedIds = response?.items?.map((variable: any, index: number) => ({
                        id: index+1,
                        key: variable.key,
                        value: variable.value,
                        description: variable.description
                    }))
                    dispatch(setVariablesAction(arrayWithGeneratedIds));
                } else {
                    setError("User doesn't exist.");
                }
            }
            setLoading(false);
        },
    };
};
