export const USER_ROLE = {
  ADU: "ADU",
  HU: "HU",
  FU: "FU",
};

export const SORT_BY = {
  COUNTRY_SYSTEM: "COUNTRY_SYSTEM",
  DOB: "DOB",
  CREATED_AT: "CREATED_AT",
};

export const EARNINGS_STATUS = {
  FREEZED: "FREEZED",
  ALLOWED: "ALLOWED",
  CANCELED: "CANCELED"
}