import axios from "axios";
import { IPagination } from "../interfaces/request";
import {IMeetingAnalyticsResponse} from "../interfaces/meeting";

export interface IGetMeetings {
  search_by?: string;
  sort_by?: string;
  pagination: IPagination;
}

export const getMeetings = (data: IGetMeetings) => {
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/admin/meetings`,
    data,
  })
    .then((response: any) => response?.data)
    .catch((error) => error.response?.data);
};

export const getMeetingAnalyticsByUserId = (userId: number): Promise<IMeetingAnalyticsResponse> => {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/admin/meeting/analytics/${userId}`,
  })
      .then((response: any) => response?.data)
      .catch((error) => error.response?.data);
};
