import {useEffect, useState} from "react";
import {getUserComplaintsCount} from "../../api/user";


export const useUserComplaintsCount = (userId: string, body: any) => {
    const [data, setData] = useState<any>()
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        (async () => {
            setLoading(true)
            const response = await getUserComplaintsCount(userId, body)
            if (response) {
                setData(response?.data)
                setLoading(false)
            }
        })()
        // eslint-disable-next-line
    }, [])


    return {
        data,
        loading
    }
}
