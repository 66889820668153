import React, { useCallback, useEffect, useState } from "react";
import {getUsers, getUsersCount} from "../../api/user";
import Table from "../../components/Table/index";
import Input from "../../components/Input";
import CustomSelect from "../../components/Select";
import { PAGE_SIZE, INIT_PAGINATION } from "../../constant/pagination";
import { SORT_BY } from "../../constant/sort-users";
import {
  UsersHeader,
  UsersTableName,
} from "../../constant/TableHeaders/users-header";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { ISort } from "../../interfaces/sort";
import Loader from "../../components/Loader";
import { IExtendedUserInfo } from "../../interfaces/user";
import moment from "moment";
import { usePagination } from "../../service/common/pagination";
import { basicBodyVariables } from "../../utils/body-variables";
import { useGetAllUsers } from "../../service/user/users";
import User from "../User";
import { onHandleSearchAndSort } from "../../utils/sort-search";
import { overflowBody } from "../../utils/style-document";
import { Checkbox } from "@mui/material";
import SuccessOrErrorModal from "../../components/Modal/SuccessOrErrorModal";
import { INFO_MODAL } from "../../enum/successOrErrorModal";
import { useHistory } from "react-router-dom";
// import { updateUsersHeader } from "./updateUserHeader";

const Users: React.FC = () => {
  const [search, setSearch] = useState<string>("");
  const [sort, setSort] = useState<ISort>({
    value: SORT_BY[0].value,
    label: SORT_BY[0].label,
  });
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [users, setUsers] = useState<any>([]);
  const [usersCount, setUsersCount] = useState<number>(0);
  const [openUserInfo, setOpenUserInfo] = useState<boolean>(false);
  const [userId, setUserId] = useState<number>();
  const [selectSuspendUsers, setSelectSuspendUsers] = useState<boolean>(false);
  const [openSuccessOrErrorModal, setOpenSuccessOrErrorModal] = useState<boolean>(false);
  // eslint-disable-next-line
  const [isCommentUpdated, setIsCommentUpdated] = useState<boolean>(false)
  const history = useHistory();

  const { onHandleSort, onHandleSearch, onHandleClear } = onHandleSearchAndSort(
    setCurrentPage,
    setSort,
    setSearch
  );
  // const updatedUsersHeader = updateUsersHeader(users);

  const { usersData, loading } = useGetAllUsers({
    pagination: INIT_PAGINATION,
    search_by: search,
    sort_by: sort.value,
    isSuspended: selectSuspendUsers,
  });

  const [newData] = usePagination(getUsers, {
    variables: basicBodyVariables(
      {
        page: currentPage + 1,
        pageSize: PAGE_SIZE,
      },
      search,
      sort.value,
      selectSuspendUsers
    ),
  });

  useEffect(() => {
    setUsers(usersData?.items);
  }, [usersData]);

  useEffect(() => {
    (async () => {
      const {data} = await getUsersCount();
      setUsersCount(data)
    })()

  },[]);

  useEffect(() => {
    if (newData && newData?.items && newData?.items?.length > 0) {
      setUsers([...users, ...newData?.items]);
      setCurrentPage(newData?.currentPage);
    }
    // eslint-disable-next-line
  }, [newData]);

  const updateUserList = useCallback(
    (user: IExtendedUserInfo, changedUser: IExtendedUserInfo) => {
      const index = users?.findIndex((u: any) => u?.id === user?.id);
      setUsers((prevState: any) => {
        prevState[index] = changedUser;
        return prevState;
      });
    },
    [users, setUsers]
  );

  const editedUsers = users?.map((user: IExtendedUserInfo) => {
    return {
      ...user,
      account_creation: moment(user?.account_creation).format("DD-MM-YYYY"),
      last_active_at: moment(user?.last_active_at).format("DD-MM-YYYY"),
      role: (user?.id_verified === true && user?.role === 'HU') ? `a${user?.role}` : user?.role
    };
  });


  if ((!users || users?.length === 0 || loading) && search === "") {
    return <Loader />;
  }

  const openUserCard = (value: any) => {
    setOpenUserInfo(true);
    setUserId(value?.id);
  };

  overflowBody(openUserInfo);

  return (
    <>
      {openUserInfo && (
        <User
          userId={userId}
          setOpen={setOpenUserInfo}
          updateUserList={updateUserList}
        />
      )}
      <div className="users-page">
      <div className="back-btn-div pl-2">
        <button
          className="back-btn mb-2"
          onClick={() => history.goBack()}
        >
          Back
        </button>
        </div>
        <div className="top-panels">

          <label className="input-wrapper">
            <Input
              onChange={onHandleSearch}
              value={search}
              placeholder={"Search"}
              type={"search"}
              classInput="input-search"
            />
            <span className="clear-input" onClick={onHandleClear}>
              {search ? <HighlightOffIcon /> : null}
            </span>
          </label>
          <CustomSelect
            onChange={onHandleSort}
            placeholder={sort.label || "Sort"}
            options={SORT_BY}
          />
        </div>
        <div className="check-box-container">
          <Checkbox
            inputProps={{ "aria-label": "Checkbox A" }}
            checked={selectSuspendUsers}
            value={selectSuspendUsers}
            onChange={(event) => {
              setSelectSuspendUsers(event.target.checked);
              setCurrentPage(0);
            }}
          />
          <span>Show Blocked Users</span>
        </div>
          <div className="total-count">Total users: <span>{usersCount}</span></div>

        {users?.length ? (
          <Table
          header={UsersHeader}
          items={editedUsers}
            tableName={UsersTableName}
            onClickHandler={openUserCard}
          />
        ) : (
          <h2 className="nth-found">Nothing found</h2>
        )}
            {/* <Modal
                open={openModal}
                setOpen={closeModal}
                title={`System Meeting ID`}
                // subtitle={`Admin Comment`}
                // confirmButton={"Submit"}
                // content={modalContent()}
                // onComplete={saveCommentToDB}
                // disabled={
                //     (selectedItem?.status === "Other_Failure"
                //     ? true
                //     : !meetingFailed)
                //     && (selectedItem?.comment === adminComment)
                //    }
            /> */}
            <SuccessOrErrorModal
                onComplete={isCommentUpdated ? () => window.location.reload() : () => setOpenSuccessOrErrorModal(false)}
                open={openSuccessOrErrorModal}
                setOpen={setOpenSuccessOrErrorModal}
                content={isCommentUpdated ? "Admin comment saved successfully!" : "Server Error!"}
                type={isCommentUpdated ? INFO_MODAL.SUCCESS : INFO_MODAL.ERROR}
                button={"Close"}
                buttonClass={"close-modal-button"}
            />
      </div>
    </>
  );
};

export default Users;
