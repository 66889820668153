import React, {CSSProperties} from "react"
import Modal from "../../../../../components/Modal";
import {GridRowData} from "@mui/x-data-grid";
import {Autocomplete, TextField} from "@mui/material";
import {QUALITY_RESOLUTIONS} from "../../../../../enum/variables";


interface IProps {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    onComplete: () => Promise<void>,
    setRequestBodyJSONValue: (jsonValue: string) => void,
    data: GridRowData
}



const STYLES_TIP_TEXT: CSSProperties = {display: 'inline-block', padding: "10px 0 10px", fontSize: 14};
const STYLES_INPUT: CSSProperties = {marginBottom: 15};


const EditStringsModal: React.FC<IProps> = (
    {
        open,
        setOpen,
        data,
        onComplete,
        setRequestBodyJSONValue
    }) => {

    return (
        <Modal
            title={`Edit ${data.key} (${data.description})`}
            open={open}
            setOpen={setOpen}
            confirmButton={"Save"}
            onComplete={onComplete}
            cancelButton={"Cancel"}
            disableClose={true}
            content={
                <React.Fragment>
                    <form >
                        <span style={STYLES_TIP_TEXT}>
                            Select option that you want change
                        </span>
                       <Autocomplete
                            options={QUALITY_RESOLUTIONS}
                            value={data?.value}
                            onChange={(_,value) => value && setRequestBodyJSONValue(value)}
                            freeSolo={false}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    style={STYLES_INPUT}
                                    label={'Resolutions'}
                                />}
                        />
                    </form>
                </React.Fragment>
            }
        />
    )
}

export default EditStringsModal;