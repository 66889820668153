import Modal from "../../../../components/Modal";
import React, {useState} from "react";
import {TextField} from "@mui/material";
import {IUserEarningsResponse} from "../../../../interfaces/earnings";
import {changeUserEarnings} from "../../../../api/earnings";

interface IProps {
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    earnings: IUserEarningsResponse,
    userId: number,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const ChangeEarningsBalance: React.FC<IProps> = ({open, setOpen, earnings, userId, setLoading}) => {
    const [externalEarnings, setExternalEarnings] = useState<string>("0");
    const error = "Can’t deduct an amount larger than user’s Earnings balance";
    const isDisabled = !externalEarnings.length || (earnings && earnings?.unremittedEarnings + +externalEarnings) < 0 || +externalEarnings === 0;

    const onChange = (value: string) => {
        setExternalEarnings(value)
    };

    const onComplete = async () => {
        setLoading(true);
        const response = await changeUserEarnings(userId, {
            amount: +externalEarnings
        });
        response &&  setLoading(false);
    };



    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title="You can add or subtract earnings of this user"
            cancelButton="Cancel"
            confirmButton="Confirm"
            disabled={isDisabled}
            onComplete={onComplete}
            content={
                <form className={"earnings-form"}>
                    <TextField
                        type={"number"}
                        label="Amount"
                        placeholder="10"
                        onChange={(e) => onChange(e.target.value)}
                        value={externalEarnings}
                        fullWidth
                        inputProps={{
                            step: "10",
                            pattern: "^[0-9]+$"
                        }}
                    />
                    {
                        (earnings && earnings?.unremittedEarnings + +externalEarnings) < 0 &&
                        <label className="error-label">{error}</label>
                    }
                </form>
            }
        />
    )
};

export default ChangeEarningsBalance;