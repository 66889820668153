import axios from "axios";
import {IGetListOfSystemErrors, ISystemErrorStatus} from "../interfaces/system-errors";

export const getListOfSystemErrors = (data: IGetListOfSystemErrors) => {
    return axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/system/errors`,
        data
    })
        .then((response: any) => response?.data)
        .catch((error) => error.response?.data);
};

export const updateStatusOfSystemError = (id: string, status: ISystemErrorStatus) => {
    return axios({
        method: "PUT",
        url: `${process.env.REACT_APP_API_URL}/system/error/${id}`,
        data: {status}
    })
        .then((response: any) => response?.data)
        .catch((error) => error.response?.data);
};

export const getUnreadSystemErrorsCount = () => {
    return axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/admin/system/errors/unread/count`,
    })
        .then((response: any) => response?.data)
        .catch((error) => error.response?.data);
};

export const updateSystemErrorFromUnreadToRead = (ids: string[]) => {
    return axios({
        method: "PUT",
        url: `${process.env.REACT_APP_API_URL}/admin/system/errors/read`,
        data: {ids}
    })
        .then((response: any) => response?.data)
        .catch((error) => error.response?.data);
};