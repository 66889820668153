export const FORMATS = ['header', 'bold', 'italic', 'underline', 'strike', 'link', 'align', 'font', 'size', 'color']
export const MODULES = {
    toolbar: [
        [{'header': '1'}, {'header': '2'}, {'font': []}],
        [{size: []}],
        ['bold', 'italic', 'underline', 'strike'],
        [{align: ''}, {align: 'center'}, {align: 'right'}, {align: 'justify'}],
        ['link'],
        [{'color': ["#ffffff", "#000000", "#555555", "#DDDDDD", "#ff0000", "#FF766B", "#405DE6", "#228b22", "#65E5BF", "#0000ff", "#fbfbfb", "#3d5170", "#F2F4F7", "#6A6D7C", "rgba(0, 0, 0, 0)"]}],
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    }
}