import {useEffect, useState} from "react";
import {IPagination} from "../../interfaces/request";
import { getUsers } from "../../api/user";

export const useGetAllUsers = (body: { pagination: IPagination; search_by: string; sort_by: string; isSuspended?: boolean; }) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [usersData, setUsersData] = useState<any>()
    

    const getData = async () => {
        setLoading(true)
        const response = await getUsers(body)
        if (response) {
            setLoading(false)
            setUsersData(response)
        }
    }

    useEffect(() => {
        (getData)()
        return () => setUsersData(undefined)
        // eslint-disable-next-line
    }, [body.search_by, body.sort_by, body.isSuspended])

    return {
        usersData,
        loading
    }
}
