const DATE = "created_at";
const USER = "title";

export const SORT_BY = [
  {
    value: DATE,
    label: "Date",
  },
  {
    value: USER,
    label: "title",
  },
];