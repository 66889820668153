import { useState } from "react";
import { IsJsonString } from "../../utils/validation";


export const useBeautifyJsonString = () =>{

    const [beautifiedString, setBeautifiedString] = useState<string>('')

    const addWhitespace = (count: number) =>{
        let text: string = '';
        for(let i=0; i<count; i++){
            text += `&nbsp;`
        }
        return text
    }

    const beautifyString = (text: string) =>{
        if(IsJsonString(text)){
            let tempText = '';
            let space = 0;
            for(let i=0; i<text.length; i++){
                switch(text[i]){
                    case '{':
                        space += 5;
                        tempText = `${tempText}${text[i]}<br/>${addWhitespace(space)}`
                        break;
                    case '}':
                        space -= 5
                        tempText = `${tempText}<br/>${addWhitespace(space)}${text[i]}`
                        break;
                    case ',':
                            tempText = `${tempText}${text[i]}<br/>${addWhitespace(space)}`
                        break;
                    default:
                        tempText = `${tempText}${text[i]}`
                }
            }
            setBeautifiedString(tempText)
        }else{
            setBeautifiedString(`Invalid JSON String!`)
        }
    }

    return {
        beautifiedString,
        beautifyString
    }
}