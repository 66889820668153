import React, {useEffect} from "react";
import {IVerificationRequestObject} from "../../interfaces/verification-requests";
import {updateSystemErrorFromUnreadToRead} from "../../api/system-errors";

export const useUpdateSystemErrorFromUnreadToRead = (clickedCardData: any, setState: React.Dispatch<React.SetStateAction<any>>) => {
    useEffect(() => {
        (async () => {
            const ids = [clickedCardData?.id]
            if (clickedCardData && !clickedCardData?.is_read && ids?.length) {
                await updateSystemErrorFromUnreadToRead(ids)
                setState(((prevState: IVerificationRequestObject[]) => {
                    try {
                        const copy = JSON.parse(JSON.stringify(prevState))
                        copy?.forEach((i: IVerificationRequestObject) => {
                            if (ids?.includes(i.id)) {
                                i.is_read = true
                            }
                        })
                        return copy
                    } catch (err) {
                        return
                    }
                }))
            }
        })()
        // eslint-disable-next-line
    }, [clickedCardData?.id])
}