import React, { SetStateAction, useCallback, useEffect, useRef, useState } from "react"
import Modal from "../../../../components/Modal";
import { TextField } from "@mui/material";
import { createNewSmtp, updateSMTP } from "../../../../api/smtp";
import { IUpdateSmtpBody } from "../../../../interfaces/smtp";
import { IconButton, InputAdornment } from "@material-ui/core";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";


interface IProps {
    open: boolean,
    setOpen: React.Dispatch<SetStateAction<boolean>>,
    onComplete: any,
    edit: Boolean,
    editableData?: IUpdateSmtpBody,
    setIsEdit: React.Dispatch<SetStateAction<boolean>>
}

const AddNewSMTPModal: React.FC<IProps> = ({ open, setOpen, onComplete, edit, editableData, setIsEdit }) => {
    const autoCompleteRef = useRef<any>(null)
    const [hostname, setHostname] = useState<string>("")
    const [username, setUsername] = useState<string>("")
    const [port, setPort] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [showPassword, setShowPassword] = React.useState(false);

    const handleHostChange = useCallback((e: any) => {
        e.target.value ? setHostname(e.target.value) : setHostname("")
    }, [setHostname])

    const handleUsernameChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setUsername(event.target.value)
    }, [setUsername])

    const handlePortChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setPort(event.target.value)
    }, [setPort])

    const handlePasswordChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setPassword(event.target.value)
    }, [setPassword])

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    const disableButton = !username || !password || !hostname || !port

    useEffect(() => {
        if (editableData) {
            setHostname(editableData?.host)
            setPort(editableData.port)
            setPassword(editableData.password)
            setUsername(editableData.username)
        }
    }, [editableData])
    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title={edit ? `Edit SMTP configuration` : `Add SMTP configuration`}
            refToIgnore={autoCompleteRef}
            onComplete={() => !edit ? onComplete(createNewSmtp, {
                host: hostname,
                password: password,
                port: port,
                username: username,
            }) : onComplete(updateSMTP, {
                id: editableData?.id,
                host: hostname,
                password: password,
                port: port,
                username: username,
            })}
            confirmButton={edit ? "Save" : "Create"}
            cancelButton={"Cancel"}
            disabled={disableButton}
            content={
                <form className={"vat-form"}>

                    <TextField
                        label="Email"
                        style={{ marginBottom: 15 }}
                        onChange={handleUsernameChange}
                        value={username}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        type={showPassword ? 'text' : 'password'}
                        label="Password"
                        style={{ marginBottom: 15 }}
                        onChange={handlePasswordChange}
                        value={password}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    {/* <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                     <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel> */}
                    {/* <OutlinedInput
                        label="Password"
                        placeholder="*****"
                        onChange={handlePasswordChange}
                        id="outlined-adornment-password"
                        type={showPassword ? 'text' : 'password'}
                        style={{ marginBottom: 15 }}
                        inputProps={{
                            shrink: true,
                        }}
                        fullWidth
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                     <Input
            id="standard-adornment-password"
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          /> */}
                    {/* </FormControl> */}
                    <TextField
                        label="SMTP"
                        style={{ marginBottom: 15 }}
                        placeholder="smtp.gmail.com"
                        onChange={handleHostChange}
                        value={hostname}
                        fullWidth
                        inputProps={{
                            pattern: "[a-z]"
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        label="Port"
                        onChange={handlePortChange}
                        value={port}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </form>}
        />
    )
}

export default AddNewSMTPModal;