import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../enum/routes";
import Table from "../../components/Table";
import moment from "moment";
import { COLUMN_TYPE } from "../../interfaces/table";
import Modal from "../../components/Modal";
import {
  deleteTemplateVersion,
  getAllTemplateVersions,
} from "../../api/email-templates-version";

interface IProps {
  setList: (value: string) => void;
  list: any;
}

const TemplateList: React.FC<IProps> = ({ list, setList }) => {
  const history = useHistory();
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [deleteTemplateId, setDeleteTemplateId] = useState<string>("");
  // const [templateList, setTemplateList] = useState(list);
  const editedTemplate =list.length>0 && list?.map((items: any, index: any) => {
    return {
      ...items,
      updated_at: moment(items?.updated_at).format("YYYY-MM-DD"),
    };
  });

  const EmailTemplateHeader = [
    {
      title: "ID",
      type: COLUMN_TYPE.STRING,
      meta: {
        key: "id",
      },
    },
    {
      title: "Template Name",
      type: COLUMN_TYPE.STRING,
      meta: {
        key: "template_version_name",
      },
    },
    {
      title: "Template Category",
      type: COLUMN_TYPE.STRING,
      meta: {
        key: "template",
        object_key: "template_name",
      },
    },
    {
      title: "Created At",
      type: COLUMN_TYPE.STRING,
      meta: {
        key: "created_at",
      },
    },
    {
      title: "Updated At",
      type: COLUMN_TYPE.STRING,
      meta: {
        key: "updated_at",
      },
    },
    {
      title: "Delete",
      type: COLUMN_TYPE.BUTTON,
      meta: {
        label: "Delete",
        onClick: async ({ history, item, event}: any) => {
          setDeleteTemplateId(item.id);
          setOpenConfirmModal(true);
          event.stopPropagation()
        },
      },
    },
  ];

  const deleteTemplateHandle = async () => {
    await deleteTemplateVersion(deleteTemplateId);
    const response = await getAllTemplateVersions();
    setList(response);
  };
  const openTemplate = (value: any) => {
    history.push({
      pathname: ROUTES.PAGES.TEMPLATES,
      state: { value },
    });
  };

  return (
    <>
      {list?.length > 0 ? (
        <div>
          <div className="total-count">
            Total templates: <span>{list?.length}</span>
          </div>
          <Table
            header={EmailTemplateHeader}
            items={editedTemplate}
            tableName="Email Templates"
            onClickHandler={openTemplate}
          />
          {openConfirmModal && (
            <Modal
              title={"Delete Template"}
              // onComplete={() => onComplete(updateVatField, requestBodyForUpdatingField)}
              confirmButton={<button>Confirm</button>}
              cancelButton={<button>Cancel</button>}
              content={`Are you sure you want to delete this email template?`}
              open={openConfirmModal}
              setOpen={setOpenConfirmModal}
              onComplete={deleteTemplateHandle}
            />
          )}
        </div>
      ) : (
        <div className="no-template-found">
          <Table
            header={EmailTemplateHeader}
            items={editedTemplate}
            tableName="Email Templates"
            // onClickHandler={openUserCard}
          />
          <p>No Templates Available</p>
        </div>
      )}
    </>
  );
};

export default TemplateList;
