import React from "react"
import {IGetSystemErrorsResponse, ISystemErrorStatus} from "../../interfaces/system-errors";
import {Button} from "@material-ui/core";
import Loader from "../../components/Loader";
import {SYSTEM_ERROR_STATUSES} from "../../enum/system-error";
import { useHistory } from "react-router-dom";


interface IProps {
    clickedErrorRowInfo: IGetSystemErrorsResponse,
    onErrorDetailsClose: () => void,
    onUpdateStatus: (id: string, status: ISystemErrorStatus) => Promise<void>
    updateStatusLoading: boolean,
}

const ErrorInfoPage: React.FC<IProps> = (
    {
        clickedErrorRowInfo,
        onErrorDetailsClose,
        updateStatusLoading,
        onUpdateStatus
    }) => {
        const history = useHistory();

    return (
        <>
     
        <div className={"error-info-container details-container"}>
        <div className="back-btn-div">
               <button
                   className="back-btn mt-4"
                   onClick={() =>history.goBack()}
               >
                   Back
               </button> </div>
            {updateStatusLoading && <Loader/>}
            {/* <Button variant="outlined" className="go-back-button" onClick={onErrorDetailsClose}>Go back</Button> */}
            <div className={"info"}>
                <p>ID</p>
                <p>{clickedErrorRowInfo.id}</p>
            </div>
            <div className={"info"}>
                <p>Status</p>
                <p>{clickedErrorRowInfo.status}</p>
            </div>
            <div className={"info"}>
                <p>Status code</p>
                <p>{clickedErrorRowInfo.status_code}</p>
            </div>
            <div className={"info"}>
                <p>Module</p>
                <p>{clickedErrorRowInfo.module}</p>
            </div>
            <div className={"info"}>
                <p>Target</p>
                <p>{clickedErrorRowInfo.target}</p>
            </div>
            <div className={"info"}>
                <p>Timestamp</p>
                <p>{clickedErrorRowInfo.created_at}</p>
            </div>
            <pre>
                {clickedErrorRowInfo.stacktrace}
            </pre>
            {clickedErrorRowInfo?.status === SYSTEM_ERROR_STATUSES.PENDING &&
            <Button
                onClick={async () => await onUpdateStatus(clickedErrorRowInfo.id, SYSTEM_ERROR_STATUSES.RESOLVED)}
                className={"action-btn"}>
                Set as resolved
            </Button>}
        </div></>
    )
}


export default ErrorInfoPage;