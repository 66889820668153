import React, {HTMLAttributes} from 'react';
import "./index.scss"

interface IButton extends HTMLAttributes<HTMLDivElement> {
    name: string,
    onClickHandler?: () => void
}

const Button: React.FC<IButton> = ({name, onClickHandler}) => {
    return (
        <div className="button" onClick={onClickHandler}>
            <label>{name}</label>
        </div>
    )
}

export default Button;