import {useEffect, useState} from "react";
import { IGetSMTPTableInfo } from "../../interfaces/smtp";
import { getSmtpTable } from "../../api/smtp";

export const useSMTPInfo = (triggerRefetch?: boolean) => {
    const [smtpTableInfo, setSmtpTableInfo] = useState<IGetSMTPTableInfo[]>()
    const [smtpLoading, setSmtpLoading] = useState<boolean>(false)

    useEffect(() => {
        (async () => {
            setSmtpLoading(true)
            const response = await getSmtpTable()
            if (response) {
                setSmtpLoading(false)
                setSmtpTableInfo(response)
            }
        })()
    }, [triggerRefetch])

    return {
        smtpTableInfo,
        smtpLoading,
        setSmtpLoading
    }
}