import React from "react";
import {Route, Redirect} from "react-router-dom";
import {RootStateOrAny, useSelector} from "react-redux";
import {RouteProps} from "react-router";
import {ROUTES} from "../enum/routes";

interface IGuardRoute extends RouteProps {
    component: React.FC<any> | React.ComponentClass<any>;
}

const AuthGuardRoute = ({component: Component, ...rest}: IGuardRoute) => {
    const user = useSelector((state: RootStateOrAny) => state.auth?.user?.user);
    return (
        <Route
            {...rest}
            render={(props) =>
                user ? <Component {...props} /> : <Redirect to={ROUTES.AUTH.LOGIN}/>
            }
        />
    );
};
export default AuthGuardRoute;
