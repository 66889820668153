import {useEffect, useState} from "react";
import {CancelledWithingPeriod, IMeetingAnalyticsResponse} from "../../interfaces/meeting";
import {getMeetingAnalyticsByUserId} from "../../api/meetings";


export const useMeetingAnalytics = (userId: number) => {
    const [meetingAnalyticsLoading, setMeetingAnalyticsLoading] = useState<boolean>(false)
    const [meetingAnalytics, setMeetingAnalytics] = useState<IMeetingAnalyticsResponse>()
    const oneDay = 86400;
    const threeDays = 259200

    useEffect(() => {
        (async () => {
            setMeetingAnalyticsLoading(true)
            const response = await getMeetingAnalyticsByUserId(userId)
            if (response) {
                setMeetingAnalytics(response)
                setMeetingAnalyticsLoading(false)
            }
        })()
    }, [userId])

    const getCancelledMeetingWithinPeriodLabelsAndValue = (userAnalyticsArr: CancelledWithingPeriod[] | undefined) => {
        return userAnalyticsArr && userAnalyticsArr?.map((m) => {
            if (+m.period === oneDay) {
                return {label: `<${oneDay / 3600}h`, total: m?.count}
            } else if(+m.period === 0) {
                return {label: `${oneDay / 3600}h < c < ${threeDays / 3600}h`, total: m?.count}
            } else {
                return {label: `> ${threeDays / 3600}h`, total: m?.count}
            }
        })
    }

    return {
        meetingAnalytics,
        meetingAnalyticsLoading,
        getCancelledMeetingWithinPeriodLabelsAndValue
    }
}
