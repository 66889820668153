import { COLUMN_TYPE } from "../../components/Table/column-factory";
import { HeaderItemType } from "../../interfaces/table";
import { ROUTES } from "../../enum/routes";

export const SystemMeetingTableName = "Test Meetings"

export const SystemMeetingsHeader = [
  {
    title: "ID",
    type: COLUMN_TYPE.STRING,
    meta: {
      key: "id",
    },
  },
  {
    title: "Status",
    type: COLUMN_TYPE.STRING,
    meta: {
      key: "status",
    },
  },
  {
    title: "Created At",
    type: COLUMN_TYPE.STRING,
    meta: {
      key: "created_at",
    },
  },
  {
    title: "Updated At",
    type: COLUMN_TYPE.STRING,
    meta: {
      key: "updated_at",
    },
  },
  {
    title: "Test Meeting ID",
    type: COLUMN_TYPE.LINK,
    meta: {
      key: "system_meeting_id",
      onClick: ({ history, item }: any) => {
        history.push({
          pathname: `${ROUTES.PAGES.SYSTEM_MEETING_TO_USERS}`,
          state: {
            system_meeting_id : item.system_meeting_id
          }
        });
      },
    },
  },
  {
    title: "Admin Comment",
    type: COLUMN_TYPE.BUTTON,
    meta: {
      label: "Add",
      onClick: () =>{}
    },
  },
] as HeaderItemType[];
