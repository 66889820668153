import React, {useEffect} from "react";
import {Switch, Route, Redirect, useHistory} from "react-router-dom";
import "./scss/app.scss";
import {ROUTES} from "./enum/routes";
import MainContainer from "./pages/MainContainer";
import Login from "./pages/Login";
import {useCookies} from "./service/auth/cookies";
import {AUTH_KEY} from "./enum/auth";
import {useAuth} from "./service/auth/auth";
import AuthGuardRoute from "./guard/auth-guard";
import "bootstrap/dist/css/bootstrap.min.css";
import {useCookie} from "react-use";

const App: React.FC = () => {
    const {getItem} = useCookies();
    const {authCheck} = useAuth();
    const history = useHistory();
    const [values] = useCookie(AUTH_KEY)

    useEffect(() => {
        (async () => {
            const cookieValue = await getItem(AUTH_KEY);
            cookieValue && await authCheck(() => history.push(ROUTES.PAGES.OVERVIEW));
        })();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="App">
            <Switch>
                {!values && <Route path={ROUTES.AUTH.LOGIN} component={Login} exact/>}
                <AuthGuardRoute path={ROUTES.HOME} component={MainContainer}/>
                <Redirect to={ROUTES.PAGES.USERS}/>
            </Switch>
        </div>
    );
}

export default App;
