import React, {useEffect, useState} from 'react';
import {DataGrid, GridCellEditCommitParams, GridColumns} from '@mui/x-data-grid';
import SuccessOrErrorModal from "../../components/Modal/SuccessOrErrorModal";
import {INFO_MODAL} from "../../enum/successOrErrorModal";
import Modal from "../../components/Modal";
import Loader from "../../components/Loader";
import {useVatInfo} from "../../service/vat/useVatInfo";
import {updateVatField} from "../../api/vat";
import AddIcon from '@material-ui/icons/Add';
import AddNewVatCountryModal from "./Modals/AddNewVatCountry";
import { useHistory } from 'react-router-dom';


const VAT = () => {
    //MODAL WINDOW
    const [openErrorModal, setOpenErrorModal] = useState<boolean>(false)
    const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false)
    const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false)
    const [openValidationErrorModal, setOpenValidationErrorModal] = useState<boolean>(false)
    const [openAddFieldModal, setOpenAddFieldModal] = useState<boolean>(false)
    const [openCountryExistModal, setOpenCountryExistModal] = useState<boolean>(false)
    const [triggerRefetch, setTriggerRefetch] = useState<boolean>(false)
    const {vatTableInfo, vatLoading} = useVatInfo(triggerRefetch)
    const history = useHistory()

    //DEFAULT VALUES
    const defaultRows = [
        {id: 1, country: 'Australia', active: "Yes", rate: 0.1, registration_date: ""},
        {id: 2, country: 'Austria', active: "Yes", rate: 0.1, registration_date: ""},
        {id: 3, country: 'Belgium', active: "Yes", rate: 0.1, registration_date: ""},
        {id: 4, country: 'Australia', active: "Yes", rate: 0.1, registration_date: ""},
    ];
    const [rows, setRows] = useState(defaultRows);

    const columns: GridColumns = [
        {field: 'country', headerName: 'County', editable: false, width: 300},
        {field: 'country_code', headerName: 'County Code', editable: false, width: 300},
        {
            field: 'active',
            headerName: 'VAT Flag',
            editable: true,
            width: 300,
            type: "singleSelect",
            valueOptions: ["Yes", "No"]
        },
        {field: 'rate', headerName: 'VAT Rate', editable: true, width: 300},
        {field: 'national_currency', headerName: 'National currency', editable: false, width: 300},
        {field: 'registration_date', headerName: 'Registration date', editable: false, width: 300},
        // {field: 'last_payment_date', headerName: 'Last payment date', editable: true, width: 300},
    ];

    const [requestBodyForUpdatingField, setRequestObject] = useState<{ id: number, active: boolean, rate: number }>({
        id: 0,
        active: true,
        rate: 0
    })


    useEffect(() => {
        if (vatTableInfo) {
            setRows(vatTableInfo)
        }
    }, [vatTableInfo])

    const detectWhichFieldWasChanged = (field: string, row: any, editedValue: any) => {
        switch (field) {
            case "active":
                return {id: row?.id, active: editedValue === "Yes", rate: row?.rate}
            case "rate":
                return {id: row?.id, active: row?.active === "Yes", rate: editedValue}
            default:
                return {id: row?.id, active: row?.active === "Yes", rate: row?.rate}
        }
    }

    const validateCorrectFieldFormat = (field: string, editedValue: any) => {
        if (field === "rate") {
            return !Number.isNaN(+editedValue)
        } else {
            return true
        }
    }

    const handleCellEditCommit = React.useCallback(
        ({id, value: valueEdit, field}: GridCellEditCommitParams) => {
            const updatedRows = rows?.map((row: any) => {
                if (row.id === id) {
                    if (validateCorrectFieldFormat(field, valueEdit)) {
                        setRequestObject(detectWhichFieldWasChanged(field, row, valueEdit))
                        setOpenConfirmModal(true)
                        return {...row, value: valueEdit};
                    } else {
                        setOpenValidationErrorModal(true)
                        return {...row};
                    }

                }
                return row;
            });
            setRows(updatedRows);
        },
        [rows],
    );

    const onComplete = async (apiRequest: (params?: any) => Promise<any>, requestBody: any) => {
        setTriggerRefetch(true)
        const response = await apiRequest(requestBody)
        if (!response) {
            setOpenErrorModal(true)
            setTriggerRefetch(false)
        } else {
            // setOpenSuccessModal(true)
            setTriggerRefetch(false)
        }
    }

    if (vatLoading) {
        return <Loader/>
    }

    return (
        <>
        <div className="back-btn-div">
               <button
                   className="back-btn mt-2"
                   onClick={() => history.goBack()}
               >
                   Back
               </button> </div>
        <div className={'vat-container'}>
            <DataGrid
                rows={rows}
                columns={columns}
                onCellEditCommit={handleCellEditCommit}
                pagination={undefined}
                autoHeight
                loading={false}
            />
            {openValidationErrorModal && <SuccessOrErrorModal
                title={"Error"}
                type={INFO_MODAL.ERROR}
                button={<button>OK</button>}
                content={'Field value is not valid!'}
                open={openValidationErrorModal}
                setOpen={setOpenValidationErrorModal}
            />}
            {openErrorModal && <SuccessOrErrorModal
                title={"Error"}
                type={INFO_MODAL.ERROR}
                button={<button>OK</button>}
                content={'Something went wrong!'}
                open={openErrorModal} setOpen={setOpenErrorModal}
            />}
            {openCountryExistModal && <SuccessOrErrorModal
                title={"Error"}
                type={INFO_MODAL.ERROR}
                button={<button>OK</button>}
                content={'Country already exist!'}
                open={openCountryExistModal} setOpen={setOpenCountryExistModal}
            />}
            {openSuccessModal && <SuccessOrErrorModal
                title={"Confirm"}
                type={INFO_MODAL.SUCCESS}
                button={<button>Confirm</button>}
                content={`You successfully updated vat field!`}
                open={openSuccessModal} setOpen={setOpenSuccessModal}
            />}
            {openConfirmModal && <Modal
                title={"Confirm"}
                onComplete={() => onComplete(updateVatField, requestBodyForUpdatingField)}
                confirmButton={<button>Confirm</button>}
                content={`Are you sure you want to change this value?`}
                open={openConfirmModal} setOpen={setOpenConfirmModal}
            />}
            {openAddFieldModal &&
            <AddNewVatCountryModal
                open={openAddFieldModal}
                setOpen={setOpenAddFieldModal}
                onComplete={onComplete}
            />}
            <div className={"add-field"} onClick={() => setOpenAddFieldModal(true)}>
                <AddIcon/>
            </div>
        </div></>
    )
}
export default VAT;
