import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "../../scss/app.scss";

interface emailReceieverProps {
    excelData: any;
    fileType: string;
}
const EmailReceiver: React.FC<emailReceieverProps> = ({ excelData , fileType}) => {
    return (
        <div className='container'>
            <h2>Data from {fileType.toUpperCase()} File</h2>
            <table className="table mt-4">
                <tbody>
                    {excelData.map((row: any, rowIndex: any) => (
                        <tr>
                            {/* <th scope="row">{cellIndex}</th> */}
                            <th scope="row">{rowIndex + 1}</th>
                            {Object.values(row).map((cell, cellIndex) => (
                               <>
                               <td key={cellIndex}>{cell}</td></> 
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default EmailReceiver;
