import React from "react";

interface IProps {
  onChange?: (e: React.ChangeEvent<any>) => void;
  onBlur?: (e: React.ChangeEvent<any>) => void;
  value: string;
  type?: string;
  placeholder?: string;
  classInput?: string
}

const Input: React.FC<IProps> = ({ onChange, onBlur, value, type, placeholder, classInput }) => {
  return (
    <div className="input-container">
      <input
        className={`input ${classInput}`}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        placeholder={placeholder}
        type={type}
      />
    </div>
  );
};

export default Input;
