import React, { SetStateAction } from "react";
import { Button, IconButton } from "@material-ui/core";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import { Controlled as CodeMirror } from "react-codemirror2";
import "codemirror/lib/codemirror.css";
import "codemirror/mode/htmlmixed/htmlmixed";
import "codemirror/theme/material.css";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { getSelectedTemplateVersions } from "../../api/email-templates-version";
// import { Delete } from "@material-ui/icons";

interface EditorProps {
  toggleMaximize: () => void;
  html: any;
  handleHtmlChange: (editor: any, data: any, value: any) => Promise<void>;
  isHtmlMaximized: boolean;
  // uploadImage: () => void,
  handleDesktopImageChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selectedDesktopFile?: File;
  setDesktopSelectedFile: React.Dispatch<SetStateAction<any>>;
  handleMobileImageChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selectedMobileFile?: File;
  setMobileSelectedFile: React.Dispatch<SetStateAction<any>>;
  imageTypeSelected?: string;
  removeImage: (value: any) => void;
  templateId:number
}

const HtmlEditorPane: React.FC<EditorProps> = ({
  html,
  handleHtmlChange,
  toggleMaximize,
  isHtmlMaximized,
  handleDesktopImageChange,
  selectedDesktopFile,
  setDesktopSelectedFile,
  handleMobileImageChange,
  selectedMobileFile,
  setMobileSelectedFile,
  imageTypeSelected,
  removeImage,
  templateId
}) => {
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });
  const removeImageHandle = (image_type: string, imageUrl: string) => {
    (async () => {
      const response = await getSelectedTemplateVersions(
        +templateId
        );
      if (response) {
        imageUrl = image_type === "Desktop_Image" ? response?.desktop_image : response?.mobile_image;
        image_type === "Desktop_Image" && setDesktopSelectedFile('');
        image_type === "Mobile_Image" && setMobileSelectedFile('');
        removeImage({ image_type, imageUrl});
      }
    })();
  };
  return (
    // <div className="editor-pane ">
    <div className="card h-100 mt-4">
      <div
        className="card-header align-items-center"
        style={{ display: "grid", gridTemplateColumns: "1fr auto" }}
      >
        <div>
          <h6 className="mb-0">HTML</h6>
        </div>
        <div className="row mt-0 ml-2 d-flex
    justify-contents-center
    align-items-center">
          <div>
            <Button
              size="small"
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
              className="ml-2"
              disabled={!imageTypeSelected}
            >
              Desktop Image
              <VisuallyHiddenInput
                accept="image/*"
                type="file"
                onChange={handleDesktopImageChange}
              />
            </Button>

          </div>

          <div>
            <Button
              size="small"
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
              className="ml-2"
              disabled={imageTypeSelected !== "DUAL_IMAGE"}
            >
              Mobile Image
              <VisuallyHiddenInput
                accept="image/*"
                type="file"
                onChange={handleMobileImageChange}
              />
            </Button>

          </div>
          <div> <IconButton onClick={toggleMaximize}>
              {!isHtmlMaximized ? <FullscreenIcon /> : <FullscreenExitIcon />}
              {/* <CloseFullscreenIcon/> */}
            </IconButton></div>
          <div className="row mt-0 w-100">
            <div className="col-6 col-md-6 col-lg-6">
            {selectedDesktopFile && (
              <div className="image-name-remove-btn">
                <p className="ml-2 mb-0">
                  {selectedDesktopFile?.name?.slice(0, 4)}
                  {`.${selectedDesktopFile?.name?.split(".")?.pop()}`}
                </p>
                <span onClick={() => removeImageHandle("Desktop_Image", "''")} className="pl-2">
                  <HighlightOffIcon />
                </span>
              </div>
            )}
          </div>
          <div className="col-6 col-md-6 col-lg-6">
            {selectedMobileFile && (
              <div className="image-name-remove-btn-mob">
                <p className="ml-2">
                  {" "}
                  {selectedMobileFile?.name?.slice(0, 4)}
                  {`.${selectedMobileFile?.name?.split(".")?.pop()}`}
                </p>
                <span
                  className="cross-btn pl-2"
                  onClick={() => removeImageHandle("Mobile_Image", "''")}
                >
                  <HighlightOffIcon />
                </span>
              </div>
            )}
            </div>
            </div>


               
          {/* <div className='file-selected mb-0'>
           
            {/* {(selectedDesktopFile != null && selectedDesktopFile !== undefined)  && <IconButton onClick={() => setDesktopSelectedFile(null)}> <Delete/>
            </IconButton>} */}
          {/* <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            onClick={handleRemoveFile}
          ></div>  */}
        </div>
      </div>
      <CodeMirror
        value={html}
        options={{
          mode: "htmlmixed",
          theme: "material",
          lineNumbers: true,
          lineWrapping: true,
          viewportMargin: Infinity,
        }}
        onBeforeChange={handleHtmlChange}
      />
    </div>
    //  </div>
  );
};

export default HtmlEditorPane;
