import moment from "moment";
import {GET_FULL_DATE_WITH_TIME} from "../enum/date-formats";

export const patternEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
export const patternPassword = /^.{6,}$/


export function IsJsonString(str: string) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function isArrayOfStrings(arr: any[]) {
    return arr.every((item) => typeof item === "string");
}

export function isArrayOfNumbers(arr: any[]) {
    return arr.every((item) => typeof item === "number");
}

export function isArrayOfDates(arr: any[]) {
    return arr.every((item) => {
        const date = moment(item, GET_FULL_DATE_WITH_TIME)
        return date.isValid()
    })
}

export function isNumber(value: string) {
    return !isNaN(parseFloat(value)) && !isNaN(+value);
}

export function arrayEquals(a: any[], b: any[]) {
    return Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index]);
}

