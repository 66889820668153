import axios from "axios";
import { ICreateSmtpRequestBody, IGetSMTPTableInfo, IUpdateSmtpBody } from "../interfaces/smtp";

export const getSmtpTable = (): Promise<IGetSMTPTableInfo[]> => {
    // export const getSmtpTable = (): Promise<any> => {
    return axios.get(`${process.env.REACT_APP_API_URL}/smtp-mail`)
        .then((response) => response && response.data.smtpMail)
        .catch((error) =>
            error &&
            error.response &&
            error.response.message
                ? {error: error.response.message}
                : {error: 'unknown error'},
        );
}

export const updateSMTP = (data: IUpdateSmtpBody): Promise<IGetSMTPTableInfo[]> => {
    return axios.put(`${process.env.REACT_APP_API_URL}/smtp-mail/update`, data)
        .then((response) => {
            return response.data
        })
        .catch((error) =>
            error &&
            error.response &&
            error.response.message
                ? {error: error.response.message}
                : {error: 'unknown error'},
        );
}

export const createNewSmtp = (data: ICreateSmtpRequestBody) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/smtp-mail/create`, data)
        .then((response) => response && response.data )
        .catch((error) =>
            error &&
            error.response &&
            error.response.message
                ? {error: error.response.message}
                : {error: 'unknown error'},
        );
}

export const deleteSmtpData = (id: number): Promise<any> => {
    return axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_API_URL}/smtp-mail/${id}`
      })
        .then((response: any) => response?.data)
        .catch((error) => error.response?.data);
}