import React, {useCallback, useState} from "react";
import {GridRowData} from "@mui/x-data-grid";
import {DATA_TYPE, ICorrectRowValue} from "./system-variables-table";
import {useRowValueValidation} from "./validate-variable-row";

export const useNumberVariablesControl = (data: GridRowData) => {
    const [value, setValue] = useState<string>(data.value)
    const newVariable: ICorrectRowValue = {
        id: data.id,
        key: data.key,
        value,
        fieldType: data.fieldType
    }

    const {isValid, errorMessage} = useRowValueValidation(newVariable)

    const disabled = data.value === value || !isValid || value === ""

    const onChange = useCallback((event:  React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setValue(event.target.value)
    }, [setValue])

    const renderValueWithAdditionalUnitDependingOnDataType = () => {
        switch (data.dataType) {
            case DATA_TYPE.PERCENTAGE :
                return `${+value * 100}%`
            case DATA_TYPE.MONEY:
                return `${value}$`
            case DATA_TYPE.HOURS:
                return `${value} hours`
            case DATA_TYPE.MINUTES:
                return `${value} minutes`
            case DATA_TYPE.SECONDS:
                return `${+value / 60} minutes`
            default:
                return ""
        }
    }

    return {
        value,
        onChange,
        isValid,
        disabled,
        errorMessage,
        renderValueWithAdditionalUnitDependingOnDataType
    }
}