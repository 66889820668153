import React, {useEffect} from "react";
import {IComplaint, IEventWhichIncludesComplaints} from "../../interfaces/complaints";
import {updateComplaintsFromUnreadToRead} from "../../api/complaint";
import {UNREAD_INDICATOR_DISAPPEAR_DELAY} from "../../enum/unread-indicator-dissapear-time";

export const useUpdateComplaintsFromUnreadToRead = (clickedCardInfo: any, setState: React.Dispatch<React.SetStateAction<any>>) => {
    useEffect(() => {
        (async () => {
            const ids = clickedCardInfo?.complaints?.filter((c: IComplaint) => !c.is_read)?.map((c: IComplaint) => c.id)
            if (ids?.length) {
                setTimeout(async () => {
                    await updateComplaintsFromUnreadToRead(ids)
                    setState(((prevState: IEventWhichIncludesComplaints[]) => {
                        try {
                            const copy = JSON.parse(JSON.stringify(prevState))
                            copy?.forEach((e: IEventWhichIncludesComplaints) => {
                                e?.complaints?.forEach((c) => {
                                    if (ids?.includes(c.id)) {
                                        c.is_read = true
                                    }
                                })
                            })
                            return copy
                        } catch (err) {
                            return
                        }
                    }))
                }, UNREAD_INDICATOR_DISAPPEAR_DELAY)
            }
        })()
        // eslint-disable-next-line
    }, [clickedCardInfo])
}