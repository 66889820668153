import React, {HTMLAttributes} from 'react';
import "./index.scss"

interface IMeetingLink extends HTMLAttributes<HTMLDivElement> {
    meetingLink: string,
    showCopyLink?: boolean,
    onClickHandler?: () => void
}

const MeetingLink: React.FC<IMeetingLink> = ({meetingLink, onClickHandler, showCopyLink}) => {
    return (
        <div className="system-meeting-link-container">
            <div className={`system-meeting-link ${showCopyLink}`}>{meetingLink}</div>
            <div className="system-meeting-link-button">
                {showCopyLink && <label onClick={onClickHandler}>Copy Link</label>}
            </div>
        </div>
    )
}

export default MeetingLink;