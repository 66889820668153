import React, { useEffect, useState } from "react";
import {getDBSBalance, getTWBalance} from "../../api/corporate-accounts";
import { CircularProgress } from "@mui/material";
import {LIST_OF_CURRENCIES} from "../../enum/list-of-currencies";
import {IDBSBalanceResponse} from "../../interfaces/corpotare-accounts";
import { useHistory } from "react-router-dom";

const CorporateAccounts: React.FC = () => {
  const [TWLoading, setTWLoading] = useState<boolean>(false);
  const [DBSLoading, setDBSLoading] = useState<boolean>(false);
  const [TWBalance, setTWBalance] = useState<any>();
  const [dbsBalance, setDbsBalance] = useState<IDBSBalanceResponse>();
  const history = useHistory()

  useEffect(() => {
    (async () => {
      setTWLoading(true);
      const response = await getTWBalance({ currency: LIST_OF_CURRENCIES.USD.code});
      setTWBalance(response?.data);
      response && setTWLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setDBSLoading(true);
      const response = await getDBSBalance();
      setDbsBalance(response?.data);
      response && setDBSLoading(false);
    })();
  }, []);

  const numberWithCommas = (amount: number) =>  {
    return  amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const renderStatistic = (
    title: string,
    statistics: number | undefined,
    currency: string,
  ) => {
    return (
      <div className="info">
        <span>{title}:</span>
        <span>
          {statistics ? numberWithCommas(statistics) : 0}
          {currency}
        </span>
      </div>
    );
  };
  return (
    <>
    <div className="back-btn-div">
           <button
               className="back-btn mt-2"
               onClick={() => history.goBack()}
           >
               Back
           </button> </div>
    <div className="corporate-accounts-page">
      <div className="corporate-accounts-card">
        <div className="balance">
          <h2>Accounts Balance</h2>
            <hr/>
          <h5>DBS balance:</h5>
          <p>
            {renderStatistic("USD", dbsBalance?.dbsUSDBalance, "$")}
            {renderStatistic("SGD", dbsBalance?.dbsSGDBalance, "$")}
            {DBSLoading && <CircularProgress className="corporate-accounts-loader-dbs" />}
          </p>
          <hr />
          <h5>Transferwise balance:</h5>
          <p>
            {renderStatistic("USD", TWBalance?.amount?.value, "$")}
            {TWLoading && <CircularProgress className="corporate-accounts-loader-tw" />}
          </p>
        </div>
      </div>
    </div></>
  );
};
export default CorporateAccounts