import {GridRowData} from "@mui/x-data-grid";
import React, {FormEvent, useCallback, useEffect, useMemo, useState} from "react";
import {DATA_TYPE} from "./system-variables-table";
import {getLanguagesList} from "../../utils/get-languages-list";
import {arrayEquals} from "../../utils/validation";

const AMOUNT_OF_ELEMENTS_TO_SHOW = 17

export const useStringsArrayVariableControl = (data: GridRowData) => {
    const items = useMemo(() => {
        try {
            const parsedItems = JSON.parse(data?.value)
            return parsedItems?.items?.sort((a: string, b: string) => a.localeCompare(b))
        } catch (e) {
            return []
        }
    }, [data])
    const [newItems, setNewItems] = useState<string[]>(items)
    const [newChipValue, setNewChipValue] = useState<string>("")
    const [showMore, setShowMore] = useState<boolean>(false)
    const [error, setError] = useState<string>("")

    useEffect(() => {
        if (!newItems.includes(newChipValue)) setError("")
    }, [newItems, newChipValue])

    const onChange = useCallback((event: React.SyntheticEvent<Element, Event>, value: any) => {
        value ? setNewChipValue(value) : setNewChipValue("")
    }, [setNewChipValue])

    const autoComplete = useMemo(() => {
        switch (data.dataType) {
            case DATA_TYPE.LIST_OF_LANGUAGES:
                return {
                    label: "Languages",
                    options: getLanguagesList()
                }
            default:
                return null
        }
    }, [data.dataType])

    const itemsToShow = useMemo(() => {
        return showMore ? newItems : newItems.slice(0, AMOUNT_OF_ELEMENTS_TO_SHOW)
    }, [showMore, newItems])

    const onDelete = useCallback((chip: string) => {
        setNewItems(((prevState) => prevState.filter((i) => i !== chip)))
    }, [setNewItems])

    const onAdd = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (!!newChipValue.length) {
            const optionAlreadyExist = newItems.includes(newChipValue)
            if (!optionAlreadyExist) {
                setNewItems((prevState) => [...prevState, newChipValue])
                setShowMore(true)
                setNewChipValue("")
                setError("")
            } else {
                setError(`"${newChipValue}" already exists in the list`)
            }
        }
    }

    const toggleShowMore = () => {
        setShowMore(!showMore)
    }

    const disabled = arrayEquals(items, newItems)

    return {
        newChipValue,
        onChange,
        itemsToShow,
        showMoreOrLessLabel: showMore ? "Less..." : "More...",
        toggleShowMore,
        onAdd,
        onDelete,
        disabled,
        autoComplete,
        error,
        items: newItems
    }
}