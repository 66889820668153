export const TICKET_TYPE = {
    INDIVIDUAL_POST_REPORT: "INDIVIDUAL_POST_REPORT",
    INDIVIDUAL_PAID_CONTENT_REPORT: "INDIVIDUAL_PAID_CONTENT_REPORT",
    INDIVIDUAL_USER_REPORT: "INDIVIDUAL_USER_REPORT",
    SUPPORT_FORM: "SUPPORT_FORM",
    FEEDBACK_FORM: "FEEDBACK_FORM",

    REPORT_FORM: "REPORT_FORM"
};

export const TICKET_STATUS = {
    ACTIVE: "ACTIVE",
    RESOLVED: "RESOLVED",
    REJECTED: "REJECTED"
}
