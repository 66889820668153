import { IPagination } from "../interfaces/request"

export const bodyVariablesForTickets = (
        pagination: IPagination, 
        sort: string, 
        sort_by: string, 
        filter: {key: string, ticket_status: string, ticket_type: string},
        totalCountOfPages?: number
    ) => {
    return {
        filter,
        pagination,
        sort_by,
        sort,
        totalCountOfPages
    }
}

export const basicBodyVariables= (
    pagination: IPagination, 
    search_by: string,
    sort_by: string,
    isSuspended?: boolean,

) => {
return {
    pagination,
    search_by,
    sort_by,
    isSuspended,
}
}

export const emailNotificationMangementBodyVariable= (
    pagination: IPagination, 
    search_by: string,
    sort_by: string,
    type: string,
    isSuspended?: boolean
) => {
return {
    pagination,
    search_by,
    sort_by,
    type,
    isSuspended
}
}