import { Props } from "react-select";

const UserContainer: React.FC<Props> = ({ systemMeetingToUsersData }) => {
    return (
        <div className="user-container">
            <h5>Users</h5>
            <div className="user-container-data">
                {systemMeetingToUsersData?.map((meeting: any, index: number) => (
                    <div className="system-meeting-key-value" key={index}>
                        <div className="system-meeting-key">User ID </div>
                        <div className="system-meeting-value">{meeting.user_id}</div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default UserContainer;