import moment from "moment";
import {getDateStringFromUTCAndTImezone} from "./date-string-utc-timezone";
import {GET_DATE_WITH_MONTH_SHORTEN_DAY} from "../enum/date-formats";
import {getTimezoneLabelInAlpha3ByValue} from "./get-time-zones-label";

export type IRetrieveValueType = "string" | "number" | "date" | "timezone-date" | "price" | "user-profile-link"

export const  retrievePropertyValueFromTheObject= (key: string, object: any, valueTypeExpected: IRetrieveValueType = "string", subKey?: string, dateFormat?: string) => {
    let defaultReturnedValue: any
    let value = subKey ? object[key][subKey] : object[key]
    switch (valueTypeExpected) {
        case "string":
            defaultReturnedValue = ""
            break;
        case "number":
            defaultReturnedValue = 0
            break;
        case "price":
            defaultReturnedValue = "0$"
            value = `${object[key]}$`
            break;
        case "date":
            defaultReturnedValue = moment().format()
            break
        case "timezone-date":
            defaultReturnedValue = moment().format()
            const dateInTimezone = getDateStringFromUTCAndTImezone(subKey ? object[key][subKey] : object[key], moment.tz.guess())
            const formattedDate = dateFormat ? moment(dateInTimezone).format(dateFormat) : moment(dateInTimezone).format(GET_DATE_WITH_MONTH_SHORTEN_DAY)
            const timeZoneLabel = getTimezoneLabelInAlpha3ByValue(moment.tz.guess())
            value = `${formattedDate} ${timeZoneLabel}`
            break
        case "user-profile-link":
            defaultReturnedValue = `${process.env.REACT_APP_CHUSP_DOMAIN}/p/users/user/`
            value = `${process.env.REACT_APP_CHUSP_DOMAIN}/p/users/user/${object[key]}`
            break;
        default:
            defaultReturnedValue = ""
            break
    }
    return object[key] ? value : defaultReturnedValue
}