import axios, {AxiosResponse} from "axios";

export const getPopScoreByUser = (userId: string) => {
    return axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/score/pop/${userId}`,
    })
        .then((response: AxiosResponse) => response && response?.data)
        .catch((error) => error.response?.data);
}

export const getSuccessRateByUser = (userId: number) => {
    return axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/score/success/${userId}`,
    })
        .then((response: AxiosResponse) => response && response?.data)
        .catch((error) => error.response?.data);
}
