import {IExtendedUserInfo} from "../../interfaces/user";
import {USER_ROLE} from "../../enum/user";
import {changeUserRoleByAdmin} from "../../api/give-admin-rights";
import {useState} from "react";

export const useChangeUserRole = (user: IExtendedUserInfo | undefined, updateUserList: (user: IExtendedUserInfo, changedUser: IExtendedUserInfo) => void) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false)
    const [openErrorModal, setOpenErrorModal] = useState<boolean>(false)
    const onChangeUserRole = async () => {
        if (user && user?.role !== USER_ROLE.ADU) {
            const userCopy = JSON.parse(JSON.stringify(user))
            userCopy.role = USER_ROLE.ADU
            updateUserList(user, userCopy)
            setLoading(true)
            const response = await changeUserRoleByAdmin(user?.id, {
                role: USER_ROLE.ADU
            })
            response && setLoading(false)
            if (response && response?.data?.isExpected) {
                setOpenSuccessModal(true)
            } else {
                setOpenErrorModal(true)
            }
        }
    }

    return {
        onChangeUserRole,
        loading,
        successModal: {
            open: openSuccessModal,
            setOpen: setOpenSuccessModal
        },
        errorModal: {
            open: openErrorModal,
            setOpen: setOpenErrorModal
        },
        showButton: user && user?.role !== USER_ROLE.ADU
    }
}