import React, {CSSProperties} from "react";
import {Avatar} from "@material-ui/core";
import StarsIcon from "@material-ui/icons/Stars";
import {UserRoleStyles} from "../../../constant/user";
import {useUserComplaintsCount} from "../../../service/user/user-complaints"
import {COMPLAINT_USER_ROLE} from "../../../enum/complaint";
import {blockUser} from "../../../api/user";
import {useHistory} from "react-router-dom"
import {ROUTES} from "../../../enum/routes";
import {useUsersPopScore} from "../../../service/user/popscore";
import {IBasicUserInfo} from "../../../interfaces/user";

interface IProps {
    user: IBasicUserInfo,
    complaintUserRole: typeof COMPLAINT_USER_ROLE.AUTHOR | typeof COMPLAINT_USER_ROLE.RESPONDENT,
    roleInTheMeeting: string
}

const STYLES_TOP_CONTAINER: CSSProperties = {display: "flex"}
const STYLES_AVATAR_CONTAINER: CSSProperties = {display: "flex", flexDirection: "column", alignItems: "center"}
const STYLES_ACTION_BUTTONS: CSSProperties = {display: "flex", flexDirection: "column", alignItems: "center", marginTop: "3%"}
const STYLES_ACTION_BUTTON: CSSProperties = {margin: "2% 0"}
const STYLES_DESCRIPTION_CONTAINER: CSSProperties = {width: "100%", marginLeft: "3%", marginTop: "3%"}
const STYLES_CLICKABLE_PARAGRAPH: CSSProperties = {display: "flex", cursor: "pointer", width: '100%', fontSize: 16}
const STYLES_COMMON_PARAGRAPH: CSSProperties = {display: "flex", alignItems: "center", width: "100%", fontSize: 16}
const STYLES_SVG: CSSProperties = {fontSize: 20}
const STYLES_USER_SPAN: CSSProperties = {marginLeft: "auto", fontSize: 16}
const STYLES_USER_ROLE: CSSProperties = {marginLeft: "auto", fontSize: 16, width: 35, height: 35}

const UserInfo: React.FC<IProps> = ({user, complaintUserRole, roleInTheMeeting}) => {
    const {data} = useUserComplaintsCount(user?.id, {type: complaintUserRole})
    const {totalScore} = useUsersPopScore(user?.id)
    const history = useHistory()
    const suspendUser = async () => {
        const response = await blockUser(user?.id)
        if (response) {
            history.goBack()
        }
    }

    const contactUser = () => {
        history.push({
            pathname: ROUTES.PAGES.EMAIL_SERVICE,
            state: {
                first_name: user?.first_name,
                email: user?.email,
                from: process.env.REACT_APP_SERVICE_EMAIL
            }
        })
    }

    return (
        <div className="info">
            <div style={STYLES_TOP_CONTAINER}>
                <div style={STYLES_AVATAR_CONTAINER}>
                    <Avatar src={user?.public_avatar_url} style={{width: "120px", height: "120px"}}/>
                    <div style={STYLES_ACTION_BUTTONS}>
                        <div style={STYLES_ACTION_BUTTON} className="red-button" onClick={suspendUser}>Suspend</div>
                        <div style={STYLES_ACTION_BUTTON} className="green-button" onClick={contactUser}>Contact</div>
                    </div>
                </div>
                <div style={STYLES_DESCRIPTION_CONTAINER}>
                    <p style={STYLES_CLICKABLE_PARAGRAPH}
                       onClick={() => window.open(`${process.env.REACT_APP_CHUSP_DOMAIN}/p/users/user/${user?.id}`, "_blank")}>
                        Name:
                        <span style={STYLES_USER_SPAN}>{user?.first_name}</span>
                    </p>
                    <p style={STYLES_COMMON_PARAGRAPH}>
                        Email:
                        <span style={STYLES_USER_SPAN}>{user?.email}</span>
                    </p>
                    <p style={STYLES_COMMON_PARAGRAPH}>
                        Role in the meeting:
                        <span style={STYLES_USER_ROLE}
                              className={`${UserRoleStyles[roleInTheMeeting]} role`}>{roleInTheMeeting}</span>
                    </p>
                    <p style={STYLES_COMMON_PARAGRAPH}>
                        Success rate:
                        <span style={STYLES_USER_SPAN}>{totalScore} <StarsIcon style={STYLES_SVG}/></span>
                    </p>
                    <p style={STYLES_COMMON_PARAGRAPH}>
                        Total complaints lodged:
                        <span style={STYLES_USER_SPAN}>{data ? data?.total : "0"}</span>
                    </p>
                    <p style={STYLES_COMMON_PARAGRAPH}>
                        Valid complaints lodged :
                        <span style={STYLES_USER_SPAN}>{data ? data?.accepted : "0"}</span>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default UserInfo;
