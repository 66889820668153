import React, { SetStateAction, useCallback, useState } from "react";
import Modal from "../../components/Modal";
import { TextField } from "@material-ui/core";
import { createEmailTemplate } from "../../api/email-templates";

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  onComplete: any;
}

const CreateTemplate: React.FC<IProps> = ({ open, setOpen, onComplete }) => {
  const [templateName, setTemplateName] = useState<string>("");
  const disableButton = !templateName;

  const handleTemplateNameChange = useCallback(
    (e: any) => {
      e.target.value ? setTemplateName(e.target.value) : setTemplateName("");
    },
    [setTemplateName]
  );
  return (
    <Modal
      open={open}
      setOpen={setOpen}
      // title={"Create New Category"}
      title={"Create New Template"}
      confirmButton={"Create"}
      cancelButton={"Cancel"}
      onComplete={() =>
        onComplete(createEmailTemplate, { template_name: templateName })
      }
      disabled={disableButton}
      content={
        <form className="template-form">
          <TextField
            label="Enter Template Name"
            style={{ marginBottom: 15 }}
            onChange={handleTemplateNameChange}
            value={templateName}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </form>
      }
    />
  );
};
export default CreateTemplate;
