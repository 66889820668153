import React, { useEffect, useRef, useState } from "react";
import "codemirror/lib/codemirror.css";
import "codemirror/mode/htmlmixed/htmlmixed";
import "codemirror/mode/css/css";
import EditIcon from "@material-ui/icons/Edit";
import {
  Button,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  TextField,
  FormControl,
  Select,
  FormHelperText,
} from "@material-ui/core";
// import { FormControl, Select, Stack } from "@mui/material";
import { getAllEmailTemplatesList } from "../../api/email-templates";
import {
  createEmailTemplateVersion,
  getSelectedTemplateVersions,
  updateEmailTemplateVersion,
} from "../../api/email-templates-version";
import SuccessOrErrorModal from "../../components/Modal/SuccessOrErrorModal";
import { INFO_MODAL } from "../../enum/successOrErrorModal";
import { ROUTES } from "../../enum/routes";
import { useHistory } from "react-router-dom";
import HtmlEditorPane from "./htmlEditorPane";
import Preview from "./Preview";
import axios from "axios";
// import ThumbnailGenerator from "../ThumbnailGenerator/ThumbnailGenerator";
import { makeStyles } from "@material-ui/core/styles";
import { Stack } from "@mui/material";
// import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Modal from "../../components/Modal";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
interface MyTemplateId {
  id: number;
  template_name: string;
  value: any;
}
interface templateCategorySelect {
  categoryName: string;
  categoryId: number | undefined;
}

const TemplateEditor = () => {
  const classes = useStyles();
  const [html, setHtml] = useState(`<html><head>
  <style type="text/css" scoped="">
    /* For dynamic background image swapping, either retain this code or utilize provided variables for user-friendly implementation.*/
    :root {
      --background-image-url: url('');
      --mobile-background-image-url: url('');
    }
    body {
      background-image: var(--background-image-url);
     height: 600px;
     background-position: center;
     background-repeat: no-repeat;
     background-size: cover;
     background-attachment: fixed; 
     background-size: 100% 100%;   
  }

  @media (max-width: 768px) {
      body {
          background-image: linear-gradient(rgba(0,0,0, 0.7) 0 100%), var(--mobile-background-image-url);
          height: auto;
          background-attachment: fixed; 
          background-size: 100% 100%;
          background-color: black;
          height: 600px;
      }
    }
  </style>
 </head>
 <body>
 <!-- Begin your HTML code here. -->
</body></html>`);
  const [templateData, setTemplateData] = useState<any>([]);
  const css = "h1 { color: blue; }";
  const [editable, setEditable] = useState<boolean>(false);
  const [tempCategoryList, setTempCategoryList] = useState([]);
  const [templateName, setTemplateName] = useState<string>("");
  const [templateTypeId, setTemplateTypeId] = useState<number>();
  const [templateImageType, setTemplateImageType] = useState<string>();
  const [temlpateEmailHeader, setTemplateEmailHeader] = useState<string>('');

  const [value, setValue] = useState(1);
  const cardRef = useRef(null);
  // const [minHeight, setMinHeight] = useState(700);
  const minHeight = 700;
  const [isHtmlMaximized, setIsHtmlMaximized] = useState(false);
  const [isPreviewMaximized, setIsPreviewMaximized] = useState(false);
  const [selectedTemplateName, setSelectedTemplateName] = useState<string>("");
  const [selectedTemplateEmailHeader, setSelectedTemplateEmailHeader] = useState<string>('');
  const [selectedTemplateCategory, setSelectedTemplateCategory] =
    useState<templateCategorySelect>({
      categoryName: "",
      categoryId: undefined,
    });
  const [selectedTemplateImageType, setSelectedTemplateImageType] =
    useState<string>("");
  // useState<templateCategorySelect>({ categoryName: "", categoryId: undefined });
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedDesktopFile, setDesktopSelectedFile] = useState<File>();
  const [selectedMobileFile, setMobileSelectedFile] = useState<File>();
  const [opacity, setOpacity] = useState<number>(0.1);

  const [isTypeFieldEmpty, setIsTypeFieldEmpty] = useState<boolean>(false);
  const [isImageTypeFieldEmpty, setIsImageTypeFieldEmpty] =
    useState<boolean>(false);
  const [isTempalteNameFieldEmpty, setIsTempalteNameFieldEmpty] =
    useState<boolean>(false);
  const [isImageTypeChange, setIsImageTypeChange] = useState<boolean>(false);
  const [isEmailHeaderEmpty, setIsEmailHeaderEmpty] = useState<boolean>(false);

  const tempImageOptions = [
    { name: "Single Image", value: "SINGLE_IMAGE" },
    { name: "Dual Image", value: "DUAL_IMAGE" },
  ];
  const history = useHistory();
  let templateId: MyTemplateId | undefined = history.location.state as
    | MyTemplateId
    | undefined;

  const handleHtmlChange = async (editor: any, data: any, value: any) => {
    setHtml(value);
  };

  // const handleCssChange = (editor: any, data: any, value: any) => {
  //   setCss(value);
  // };
  const handleImageRemove = (value: any) => {
    const { image_type, imageUrl } = value;
    if(image_type === "Desktop_Image") {
      setTemplateData({
        ...templateData,
        desktop_image: imageUrl
      });
    } else if (image_type === "Mobile_Image"){
      setTemplateData({
        ...templateData,
        mobile_image: imageUrl
      });
    }
    changeBodyBackground(image_type, imageUrl)
  }
  const handleChange = (e: any) => {
    setTemplateTypeId(e.target.value);
    setIsTypeFieldEmpty(false);
  };
  const templateNameHandler = (e: any) => {
    setTemplateName(e.target.value);
    setIsTempalteNameFieldEmpty(false);
  };
  const handleImageTypeChange = (e: any) => {
    // setDesktopSelectedFile(undefined)
    // setMobileSelectedFile(undefined)
    setIsImageTypeChange(true);
    setTemplateImageType(e.target.value);
    setSelectedTemplateImageType(e.target.value);
    setIsImageTypeFieldEmpty(false);
  };
  const emailHeaderHandler = (e: any) => {
    setTemplateEmailHeader(e.target.value);
    setIsEmailHeaderEmpty(false);
  };
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const saveTemplateHandle = async () => {
    if (selectedTemplateImageType === "DUAL_IMAGE") {
      if (!selectedDesktopFile && isImageTypeChange) {
        setOpenErrorModal(true);
        setErrorMessage("Upload desktop background image.");
        return;
      } else if (!selectedMobileFile && isImageTypeChange) {
        setOpenErrorModal(true);
        setErrorMessage("Upload mobile background image.");
        return;
      }
    } else if (
      selectedTemplateImageType === "SINGLE_IMAGE" &&
      !selectedDesktopFile &&
      isImageTypeChange
    ) {
      setOpenErrorModal(true);
      setErrorMessage("Upload desktop background image.");
    }
    if (!!templateTypeId && !!templateImageType && !!templateName && !!temlpateEmailHeader) {
      const response = await createEmailTemplateVersion({
        template_version_name: templateName,
        template_id: templateTypeId,
        template_email_header: temlpateEmailHeader,
        html_content: html,
        image_type: templateImageType,
        mobile_image: templateData?.mobile_image,
        desktop_image: templateData?.desktop_image,
        mobile_opacity: opacity,
        desktop_opacity: opacity
      });

      if (response.message) {
        setOpenSuccessModal(true);
        setSuccessMessage(response.message);
      } else {
        setOpenErrorModal(true);
        setErrorMessage(response.message);
      }
      // } else if (!!templateId && !!templateName && !!temlpateEmailHeader) {

    } 
    // else if (!!selectedTemplateCategory.categoryId && !!selectedTemplateImageType && !!selectedTemplateName && !!selectedTemplateEmailHeader) {
    //   const response = await createEmailTemplateVersion({
    //     template_version_name: selectedTemplateName,
    //     template_id: selectedTemplateCategory.categoryId,
    //     template_email_header: selectedTemplateEmailHeader,
    //     html_content: html,
    //     image_type: selectedTemplateImageType,
    //     mobile_image: templateData?.mobile_image,
    //     desktop_image: templateData?.desktop_image,
    //     mobile_opacity: opacity,
    //     desktop_opacity: opacity
    //   });

    //   if (response.message) {
    //     setOpenSuccessModal(true);
    //     setSuccessMessage(response.message);
    //   } else {
    //     setOpenErrorModal(true);
    //     setErrorMessage(response.message);
    //   }
    // } 
    else if(!editable) {
      !templateTypeId && setIsTypeFieldEmpty(true);
      !templateImageType && setIsImageTypeFieldEmpty(true);
      !templateName && setIsTempalteNameFieldEmpty(true);
      !temlpateEmailHeader && setIsEmailHeaderEmpty(true);
    }
    else if (templateData?.id) {
      const response = await updateEmailTemplateVersion(templateData.id, {
        template_version_name: templateId?.template_name,
        template_id: templateTypeId || templateId?.value?.template_id,
        template_email_header: temlpateEmailHeader || selectedTemplateEmailHeader,
        html_content: html,
        image_type: templateImageType || selectedTemplateImageType,
        thumbnail: templateData?.thumbnail,
        mobile_image: templateData.mobile_image,
        desktop_image: templateData.desktop_image,
        mobile_opacity: opacity || templateData.mobile_opacity,
        desktop_opacity: opacity || templateData.desktop_opacity
      });
      if (response.message) {
        setOpenSuccessModal(true);

        setSuccessMessage(response.message);
      }
    } 
  };

  useEffect(() => {
    (async () => {
      const response = await getAllEmailTemplatesList();
      if (response) {
        setTempCategoryList(response);
      }
    })();

    if (!!templateId?.value?.id) {
      (async () => {
        const response = await getSelectedTemplateVersions(
          +templateId?.value?.id
        );
        if (response) {
          setTemplateData(response);
          setHtml(response.html_content);
          setSelectedTemplateName(response.template_version_name);
          setSelectedTemplateEmailHeader(response.template_email_header)
          setSelectedTemplateImageType(response.image_type);
          // setMobileSelectedFile(response.mobile_image);
          // setDesktopSelectedFile(response.desktop_image);
          setSelectedTemplateCategory({
            categoryName: response.template.template_name,
            categoryId: response.template_id,
          });
        }
      })();
      setEditable(true);
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  const toggleMaximize = () => {
    setIsHtmlMaximized(!isHtmlMaximized);
    setIsPreviewMaximized(false);
  };
  const togglePreviewMaximize = () => {
    setIsPreviewMaximized(!isPreviewMaximized);
    setIsHtmlMaximized(!isHtmlMaximized);
  };
  const handleMobileImageChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!e.target.files) return;
    const file = e?.target?.files[0];
    if (file.type.startsWith("image/")) {
      setMobileSelectedFile(e?.target?.files[0]);
      await uploadImage("Mobile_Image", file);
    } else {
      setOpenErrorModal(true);
      setErrorMessage("Invalid file type. Please select an image.");
      setErrorMessage("Invalid file type. Please select an image.");
    }
  };
  const handleDesktopImageChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!e.target.files) return;
    const file = e?.target?.files[0];
    if (file.type.startsWith("image/")) {
      setDesktopSelectedFile(e?.target?.files[0]);
      await uploadImage("Desktop_Image", file);
    } else {
      setOpenErrorModal(true);
      setErrorMessage("Invalid file type. Please select an image.");
      setErrorMessage("Invalid file type. Please select an image.");
    }
  };
  const uploadContent = (data: FormData) => {
    return axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/template-version/upload`,
      data,
    })
      .then((response: any) => response && response.data)
      .catch((error) =>
        error && error.response && error.response.message
          ? { error: error.response.message }
          : { error: "unknown error" }
      );
  };
  const uploadImage = async (ImageType: string, file: any) => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      const response = await uploadContent(formData);
      if (response) {
        if (ImageType === "Mobile_Image") {
          setTemplateData({
            ...templateData,
            mobile_image: response?.data?.originalFile?.url,
          });
          changeBodyBackground(
            "Mobile_Image",
            response?.data?.originalFile?.url
          );
        } else if (ImageType === "Desktop_Image") {
          setTemplateData({
            ...templateData,
            desktop_image: response?.data?.originalFile?.url,
          });
          changeBodyBackground(
            "Desktop_Image",
            response?.data?.originalFile?.url
          );
        }
        setOpenSuccessModal(true);
        setSuccessMessage("Background Image updated successfully.");
        // changeBodyBackground("")
      } else {
        setOpenErrorModal(true);
        setErrorMessage("Failed to upload image.");
      }
    }
  };
  const checkHeaderExistence = (doc: any) => {
    // Check for the presence of header elements in the document
    const headerElements = doc.querySelectorAll("header");

    // If any header element is found, return true
    if (headerElements.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  // Example usage:

  const changeBodyBackground = (image_type: string, imageUrl: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const body = doc.querySelector("body");

    if (body) {
      const styleTag = doc.querySelector("style");
      // Update the CSS text within the style tag based on the image type
      if (styleTag) {
        let cssText = styleTag.textContent || styleTag.innerText;
        if (image_type === "Desktop_Image") {
          // eslint-disable-next-line
          cssText = cssText.replace(/--background-image-url:\s*url\([^\)]*\);/, `--background-image-url: url(${imageUrl});`);
        } else if (image_type === "Mobile_Image") {
          // eslint-disable-next-line
          cssText = cssText.replace(/--mobile-background-image-url:\s*url\([^\)]*\);/, `--mobile-background-image-url: url(${imageUrl});`);
        }
        // Update the CSS text within the style tag
        styleTag.textContent = cssText;
      }

      // Update the CSS rule for the background image URL based on the image type

      const headerExists = checkHeaderExistence(doc);
      if (headerExists) {
        const imageElement = body?.querySelector("div > img");
        // // Change the src attribute of the image
        if (imageElement) {
          imageElement.setAttribute("src", imageUrl);
        }
      }
      setHtml(doc.documentElement.outerHTML);
    }
  };
  const handleBgOpacityChange = (opacity: number) => {
    setOpacity(opacity);
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const body = doc.querySelector("body");

    if (body) {
      const styleTag = doc.querySelector("style");
      // Update the CSS text within the style tag based on the image type
      if (styleTag) {
        let cssText = styleTag.textContent || styleTag.innerText;

        // Find the existing opacity value in the CSS
        cssText = cssText.replace(/--mobile-background-transparency:\s*linear-gradient\(rgba\(0,0,0,([^)]+)\)\s*0\s*100%\);/,
          `--mobile-background-transparency: linear-gradient(rgba(0,0,0,${opacity}) 0 100%);`);

        // Update the CSS text within the style tag
        styleTag.textContent = cssText;
        setHtml(doc.documentElement.outerHTML);

      }
    }

  }
  return (
    <div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="back-btn-div">
          {/* <Button
            className="back-btn"
            variant="contained"
            onClick={() => history.push(ROUTES.PAGES.TEMPLATE)}
          >
            Back
          </Button> */}
          <button className="back-btn" onClick={() => history.push(ROUTES.PAGES.TEMPLATE)}>Back</button>
        </div>
        {/* <ThumbnailGenerator htmlContent={html} /> */}
        <div className="card w-100">
          <div className="card-header">
            <div className="row mt-0 d-flex justify-content-between">
              {editable ? (
                <div className="col-lg-8 col-md-8 col-sm-8 d-flex">
                  <div className="col-lg-3 col-md-3 col-sm-3 d-flex align-items-center">
                    <FormControl className={classes.formControl}>
                      {" "}
                      <FormLabel component="legend">
                        Template Type:{" "}
                        <b>{selectedTemplateCategory.categoryName}</b>
                      </FormLabel>
                    </FormControl>
                  </div>
                  <div className="col-lg-4 col-md-5 col-sm-5 d-flex align-items-center">
                    <FormControl className={classes.formControl}>
                      {" "}
                      <FormLabel component="legend">
                        Template Name: <b>{selectedTemplateName}</b>
                      </FormLabel>{" "}
                    </FormControl>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-4 d-flex align-items-center">
                    <FormControl className={classes.formControl}>
                      {" "}
                      <FormLabel component="legend">
                        Image Type: <b>  {
                          tempImageOptions.map((imgData: any) => {
                            return imgData.value === selectedTemplateImageType && imgData.name
                          })}</b>
                      </FormLabel>
                    </FormControl>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 d-flex align-items-center">
                    <FormControl className={classes.formControl}>
                      {" "}
                      <FormLabel component="legend">
                        Email Header: <b>{selectedTemplateEmailHeader}</b>
                      </FormLabel>{" "}
                    </FormControl>
                    <IconButton
                      onClick={() => {
                        setTemplateName(selectedTemplateName);
                        setTemplateTypeId(selectedTemplateCategory.categoryId);
                        setTemplateEmailHeader(selectedTemplateEmailHeader)
                        setTemplateImageType(selectedTemplateImageType)
                        setEditable(!editable);
                      }}
                      className="pt-1"
                    >
                      <EditIcon />
                    </IconButton>
                  </div>
                </div>
              ) : (
                <div className="col-lg-8 col-md-8 col-sm-8">
                  <div className="row mt-2">
                    <div className="col-lg-3 col-md-3 col-sm-3">
                      <FormControl
                        fullWidth
                        className={classes.formControl}
                        error={isTypeFieldEmpty}
                      >
                        <InputLabel id="demo-simple-select-label">
                          Template Type
                        </InputLabel>
                        <Select
                          placeholder="Select template type."
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={templateTypeId}
                          label="Template Type"
                          onChange={handleChange}
                        >
                          {/* <MenuItem value={selectedTemplateCategory.categoryId}>{selectedTemplateCategory.categoryName}</MenuItem> */}
                          {tempCategoryList.length > 0 && tempCategoryList?.map((items: any) => {
                            return (
                              <MenuItem value={items.id}>
                                {items.template_name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {isTypeFieldEmpty && (
                          <FormHelperText> Select template type</FormHelperText>
                        )}
                      </FormControl>
                    </div>

                    {/* </div> */}
                    <div className="col-lg-3 col-md-3 col-sm-3">
                      <FormControl fullWidth className={classes.formControl}>
                        <TextField
                          label="Template Name"
                          style={{ marginBottom: 15 }}
                          placeholder="Enter template name."
                          fullWidth
                          value={templateName}
                          onChange={(e) => templateNameHandler(e)}
                          error={isTempalteNameFieldEmpty}
                          // InputLabelProps={{
                          //   shrink: true,
                          // }}
                          helperText={
                            isTempalteNameFieldEmpty && " Enter template name"
                          }
                        />
                      </FormControl>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3">
                      <FormControl
                        fullWidth
                        className={classes.formControl}
                        error={isImageTypeFieldEmpty}
                      >
                        <InputLabel id="demo-simple-select-label">
                          Image Type
                        </InputLabel>
                        <Select
                          placeholder="Select Image type."
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selectedTemplateImageType}
                          label="Image Type"
                          onChange={handleImageTypeChange}
                        >
                          {tempImageOptions?.map((items: any, index) => {
                            return (
                              <MenuItem value={items.value}>
                                {items.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {isImageTypeFieldEmpty && (
                          <FormHelperText>Select image type</FormHelperText>
                        )}
                      </FormControl>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3">
                      <FormControl fullWidth className={classes.formControl}>
                        <TextField
                          label="Email Header"
                          style={{ marginBottom: 15 }}
                          placeholder="Enter email header."
                          fullWidth
                          value={temlpateEmailHeader}
                          onChange={(e) => emailHeaderHandler(e)}
                          error={isEmailHeaderEmpty}
                          // InputLabelProps={{
                          //   shrink: true,
                          // }}
                          helperText={
                            isEmailHeaderEmpty && " Enter email header"
                          }
                        />
                      </FormControl>
                    </div>
                  </div>
                  <div>
                    {/* <div className="col-lg-5 col-md-5 col-sm-5">
                  <FormControl fullWidth className="ml-4">
                    <InputLabel id="demo-simple-select-label">
                      Template background Image
                    </InputLabel>
                    <Select
                      placeholder="Please select template type."
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={templateTypeId}
                      label="Template Type"
                      onChange={handleChange}
                    >
                      {tempImageOptions?.map((items: any) => {
                        return (
                          <MenuItem value={items.id}>
                            {items.template_name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  */}
                  </div>{" "}
                </div>
              )}
              <div className="col-lg-2 col-md-4 col-sm-4 d-flex align-items-center justify-content-end">
                <Stack spacing={2} direction="row" className="float-right">
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ backgroundColor: "#1554f6" }}
                    onClick={saveTemplateHandle}
                  >
                    Save
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => history.push(ROUTES.PAGES.TEMPLATE)}
                  >
                    Cancel
                  </Button>
                </Stack>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="row mt-0">
          {!isHtmlMaximized ? (
            <div className="col-lg-4 col-md-5 col-sm-6" id="html">
              <HtmlEditorPane
                html={html}
                handleHtmlChange={handleHtmlChange}
                toggleMaximize={toggleMaximize}
                isHtmlMaximized={isHtmlMaximized}
                handleDesktopImageChange={handleDesktopImageChange}
                // uploadImage={uploadImage}
                selectedDesktopFile={selectedDesktopFile}
                setDesktopSelectedFile={setDesktopSelectedFile}
                handleMobileImageChange={handleMobileImageChange}
                selectedMobileFile={selectedMobileFile}
                setMobileSelectedFile={setMobileSelectedFile}
                imageTypeSelected={selectedTemplateImageType}
                removeImage={handleImageRemove}
                templateId={+templateId?.value?.id}
              />
            </div>
          ) : (
            <div className="col-lg-12 col-md-12 col-sm-12" id="html">
              <HtmlEditorPane
                html={html}
                handleHtmlChange={handleHtmlChange}
                toggleMaximize={toggleMaximize}
                isHtmlMaximized={isHtmlMaximized}
                handleDesktopImageChange={handleDesktopImageChange}
                // uploadImage={uploadImage}
                selectedDesktopFile={selectedDesktopFile}
                setDesktopSelectedFile={setDesktopSelectedFile}
                handleMobileImageChange={handleMobileImageChange}
                selectedMobileFile={selectedMobileFile}
                setMobileSelectedFile={setMobileSelectedFile}
                imageTypeSelected={selectedTemplateImageType}
                removeImage={handleImageRemove}
                templateId={+templateId?.value?.id}
              />
            </div>
          )}

          {!isPreviewMaximized && !isHtmlMaximized ? (
            <div className="col-lg-8 col-md-7 col-sm-6" id="preview">
              <Preview
                value={"" + value}
                handleTabChange={handleTabChange}
                minHeight={minHeight}
                html={html}
                css={css}
                cardRef={cardRef}
                toggleMaximize={togglePreviewMaximize}
                isPreviewMaximized={isPreviewMaximized}
                optionForOpacity={true}
                handleBgOpacityChange={(e) => handleBgOpacityChange(e)}
                selectedOpacity={templateData.desktop_opacity}
              />
            </div>
          ) : (
            isHtmlMaximized && <div className="col-lg-12 col-md-12 col-sm-12" id="preview">
              {" "}
              <Preview
                value={"" + value}
                handleTabChange={handleTabChange}
                minHeight={minHeight}
                html={html}
                css={css}
                cardRef={cardRef}
                toggleMaximize={togglePreviewMaximize}
                isPreviewMaximized={isPreviewMaximized}
                optionForOpacity={true}
                handleBgOpacityChange={(e) => handleBgOpacityChange(e)}
                selectedOpacity={templateData.mobile_opacity}
              />
            </div>
          )}
        </div>
      </div>
      <Modal
        id="Preview"
        title="Template Preview"
        width="100%"
        disableClose={true}
        confirmButton={"Ok"}
        cancelButton={"Cancel"}
        // content={ 
        // <Preview
        //   showTabList={false}
        //   value={"" + value}
        //   handleTabChange={handleTabChange}
        //   minHeight={minHeight}
        //   html={html}
        //   css={css}
        //   cardRef={cardRef}
        //   toggleMaximize={togglePreviewMaximize}
        //   isPreviewMaximized={isPreviewMaximized}
        // />}
        content={
          <> {"" + value === "" + 1 && <div className="col-lg-12 col-md-12 col-sm-12">
            <div style={{
              border: '1px solid #ccc', padding: '10px',
              // transform: 'scale(0.8)'
            }} className="row d-flex justify-content-center align-items-center">
              <iframe
                id="desktop_iframe"
                srcDoc={`<html><body>${html}</body></html>`}
                title="Preview"
                sandbox="allow-scripts"
                width="100%" height="100%"
              />
            </div>
          </div>}
            {"" + value === "" + 2 && <div className="row d-flex justify-content-center align-items-center">
              <div className="col-lg-6 col-md-6 col-sm-6 d-flex justify-content-center align-items-center" >
                <div style={{
                  border: '1px solid #ccc', padding: '10px',
                }}>
                  <iframe id="mobile_iframe" title="Mobile Preview" srcDoc={`<html><style></style><body>${html}</body></html>`}
                    width="370" height="640px" sandbox="allow-scripts" >
                  </iframe></div></div>
            </div>
            }</>}
        open={isPreviewMaximized} setOpen={togglePreviewMaximize} />
      {openSuccessModal && (
        <SuccessOrErrorModal
          title={"Success"}
          open={openSuccessModal}
          setOpen={setOpenSuccessModal}
          type={INFO_MODAL.SUCCESS}
          // onComplete={() => history.goBack()}
          content={successMessage}
          button={<button>Ok</button>}
        // onActionClicked={() => history.goBack()}
        />
      )}
      {openErrorModal && (
        <SuccessOrErrorModal
          title={"Error"}
          open={openErrorModal}
          setOpen={setOpenErrorModal}
          type={INFO_MODAL.ERROR}
          // onComplete={() => history.goBack()}
          content={errorMessage}
          button={<button>Ok</button>}
        // onActionClicked={() => history.goBack()}
        />
      )}
    </div>
  );
};

export default TemplateEditor;
