import axios from "axios";
import {IRequestUserNewRole} from "../interfaces/user";

export const changeUserRoleByAdmin = (userId: string, data: IRequestUserNewRole) => {
    return axios({
        method: "PUT",
        url: `${process.env.REACT_APP_API_URL}/admin/user/role/${userId}`,
        data,
    })
        .then((response: any) => response?.data)
        .catch((error) => error.response?.data);
};