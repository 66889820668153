import axios from "axios";
import { IEarningsAnalyticsRequestBody } from "../interfaces/analytics";
import { REQUEST_DATE_FORMAT } from "../enum/date-formats";
import { isCorrectFormat } from "../utils/data-prerequest-validation";

export const getEarningsAnalytics = (data: IEarningsAnalyticsRequestBody) => {
  const isValid = isCorrectFormat(data.date_from, REQUEST_DATE_FORMAT);
  if (isValid) {
    return axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/admin/analytics/earnings`,
      data,
    })
      .then((response: any) => response?.data)
      .catch((error) => error.response?.data);
  }
};

export const getSpendingAnalytics = (data: IEarningsAnalyticsRequestBody) => {
  const isValid = isCorrectFormat(data.date_from, REQUEST_DATE_FORMAT);
  if (isValid) {
    return axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/admin/analytics/spending`,
      data,
    })
      .then((response: any) => response?.data)
      .catch((error) => error.response?.data);
  }
};

export const getPayableAndTotalEarnings = () => {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/admin/analytics/earnings/cut-off-point`,
  })
    .then((response: any) => response?.data)
    .catch((error) => error.response?.data);
};