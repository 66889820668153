import React from "react"
import {Button, TextField} from "@mui/material";
import {INewTopicValues} from "../../../service/faq";
import {FORMATS, MODULES} from "../../../constant/react-quill";
import ReactQuill from "react-quill";


interface IProps {
    containerRef: any,
    buttonTitle: string,
    onButtonAction: () => Promise<void>,
    onNewFaqValueChange: (key: string, value: string) => void,
    newFaqValues: INewTopicValues,
    disableAction: boolean
}

const EditAnswerQuestionForm: React.FC<IProps> = (
    {
        containerRef,
        buttonTitle,
        onButtonAction,
        onNewFaqValueChange,
        newFaqValues,
        disableAction
    }) => {
    return (
        <div style={{animation: "opacity 0.3s ease-in-out"}} ref={containerRef}>
            <TextField
                style={{margin: "5px 0", background: "white"}}
                fullWidth
                label={"Question"}
                placeholder={"Enter a new question..."}
                onChange={(e) => onNewFaqValueChange("question", e.target.value)}
                value={newFaqValues["question"]}
                InputLabelProps={{
                    shrink: true,
                }}
                multiline
                minRows={1}
                maxRows={10}
            />
            <ReactQuill
                className={"answer-textfield"}
                placeholder={"Enter a new answer..."}
                theme="snow"
                formats={FORMATS}
                modules={MODULES}
                onChange={(content) => onNewFaqValueChange("answer",content)}
                value={newFaqValues["answer"]}
            />
            <Button
                disabled={disableAction}
                onClick={onButtonAction}
                className={"action-question-btn"}>
                {buttonTitle}
            </Button>
        </div>
    )
}


export default EditAnswerQuestionForm;