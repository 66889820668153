import React, {useRef, useState} from "react"
import {IVerificationDecision, IVerificationRequestObject} from "../../../interfaces/verification-requests";
import {Button} from "@material-ui/core";
import {Avatar} from "@mui/material";
import {VERIFICATION_REQUEST_STATUSES} from "../../../enum/verification-request";
import {makeVerificationRequestDecision} from "../../../api/verification-requests";
import Loader from "../../../components/Loader";
import {useClickAway} from "react-use";
import ReasonForRejectModal from "./reason-for-reject-modal";


interface IProps {
    info: IVerificationRequestObject
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    updateVerificationRequestList: (verificationRequest: IVerificationRequestObject, resolvedVerificationRequest: IVerificationRequestObject) => void
}

const VerificationRequestInfoCard: React.FC<IProps> = ({info, setOpen, updateVerificationRequestList}) => {
    const previewImageRef = useRef<HTMLImageElement>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [passportLoaded, setPassportLoaded] = useState<boolean>(false)
    const [previewImage, setPreviewImage] = useState<boolean>(false)
    const first_name = !!info?.user?.first_name ? info?.user?.first_name : ""
    const last_name = !!info?.user?.last_name ? info?.user?.last_name : ""
    const user_link = `${process.env.REACT_APP_CHUSP_DOMAIN}/p/users/user/${info?.user?.id}`

    const [openReasonForReject, setOpenReasonForReject] = useState<boolean>(false)

    const [reasonForReject, setReasonForReject] = useState<string>("")

    useClickAway(previewImageRef, () => setPreviewImage(false))

    const onMakeDecision = async (decision: IVerificationDecision) => {
        if (!!decision.length) {
            setLoading(true)
            const response = await makeVerificationRequestDecision({status: decision}, info?.id)
            if (response) {
                const copy = JSON.parse(JSON.stringify(info))
                info.status = decision
                updateVerificationRequestList(info, copy)
                setOpen(false)
                setLoading(false)
            }
        }
    }

    const onReasonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setReasonForReject(e.target.value)
    }

    const rejectReasonsModalProps = {
        open: openReasonForReject,
        setOpen: setOpenReasonForReject,
        reason: {
            value: reasonForReject,
            onChange: onReasonChange
        },
        onMakeDecision: onMakeDecision
    }
    return (
        <div className={"details-container"}>
            {loading && <Loader/>}
            <Button variant="outlined" className="go-back-button" onClick={() => setOpen(false)}>Go back</Button>
            <div className={"request-info"}>
                <div className={"user"}>
                    <div className={"user_additional_info"}>
                        <p>Name: <span>{first_name} {last_name}</span></p>
                        <p>Email: <span>{info?.user?.email}</span></p>
                        <p>Profile: <a href={user_link} rel="noreferrer" target={"_blank"}>{user_link}</a></p>
                    </div>
                    <Avatar className={"user_avatar"} src={info.user.public_avatar_url}/>
                </div>
                <div className={"passport-id"}>
                    {passportLoaded && <h2>Passport ID</h2>}
                    <img
                        onClick={() => setPreviewImage(true)}
                        onLoad={() => setPassportLoaded(true)}
                        className={`${!passportLoaded ? "blurred" : ""}`}
                        src={info.document_url}
                        alt={"passport"}
                    />
                </div>
                {passportLoaded && <div className={"decision"}>
                    <button
                        onClick={() => onMakeDecision(VERIFICATION_REQUEST_STATUSES.ACCEPTED)}
                        className={"decision_accept"}>Accept
                    </button>
                    <button
                        onClick={() => onMakeDecision(VERIFICATION_REQUEST_STATUSES.REJECTED_RETAKE_PICTURE)}
                        className={"decision_retake"}>Retake
                    </button>
                    <button
                        onClick={() => setOpenReasonForReject(true)}
                        className={"decision_reject"}>Reject
                    </button>
                </div>}
            </div>
            {previewImage && <div className={"passport_full_screen"}>
                <img ref={previewImageRef} src={info?.document_url} alt={"Passport id"}/>
            </div>}
            {openReasonForReject && <ReasonForRejectModal {...rejectReasonsModalProps}/>}
        </div>
    )
}


export default VerificationRequestInfoCard;