import React from "react";
import {ROUTES} from "../enum/routes";
import GroupIcon from "@material-ui/icons/Group";
import ReportIcon from "@material-ui/icons/Report";
import HelpIcon from '@material-ui/icons/Help';
import FeedbackIcon from '@material-ui/icons/Feedback';
import AssessmentIcon from "@material-ui/icons/Assessment";
import VoiceChatIcon from "@material-ui/icons/VoiceChat";
import SettingsIcon from "@material-ui/icons/Settings";
import DescriptionIcon from '@material-ui/icons/Description';
import PolicyIcon from '@material-ui/icons/Policy';
import SmsIcon from '@material-ui/icons/Sms';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CorporateAccountsIcon from '@material-ui/icons/AccountBalanceWallet';
import VerifiedUserRounded from '@material-ui/icons/VerifiedUserRounded';
import EmailIcon from '@material-ui/icons/Email';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import ErrorIcon from '@material-ui/icons/Error';

const SidebarItems = [
    {
        title: "Users",
        path: ROUTES.PAGES.USERS,
        icon: <GroupIcon/>,
        description: "List of all users"
    },

    {
        title: "Reports",
        path: ROUTES.PAGES.REPORTS,
        icon: <ReportIcon/>,
        description: "Individual user and posts reports"
    },
    {
        title: "Support",
        path: ROUTES.PAGES.SUPPORT,
        icon: <HelpIcon/>,
        description: "Help users in solving platform issues"
    },
    {
        title: "Feedback",
        path: ROUTES.PAGES.FEEDBACK,
        icon: <FeedbackIcon/>,
        description: "Communicate with users"
    },

    {
        title: "Complaints",
        path: ROUTES.PAGES.COMPLAINTS,
        icon: <SmsIcon/>,
        description: "Solving platform disputes"
    },

    {
        title: "Hosting escalation",
        path: ROUTES.PAGES.HOSTING_ESCALATION,
        icon: <SmsIcon/>,
        description: "Hosting escalation. Pre exit complaints"
    },

    {
        title: "Meetings",
        path: ROUTES.PAGES.MEETINGS,
        icon: <VoiceChatIcon/>,
        description: "Monitor meeting statuses"
    },
    
    {
        title: "System Meetings",
        path: ROUTES.PAGES.SYSTEM_MEETINGS,
        icon: <VoiceChatIcon/>,
        description: "Track failed meetings"
    },

    {
        title: "Analytics",
        path: ROUTES.PAGES.ANALYTICS,
        icon: <AssessmentIcon/>,
        description: "Platform analytics"
    },
    {
        title: "Paid Content Sales",
        path: ROUTES.PAGES.PAID_CONTENT_SALES,
        icon: <AssessmentIcon/>,
        description: "Paid Content transaction history"
    },
    {
        title: "DBS Payouts",
        path: ROUTES.PAGES.DBS_TO_WISE_PAYOUT,
        icon: <AssessmentIcon/>,
        description: "DBS to Wise payout history"
    },
    {
        title: "Terms",
        path: ROUTES.PAGES.TERMS,
        icon: <DescriptionIcon/>,
        description: "Platform terms"
    },
    {
        title: "Privacy Policy",
        path: ROUTES.PAGES.POLICY,
        icon: <PolicyIcon/>,
        description: "Platform privacy policies"
    },
    {
        title: "System",
        path: ROUTES.PAGES.SYSTEM_VARIABLE,
        icon: <SettingsIcon/>,
        description: "System control (system variables)"
    },
    {
        title: "VAT",
        path: ROUTES.PAGES.VAT,
        icon: <AccountBalanceIcon/>,
        description: "VAT system control"
    },
    {
        title: "Corporate accounts",
        path: ROUTES.PAGES.CORPORATE_ACCOUNTS,
        icon: <CorporateAccountsIcon/>,
        description: "Monitor corporate accounts balance"
    },
    {
        title: "Verification requests",
        path: ROUTES.PAGES.VERIFICATION_REQUESTS,
        icon: <VerifiedUserRounded/>,
        description: "User verification requests"
    },
    {
        title: "Email Notification Management",
        path: ROUTES.PAGES.EMAIL_NOTIFICATION_MANAGEMENT,
        icon: <EmailIcon/>,
        description: "Email list for email notification"
    },
    {
        title: "Email service",
        path: ROUTES.PAGES.EMAIL_SERVICE,
        icon: <EmailIcon/>,
        description: "Bulk emailing system. Email provider."
    },
    {
        title: "SMTP Configuration",
        path: ROUTES.PAGES.SMTP,
        icon: <EmailIcon/>,
        description: "Add SMTP settings"
    },
    {
        title: "Email Templates",
        path: ROUTES.PAGES.TEMPLATE,
        icon: <EmailIcon/>,
        description: "Create template"
    },
    {
        title: "FAQ",
        path: ROUTES.PAGES.FAQ,
        icon: <LiveHelpIcon/>,
        description: "FAQ. Questions/answers"
    },
    {
        title: "System errors",
        path: ROUTES.PAGES.SYSTEM_ERRORS,
        icon: <ErrorIcon/>,
        description: "Track system errors"
    }
];


export default SidebarItems;
