import {useEffect, useState} from "react";
import {getUserSpending} from "../../api/spendings";
import {IUserSpendingResponse} from "../../interfaces/spendings";

export const useUserSpending = (userId: number) => {
    const [userSpendingLoading, setUserSpendingLoading] = useState<boolean>(false)
    const [spending, setSpending] = useState<IUserSpendingResponse>()

    useEffect(() => {
        (async () => {
            setUserSpendingLoading(true)
            const response = await getUserSpending(userId)
            if (response) {
                setSpending(response?.data)
                setUserSpendingLoading(false)
            }
        })()
    }, [userId])

    return {
        spending,
        userSpendingLoading
    }
}