import axios from "axios";
import {IPagination} from "../interfaces/request";
import {IUserTicketsResponse} from "../interfaces/tickets";

export interface IGetReports {
    filter: { key: string, ticket_type: string, ticket_status: string };
    pagination: IPagination;
    sort_by?: string;
    sort?: string;
}

export const getAllTickets = (data: IGetReports) => {
    return axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/admin/tickets`,
        data,
    })
        .then((response: any) => response?.data)
        .catch((error) => error.response?.data);
};


export const getTicketsByAuthorId = (userId: number): Promise<IUserTicketsResponse> => {
    return axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/admin/ticket/${userId}`,
    })
        .then((response: any) => response?.data)
        .catch((error) => error.response?.data);
};


export const updateTicketStatus = (ticketId: number, resolved: boolean) => {
    return axios({
        method: "PATCH",
        url: `${process.env.REACT_APP_API_URL}/admin/ticket/${ticketId}`,
        data: {resolved}
    })
        .then((response: any) => response?.data)
        .catch((error) => error.response?.data);
}

export const getUnreadTicketsCount = (type: string) => {
    return axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/admin/tickets/unread/count`,
        data: {type}
    })
        .then((response: any) => response?.data)
        .catch((error) => error.response?.data);
}

export const updateTicketsFromUnreadToRead = (ids: string[]) => {
    return axios({
        method: "PUT",
        url: `${process.env.REACT_APP_API_URL}/admin/tickets/read`,
        data: {ids}
    })
        .then((response: any) => response?.data)
        .catch((error) => error.response?.data);
}
